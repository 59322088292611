import logger from './logger'

// https://github.com/electron/electron/issues/2288
export function isElectron(): boolean {
  // Renderer process
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (((window as any)?.process as any)?.type === 'renderer') {
    return true
  }

  // Main process
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!!(process as any)?.versions?.electron) {
    return true
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (navigator?.userAgent?.includes('Electron')) {
    return true
  }

  return false
}

export async function getElectronVersion(): Promise<string | null> {
  try {
    return await window.ipcRenderer?.invoke('electron-app-version')
  } catch (err) {
    logger.warn('Error getting electron version', err)
    return null
  }
}

export async function getElectronPlatform(): Promise<string> {
  try {
    return await window.ipcRenderer?.invoke('which-platform')
  } catch (err) {
    logger.warn('Error getting electron platform', err)
    return 'web'
  }
}

export type ElectronClientInfo = {
  chrome: string
  electron: string
  node: string
  platform: string
  v8: string
  version: string
}
export async function getElectronClientInfo(): Promise<ElectronClientInfo | null> {
  try {
    return await window.ipcRenderer?.invoke('client-info')
  } catch (err) {
    logger.warn('Error getting electron client info', err)
    return null
  }
}
