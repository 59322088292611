import { getVolleyStatus } from 'modules/api/application'
import {
  CAPTIONS_PREFERENCE,
  DEFAULT_PLAYBACK_RATE,
  LAST_TEAM_PREFERENCE,
  PLAYBACK_RATE_PREFERENCE,
  REVIEW_BEFORE_SEND_PREFERENCE,
} from 'modules/constants'
import { readPreference, writePreference } from 'modules/hooks/preferences'
import { MomentType } from 'modules/types/moments'
import { call, delay, put, SagaGenerator, select, spawn, takeLatest } from 'typed-redux-saga'
import { actions as authenticationActions } from '../authentication/slice'
import { selector as  authenticationSelector } from '../authentication/slice'
import { selector as conversationsSelector } from '../conversations/slice'
import { actions as initializeActions } from '../initialize/slice'
import { actions as streamUploadsActions, selector as streamUploadsSelector, RequestStopAction } from '../stream-uploads/slice'
import { actions as tokensActions, ApplyTokenSuccessAction } from '../tokens/slice'
import { actions, MomentFinishedPlayingAction, selector, SetPlaybackAction, SetReviewBeforeSendAction } from './slice'

const VOLLEY_STATUS_DELAY_MS = 10 * 60 * 1000
const VOLLEY_DOWN_STATUS_DELAY_MS = 60 * 1000

export default function*(): SagaGenerator<void> {
  yield* takeLatest(initializeActions.requestVolleyStatus, fetchVolleyStatus)
  yield* takeLatest(initializeActions.requestApp, loadPreferences)
  yield* takeLatest(actions.selectTeam, action => cacheLastTeamId(action.payload.teamId))
  yield* takeLatest(actions.selectConversation, action => cacheLastTeamId(action.payload.teamId))
  yield* takeLatest(actions.selectMoment, action => cacheLastTeamId(action.payload.teamId))
  yield* takeLatest(actions.momentFinishedPlaying, momentFinishedPlaying)
  yield* takeLatest(actions.setPlayback, cachePlaybackPreferences)
  yield* takeLatest(actions.setReviewBeforeSend, cacheReviewPreferences)
  yield* takeLatest(authenticationActions.signOut, handleSignOut)
  yield* takeLatest(tokensActions.applyTokenSuccess, handleApplyTokenSuccess)
  yield* takeLatest(streamUploadsActions.requestStop, handleScreenRecordingStopRequested)
}

export function* fetchVolleyStatus(): SagaGenerator<void> {
  const volleyStatus = yield* call(getVolleyStatus)
  yield* put(actions.fetchVolleyStatusSuccess(volleyStatus))

  yield* spawn(() => refetchVolleyStatus(volleyStatus.isUp))
}

function* refetchVolleyStatus(isVolleyUp: boolean): SagaGenerator<void> {
  yield* delay(isVolleyUp ? VOLLEY_STATUS_DELAY_MS : VOLLEY_DOWN_STATUS_DELAY_MS)
  yield* call(fetchVolleyStatus)
}

export function* cacheLastTeamId(teamId?: string): SagaGenerator<void> {
  
  const appLoggedInState = yield* select(authenticationSelector.appLoggedInState)
  if (!teamId || appLoggedInState !== 'loggedIn') {
    return
  }
  
  writePreference(LAST_TEAM_PREFERENCE, teamId)
}

export function* cachePlaybackPreferences(action: SetPlaybackAction): SagaGenerator<void> {
  const { captions, rate } = action.payload

  if (captions !== undefined) {
    writePreference(CAPTIONS_PREFERENCE, captions)
  }
  if (rate !== undefined) {
    writePreference(PLAYBACK_RATE_PREFERENCE, rate)
  }
}

export function* cacheReviewPreferences(action: SetReviewBeforeSendAction): SagaGenerator<void> {
  const { reviewBeforeSend } = action.payload

  writePreference(REVIEW_BEFORE_SEND_PREFERENCE, reviewBeforeSend)
}

export function* momentFinishedPlaying(action: MomentFinishedPlayingAction): SagaGenerator<void> {
  const { conversationId, momentId } = action.payload

  const conversationsState = yield* select(conversationsSelector.select)
  const convoMoments = conversationsState.fullMoments[conversationId]
  if (!convoMoments) {
    return
  }

  const recentMomentIds = convoMoments.moments.map(rm => rm.momentId)
  const currentMomentIndex = recentMomentIds.indexOf(momentId)

  // Moment deleted?
  if (currentMomentIndex === -1) {
    yield* put(actions.selectConversation({ conversationId }))
  } else if (currentMomentIndex < convoMoments.moments.length - 1) {
    yield* put(actions.selectMoment({ conversationId, momentId: recentMomentIds[currentMomentIndex + 1] }))
  } else {
    yield* put(actions.exitMoment())
  }
}

export function* handleSignOut(): SagaGenerator<void> {
  yield* put(actions.setHasAuthenticatedRoute({ hasAuthenticatedRoute: false }))
  writePreference(LAST_TEAM_PREFERENCE, null)
  
}

export function* handleApplyTokenSuccess(action: ApplyTokenSuccessAction): SagaGenerator<void> {
  const { conversationId, teamId } = action.payload

  if (conversationId) {
    yield* put(actions.selectConversation({ conversationId, teamId }))
  } else if (teamId) {
    yield* put(actions.selectTeam({ teamId }))
  }
}

export function* loadPreferences(): SagaGenerator<void> {
  const captions = readPreference(CAPTIONS_PREFERENCE, false)
  const rate = readPreference(PLAYBACK_RATE_PREFERENCE, DEFAULT_PLAYBACK_RATE)
  const reviewBeforeSend = readPreference(REVIEW_BEFORE_SEND_PREFERENCE, true)
  yield* put(actions.setPlayback({ captions, rate }))
  yield* put(actions.setReviewBeforeSend({ reviewBeforeSend }))
}

export function* handleScreenRecordingStopRequested(action: RequestStopAction): SagaGenerator<void> {
  const { conversationId, teamId, uploadId } = action.payload

  const { uploads } = yield* select(streamUploadsSelector.select)
  if (!uploads[uploadId] || uploads[uploadId].type !== MomentType.Desktop) {
    return
  }

  const { selectedConversationId, selectedTeamId } = yield* select(selector.select)
  if (selectedConversationId === conversationId && selectedTeamId === teamId) {
    return
  }

  yield* put(actions.selectConversation({ conversationId, teamId }))
}
