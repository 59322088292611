import styled from '@emotion/styled'
import { alpha } from '@mui/material'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import React from 'react'

const BORDER_RADIUS = 7

type VolButtonProps = ButtonProps & {
  isLink: boolean
}
const VolButton = styled(MuiButton, { shouldForwardProp: propName => propName !== 'isLink' }) <VolButtonProps>`
  box-shadow: none;
  font-size: 13px;
  line-height: 1.25;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  text-transform: none;
  min-width: ${({ size, variant }) => getMinWidth(variant, size)};

  &.MuiButton-contained {
    background-color: ${({ theme }) => theme.palette.common.white};
    border: none;
    border-radius: ${BORDER_RADIUS}px;
    color: ${({ theme }) => theme.palette.common.black};
    padding: 7px;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.palette.common.black};
      box-shadow: none;
      background-color: ${({ theme }) => alpha(theme.palette.common.black, theme.palette.action.hoverOpacity)};
    }
  }
  &.MuiButton-containedPrimary {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border: none;
    color: ${({ theme }) => theme.palette.common.white};

    &:hover,
    &:active {
      color: #e6e6e6;
      background-color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
  &.MuiButton-containedSecondary {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border: none;
    color: ${({ theme }) => theme.palette.common.white};

    &:hover,
    &:active {
      color: #e6e6e6;
      background-color: ${({ theme }) => theme.palette.secondary.dark};
    }
  }
  &.MuiButton-outlined {
    background-color: ${({ theme }) => theme.palette.common.white};
    border: 1px solid ${({ theme }) => alpha(theme.palette.common.black, 0.1)};
    border-radius: ${BORDER_RADIUS}px;
    color: ${({ theme }) => theme.palette.common.black};
    padding: 7px;
  }
  &.MuiButton-outlinedPrimary {
    color: ${({ theme }) => theme.palette.primary.main};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
  &.MuiButton-outlinedSecondary {
    color: ${({ theme }) => theme.palette.secondary.main};
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  }
  &.MuiButton-text {
    font-weight: ${({ isLink, theme }) => isLink ? theme.typography.fontWeightMedium : theme.typography.fontWeightBold};
    min-width: auto;

    &:hover {
      background-color: ${({ isLink, theme }) => isLink
    ? 'unset'
    : alpha(theme.palette.common.black, theme.palette.action.hoverOpacity)};
      border-radius: ${BORDER_RADIUS}px;
      color: ${({ theme }) => theme.palette.common.black};
    }
  }
  &.MuiButton-textPrimary {
    &:hover {
      background-color: ${({ isLink, theme }) => isLink
    ? 'unset'
    : alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)};
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
  &.MuiButton-textSecondary {
    &:hover {
      background-color: ${({ isLink, theme }) => isLink
    ? 'unset'
    : alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity)};
      color: ${({ theme }) => theme.palette.secondary.dark};
    }
  }
  &.MuiButton-textSizeLarge {
    font-size: 0.9375rem;
    padding: 16px 32px;
  }
  &.MuiButton-sizeLarge {
    border-radius: 9px;
    font-size: 0.9375rem;
    line-height: 2.13;
    padding: 10px 21px;
  }
`

function getMinWidth(variant: ButtonProps['variant'], size?: ButtonProps['size']): string {
  return variant === 'link'
    ? 'auto'
    : `${size === 'large' ? 98 : 62}px`
}

export type ButtonProps = Omit<MuiButtonProps, 'variant'> & {
  target?: string
  rel?: string
  variant?: MuiButtonProps['variant'] | 'link'
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, variant, ...rest } = props

  return (
    <VolButton
      isLink={variant === 'link'}
      ref={ref}
      variant={(variant === 'link' ? 'text' : variant) || 'contained'}
      {...rest}
    >
      {children}
    </VolButton>
  )
})
Button.displayName = 'Button'

export default Button
