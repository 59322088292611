import { Action } from '@reduxjs/toolkit'
import * as api from 'modules/api/moments'
import { DEFAULT_AUDIO_MIME_TYPE, DEFAULT_MIME_TYPE } from 'modules/constants'
import logger from 'modules/logger'
import { actions as alertActions } from 'modules/store/alerts/slice'
import { ConversationType } from 'modules/types/conversations'
import { KnownMimeType, MomentState, MomentType } from 'modules/types/moments'
import { Upload } from 'modules/types/uploads'
import { EventChannel } from 'redux-saga'
import { call, delay, fork, put, race, SagaGenerator, select, take, takeEvery, takeLatest } from 'typed-redux-saga'
import { actions as applicationActions, selector as applicationSelector } from '../../application/slice'
import { selector as conversationsSelector } from '../../conversations/slice'
import { actions as deviceSettingsActions } from '../../device-settings/slice'
import { actions as momentsActions, DeleteMomentAction } from '../../moments/slice'
import {
  actions,
  CancelUploadAction,
  CloseUploadSuccessAction,
  CreateAudioUploadAction,
  CreateDesktopUploadAction,
  CreateFileUploadAction,
  CreateGiphyUploadAction,
  CreateUploadSuccessAction,
  CreateUploadSuccessPayload,
  CreateVideoUploadAction,
  selector,
  UploadFailureAction,
  UploadSegmentAction,
  UploadSegmentPayload,
} from '../slice'
import { getMomentTypeFromMimeType } from '../utils'
import {
  handleEmptyDesktopDataBlob,
  isDesktopBlobTooSmall,
  onScreenRecordingError,
  watchOnScreenRecordingDataElectronEvent,
  watchOnScreenRecordingErrorElectronEvent,
  watchOnScreenRecordingLaunchCanceledElectronEvent,
  watchOnScreenRecordingStartedElectronEvent,
  watchOnScreenRecordingStartingElectronEvent,
  watchOnScreenRecordingStopElectronEvent,
  watchOnScreenRecordingTranscriptionAudioDataElectronEvent
} from './desktop'
import {
  addTranscriptionUploadParams,
  onCloseTranscription,
  onStopTranscriptionRequested,
  onTranscriptionUploadCreated,
} from './transcription'

const UPLOAD_MAX_RETRIES = 3

export default function*(): SagaGenerator<void> {
  yield* takeEvery(actions.createVideoUpload, createVideoUpload)
  yield* takeEvery(actions.createDesktopUpload, createDesktopUpload)
  yield* takeEvery(actions.createAudioUpload, createAudioUpload)
  yield* takeEvery(actions.createFileUpload, createFileUpload)
  yield* takeEvery(actions.createGiphyUpload, createGiphyUpload)
  yield* takeEvery(actions.uploadSegment, uploadStreamedSegment)
  yield* takeEvery(actions.uploadSegmentSuccess, action => finalizeSegmentUpload(action.payload.uploadId))
  yield* takeEvery(actions.cancelUpload, cancelUpload)
  yield* takeEvery(actions.uploadSegmentFailure, handleUploadFailure)
  yield* takeEvery(actions.closeUploadFailure, handleUploadFailure)
  yield* takeEvery(momentsActions.deleteMoment, handleDeleteMoment)
  yield* takeLatest(deviceSettingsActions.selectedDeviceDisconnected, handleSelectedDeviceDisconnected)

  yield* takeEvery(actions.createUploadSuccess, onTranscriptionUploadCreated)
  yield* takeEvery(actions.requestStop, onStopTranscriptionRequested)
  yield* takeEvery(actions.closeUpload, action => finalizeSegmentUpload(action.payload.uploadId))
  yield* takeEvery(actions.closeUploadSuccess, action => onCloseTranscription(action.payload.uploadId))
  yield* takeEvery(actions.cancelUpload, action => onCloseTranscription(action.payload.uploadId))
  yield* takeEvery(actions.transcriptionComplete, action => finalizeSegmentUpload(action.payload.uploadId))
  yield* takeEvery(actions.transcriptionFailed, action => finalizeSegmentUpload(action.payload.uploadId))
  yield* takeEvery(actions.closeUploadSuccess, removeUploadWhenComplete)

  yield* fork(watchOnScreenRecordingStartingElectronEvent)
  yield* fork(watchOnScreenRecordingStartedElectronEvent)
  yield* fork(watchOnScreenRecordingErrorElectronEvent)
  yield* fork(watchOnScreenRecordingDataElectronEvent)
  yield* fork(watchOnScreenRecordingTranscriptionAudioDataElectronEvent)
  yield* fork(watchOnScreenRecordingLaunchCanceledElectronEvent)
  yield* fork(watchOnScreenRecordingStopElectronEvent)
}

/*
  # How to Volley:

  - Store a random ID to identify the new volley. This is because it's possible that segments are going to start coming
    in before we even have a moment record!
  - Create a moment record via API. This comes back with an ID and an S3 URL to start uploading URLs to.
  - Upload segments _directly_ to S3. Once uploaded, tell the API that it was uploaded.
      - If we didn't get an S3 URL in the create API request, we can request one from the API directly.
  - When the final segment has been uploaded, close the moment via API.

  ## Caveats

  - If a user cancels a volley and the moment has been created, delete the moment via API.
  - Some volleys (such as files) aren't created in multiple segments. Those become just one big segment that are
    immediately closed.
*/
export function* createVideoUpload(action: CreateVideoUploadAction): SagaGenerator<void> {
  const { conversationId, reviewBeforeSend, teamId, uploadId } = action.payload
  try {
    yield* createMoment({
      conversationId,
      mimeType: DEFAULT_MIME_TYPE,
      reviewBeforeSend,
      teamId,
      type: MomentType.Video,
      uploadId,
    })
  } catch (error) {
    logger.error('Error creating video upload', error, { ...action.payload })

    yield* put(actions.createUploadFailure({ uploadId }))
    yield* put(alertActions.pushAlert({
      message: 'An error occurred initializing recording. Please try again.',
      severity: 'error',
    }))
  }
}

export function* createDesktopUpload(action: CreateDesktopUploadAction): SagaGenerator<void> {
  const { conversationId, reviewBeforeSend, teamId, uploadId } = action.payload

  try {
    yield* createMoment({
      conversationId,
      keepVideoRes: true,
      mimeType: DEFAULT_MIME_TYPE,
      reviewBeforeSend,
      teamId,
      type: MomentType.Desktop,
      uploadId,
    })
  } catch (error) {
    logger.error('Error creating desktop upload', error, { ...action.payload })

    yield* put(actions.createUploadFailure({ uploadId }))
    yield* call(onScreenRecordingError, 'create-moment-error')
    yield* put(alertActions.pushAlert({
      message: 'An error occurred initializing recording. Please try again.',
      severity: 'error',
    }))
  }
}

export function* createAudioUpload(action: CreateAudioUploadAction): SagaGenerator<void> {
  const { conversationId, reviewBeforeSend, teamId, uploadId } = action.payload

  try {
    yield* createMoment({
      conversationId,
      mimeType: DEFAULT_AUDIO_MIME_TYPE,
      reviewBeforeSend,
      teamId,
      type: MomentType.Audio,
      uploadId,
    })
  } catch (error) {
    logger.error('Error creating audio upload', error, { ...action.payload })

    yield* put(actions.createUploadFailure({ uploadId }))
    yield* put(alertActions.pushAlert({
      message: 'An error occurred initializing recording. Please try again.',
      severity: 'error',
    }))
  }
}

type CreateMomentParams = {
  conversationId: string
  duration?: number
  filename?: string
  keepVideoRes?: boolean
  mimeType: KnownMimeType
  reviewBeforeSend: boolean
  source?: string
  teamId: string
  type: MomentType
  uploadId: string
}
function* createMoment(params: CreateMomentParams): SagaGenerator<void> {
  const {
    conversationId,
    duration,
    filename,
    keepVideoRes,
    mimeType,
    reviewBeforeSend,
    source,
    teamId,
    type,
    uploadId,
  } = params

  const createParams = { conversationId, filename, keepVideoRes, mimeType, source, type }
  const createResult = yield* call(api.createMomentVol, createParams)
  const state = yield* select(selector.select)
  if (state.uploads[uploadId]?.isCanceled) {
    return yield* cancelMoment(uploadId, createResult.id)
  }
  const parentConversationId = yield* getParentConversationId(conversationId)

  let putParams: CreateUploadSuccessPayload = {
    conversationId,
    createdOn: createResult.createdOn,
    duration,
    filename,
    firstSegmentUrl: createResult.uploadUrl,
    mimeType,
    momentId: createResult.id,
    parentConversationId,
    reviewBeforeSend,
    teamId,
    type,
    uploadId,
  }
  putParams = addTranscriptionUploadParams(createResult, putParams)
  yield* put(actions.createUploadSuccess(putParams))
}

function* cancelMoment(uploadId: string, momentId: string): SagaGenerator<void> {
  yield* call(api.cancelMoment, { momentId })
  yield* put(actions.cancelUploadSuccess({ uploadId }))
}

function* getParentConversationId(conversationId: string): SagaGenerator<string | undefined> {
  const { conversations } = yield* select(conversationsSelector.select)
  return conversations[conversationId]?.vType === ConversationType.Thread
    ? conversations[conversationId].parentConversationId ?? undefined
    : undefined
}

export function* createFileUpload(action: CreateFileUploadAction): SagaGenerator<void> {
  const { conversationId, file, teamId, thumbUrl, uploadId } = action.payload
  const mimeType = file.type as KnownMimeType
  const type = getMomentTypeFromMimeType(mimeType)

  try {
    yield* createMoment({
      conversationId,
      duration: type !== MomentType.Movie ? 5 : yield* call(getVideoDurationFromFile, file),
      filename: type === MomentType.File ? file.name : undefined,
      mimeType,
      reviewBeforeSend: false,
      teamId,
      type,
      uploadId,
    })

    yield* put(actions.uploadSegment({
      data: file,
      index: 0,
      isFinalSegment: true,
      thumbUrl,
      uploadId,
    }))
  } catch (error) {
    logger.error('Error creating file moment', error, { ...action.payload })

    yield* put(actions.createUploadFailure({ uploadId }))
    yield* put(alertActions.pushAlert({
      message: 'An error occurred while preparing file to upload. Please try again.',
      severity: 'error',
    }))
  }
}

async function getVideoDurationFromFile(file: File): Promise<number> {
  return new Promise(res => {
    const video = document.createElement('video')
    video.preload = 'metadata'
    video.onloadedmetadata = () => {
      URL.revokeObjectURL(video.src)
      res(video.duration)
    }
    video.src = URL.createObjectURL(file)
  })
}

export function* createGiphyUpload(action: CreateGiphyUploadAction): SagaGenerator<void> {
  const { conversationId, name, teamId, thumbUrl, uploadId, url } = action.payload

  try {
    const filename = `${name}${!name.endsWith('.gif') ? '.gif' : ''}`
    const file = yield* call(api.fetchGiphyFile, url, filename)
    const tierLimit = yield* select(conversationsSelector.currentSubscriptionTier(conversationId))
    const mimeType = file.type as KnownMimeType
    const uploadSizeLimit = tierLimit.fileSize

    if (file.size > uploadSizeLimit.bytes) {
      yield* put(alertActions.pushAlert({
        message: `Gif is too big. Max file size is ${uploadSizeLimit.humanReadable}. Please choose a different one.`,
        severity: 'warning',
      }))
      return
    }

    yield* createMoment({
      conversationId,
      duration: 5,
      filename,
      mimeType,
      reviewBeforeSend: false,
      source: 'GIPHY',
      teamId,
      type: MomentType.Image,
      uploadId,
    })

    yield* put(actions.uploadSegment({
      data: file,
      index: 0,
      isFinalSegment: true,
      thumbUrl,
      uploadId,
    }))
  } catch (error) {
    logger.error('Error creating giphy moment', error, { ...action.payload })

    yield* put(actions.createUploadFailure({ uploadId }))
    yield* put(alertActions.pushAlert({
      message: 'An error occurred while creating the Giphy volley. Please try again.',
      severity: 'error',
    }))
  }
}

function isBlobTooSmall(type: MomentType, size: number): boolean {
  return type === MomentType.Desktop ? isDesktopBlobTooSmall(size) : size === 0
}

export function* uploadStreamedSegment(action: UploadSegmentAction): SagaGenerator<void> {
  const { uploadId } = action.payload

  const state = yield* select(selector.select)
  const upload = state.uploads[uploadId]

  if (!upload) {
    return // upload isIntro or was fully canceled/failed, bail
  }

  let { firstSegmentUrl, momentId } = upload
  if (!momentId) {
    const { success } = yield* race({
      cancel: take((action: Action) => actions.cancelUpload.match(action) && action.payload.uploadId === uploadId),
      failure: take((action: Action) => actions.createUploadFailure.match(action) && action.payload.uploadId === uploadId),
      success: take<CreateUploadSuccessAction>((action: Action) =>
        actions.createUploadSuccess.match(action) && action.payload.uploadId === uploadId),
    })
    if (!success) {
      return
    }

    ({ firstSegmentUrl, momentId } = success.payload)
  }

  yield* race({
    cancel: take((action: Action) => actions.cancelUpload.match(action) && action.payload.uploadId === uploadId),
    upload: call(uploadSegment, { ...upload, ...action.payload, firstSegmentUrl, momentId }),
  })
}

type UploadSegment = Omit<Upload, 'momentId'> & UploadSegmentPayload & {
  momentId: string
}
export function* uploadSegment(params: UploadSegment): SagaGenerator<void> {
  const { data, firstSegmentUrl, index, isCanceled, momentId, type, uploadId } = params
  if (isCanceled) {
    return
  }

  try {
    if (index === 0 && isBlobTooSmall(type, data.size)) {
      return yield* handleEmptyDataPacket(params)
    }

    const url = index === 0 && firstSegmentUrl
      ? firstSegmentUrl
      : yield* call(api.getMomentUploadUrl, { momentId, segmentNumber: index })

    const didUpload = yield* uploadSegmentData({ ...params, url })
    if (!didUpload) {
      return
    }

    const isFinalSegment = !!params.isFinalSegment || !!params.stopRequested?.hardStop
    yield* call(api.onMomentUpload, { isFinalSegment, momentId, segmentNumber: index })
    yield* put(actions.uploadSegmentSuccess({ index, isFinalSegment, uploadId }))
  } catch (error) {
    logger.error('Error uploading streamed segment', error, { index, isFinalSegment: params.isFinalSegment, momentId, stopRequested: params.stopRequested })
    yield* put(actions.uploadSegmentFailure({ uploadId, momentId, type }))
  }
}

function* handleEmptyDataPacket(upload: Upload): SagaGenerator<void> {
  if (upload.type === MomentType.Desktop) {
    yield* call(handleEmptyDesktopDataBlob, upload.id)
  } else {
    yield* put(actions.cancelUpload({ uploadId: upload.id, momentId: upload.momentId }))
    yield* put(alertActions.pushAlert({
      message: `Nothing to record. Is your ${upload.type === MomentType.Audio ? 'microphone' : 'camera'} disconnected?`,
      severity: 'error',
    }))
  }
}

type UploadSegmentData = UploadSegment & {
  url: string
}
function* uploadSegmentData(params: UploadSegmentData): SagaGenerator<boolean> {
  const { data, index, type, uploadId, url } = params

  let attempts = 0
  let hasErrors = false
  let didUpload = false

  while (!didUpload && attempts < UPLOAD_MAX_RETRIES) {
    let uploadChannel: EventChannel<api.ProgressResult | Error> | null = null

    try {
      if (type !== MomentType.File && type !== MomentType.Movie) {
        yield* call(api.uploadSegment, url, data)
        hasErrors = false
        didUpload = true
        break
      }

      uploadChannel = yield* call(api.createUploadSegmentChannel, url, data)
      while (true) {
        const result = yield* take(uploadChannel)
        if (result instanceof Error) {
          throw result
        }

        const { done, loaded, size } = result as api.ProgressResult
        if (done) {
          hasErrors = false
          didUpload = true
          uploadChannel.close()
          break
        }

        yield* put(actions.uploadSegmentProgress({ index, loaded, size, uploadId }))
      }
    } catch (error) {
      hasErrors = true
      logger.warn(`Error uploading segment on attempt ${++attempts}`, error)

      if (attempts < UPLOAD_MAX_RETRIES) {
        yield* delay(3000)
      }
    }

    uploadChannel?.close()
  }

  if (hasErrors) {
    throw new Error(`Failed to upload segment after ${UPLOAD_MAX_RETRIES} retries`)
  }

  return didUpload
}

export function* finalizeSegmentUpload(uploadId: string): SagaGenerator<void> {
  const state = yield* select(selector.select)
  const upload = state.uploads[uploadId]
  if (!upload || !upload.momentId || upload.isCanceled) {
    return
  }

  const areAllSegmentsComplete = upload.segments.every(s => s.isUploaded)
    && upload.segments.some(s => s.isFinalSegment)
    && (!upload.transcription || upload.transcription.isComplete || upload.transcription.isFailed)
  if (areAllSegmentsComplete && (!upload.reviewBeforeSend || upload.closeRequested)) {
    try {
      yield* call(api.closeMoment, {
        momentId: upload.momentId,
        segmentCount: upload.segments.length,
        scheduledDate: upload.scheduledDate,
      })
      yield* put(actions.closeUploadSuccess({ momentId: upload.momentId, uploadId: upload.id }))
      if (upload.scheduledDate) {
        yield* put(alertActions.pushAlert({ message: 'Volley scheduled successfully', severity: 'success' }))
      }
    } catch (error) {
      yield* put(actions.closeUploadFailure({ uploadId: upload.id, momentId: upload.momentId, type: upload.type }))
    }
  }
}

export function* cancelUpload(action: CancelUploadAction): SagaGenerator<void> {
  try {
    for (let i = 0; i < 20; i++) {
      const state = yield* select(selector.select)
      const uploadState = state.uploads[action.payload.uploadId]
      const momentId = action.payload.momentId || uploadState?.momentId

      if (momentId) {
        yield* cancelMoment(action.payload.uploadId, momentId)
        break
      } else if (!momentId && !uploadState) {
        break
      } else {
        yield* delay(250)
      }
    }
  } catch (error) {
    logger.error('Error canceling upload', error, { momentId: action.payload.momentId })
    yield* put(alertActions.pushAlert({
      message: 'There was an error canceling your volley. You may need to refresh or restart to delete it manually.',
      severity: 'error',
    }))
  }
}

export function* handleUploadFailure(action: UploadFailureAction): SagaGenerator<void> {
  if (action.payload.type === MomentType.Desktop) {
    yield* call(onScreenRecordingError, 'upload-segment-error')
  }

  yield* put(actions.cancelUpload({ uploadId: action.payload.uploadId, momentId: action.payload.momentId }))
  yield* put(alertActions.pushAlert({
    severity: 'error',
    message: 'An error occurred while uploading. Please try again.'
  }))
}

export function* handleDeleteMoment(action: DeleteMomentAction): SagaGenerator<void> {
  const { momentId, type } = action.payload
  if (type === MomentType.Text) {
    return
  }

  const state = yield* select(selector.select)
  const upload = Object.values(state.uploads).find(u => u.momentId === momentId)

  if (upload) {
    yield* put(actions.cancelUploadSuccess({ uploadId: upload.id }))
  }
}

export function* removeUploadWhenComplete(action: CloseUploadSuccessAction): SagaGenerator<void> {
  const { momentId, scheduledDate, uploadId } = action.payload

  if (!scheduledDate) {
    const { sleep } = yield* race({
      completed: take((action: Action) =>
        (momentsActions.refetchMomentsSuccess.match(action) || momentsActions.fetchMomentsSuccess.match(action))
        && action.payload.moments[momentId]
        && action.payload.moments[momentId].vState === MomentState.Complete),
      sleep: delay(10 * 60 * 1000),
    })
    if (sleep) {
      logger.warn('Removing upload before moment is complete', { momentId, uploadId })
    }

    const { selectedMomentId } = yield* select(applicationSelector.select)
    if (selectedMomentId === momentId) {
      yield* race([
        take(applicationActions.exitMoment),
        take(applicationActions.selectTeam),
        take(applicationActions.selectConversation),
        take((action: Action) => applicationActions.selectMoment.match(action) && action.payload.momentId !== momentId),
      ])
    }

    yield* delay(2000)
  }

  yield* put(actions.removeUpload({ uploadId }))
}

export function* handleSelectedDeviceDisconnected(): SagaGenerator<void> {
  const { uploads } = yield* select(selector.select)

  for (const upload of Object.values(uploads)) {
    if (!upload.stopRequested && !upload.isCanceled) {
      yield* put(actions.requestStop({
        conversationId: upload.conversationId,
        hardStop: true,
        reviewBeforeSend: true,
        teamId: upload.teamId,
        uploadId: upload.id,
      }))
    }
  }
}
