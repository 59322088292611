import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { MomentType } from 'modules/types/moments'
import { CreateUploadPayload, StreamUploadsState } from '.'

type CreateDesktopUploadPayload = CreateUploadPayload & { noTranscription?: boolean, reviewBeforeSend: boolean }
export type CreateDesktopUploadAction = PayloadAction<CreateDesktopUploadPayload>
export const createDesktopUpload: CaseReducer<StreamUploadsState, CreateDesktopUploadAction> = (state, action) => ({
  ...state,
  uploads: {
    ...state.uploads,
    [action.payload.uploadId]: {
      conversationId: action.payload.conversationId,
      createdAt: new Date().getTime(),
      id: action.payload.uploadId,
      reviewBeforeSend: action.payload.reviewBeforeSend,
      segments: [],
      teamId: action.payload.teamId,
      transcription: !action.payload.noTranscription
        ? { audioSegments: [], isComplete: false, transcribedSegments: [] }
        : undefined,
      type: MomentType.Desktop,
    }
  }
})

export const prepareToRecordScreen: CaseReducer<StreamUploadsState, PayloadAction> = (state, _) => ({
  ...state,
  isPreparingToRecordScreen: true
})
export const prepareToRecordScreenCanceled: CaseReducer<StreamUploadsState, PayloadAction> = (state, _) => ({
  ...state,
  isPreparingToRecordScreen: false
})
type RecordScreenCountdownFinishedAction = PayloadAction<{ uploadId: string }>
export const recordScreenCountdownFinished: CaseReducer<StreamUploadsState, RecordScreenCountdownFinishedAction> = (state, action) => ({
  ...state,
  isPreparingToRecordScreen: false,
  uploads: {
    ...state.uploads,
    [action.payload.uploadId]: {
      ...state.uploads[action.payload.uploadId],
      createdAt: new Date().getTime(),
    }
  }
})
