import { getElectronPlatform, getElectronVersion, isElectron } from 'modules/electron-utils'
import logger from 'modules/logger'
import { AuthProvider } from 'modules/types/auth'
import { ConversationType } from 'modules/types/conversations'
import { KnownMimeType, MomentType } from 'modules/types/moments'
import { TokenType } from 'modules/types/tokens'
const { version } = require('../../../version.json')
import firebase from '../firebase'
import segment, { initialize } from './segment'

export {
  initialize as initializeSegment,
  segment,
}

type VolleyEvent = {
  conversationId: string
  conversationType: ConversationType
  momentId: string
  teamId: string
  userId: string
  volleyType: MomentType
}
type VolleyViewEvent = VolleyEvent & {
  isAnonymous: boolean
}
export function emitVolleyViewEvent({ conversationId, momentId, teamId, userId, ...rest }: VolleyViewEvent): void {
  void sendEvent('volleyView', {
    ...rest,
    conversationID: conversationId,
    momentID: momentId,
    teamID: teamId,
    userID: userId,
  })
}

type VolleyCreateEvent = VolleyEvent & {
  duration: number
}
export function emitVolleyCreateEvent({ conversationId, momentId, teamId, userId, ...rest }: VolleyCreateEvent): void {
  void sendEvent('volleyCreate', {
    ...rest,
    conversationID: conversationId,
    momentID: momentId,
    teamID: teamId,
    userID: userId,
  })
}

type VolleyCancelEvent = Omit<VolleyEvent, 'momentId'> & {
  momentId?: string
}
export function emitVolleyCancelEvent({ conversationId, momentId, teamId, userId, ...rest }: VolleyCancelEvent): void {
  void sendEvent('volleyCancel', {
    ...rest,
    conversationID: conversationId,
    momentID: momentId,
    teamID: teamId,
    userID: userId,
  })
}

export function emitVolleyDeleteEvent({ conversationId, momentId, teamId, userId, ...rest }: VolleyEvent): void {
  void sendEvent('volleyDelete', {
    ...rest,
    conversationID: conversationId,
    momentID: momentId,
    teamID: teamId,
    userID: userId,
  })
}

export type TeamJoinEvent = {
  inviterId: string
  teamId: string
  tokenId: string
  tokenType: TokenType
  userId: string
}
export function emitTeamJoinEvent({ inviterId, teamId, tokenId, userId, ...rest }: TeamJoinEvent): void {
  void sendEvent('teamJoin', { ...rest, inviterID: inviterId, teamID: teamId, tokenID: tokenId, userID: userId })
}

type TeamCreateEvent = {
  teamId: string
  userId: string
}
export function emitTeamCreateEvent({ teamId, userId }: TeamCreateEvent): void {
  void sendEvent('teamCreate', { teamID: teamId, userID: userId })
}

export type ConversationJoinEvent = {
  conversationId: string
  conversationType: ConversationType
  inviterId: string
  teamId: string
  tokenId: string
  tokenType: TokenType
  userId: string
}
export function emitConversationJoinEvent(eventData: ConversationJoinEvent): void {
  const { conversationId, inviterId, teamId, tokenId, userId, ...rest } = eventData

  void sendEvent('conversationJoin', {
    ...rest,
    conversationID: conversationId,
    inviterID: inviterId,
    teamID: teamId,
    tokenID: tokenId,
    userID: userId,
  })
}

export type ConversationEvent = {
  conversationId: string
  conversationType: ConversationType
  teamId: string
  userId: string
}
export function emitConversationCreateEvent({ conversationId, teamId, userId, ...rest }: ConversationEvent): void {
  void sendEvent('conversationCreate', {
    ...rest,
    conversationID: conversationId,
    teamID: teamId,
    userID: userId,
  })
}

export type SignInEvent = {
  authProvider: AuthProvider
  userId: string
}
export function emitSignIn({ authProvider, userId }: SignInEvent): void {
  void sendEvent('signIn', { authProvider, userID: userId })
}

export type AccountCreateEvent = {
  authProvider: AuthProvider
  inviterId?: string
  promotedFromVisitor: boolean
  tokenId?: string
  tokenType?: TokenType
  userId: string
}
export function emitAccountCreateEvent({ inviterId, tokenId, userId, ...rest }: AccountCreateEvent): void {
  void sendEvent('accountCreate', {
    ...rest,
    inviterID: inviterId,
    tokenID: tokenId,
    userID: userId,
  })
}

type MarkForFollowUpEvent = {
  userId: string
  volleyType: MomentType
}
export function emitMarkForFollowUpCreateEvent({ userId, volleyType }: MarkForFollowUpEvent): void {
  void sendEvent('markForFollowUpCreate', { userID: userId, volleyType })
}

export function emitMarkForFollowUpViewEvent({ userId, volleyType }: MarkForFollowUpEvent): void {
  void sendEvent('markForFollowUpView', { userID: userId, volleyType })
}

export type SearchOpenEvent = {
  teamId: string
  userId: string
}
export function emitConversationSearchOpenEvent({ teamId, userId }: SearchOpenEvent): void {
  void sendEvent('conversationSearchOpen', { teamID: teamId, userID: userId })
}

export type SearchResultViewEvent = {
  conversationId: string
  teamId: string
  userId: string
}
export function emitConversationSearchResultViewEvent({ conversationId, teamId, userId }: SearchResultViewEvent): void {
  void sendEvent('conversationSearchResultView', { conversationID: conversationId, teamID: teamId, userID: userId })
}

type ChannelEvent = {
  conversationId: string
  teamId: string
  userId: string
}
export function emitChannelPromoteConversationEvent({ conversationId, teamId, userId }: ChannelEvent): void {
  void sendEvent('channelPromoteConversation', { conversationID: conversationId, teamID: teamId, userID: userId })
}

type ConversationAddUserEvent = ConversationEvent & {
  addedUserId: string
}
export function emitConversationAddUserEvent(eventData: ConversationAddUserEvent): void {
  const { addedUserId, conversationId, teamId, userId, ...rest } = eventData

  void sendEvent('conversationAddUser', {
    ...rest,
    addedUserID: addedUserId,
    conversationID: conversationId,
    teamID: teamId,
    userID: userId,
  })
}

export function emitConversationLeaveEvent({ conversationId, teamId, userId, ...rest }: ConversationEvent): void {
  void sendEvent('conversationLeave', {
    ...rest,
    conversationID: conversationId,
    teamID: teamId,
    userID: userId,
  })
}

export function emitChannelDeleteEvent({ conversationId, teamId, userId }: ChannelEvent): void {
  void sendEvent('channelDelete', { conversationID: conversationId, teamID: teamId, userID: userId })
}

type TalkTomeOnVolleyEvent = {
  userId: string
}
export function emitTalkToMeOnVolleyIntroCreateEvent({ userId }: TalkTomeOnVolleyEvent): void {
  void sendEvent('ttmovIntroCreate', { userID: userId })
}

export function emitTalkToMeOnVolleyLinkDisableEvent({ userId }: TalkTomeOnVolleyEvent): void {
  void sendEvent('ttmovLinkDisable', { userID: userId })
}

export function emitTalkToMeOnVolleyLinkEnableEvent({ userId }: TalkTomeOnVolleyEvent): void {
  void sendEvent('ttmovLinkEnable', { userID: userId })
}

export function emitTalkToMeOnVolleyPreviewPageViewEvent({ userId }: TalkTomeOnVolleyEvent): void {
  void sendEvent('ttmovPreviewPageView', { userID: userId })
}

export function emitTalkToMeOnVolleyEmbedLinkCopyEvent({ userId }: TalkTomeOnVolleyEvent): void {
  void sendEvent('ttmovEmbedLinkCopy', { userID: userId })
}

type SlackEvent = {
  teamId: string
  userId: string
}
export function emitSlackConnectRequestEvent({ teamId, userId }: SlackEvent): void {
  void sendEvent('slackConnectRequest', { teamID: teamId, userID: userId })
}

type SlackChannelEvent = SlackEvent & {
  conversationId: string
}
export function emitSlackChannelLinkedEvent({ conversationId, teamId, userId }: SlackChannelEvent): void {
  void sendEvent('slackChannelLinked', { conversationID: conversationId, teamID: teamId, userID: userId })
}

export function emitSlackChannelUnlinkedEvent({ conversationId, teamId, userId }: SlackChannelEvent): void {
  void sendEvent('slackChannelUnlinked', { conversationID: conversationId, teamID: teamId, userID: userId })
}

type ChecklistType = 'createTeam' | 'joinTeam' | 'default'
export type ScreenViewType = 'consult' | 'grow' | ChecklistType
export type ScreenViewEvent = {
  conversationId?: string
  fromTeamJoin?: boolean
  isAnonymous: boolean
  isOnboarding?: boolean
  name: string
  offerId?: string
  teamId?: string
  type?: ScreenViewType
  userId: string
}
export function emitScreenViewEvent({ conversationId, offerId, teamId, userId, ...rest }: ScreenViewEvent): void {
  void sendEvent('screenView', {
    ...rest,
    conversationID: conversationId,
    offerID: offerId,
    teamID: teamId,
    userID: userId,
  })
}

export type ButtonPressEvent = {
  authProvider?: AuthProvider
  conversationId?: string
  conversationType?: ConversationType
  isAnonymous: boolean
  location?: 'moreButton' | 'timeline' | 'viewer'
  mentionedUserId?: string
  momentId?: string
  name: string
  offerId?: string
  teamId?: string
  type?: string
  userId: string
  volleyType?: MomentType
}
export function emitButtonPressEvent(eventData: ButtonPressEvent): void {
  const { conversationId, mentionedUserId, momentId, teamId, userId, ...rest } = eventData

  void sendEvent('buttonPress', {
    ...rest,
    conversationID: conversationId,
    mentionedUserID: mentionedUserId,
    momentID: momentId,
    teamID: teamId,
    userID: userId,
  })
}

export type VideoViewEvent = {
  duration: number
  fromTeamJoin?: boolean
  isAnonymous: boolean
  name: string
  teamId?: string
  userId: string
}
export function emitVideoViewEvent(eventData: VideoViewEvent): void {
  const { teamId, userId, ...rest } = eventData

  void sendEvent('videoView', {
    ...rest,
    teamID: teamId,
    userID: userId,
  })
}

export type DeepLinkType =
  | 'consultWithMe'
  | 'conversation'
  | 'followups'
  | 'helloVolley'
  | 'moment'
  | 'talk'
  | 'talkToMe'
  | 'team'
  | 'teamChannels'
  | 'teamDirectory'
  | 'teamInvite'
  | 'token'
export type DeepLinkActivatedEvent = {
  anonId?: string
  conversationId?: string
  inviterId?: string
  isAnonymous: boolean
  momentId?: string
  teamId?: string
  tokenType?: TokenType
  type: DeepLinkType
  userId: string
  utmCampaign?: string
  utmMedium?: string
  utmSource?: string
}
export function emitDeepLinkActivatedEvent(eventData: DeepLinkActivatedEvent): void {
  const { anonId, conversationId, inviterId, momentId, teamId, userId, ...rest } = eventData

  void sendEvent('deepLinkActivated', {
    ...rest,
    anonID: anonId,
    conversationID: conversationId,
    inviterID: inviterId,
    momentID: momentId,
    teamID: teamId,
    userID: userId,
  })
}

export type VolleyPublicLinkSentEvent = {
  conversationId: string
  momentId: string
  shareDestination: string
  teamId: string
  userId: string
  volleyType: MomentType
}
export function emitVolleyPublicLinkSent(eventData: VolleyPublicLinkSentEvent): void {
  const { conversationId, momentId, teamId, userId, ...rest } = eventData

  void sendEvent('volleyPublicLinkSent', {
    ...rest,
    conversationID: conversationId,
    momentID: momentId,
    teamID: teamId,
    userID: userId,
  })
}

export type VolleyOverSizeLimitEvent = {
  conversationId: string
  mimeType: KnownMimeType
  size: number
  teamId: string
  userId: string
  volleyType: MomentType
}
export function emitVolleyOverSizeLimit(eventData: VolleyOverSizeLimitEvent): void {
  const { conversationId, size, teamId, userId, ...rest } = eventData

  void sendEvent('volleyOverSizeLimit', {
    ...rest,
    conversationId,
    sizeInMB: size,
    teamID: teamId,
    userID: userId,
  })
}

export type InviteSentEvent = {
  conversationId?: string
  shareDestination: string
  teamId: string
  tokenId: string
  tokenType: TokenType
  userId: string
}
export function emitInviteSent(eventData: InviteSentEvent): void {
  const { conversationId, teamId, tokenId, userId, ...rest } = eventData

  void sendEvent('inviteSent', {
    ...rest,
    conversationID: conversationId,
    teamID: teamId,
    tokenID: tokenId,
    userID: userId,
  })
}

export type ConversationPromoteToGroupEvent = {
  conversationId: string
  teamId: string
  userId: string
}
export function emitConversationPromoteToGroup(eventData: ConversationPromoteToGroupEvent): void {
  const { conversationId, teamId, userId } = eventData

  void sendEvent('conversationPromoteToGroup', { conversationID: conversationId, teamID: teamId, userID: userId })
}

export type VolleyCopyTranscriptEvent = {
  conversationId: string
  momentId: string
  teamId: string
  userId: string
  volleyType: MomentType
}
export function emitVolleyCopyTranscript(eventData: VolleyCopyTranscriptEvent): void {
  const { conversationId, momentId, teamId, userId, ...rest } = eventData

  void sendEvent('volleyCopyTranscript', {
    ...rest,
    conversationID: conversationId,
    momentID: momentId,
    teamID: teamId,
    userID: userId,
  })
}

type VolleyTriggerDownloadEmailEvent = {
  conversationId: string
  conversationType: ConversationType
  momentId: string
  teamId: string
  userId: string
  volleyType: MomentType
}
export function emitVolleyTriggerDownloadEmailEvent(eventData: VolleyTriggerDownloadEmailEvent): void {
  const { conversationId, momentId, teamId, userId, ...rest } = eventData

  void sendEvent('volleyTriggerDownloadEmail', {
    ...rest,
    conversationID: conversationId,
    momentID: momentId,
    teamID: teamId,
    userID: userId,
  })
}

type ChannelPermissionsEvent = ChannelEvent & {
  allowedPosterCount: number
  restrictPosting: boolean
}
export function emitRemovePosterFromConversation(eventData: ChannelPermissionsEvent): void {
  const { conversationId, teamId, userId, ...rest } = eventData

  void sendEvent('removePosterFromConversation', {
    ...rest,
    conversationID: conversationId,
    teamID: teamId,
    userID: userId,
  })
}
export function emitAddPosterToConversation(eventData: ChannelPermissionsEvent): void {
  const { conversationId, teamId, userId, ...rest } = eventData

  void sendEvent('addPosterToConversation', {
    ...rest,
    conversationID: conversationId,
    teamID: teamId,
    userID: userId,
  })
}
export function emitChangeRestrictPosting(eventData: ChannelPermissionsEvent): void {
  const { conversationId, teamId, userId, ...rest } = eventData

  void sendEvent('changeRestrictPosting', {
    ...rest,
    conversationID: conversationId,
    teamID: teamId,
    userID: userId,
  })
}

export type EmbeddedTextVideoViewEvent = {
  finished: boolean
  momentId: string
  teamId: string
  userId: string
  video: string
}
export function emitEmbeddedTextVideoViewEvent(eventData: EmbeddedTextVideoViewEvent): void {
  const { momentId, teamId, userId, ...rest } = eventData

  void sendEvent('embeddedTextVideoView', { ...rest, momentID: momentId, teamID: teamId, userID: userId })
}

type GiphyEvent = {
  userId: string
}
export function emitGiphyCanceled({ userId }: GiphyEvent): void {
  void sendEvent('giphyCancelled', { userID: userId })
}
export function emitGiphySelected({ userId }: GiphyEvent): void {
  void sendEvent('giphySelected', { userID: userId })
}

let currentPlatform: string
let desktopVersion: string | null
async function sendEvent<T>(eventName: string, eventData: T) {
  if (!currentPlatform) {
    await getPlatform()
  }
  const dataToSend = {
    ...eventData,
    platform: currentPlatform,
    volleyVersion: desktopVersion ?? version
  }
  try {
    firebase.analytics.logEvent(eventName, dataToSend)
    segment()?.track(eventName, dataToSend)
  } catch (error) {
    logger.info('Error sending analytics event', error, { eventName, eventData: dataToSend })
  }
}

async function getPlatform(): Promise<void> {
  if (isElectron()) {
    ([currentPlatform, desktopVersion] = await Promise.all([
      getElectronPlatform(),
      getElectronVersion(),
    ]))
  } else {
    currentPlatform = 'web'
    desktopVersion = null
  }
}
