// mks 2021-01-27 Seeing a lot of error about "TypeError: undefined is not a
// function" coming from the inside the Amplify.configure call (below). That line reads:
//
// Object.entries(this._modules).forEach(function (_a) {
//
// So there's not a ton there that's likely to fail. This is an attempted
// polyfill for if Object.entries is missing. Granted, the app won't run - but
// it's a question of failing more gracefully. If we can boot the app, we can
// show the "not supported" dialog probably.
//
// This polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
if (!Object.entries) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.entries = function (obj: any) {
    const ownProps = Object.keys(obj)
    let i = ownProps.length
    const resArray = new Array(i) // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}
