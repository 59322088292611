import { configureStore, isPlain } from '@reduxjs/toolkit'
import config from 'modules/config'
import logger from 'modules/logger'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import appReducer from './reducers'
import rootSaga from './sagas'
export * from './actions'
export * from './selectors'

const onError = (error: Error, errorInfo: { sagaStack: string }): void => {
  logger.error('Uncaught saga error', error, errorInfo)

  throw error
}

const sagaMiddleware = createSagaMiddleware({
  onError
})

const shouldShowReduxLogging = () => config.env !== 'prod'

const reduxLogger = createLogger({
  predicate: shouldShowReduxLogging,
  collapsed: true
})

const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      isSerializable: (value: unknown) => isPlain(value) || value instanceof Blob
    }
  })
    .prepend(reduxLogger)
    .concat(sagaMiddleware),
  reducer: appReducer,
})

sagaMiddleware.run(rootSaga)

export default store
