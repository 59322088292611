import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConversationType } from 'modules/types/conversations'
import {
  SearchConversationDictionary,
  SearchExpert,
  SearchIndexItem,
  SearchUser,
  UserSearchIndex
} from 'modules/types/search'
import { AppState } from '../reducers'

type SearchResults<T> = {
  cachedAt: number
  nextPage: number | null
  results: T
}
type ResultsDictionary<T> = { [search: string]: SearchResults<T> }
export type SearchState = {
  cachedConversationResults: ResultsDictionary<SearchConversationDictionary>
  cachedExpertResults: ResultsDictionary<SearchExpert[]>
  cachedUserResults: ResultsDictionary<SearchUser[]>
  error: string | null
  index: UserSearchIndex
  initialized: boolean
  isLoading: boolean
  isOpen: boolean
  isFocused: boolean
}

const initialState: SearchState = {
  cachedConversationResults: {},
  cachedExpertResults: {},
  cachedUserResults: {},
  error: null,
  index: {
    createdAt: null,
    items: [],
  },
  initialized: false,
  isFocused: false,
  isLoading: false,
  isOpen: false,
}

const fetchUserSearchIndex: CaseReducer<SearchState, PayloadAction> = (state, _action) => ({
  ...state,
  isLoading: true,
})

export type FetchUserSearchIndexSuccessAction = PayloadAction<UserSearchIndex>
const fetchUserSearchIndexSuccess: CaseReducer<SearchState, FetchUserSearchIndexSuccessAction> = (state, action) => ({
  ...state,
  error: null,
  index: action.payload,
  initialized: true,
  isLoading: false,
})

type FetchUserSearchIndexFailureAction = PayloadAction<{ error: string }>
const fetchUserSearchIndexFailure: CaseReducer<SearchState, FetchUserSearchIndexFailureAction> = (state, action) => ({
  ...state,
  initialized: true,
  isLoading: false,
  error: action.payload.error
})

type SetSearchFocusAction = PayloadAction<{ isFocused: boolean }>
const setSearchFocus: CaseReducer<SearchState, SetSearchFocusAction> = (state, action) => ({
  ...state,
  isFocused: action.payload.isFocused,
})
const toggleSearch: CaseReducer<SearchState, PayloadAction<{ isOpen: boolean }>> = (state, action) => ({
  ...state,
  isOpen: action.payload.isOpen,
})

type UpdateUserSearchIndexSuccessAction = PayloadAction<{ items: SearchIndexItem[] }>
const updateUserSearchIndexSuccess: CaseReducer<SearchState, UpdateUserSearchIndexSuccessAction> = (state, action) => ({
  ...state,
  index: {
    ...state.index,
    items: action.payload.items,
  },
})

export type SearchExpertsAction = PayloadAction<{ page: number, text: string }>
export type SearchConversationsAction = PayloadAction<{ teamId?: string, text: string, type?: ConversationType }>
export type SearchUsersAction = PayloadAction<{ shouldIncludeGuests?: boolean, teamId?: string, text: string }>
type SearchSuccessAction<T> = PayloadAction<{ key: string, nextPage: number | null, results: T }>
const searchConversationsSuccess: CaseReducer<SearchState, SearchSuccessAction<SearchConversationDictionary>> = (state, action) => ({
  ...state,
  cachedConversationResults: {
    ...state.cachedConversationResults,
    [action.payload.key]: {
      cachedAt: new Date().getTime(),
      nextPage: action.payload.nextPage,
      results: action.payload.results,
    },
  },
})
const searchExpertsSuccess: CaseReducer<SearchState, SearchSuccessAction<SearchExpert[]>> = (state, action) => ({
  ...state,
  cachedExpertResults: {
    ...state.cachedExpertResults,
    [action.payload.key]: {
      cachedAt: new Date().getTime(),
      nextPage: action.payload.nextPage,
      results: action.payload.results,
    },
  },
})
const searchUsersSuccess: CaseReducer<SearchState, SearchSuccessAction<SearchUser[]>> = (state, action) => ({
  ...state,
  cachedUserResults: {
    ...state.cachedUserResults,
    [action.payload.key]: {
      cachedAt: new Date().getTime(),
      nextPage: action.payload.nextPage,
      results: action.payload.results,
    },
  },
})

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    fetchUserSearchIndex,
    fetchUserSearchIndexSuccess,
    fetchUserSearchIndexFailure,

    searchExperts: (state: SearchState, _: SearchExpertsAction) => state,
    searchExpertsSuccess,
    searchConversations: (state: SearchState, _: SearchConversationsAction) => state,
    searchConversationsSuccess,
    searchUsers: (state: SearchState, _: SearchUsersAction) => state,
    searchUsersSuccess,

    setSearchFocus,
    toggleSearch,

    updateUserSearchIndexSuccess,
  },
})

export const actions = searchSlice.actions
export const selector = {
  name: searchSlice.name,
  select: (state: AppState): SearchState => state.search,
  isStale: (state: AppState): boolean => {
    return state.search.initialized && state.search.index.createdAt && state.users.currentUser?.searchIndexCreatedAt
      ? state.search.index.createdAt < state.users.currentUser.searchIndexCreatedAt
      : false
  }
}
export default searchSlice.reducer
