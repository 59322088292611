import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  AccountSettingsModalProps,
  ConfirmDialogProps,
  ConversationDetailsModalProps,
  ForwardToModalProps,
  MomentDetailsModalProps,
  TalkToMeOnVolleyModalProps,
  TeamIntroModalProps,
  TeamSettingsModalProps,
  TextVolleyModalProps,
  UpdateAvailableModalProps,
} from 'modules/types/modals'
import { AppState } from 'modules/store/reducers'

export type ModalsState =
  | ConfirmDialogState
  | ConversationDetailsState
  | ForwardToState
  | MomentDetailsState
  | RecordingLimitReachedModalState
  | SettingsModalState
  | TalkToMeOnVolleyModalState
  | TextVolleyModalState
  | UpdateAvailableState
  | WorkspaceIntroModalState
  | WorkspaceSettingsModalState
  | OtherModalState

type ConfirmDialogState = Omit<ConfirmDialogProps, 'onConfirm'> & { modal: 'confirm' }
type ConversationDetailsState = ConversationDetailsModalProps & { modal: 'conversation-details' }
type ForwardToState = ForwardToModalProps & { modal: 'forward-to' }
type MomentDetailsState = MomentDetailsModalProps & { modal: 'moment-details' }
type RecordingLimitReachedModalState = { content?: string, limitInMinutes: number, modal: 'recording-limit-reached' }
type SettingsModalState = AccountSettingsModalProps & { modal: 'settings' }
type TalkToMeOnVolleyModalState = TalkToMeOnVolleyModalProps & { modal: 'talk-to-me-on-volley' }
type TextVolleyModalState = TextVolleyModalProps & { modal: 'text-volley' }
type WorkspaceIntroModalState = TeamIntroModalProps & { modal: 'team-intro' }
type WorkspaceSettingsModalState = TeamSettingsModalProps & { modal: 'team-settings' }
type UpdateAvailableState = UpdateAvailableModalProps & { modal: 'update-available' }
type OtherModalState = {
  modal: 'keyboard-shortcuts' | 'other' | 'team-create' | 'unsupported' | null
}

export const initialState: ModalsState = {
  modal: null,
}

const modalsSlice = createSlice({
  name: 'modals',
  initialState: initialState as ModalsState,
  reducers: {
    close: (_state, _action: PayloadAction) => ({ modal: null }),
    showConfirmDialog: (_state, action: PayloadAction<Omit<ConfirmDialogProps, 'onConfirm'>>) => ({
      modal: 'confirm',
      ...action.payload,
    }),
    showConversationDetails: (_state, action: PayloadAction<ConversationDetailsModalProps>) => ({
      modal: 'conversation-details',
      ...action.payload,
    }),
    showForwardTo: (_state, action: PayloadAction<ForwardToModalProps>) => ({
      modal: 'forward-to',
      ...action.payload,
    }),
    showKeyboardShortcuts: (_state, _action: PayloadAction) => ({ modal: 'keyboard-shortcuts' }),
    showMomentDetails: (_state, action: PayloadAction<MomentDetailsModalProps>) => ({
      modal: 'moment-details',
      ...action.payload,
    }),
    showOther: (_state, _action: PayloadAction) => ({ modal: 'other' }),
    showRecordingLimitReached: (_state, action: PayloadAction<{ content?: string, limitInMinutes: number }>) => ({
      modal: 'recording-limit-reached',
      ...action.payload,
    }),
    showSettings: (_state, action: PayloadAction<AccountSettingsModalProps | undefined>) => ({
      modal: 'settings',
      ...action.payload,
    }),
    showTalkToMeOnVolley: (_state, action: PayloadAction<TalkToMeOnVolleyModalProps | undefined>) => ({
      modal: 'talk-to-me-on-volley',
      ...action.payload,
    }),
    showTeamCreate: (_state, _action: PayloadAction) => ({ modal: 'team-create' }),
    showTeamIntro: (_state, action: PayloadAction<TeamIntroModalProps>) => ({
      modal: 'team-intro',
      ...action.payload,
    }),
    showTeamSettings: (_state, action: PayloadAction<TeamSettingsModalProps>) => ({
      modal: 'team-settings',
      ...action.payload,
    }),
    showTextVolley: (_state, action: PayloadAction<TextVolleyModalProps>) => ({
      modal: 'text-volley',
      ...action.payload,
    }),
    showUnsupported: (_state, _action: PayloadAction) => ({ modal: 'unsupported' }),
    showUpdateAvailable: (_state, action: PayloadAction<UpdateAvailableModalProps>) => ({
      modal: 'update-available',
      ...action.payload,
    }),
  },
})

export const actions = modalsSlice.actions
export const selector = {
  name: modalsSlice.name,
  select: (appState: AppState): ModalsState => appState.modals,
  isOpen: (appState: AppState): boolean => !!appState.modals.modal,
}
export default modalsSlice.reducer
