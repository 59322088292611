import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Token, TokenKeyResult, TokenParams, TokenResult, TokenState, TokenType } from 'modules/types/tokens'
import { ConversationType } from 'modules/types/conversations'
import { AppState } from '../reducers'
import { getTokenKey } from './utils'

type IsWorkingDictionary = { [tokenId: string]: boolean }
type TokenKeyDictionary = { [tokenKey: string]: TokenKeyResult }
type TokensDictionary = {
  [tokenId: string]: TokenResult
}
type TokensState = {
  appliedToken: Token | null
  createdTokens: TokenKeyDictionary
  isApplying: IsWorkingDictionary
  isFetching: IsWorkingDictionary
  isTogglingTtmov: boolean
  isUpdating: IsWorkingDictionary
  tokens: TokensDictionary
}

const initialState: TokensState = {
  appliedToken: null,
  createdTokens: {},
  isApplying: {},
  isFetching: {},
  isUpdating: {},
  isTogglingTtmov: false,
  tokens: {},
}

export type FetchTokenAction = PayloadAction<{ id: string }>
const fetchToken: CaseReducer<TokensState, FetchTokenAction> = (state, action) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    [action.payload.id]: true,
  },
})

const isWorkingReduceFn = (dict: IsWorkingDictionary, id: string) => {
  dict[id] = true
  return dict
}

type FetchTokenFailureAction = PayloadAction<{ error: string, id: string }>
const fetchTokenFailure: CaseReducer<TokensState, FetchTokenFailureAction> = (state, action) => ({
  ...state,
  isFetching: Object.keys(state.isFetching)
    .filter(id => id !== action.payload.id)
    .reduce(isWorkingReduceFn, {}),
  tokens: {
    ...state.tokens,
    [action.payload.id]: {
      error: action.payload.error,
      id: action.payload.id,
      token: null,
    },
  },
})

type FetchTokenSuccessAction = PayloadAction<Token>
const fetchTokenSuccess: CaseReducer<TokensState, FetchTokenSuccessAction> = (state, action) => ({
  ...state,
  isFetching: Object.keys(state.isFetching)
    .filter(id => id !== action.payload.id)
    .reduce(isWorkingReduceFn, {}),
  tokens: {
    ...state.tokens,
    [action.payload.id]: {
      error: null,
      id: action.payload.id,
      token: action.payload,
    },
  },
})

export type UpdateTokenPayload = { disable: boolean, id: string }
export type UpdateTokenAction = PayloadAction<UpdateTokenPayload>
const updateToken: CaseReducer<TokensState, UpdateTokenAction> = (state, action) => ({
  ...state,
  isUpdating: {
    ...state.isFetching,
    [action.payload.id]: true,
  },
})
export type UpdateTokenSuccessAction = PayloadAction<{ id: string, type: TokenType | null }>
const updateTokenSuccess: CaseReducer<TokensState, UpdateTokenSuccessAction> = (state, action) => ({
  ...state,
  isUpdating: Object.keys(state.isUpdating)
    .filter(id => id !== action.payload.id)
    .reduce(isWorkingReduceFn, {}),
  tokens: Object.entries(state.tokens).reduce<TokensDictionary>((acc, [tokenId, token]) => {
    if (tokenId === action.payload.id) {
      acc[tokenId] = {
        ...token,
        token: token.token ? { ...token.token, state: TokenState.Disabled } : null,
      }
    } else {
      acc[tokenId] = token
    }

    return acc
  }, {}),
})
type UpdateTokenFailureAction = PayloadAction<{ error: string, id: string }>
const updateTokenFailure: CaseReducer<TokensState, UpdateTokenFailureAction> = (state, action) => ({
  ...state,
  isUpdating: Object.keys(state.isUpdating)
    .filter(id => id !== action.payload.id)
    .reduce(isWorkingReduceFn, {}),
  tokens: {
    ...state.tokens,
    [action.payload.id]: {
      ...state.tokens[action.payload.id],
      error: action.payload.error,
    },
  },
})

export type CreateTokenAction = PayloadAction<TokenParams>
const createToken: CaseReducer<TokensState, CreateTokenAction> = (state, action) => ({
  ...state,
  createdTokens: {
    ...state.createdTokens,
    [getTokenKey(action.payload)]: 'pending',
  },
})

export type CreateTokenFailureAction = PayloadAction<{ error: string, params: TokenParams }>
const createTokenFailure: CaseReducer<TokensState, CreateTokenFailureAction> = (state, action) => ({
  ...state,
  createdTokens: {
    ...state.createdTokens,
    [getTokenKey(action.payload.params)]: 'error',
  },
  tokens: {
    ...state.tokens,
    ['error']: {
      error: action.payload.error,
      id: 'error',
      token: null,
    },
  },
})

export type CreateTokenSuccessAction = PayloadAction<{ params: TokenParams, token: Token }>
const createTokenSuccess: CaseReducer<TokensState, CreateTokenSuccessAction> = (state, action) => ({
  ...state,
  createdTokens: {
    ...state.createdTokens,
    [getTokenKey(action.payload.params)]: action.payload.token.id,
  },
  tokens: {
    ...state.tokens,
    [action.payload.token.id]: {
      error: null,
      id: action.payload.token.id,
      token: action.payload.token,
    },
  },
})

export type ApplyTokenAction = PayloadAction<{ id: string }>
const applyToken: CaseReducer<TokensState, ApplyTokenAction> = (state, action) => ({
  ...state,
  isApplying: {
    ...state.isApplying,
    [action.payload.id]: true,
  },
})

type ApplyTokenFailureAction = PayloadAction<{ error: string, id: string }>
const applyTokenFailure: CaseReducer<TokensState, ApplyTokenFailureAction> = (state, action) => ({
  ...state,
  isApplying: Object.keys(state.isApplying)
    .filter(id => id !== action.payload.id)
    .reduce(isWorkingReduceFn, {}),
})

export type ApplyTokenSuccessAction = PayloadAction<{ conversationId?: string, teamId?: string, token: Token }>
const applyTokenSuccess: CaseReducer<TokensState, ApplyTokenSuccessAction> = (state, action) => ({
  ...state,
  appliedToken: action.payload.token,
  isApplying: Object.keys(state.isApplying)
    .filter(id => id !== action.payload.token.id)
    .reduce(isWorkingReduceFn, {}),
})

export type SendEmailInviteAction = PayloadAction<{ teamName: string, url: string }>

export type ToggleTtmovAction = PayloadAction<{ conversationId: string, type: ConversationType }>
const toggleTtmov: CaseReducer<TokensState, ToggleTtmovAction> = (state, _) => ({
  ...state,
  isTogglingTtmov: true,
})
export type ToggleTtmovSuccessAction = PayloadAction<{ conversationId: string, tokenId: string | null }>
const toggleTtmovSuccess: CaseReducer<TokensState, ToggleTtmovSuccessAction> = (state, _) => ({
  ...state,
  isTogglingTtmov: false,
})

const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    applyToken,
    applyTokenFailure,
    applyTokenSuccess,

    createToken,
    createTokenFailure,
    createTokenSuccess,

    fetchToken,
    fetchTokenFailure,
    fetchTokenSuccess,

    sendEmailInvite: (state, _: SendEmailInviteAction) => state,

    toggleTtmov,
    toggleTtmovSuccess,

    updateToken,
    updateTokenFailure,
    updateTokenSuccess,
  }
})

export const actions = tokensSlice.actions
export const selector = {
  name: tokensSlice.name,
  select: (appState: AppState): TokensState => appState.tokens,
}
export default tokensSlice.reducer
