import * as localStorageFacade from 'modules/local-storage'
import { VOLLEY_PREFERENCE_PREFIX } from 'modules/constants'
import { useCallback, useState } from 'react'

function toLocalStorageKey(key: string): string {
  return `${VOLLEY_PREFERENCE_PREFIX}${key}`
}

export function writePreference<T>(key: string, value: T | null): void {
  const fullKey = toLocalStorageKey(key)

  if (value == null) {
    localStorageFacade.removeItem(fullKey)
    return
  }

  localStorageFacade.setItem(fullKey, JSON.stringify(value))
}

export function readPreference<T>(key: string, defaultValue: T, oldKey?: string): T {
  const fullKey = toLocalStorageKey(key)
  let value = localStorageFacade.getItem(fullKey)

  if (!value && oldKey) {
    const oldFullKey = toLocalStorageKey(oldKey)
    value = localStorageFacade.getItem(oldFullKey)
  }

  try {
    return value ? JSON.parse(value) : defaultValue
  } catch (err) {
    return defaultValue
  }
}

export function usePreference<T>(key: string, defaultValue: T, oldKey?: string): [T, (newValue: T | null) => void] {
  const [value, setValue] = useState<T>(readPreference(key, defaultValue, oldKey))

  const writeAndSetValue = useCallback((newValue: T | null) => {
    writePreference(key, newValue)
    return setValue(newValue ?? defaultValue)
  }, [defaultValue, key])

  return [value, writeAndSetValue]
}
