import gql from 'graphql-tag'

import { IdentityAction } from 'modules/types/auth'
import getClientInfo from 'modules/clientInfo'
import { ConversationType } from '../types/conversations'
import {
  buildMutation,
  checkForErrors,
  createMutationMap,
  mutateGraphQl,
  mutateUnauthenticatedGraphQl,
} from './graph-utils'
import logger from 'modules/logger'
import {
  BlockedUsersDictionary,
  ConversationDataDictionary,
  CreateUserParams as AppCreateUserParams,
  FollowUpMomentsDictionary,
  GuestTeamConversations,
  NotificationPreference,
  NotificationPreferencesDictionary,
  Participant,
  ParticipantsDictionary,
  PremiumUserFee,
  RecentNotification,
  SellerData,
  Settings,
  StripeConnect,
  TalkToMeOnVolleyDataDictionary,
  User,
  UserOrigin,
  UserState,
  WorkspaceTutorials,
} from 'modules/types/users'
import { BatchResult, deserializeBatchResults } from './util'
import { deserializeTierLimits, Vol_TierLimits } from './shared'

export type GetUserVol_Data = {
  approxUnviewed: number | null
  blockedUsers: BlockedUsersDictionary | undefined
  conversationData?: GetUserVol_UserConversationData
  conversationIDs: string[] | null
  disableDownloading: boolean | null
  disableForwarding: boolean | null
  disableSharing: boolean | null
  email: string
  familyName: string
  followUpMoments: GetUserVol_FollowUpMoments | null
  gigsUpdatedOn: string | null
  givenName: string
  guestIDs: GetUserVol_GuestIds | null
  id: string
  isSuperadmin: boolean
  isVisitor: boolean
  notificationPrefs: NotificationPreferencesDictionary
  phone: string | null
  premiumUserFee?: GetUserVol_PremiumUserFee
  recentNotifications?: GetUserVol_RecentNotification[]
  searchIndexCreatedOn: string | null
  sellerData: GetUserVol_SellerData | null
  settings: GetUserVol_Settings | null
  suppressConsultOffersFromSellerSearch: boolean | undefined
  teamIDs: string[] | null
  teamInfo: GetUserVol_UserTeamInfo
  thumbEOL: string | null
  thumbUpdatedOn: string | null
  thumbURL: string | null
  tierID: string
  tierEOL?: string
  tierWillRenew?: boolean
  hasFreeProWithSpacePro?: boolean
  tierLimits?: Vol_TierLimits
  ttmovData: GetUserVol_TtmovData | null
  vanityURL: string | null
  vState: UserState | undefined
  welcomeConversationID: string | null
}
export type GetUserVol_UserConversationData = {
  [conversationId: string]: GetUserVol_UserConversationData_Entry
}
type GetUserVol_UserConversationData_Entry = {
  hasNewContent?: boolean
  justJoined?: boolean
  showNewBadge?: boolean
  sortDate: string
  sortToTop?: boolean
  teamID: string
  threadUnviewedCount: number
  unviewedCount: number
  userIDs?: string[]
  vType?: ConversationType
}
export type GetUserVol_GuestIds = {
  [teamId: string]: string[]
}
export type GetUserVol_PremiumUserFee = {
  discountEOL?: string
  percentage: number
}
export type GetUserVol_UserTeamInfo = { [teamId: string]: GetUserVol_UserTeamInfo_Entry }
type GetUserVol_UserTeamInfo_Entry = {
  joinedOn: string
  viewedWelcomeOn: string
}
export type GetUserVol_RecentNotification = {
  createdOn: string
  message: string
  title: string
  vData?: {
    action: string
    conversationID?: string
    momentID?: string
    teamID?: string
    thumbURL?: string
    videoURL?: string
  }
}
export type GetUserVol_FollowUpMoments = {
  [momentId: string]: GetUserVol_FollowUpMomentsData_Entry
}
type GetUserVol_FollowUpMomentsData_Entry = {
  conversationID: string
  createdOn: string
  creatorUserID: string
  markedOn: string
  teamID: string
}
export type GetUserVol_SellerData = {
  analyticsEOL?: string
  analyticsURL?: string
  showSellerAnalyticsBadge?: boolean
  slaText?: string
}
export type GetUserVol_Settings = {
  textVolleyBackgroundColor?: string
  tutorialStatuses?: {
    linkFollowBackstop?: 'dismissed' | undefined
    longPressedAVolley?: boolean
    recordedAVolley?: boolean
    setupTeamConvList?: 'dismissed' | undefined
    showedAboutMyNetwork?: boolean
    showedNUXSuccess?: boolean
    showWelcomeScreen?: boolean
    startedAConvo?: boolean
    startedAConvoPrompt?: boolean
    viewedAVolley?: boolean
    viewedOwnVolley?: boolean
    workspaceTutorials?: WorkspaceTutorials
  }
  userOrigin?: UserOrigin
}
export type GetUserVol_TtmovData = {
  [conversationId: string]: { needsAttention?: boolean, tokenID: string, vType: ConversationType }
}
type GetUserVolParams = { clientInfo: string, id: string, visitorTeamIds: string[] }
type GetUserVolResult = { getUserVol: string | null }
const GetUserVolQuery = gql`
  mutation GetUserVol($clientInfo: String, $id: ID!, $visitorTeamIds: [ID]) {
    getUserVol(clientInfo: $clientInfo, id: $id, visitorTeamIDs: $visitorTeamIds)
  }
`

export async function getUser(userId: string, visitorTeamIds: string[] = []): Promise<User | null> {
  const clientInfo = await getClientInfo()
  const result = await mutateGraphQl<GetUserVolResult, GetUserVolParams>({
    mutation: GetUserVolQuery,
    variables: { clientInfo, id: userId, visitorTeamIds },
  })

  checkForErrors('getUserVol', result)
  return result?.data?.getUserVol
    ? deserializeUser(JSON.parse(result.data.getUserVol))
    : null
}

type UpdateUserVolParams = {
  blockUser?: string
  clientInfo: string
  catchUpConversationIds?: string[]
  catchUpConversationsInTeamIds?: string[]
  catchUpThreadsInConversationIds?: string[]
  clearJustJoinedInConversationIds?: string[]
  clearSellerAnalyticsBadge?: boolean
  disableDownloading?: boolean
  disableForwarding?: boolean
  disableSharing?: boolean
  getSignInUrl?: string
  getStripeConnectDashboardUrl?: boolean
  setUpStripeSeller?: StripeConnect
  slaText?: string | null
  suppressConsultOffersFromSellerSearch?: boolean
  thumbB64?: string | null
  thumbEmoji?: string | null
  updateFamilyName?: string
  updateFollowUps?: string
  updateGivenName?: string
  updateNotificationPreferences?: string
  updatePhone?: string
  updateSellerAnalyticsUrl?: boolean
  updateSettings?: string
  updateTeamIds?: string
  updateVanityUrl?: string
  viewedWelcomeTeamId?: string
  tierId?: string | null
  getBillingUrl?: boolean
  cancelSubscription?: boolean
}
type UpdateUserVolResult = { updateUserVol: string | null }
type UpdateUserVol_Data = GetUserVol_Data & {
  billingPortalURL?: string
  redirectURL?: string
  signInURL?: string
  stripeConnectDashboardURL?: string
}
type UpdateUserResult = {
  upgradeUrl?: string
  billingUrl?: string
  signInUrl?: string
  updatedUser: User | null
}
const UpdateUserVolMutation = createMutationMap('updateUserVol', {
  blockUser: 'AWSJSON',
  catchUpConversationIds: { backendKey: 'catchUpConversationIDs', type: '[ID]' },
  catchUpConversationsInTeamIds: { backendKey: 'catchUpConversationsInTeamIDs', type: '[ID]' },
  catchUpThreadsInConversationIds: { backendKey: 'catchUpThreadsInConversationIDs', type: '[ID]' },
  clearJustJoinedInConversationIds: { backendKey: 'clearJustJoinedInConversationIDs', type: '[ID]' },
  clearSellerAnalyticsBadge: 'Boolean',
  clientInfo: 'String',
  disableDownloading: 'Boolean',
  disableForwarding: 'Boolean',
  disableSharing: 'Boolean',
  getSignInUrl: { backendKey: 'getSignInURL', type: 'AWSJSON' },
  getStripeConnectDashboardUrl: { backendKey: 'getStripeConnectDashboardURL', type: 'Boolean' },
  setUpStripeSeller: 'String',
  slaText: 'String',
  suppressConsultOffersFromSellerSearch: 'Boolean',
  thumbB64: 'String',
  thumbEmoji: 'String',
  updateFamilyName: 'String',
  updateFollowUps: 'AWSJSON',
  updateGivenName: 'String',
  updateNotificationPreferences: { backendKey: 'updateNotificationPrefs', type: 'AWSJSON' },
  updatePhone: 'String',
  updateSellerAnalyticsUrl: { backendKey: 'updateSellerAnalyticsURL', type: 'Boolean' },
  updateSettings: 'AWSJSON',
  updateTeamIds: { backendKey: 'updateTeamIDs', type: 'AWSJSON' },
  updateVanityUrl: { backendKey: 'updateVanityURL', type: 'AWSJSON' },
  viewedWelcomeTeamId: { backendKey: 'viewedWelcomeTeamID', type: 'ID' },
  tierId: { backendKey: 'tierID', type: 'ID!' },
  getBillingUrl: { backendKey: 'getBillingPortalURL', type: 'Boolean' },
  cancelSubscription: { backendKey: 'cancelSubscription', type: 'Boolean' },
})
export type FollowUps = {
  addFollowUps?: string[],
  removeFollowUps?: string[]
}
export type BlockUserWithReason = {
  blockUserId: string
  reason: string
}
export type UpdateNotificationPreferences = { [id: string]: NotificationPreference | null }
type GetSignInUrlParams = {
  path: string
  search?: string
}
export type UpdateUserParams = Omit<UpdateUserVolParams,
  | 'blockUser'
  | 'clientInfo'
  | 'getSignInUrl'
  | 'updateFollowUps'
  | 'updateNotificationPreferences'
  | 'updateSettings'
  | 'updateTeamIds'
  | 'updateVanityUrl'
> & {
  blockUser?: BlockUserWithReason
  getSignInUrl?: GetSignInUrlParams
  updateFollowUps?: FollowUps
  updateNotificationPreferences?: UpdateNotificationPreferences
  updateSettings?: Settings
  updateTeamIds?: string[]
  updateVanityUrl?: string
  tierId?: string | null
}

export async function updateUser(params: UpdateUserParams): Promise<UpdateUserResult> {
  const {
    blockUser,
    getSignInUrl,
    updateFollowUps,
    updateNotificationPreferences,
    updateSettings,
    updateTeamIds,
    updateVanityUrl,
    ...rest
  } = params

  const serializedBlockUser = blockUser
    ? JSON.stringify({ blockUserID: blockUser.blockUserId, reason: blockUser.reason })
    : undefined
  const clientInfo = await getClientInfo()
  const serializedFollowUps = updateFollowUps ? JSON.stringify(updateFollowUps) : undefined
  const serializedGetSignInUrl = serializeGetSignInUrl(getSignInUrl)
  const serializedNotificationPreferences = updateNotificationPreferences
    ? JSON.stringify(updateNotificationPreferences)
    : undefined
  const serializedSettings = serializeSettings(updateSettings)
  const serializedTeamIds = updateTeamIds ? JSON.stringify(updateTeamIds) : undefined
  const serializedVanityUrl = updateVanityUrl ? JSON.stringify({ path: updateVanityUrl }) : undefined
  const variables: UpdateUserVolParams = {
    blockUser: serializedBlockUser,
    clientInfo,
    getSignInUrl: serializedGetSignInUrl,
    updateFollowUps: serializedFollowUps,
    updateNotificationPreferences: serializedNotificationPreferences,
    updateSettings: serializedSettings,
    updateTeamIds: serializedTeamIds,
    updateVanityUrl: serializedVanityUrl,
    ...rest,
  }
  const res = await mutateGraphQl<UpdateUserVolResult, UpdateUserVolParams>({
    mutation: buildMutation(variables, UpdateUserVolMutation),
    variables,
  })
  UpdateUserVolMutation.checkForErrors(res)

  return deserializeUpdateResults(JSON.parse(res.data?.updateUserVol ?? '{}'))
}

function deserializeUpdateResults(data?: UpdateUserVol_Data): UpdateUserResult {
  return {
    upgradeUrl: data?.redirectURL,
    billingUrl: data?.billingPortalURL,
    signInUrl: data?.signInURL,
    updatedUser: data ? deserializeUser(data) : null,
  }
}
function deserializeUser(user: GetUserVol_Data): User {
  return {
    approxUnviewed: user.approxUnviewed ?? 0,
    blockedUsers: user.blockedUsers ?? {},
    conversationData: deserializeConversationData(user.conversationData),
    conversationIds: user.conversationIDs ?? [],
    disableDownloading: !!user.disableDownloading,
    disableForwarding: !!user.disableForwarding,
    disableSharing: !!user.disableSharing,
    email: user.email,
    familyName: user.familyName,
    followUpMoments: user.followUpMoments ? deserializeFollowUpMoments(user.followUpMoments) : {},
    gigsUpdatedOn: user.gigsUpdatedOn ?? null,
    givenName: user.givenName,
    guestTeamConversations: deserializeGuestIds(user.guestIDs ?? {}),
    id: user.id,
    isSuperAdmin: user.isSuperadmin,
    isVisitor: user.isVisitor,
    notificationPreferences: user.notificationPrefs,
    phone: user.phone ?? undefined,
    premiumUserFee: deserializePremiumUserFee(user.premiumUserFee),
    recentNotifications: user.recentNotifications ? deserializeRecentNotifications(user.recentNotifications) : [],
    sellerData: deserializeSellerData(user.sellerData),
    settings: deserializeSettings(user.settings),
    searchIndexCreatedAt: user.searchIndexCreatedOn ? new Date(user.searchIndexCreatedOn).getTime() : null,
    suppressConsultOffersFromSellerSearch: user.suppressConsultOffersFromSellerSearch ?? false,
    talkToMeOnVolleyData: deserializeTalkToMeOnVolleyData(user.ttmovData),
    teamInfo: user.teamInfo || {},
    teamIds: user.teamIDs || [],
    thumbEol: user.thumbEOL,
    thumbUpdatedOn: user.thumbUpdatedOn,
    thumbUrl: user.thumbURL,
    tierId: user.tierID,
    tierEol: user.tierEOL ?? null,
    hasFreeProWithSpacePro: user.hasFreeProWithSpacePro ?? null,
    tierWillRenew: user.tierWillRenew,
    tierLimits: deserializeTierLimits(user.tierLimits),
    vanityUrl: user.vanityURL,
    vState: user.vState ?? UserState.Active,
    welcomeConversationId: user.welcomeConversationID,
  }
}

function deserializeConversationData(conversationData?: GetUserVol_UserConversationData): ConversationDataDictionary {
  if (!conversationData) {
    return {}
  }

  return Object.entries(conversationData).reduce<ConversationDataDictionary>((result, [conversationId, entry]) => {
    result[conversationId] = {
      conversationType: entry.vType ?? ConversationType.Group,
      conversationId,
      hasNewContent: entry.hasNewContent,
      justJoined: entry.justJoined,
      showNewBadge: entry.showNewBadge,
      sortDate: entry.sortDate,
      sortToTop: entry.sortToTop,
      teamId: entry.teamID,
      threadUnviewedCount: entry.threadUnviewedCount ?? 0,
      unviewedCount: entry.unviewedCount,
      userIds: entry.userIDs ?? [],
    }
    return result
  }, {})
}

function deserializeGuestIds(guestIds: GetUserVol_GuestIds): GuestTeamConversations {
  return Object.entries(guestIds).reduce<GuestTeamConversations>((result, [teamId, convoIds]) => {
    result[teamId] = convoIds
    return result
  }, {})
}

function deserializePremiumUserFee(data?: GetUserVol_PremiumUserFee): PremiumUserFee {
  return {
    discountEol: data?.discountEOL,
    percentage: data?.percentage ?? 5,
  }
}

function deserializeRecentNotifications(recentNotifications: GetUserVol_RecentNotification[]): RecentNotification[] {
  return recentNotifications.map(n => ({
    createdOn: n.createdOn,
    message: n.message,
    title: n.title,
    vData: {
      action: n.vData?.action,
      conversationId: n.vData?.conversationID,
      momentId: n.vData?.momentID,
      teamId: n.vData?.teamID,
      thumbUrl: n.vData?.thumbURL,
      videoUrl: n.vData?.videoURL,
    }
  }))
}

function deserializeFollowUpMoments(followUpMoments: GetUserVol_FollowUpMoments): FollowUpMomentsDictionary {
  return Object.entries(followUpMoments).reduce<FollowUpMomentsDictionary>((result, [momentId, entry]) => {
    result[momentId] = {
      conversationId: entry.conversationID,
      createdOn: entry.createdOn,
      creatorUserId: entry.creatorUserID,
      id: momentId,
      markedOn: entry.markedOn,
      teamId: entry.teamID,
    }

    return result
  }, {})
}

function deserializeSellerData(sellerData: GetUserVol_SellerData | null): SellerData | null {
  if (!sellerData) {
    return null
  }

  return {
    analyticsEol: sellerData.analyticsEOL,
    analyticsUrl: sellerData.analyticsURL,
    showSellerAnalyticsBadge: sellerData.showSellerAnalyticsBadge ?? false,
    slaText: sellerData.slaText || null
  }
}

function deserializeSettings(settings: GetUserVol_Settings | null): Settings {
  if (!settings) {
    return {}
  }

  return {
    textVolleyBackgroundColor: settings.textVolleyBackgroundColor,
  }
}

function deserializeTalkToMeOnVolleyData(ttmovData: GetUserVol_TtmovData | null): TalkToMeOnVolleyDataDictionary {
  if (!ttmovData) {
    return {}
  }

  return Object.entries(ttmovData).reduce<TalkToMeOnVolleyDataDictionary>((acc, [convoId, data]) => {
    acc[convoId] = {
      conversationId: convoId,
      needsAttention: data.needsAttention ?? false,
      tokenId: data.tokenID,
      vType: data.vType,
    }

    return acc
  }, {})
}

function serializeGetSignInUrl(params?: GetSignInUrlParams): string | undefined {
  if (!params) {
    return undefined
  }

  return JSON.stringify({
    continueURLEncodedQueryString: params.search,
    continueURLPath: params.path,
    handleCodeInApp: false,
  })
}

function serializeSettings(settings?: Settings): string | undefined {
  if (!settings) {
    return undefined
  }

  const settingsData: GetUserVol_Settings = {
    textVolleyBackgroundColor: settings.textVolleyBackgroundColor,
  }

  return JSON.stringify(settingsData)
}

const GetParticipantsQuery = gql`
  mutation GetParticipants($clientInfo: String, $ids: [ID], $onlyFields: [String]) {
    getUserVol(clientInfo: $clientInfo, ids: $ids, onlyFields: $onlyFields)
  }
`
type GetParticipantsParams = { clientInfo: string, ids: string[], onlyFields?: string[] }
type GetParticipantsResult = { getUserVol: string | null, error?: string }
type GetParticipants_ParticipantDictionary = { [id: string]: GetParticipants_Participant }
export type GetParticipants_Participant = {
  familyName: string
  givenName: string
  id: string
  thumbURL: string | null
}
const GET_PARTICIPANT_ONLY_FIELDS = ['familyName', 'givenName', 'id', 'thumbURL']
export async function getParticipants(ids: string[]): Promise<BatchResult<Participant>> {
  const clientInfo = await getClientInfo()
  const results = await mutateGraphQl<GetParticipantsResult, GetParticipantsParams>({
    mutation: GetParticipantsQuery,
    variables: { clientInfo, ids, onlyFields: GET_PARTICIPANT_ONLY_FIELDS }
  })

  checkForErrors('getUserVol', results, true)
  return deserializeBatchResults(results.data?.getUserVol, deserializeParticipants)
}

function deserializeParticipants(participants: GetParticipants_ParticipantDictionary): ParticipantsDictionary {
  return Object.entries(participants).reduce<ParticipantsDictionary>((dict, [id, participant]) => {
    dict[id] = deserializeParticipant(participant)
    return dict
  }, {})
}

function deserializeParticipant(participant: GetParticipants_Participant): Participant {
  return {
    familyName: participant.familyName,
    givenName: participant.givenName,
    id: participant.id,
    thumbUrl: participant.thumbURL,
  }
}

const CreateUserVolMutation = gql`
  mutation CreateUserVol(
    $clientInfo: String
    $email: String!
    $familyName: String!
    $givenName: String!
    $phone: String
    $thumbB64: String
    $userId: ID!
  ) {
    createUserVol(
      clientInfo: $clientInfo
      disableWelcome: false
      email: $email
      familyName: $familyName
      givenName: $givenName
      phone: $phone
      thumbB64: $thumbB64
      userID: $userId
    )
  }
`
type CreateUserVolResult = { createUserVol?: string | null }
type CreateUserVolParams = Omit<AppCreateUserParams, 'authProvider' | 'phoneNumber'> & {
  clientInfo: string
  phone?: string
  userId: string
}
type CreateUserParams = Omit<CreateUserVolParams, 'clientInfo'>
export async function createUser(params: CreateUserParams): Promise<void> {
  const clientInfo = await getClientInfo()
  const result = await mutateGraphQl<CreateUserVolResult, CreateUserVolParams>({
    mutation: CreateUserVolMutation,
    variables: { ...params, clientInfo }
  })

  try {
    checkForErrors('createUserVol', result, true)
  } catch (error) {
    logger.error('Failed to create user', error, { userId: params.userId })
  }
}

type CreateAnonymousUserParams = {
  clientInfo: string
  userId: string
}
const CreateAnonymousUserMutation = gql`
  mutation CreateAnonymousUser($clientInfo: String, $userId: ID!) {
    createUserVol(clientInfo: $clientInfo, userID: $userId)
  }
`
export async function createVisitorUser(userId: string): Promise<void> {
  const clientInfo = await getClientInfo()
  const result = await mutateGraphQl<CreateUserVolResult, CreateAnonymousUserParams>({
    mutation: CreateAnonymousUserMutation,
    variables: { clientInfo, userId }
  })

  try {
    checkForErrors('createUserVol', result, true)
  } catch (error) {
    logger.error('Failed to create user', error, { userId })
  }
}

type UserIdentityMutationParams = {
  action: IdentityAction
  clientInfo: string
  email: string
  password?: string
}
type UserIdentityResult = { userIdentity: string }
const UserIdentityMutation = gql`
  mutation UserIdentity($action: String!, $clientInfo: String, $email: String!, $password: String) {
    userIdentity (action: $action, clientInfo: $clientInfo, email: $email, password: $password)
  }
`

type UserIdentityParams = Omit<UserIdentityMutationParams, 'clientInfo'>
export async function userIdentity(params: UserIdentityParams): Promise<{ error?: string }> {
  const clientInfo = await getClientInfo()
  const result = await mutateUnauthenticatedGraphQl<UserIdentityResult, UserIdentityMutationParams>({
    mutation: UserIdentityMutation,
    variables: { ...params, clientInfo }
  })

  const data = JSON.parse(result.data?.userIdentity ?? '{}')

  return { error: data.error }
}
