import { selector as alerts } from './alerts/slice'
import { selector as analytics } from './analytics/slice'
import { selector as application } from './application/slice'
import { selector as authentication } from './authentication/slice'
import { selector as billings } from './billings/slice'
import { selector as conversations } from './conversations/slice'
import { selector as deviceSettings } from './device-settings/slice'
import { selector as features } from './features/slice'
import { selector as gigs } from './gigs/slice'
import { selector as integrations } from './integrations/slice'
import { selector as modals } from './modals/slice'
import { selector as moments } from './moments/slice'
import { selector as notifications } from './notifications/slice'
import { selector as offers } from './offers/slice'
import { selector as purchases } from './purchases/slice'
import { selector as search } from './search/slice'
import { selector as streamUploads } from './stream-uploads/slice'
import { selector as subscriptions } from './subscriptions/slice'
import { selector as teams } from './teams/slice'
import { selector as texts } from './texts/slice'
import { selector as tokens } from './tokens/slice'
import { selector as users } from './users/slice'

export const Selectors = {
  alerts,
  analytics,
  application,
  authentication,
  billings,
  conversations,
  deviceSettings,
  features,
  gigs,
  integrations,
  modals,
  moments,
  notifications,
  offers,
  purchases,
  search,
  streamUploads,
  subscriptions,
  teams,
  texts,
  tokens,
  users,
}
