import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { Transcription, Upload } from 'modules/types/uploads'
import { StreamUploadsState } from '.'

export type UploadTranscriptionAudioSegmentAction = PayloadAction<{ data: Blob | null, uploadId: string }>
export const uploadTranscriptionAudioSegment: CaseReducer<StreamUploadsState, UploadTranscriptionAudioSegmentAction> = (state, action) => {
  const { transcription, upload } = getUploadAndTranscription(state, action.payload.uploadId)
  return updateTranscriptionState({
    state,
    transcription,
    updates: { audioSegments: [...transcription.audioSegments, action.payload.data] },
    upload,
    uploadId: action.payload.uploadId,
  })
}
export type UploadTranscriptionAudioSegmentSuccessAction = PayloadAction<{ uploadId: string }>
export const uploadTranscriptionAudioSegmentSuccess: CaseReducer<StreamUploadsState, UploadTranscriptionAudioSegmentSuccessAction> = (state, action) => {
  const { transcription, upload } = getUploadAndTranscription(state, action.payload.uploadId)
  const audioSegments = [...transcription.audioSegments]
  audioSegments.shift()

  return updateTranscriptionState({
    state,
    transcription,
    updates: { audioSegments, isProcessing: false },
    upload,
    uploadId: action.payload.uploadId,
  })
}
export type AddTranscribedSegmentAction = PayloadAction<{ uploadId: string, segment: string }>
export const addTranscribedSegment: CaseReducer<StreamUploadsState, AddTranscribedSegmentAction> = (state, action) => {
  const { transcription, upload } = getUploadAndTranscription(state, action.payload.uploadId)
  return updateTranscriptionState({
    state,
    transcription,
    updates: { transcribedSegments: [...transcription.transcribedSegments, action.payload.segment] },
    upload,
    uploadId: action.payload.uploadId,
  })
}
export type IsProcessingTranscriptionAudioSegmentAction = PayloadAction<{ uploadId: string }>
export const setIsProcessingTranscriptionAudioSegment: CaseReducer<StreamUploadsState, IsProcessingTranscriptionAudioSegmentAction> = (state, action) => {
  const { transcription, upload } = getUploadAndTranscription(state, action.payload.uploadId)
  return updateTranscriptionState({
    state,
    transcription,
    updates: { isProcessing: true },
    upload,
    uploadId: action.payload.uploadId,
  })
}

export type TranscriptionFailedAction = PayloadAction<{ uploadId: string }>
export const transcriptionFailed: CaseReducer<StreamUploadsState, TranscriptionFailedAction> = (state, action) => {
  const { transcription, upload } = getUploadAndTranscription(state, action.payload.uploadId)
  return updateTranscriptionState({
    state,
    transcription,
    updates: { isComplete: false, isFailed: true },
    upload,
    uploadId: action.payload.uploadId,
  })
}

export type TranscriptionCompleteAction = PayloadAction<{ uploadId: string }>
export const transcriptionComplete: CaseReducer<StreamUploadsState, TranscriptionCompleteAction> = (state, action) => {
  const { transcription, upload } = getUploadAndTranscription(state, action.payload.uploadId)
  return updateTranscriptionState({
    state,
    transcription,
    updates: { isComplete: true, isFailed: false },
    upload,
    uploadId: action.payload.uploadId,
  })
}

function getUploadAndTranscription(state: StreamUploadsState, uploadId: string): { upload?: Upload, transcription: Transcription } {
  const upload = state.uploads[uploadId]
  const transcription = upload?.transcription ?? { audioSegments: [], isComplete: false, transcribedSegments: [] }

  return { upload, transcription }
}

type UpdateTranscriptionsStateParams = {
  state: StreamUploadsState
  transcription: Transcription
  updates: Partial<Transcription>
  upload?: Upload
  uploadId: string
}
function updateTranscriptionState(params: UpdateTranscriptionsStateParams): StreamUploadsState {
  const { state, transcription, updates, upload, uploadId } = params

  return upload
    ? {
      ...state,
      uploads: {
        ...state.uploads,
        [uploadId]: {
          ...upload,
          transcription: {
            ...transcription,
            ...updates,
          }
        }
      }
    }
    : state
}
