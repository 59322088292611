import { Action } from '@reduxjs/toolkit'
import * as api from 'modules/api/moments'
import logger from 'modules/logger'
import { ConversationType } from 'modules/types/conversations'
import { MomentState, MomentType, TextVolleyColor } from 'modules/types/moments'
import { call, delay, put, race, SagaGenerator, select, take, takeEvery, takeLatest } from 'typed-redux-saga'
import { actions as alertActions } from '../alerts/slice'
import { selector as conversationsSelector } from '../conversations/slice'
import { actions as momentsActions, DeleteMomentAction, selector as momentsSelector } from '../moments/slice'
import { actions as initializeActions } from '../initialize/slice'
import { actions as usersActions, selector as usersSelector } from '../users/slice'
import {
  actions,
  CancelTextAction,
  selector,
  SendTextAction,
  SendTextSuccessAction,
  StartTextAction,
  Text,
} from './slice'

export default function* main(): SagaGenerator<void> {
  yield* takeLatest(initializeActions.requestApp, initializeTextColor)
  yield* takeEvery(actions.startText, startText)
  yield* takeEvery(actions.cancelText, cancelText)
  yield* takeEvery(actions.sendText, sendText)
  yield* takeEvery(actions.sendTextSuccess, removeTextWhenComplete)
  yield* takeEvery(actions.changeColor, saveDefaultTextColor)
  yield* takeEvery(momentsActions.deleteMoment, handleDeleteMoment)
}

export function* initializeTextColor(): SagaGenerator<void> {
  const { currentUser } = yield* select(usersSelector.select)
  if (currentUser?.settings.textVolleyBackgroundColor) {
    yield* put(actions.changeColor({ color: currentUser.settings.textVolleyBackgroundColor as TextVolleyColor }))
  }
}

export function* startText(action: StartTextAction): SagaGenerator<void> {
  const { conversationId, textId } = action.payload

  try {
    yield* createMoment(conversationId, textId)
  } catch (error) {
    // We'll log here, mark as errored, and try again on submit since this is mostly an optimization anyway
    logger.error('Error creating text moment on start', error, { conversationId })
    yield* put(actions.textError({ textId }))
  }
}

function* createMoment(conversationId: string, textId: string): SagaGenerator<{ createdOn: string, momentId: string }> {
  const { createdOn, id } = yield* call(api.createMomentVol, { conversationId, type: MomentType.Text })
  const parentConversationId = yield* getParentConversationId(conversationId)

  yield* put(actions.textMomentCreated({ createdOn, momentId: id, parentConversationId, textId }))
  return { createdOn, momentId: id }
}

function* getParentConversationId(conversationId: string): SagaGenerator<string | undefined> {
  const { conversations } = yield* select(conversationsSelector.select)
  return conversations[conversationId]?.vType === ConversationType.Thread
    ? conversations[conversationId].parentConversationId ?? undefined
    : undefined
}

export function* cancelText(action: CancelTextAction): SagaGenerator<void> {
  const { textId } = action.payload
  const textState = yield* selectText(textId)

  if (!textState) {
    return
  }

  let { momentId, isEditing } = textState

  try {
    // We create the moment so fast that it's likely to get created even if we cancel quickly.
    //  Give it a few seconds for the API to resolve just in case
    if (!momentId) {
      momentId = yield* waitForMomentId(textId)
    }

    if (momentId && !isEditing) {
      yield* call(api.cancelMoment, { momentId })
    }

    yield* put(actions.cancelTextSuccess({ textId }))
  } catch (error) {
    const textState = yield* selectText(textId)

    logger.error('Error canceling text', error, { momentId: textState?.momentId })
    yield* put(alertActions.pushAlert({
      message: 'There was an error canceling your volley. You may need to refresh or restart to delete it manually.',
      severity: 'error',
    }))
  }
}

function* waitForMomentId(textId: string, conversationId?: string, isErrored?: boolean): SagaGenerator<string | undefined> {
  let momentId: string | undefined

  if (isErrored && conversationId) {
    ({ momentId } = yield* createMoment(conversationId, textId))
  } else {
    let attempts = 0
    while (!momentId && attempts < 20) {
      attempts++
      yield* delay(200)

      const textState = yield* selectText(textId)
      momentId = textState?.momentId
    }
  }

  return momentId
}

export function* sendText(action: SendTextAction): SagaGenerator<void> {
  const { color, mentionedUserIds, text, textId, scheduledDate } = action.payload
  const textState = yield* selectText(textId)

  if (!textState || textState.isCanceled) {
    return
  }

  let momentId = textState.momentId

  try {
    if (!momentId) {
      momentId = yield* waitForMomentId(textId, textState.conversationId, textState.isErrored)
    }

    if (!momentId) {
      return
    }

    if (textState.isEditing) {
      yield* call(api.updateMoment, { backgroundHexColor: color, mentionedUserIds, momentId, text })
    } else {
      yield* call(api.closeMoment, { backgroundHexColor: color, mentionedUserIds, momentId, scheduledDate, text })
    }
    yield* put(actions.sendTextSuccess({
      momentId,
      scheduledDate,
      teamId: textState.teamId,
      textId,
      wasEdited: textState.isEditing,
    }))

    if (scheduledDate) {
      yield* put(alertActions.pushAlert({ message: 'Volley scheduled successfully', severity: 'success' }))
    }
  } catch (error) {
    logger.error('Error sending text volley', error, { momentId })
    yield* put(actions.textError({ textId }))
    yield* put(alertActions.pushAlert({
      message: [
        'An error occurred sending your text.',
        { conversationId: textState.conversationId, teamId: textState.teamId, text: 'Try again.' },
      ],
      severity: 'error',
    }))
  }
}

function* selectText(textId: string): SagaGenerator<Text | undefined> {
  const state = yield* select(selector.select)
  return state.texts[textId]
}

export function* saveDefaultTextColor(): SagaGenerator<void> {
  const { currentUser } = yield* select(usersSelector.select)
  const state = yield* select(selector.select)

  if (currentUser?.settings.textVolleyBackgroundColor !== state.color) {
    yield* put(usersActions.updateCurrentUser({ settings: { textVolleyBackgroundColor: state.color } }))
  }
}

export function* handleDeleteMoment(action: DeleteMomentAction): SagaGenerator<void> {
  const { momentId, type } = action.payload
  if (type !== MomentType.Text) {
    return
  }

  const state = yield* select(selector.select)
  const text = Object.values(state.texts).find(t => t.momentId === momentId)

  if (text) {
    yield* put(actions.cancelTextSuccess({ textId: text.id }))
  }
}

export function* removeTextWhenComplete(action: SendTextSuccessAction): SagaGenerator<void> {
  const { momentId, scheduledDate, textId, wasEdited } = action.payload

  if (!scheduledDate) {
    const { moments } = yield* select(momentsSelector.select)
    if (!moments[momentId] || moments[momentId].vState !== MomentState.Complete) {
      const { sleep } = yield* race({
        completed: take((action: Action) =>
          (momentsActions.refetchMomentsSuccess.match(action) || momentsActions.fetchMomentsSuccess.match(action))
          && action.payload.moments[momentId]
          && action.payload.moments[momentId].vState === MomentState.Complete),
        sleep: delay(10 * 60 * 1000),
      })
      if (sleep) {
        logger.warn('Removing text before moment is complete', { momentId, textId })
      }
    }

    yield* delay(wasEdited ? 10000 : 2000)
  }

  yield* put(actions.textMomentCompleted({ textId }))
}
