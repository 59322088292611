import axios from 'axios'
import gql from 'graphql-tag'
import { xor } from 'lodash-es'
import config from 'modules/config'
import logger from 'modules/logger'
import {
  BillingFrequency,
  DesktopConfigData,
  ExpertSearchData,
  Feature,
  FeatureId,
  FeaturesDictionary,
  FuneralPyreData,
  MomentCountThresholdData,
  PlanLevel,
  PricingType, SpaceSize,
  SsoData,
  TeamTemplateMetadata,
  TeamTemplatesData,
  TeamTiers,
  TeamTiersData,
} from 'modules/types/features'
import { TemplateTeam } from 'modules/types/teams'
import { checkForErrors, queryUnauthenticatedGraphQl } from './graph-utils'

type GetFeatureParams = { id: string }
type GetFeatureResult = { getFeature: GetFeature, error?: string }
type GetFeature = {
  id: FeatureId
  isActive: boolean
  vData: string | null
}
const GetFeatureQuery = gql`
  query GetFeature($id: ID!) {
    getFeature(id: $id) { id, isActive, vData }
  }
`

export async function getFeatures(requestFeatureIds: FeatureId[]): Promise<FeaturesDictionary> {
  const shouldFetchTeamTemplates = requestFeatureIds.includes(FeatureId.TeamTemplates)
  const shouldFetchTeamTiers = requestFeatureIds.includes(FeatureId.TeamTiers)
  const featureIds = requestFeatureIds.filter(id => id !== FeatureId.TeamTemplates && id !== FeatureId.TeamTiers)

  const [teamTemplates, teamTiers, featuresResults] = await Promise.all([
    (shouldFetchTeamTemplates ? getTeamTemplates() : null),
    (shouldFetchTeamTiers ? getTeamTiers() : null),
    Promise.all(featureIds.map(id =>
      queryUnauthenticatedGraphQl<GetFeatureResult, GetFeatureParams>({
        query: GetFeatureQuery,
        variables: { id },
      })
    ))
  ])

  let features = featuresResults.reduce<FeaturesDictionary>((acc, res) => {
    try {
      checkForErrors('getFeature', res)

      const feature = deserializeFeature(res.data.getFeature)
      if (feature) {
        acc[feature.id] = feature
      }
    } catch (error) {
      logger.error('Error parsing feature', error, { feature: res.data?.getFeature })
    }

    return acc
  }, {})

  // Fill the result with potentially missing features with default data
  if (Object.keys(features).length < featureIds.length) {
    const missingIds = xor(featureIds, Object.keys(features) as FeatureId[])
    logger.error('Failed to fetch features. Default values will be used.', { ids: missingIds })
    features = { ...features, ...getDefaultFeatures(missingIds) }
  }

  return {
    ...features,
    ...(teamTemplates ? { [teamTemplates.id]: teamTemplates } : {}),
    ...(teamTiers ? { [teamTiers.id]: teamTiers } : {}),
  }
}

export function getDefaultFeatures(featureIds: FeatureId[]): FeaturesDictionary {
  return featureIds.reduce<FeaturesDictionary>((acc, id) => {
    const feature = deserializeFeature({ id, isActive: false, vData: null })
    if (feature) {
      acc[id] = feature
    }

    return acc
  }, {})
}

function deserializeFeature(result?: GetFeature): Feature | null {
  if (!result) {
    return null
  }

  switch (result.id) {
    case FeatureId.DesktopConfig:
      return deserializeDesktopConfigFeature(result)
    case FeatureId.ExpertSearch:
      return deserializeExpertSearchFeature(result)
    case FeatureId.FuneralPyre:
      return deserializeFuneralPyre(result)
    case FeatureId.MomentCountThreshold:
      return deserializeMomentCountThresholdFeature(result)
    case FeatureId.Sso:
      return deserializeSsoFeature(result)
    case FeatureId.TeamTemplates:
      return deserializeTeamTemplates({ isActive: true, templates: [] })
    case FeatureId.TeamTiers:
      return deserializeTeamTiers({ tiers: {} })
    default:
      return { data: {}, id: result.id, isActive: result.isActive, updatedAt: new Date().getTime() }
  }
}

function deserializeDesktopConfigFeature(result: GetFeature): Feature<DesktopConfigData> {
  const { isActive, vData } = result
  const data = vData ? JSON.parse(vData) : {}

  return {
    data: {
      features: data.features ?? {},
      slackIntegration: {
        retryAttempts: 30,
        retrySleepMs: 10000,
        slackAppVersion: 2,
        ...data.slackIntegration,
      },
    },
    id: FeatureId.DesktopConfig,
    isActive,
    updatedAt: new Date().getTime(),
  }
}

function deserializeExpertSearchFeature(result: GetFeature): Feature<ExpertSearchData> {
  const { isActive, vData } = result
  const data = vData ? JSON.parse(vData) : {}

  return {
    data: {
      apiKey: data.apiKey,
      appId: data.appID,
      searchIndex: data.searchIndex,
    },
    id: FeatureId.ExpertSearch,
    isActive,
    updatedAt: new Date().getTime(),
  }
}

function deserializeFuneralPyre(result: GetFeature): Feature<FuneralPyreData> {
  const { isActive, vData } = result
  const data = vData ? JSON.parse(vData) : {}

  return {
    data: {
      learnMore: data.learnMore ?? config.web.host,
      shutdownOn: data.endDateString,
    },
    id: FeatureId.FuneralPyre,
    isActive,
    updatedAt: new Date().getTime(),
  }
}

function deserializeMomentCountThresholdFeature(result: GetFeature): Feature<MomentCountThresholdData> {
  const { isActive, vData } = result
  const data = vData ? JSON.parse(vData) : {}

  return {
    data: {
      maxConversationMomentCount: data.maxCountingThreshold ?? 20,
      maxThreadMomentCount: data.maxCountingThresholdThread ?? 20,
    },
    id: FeatureId.MomentCountThreshold,
    isActive,
    updatedAt: new Date().getTime(),
  }
}

function deserializeSsoFeature(result: GetFeature): Feature<SsoData> {
  const { isActive, vData } = result
  const data = vData ? JSON.parse(vData) : {}

  return {
    data: {
      failedRegexMessage: data.failedRegexMessage ?? 'Password must be at least 8 characters',
      isGracePeriodActive: data.gracePeriodActive ?? false,
      passwordRegex: data.passwordRegex ?? '^[\\S]{8,}$',
    },
    id: FeatureId.Sso,
    isActive,
    updatedAt: new Date().getTime(),
  }
}

async function getTeamTemplates(): Promise<Feature<TeamTemplatesData>> {
  let teamTemplates: RawTeamTemplates = { isActive: true, templates: [] }

  try {
    const res = await axios.get(`${config.app.piecesHost}/${config.app.rootDomain}/space-template.json`
    //   , { // todo, for whatever reason using these headers causes the request to fail do to prefight CORS check
    //   headers: {
    //     'Cache-Control': 'no-cache',
    //     Pragma: 'no-cache',
    //     Expires: '0',
    //   },
    // }
    )

    teamTemplates = res.data
  } catch (error) {
    if (error.message !== 'Network Error') {
      logger.error('Failed to get team templates', error)
    }
  }

  return deserializeTeamTemplates(teamTemplates)
}

type RawTeamTemplates = {
  isActive: boolean
  templates: { space: TemplateTeam, template: TeamTemplateMetadata }[]
}
function deserializeTeamTemplates(result: RawTeamTemplates): Feature<TeamTemplatesData> {
  return {
    data: {
      templates: result.templates.length
        ? result.templates.map(t => ({
          metadata: t.template,
          team: { ...t.space, name: t.space.name ?? '' },
        }))
        : [
          {
            metadata: {
              description: 'Build your space from the ground up to suit your needs. Channels: Announcements, Random, Introduce Yourself',
              icon: '✏️',
              name: 'Start from scratch',
            },
            team: {
              adminControls: {},
              channels: [
                {
                  description: 'Share updates and announcements with everyone.',
                  isAutoJoin: true,
                  isEveryoneChannel: true,
                  name: 'Announcements',
                  preventUsersLeaving: true,
                  restrictPosting: false,
                  thumbEmoji: '📣',
                },
                {
                  description: `Say hi! Tell us who you are and what you're up to.`,
                  isAutoJoin: true,
                  name: 'Introduce Yourself',
                  preventUsersLeaving: false,
                  restrictPosting: false,
                  thumbEmoji: '✌️',
                },
                {
                  description: `Share random ideas or interesting things you've discovered recently.`,
                  isAutoJoin: true,
                  name: 'Random',
                  preventUsersLeaving: false,
                  restrictPosting: false,
                  thumbEmoji: '☕',
                }
              ],
              name: '',
              thumbB64: null,
              thumbEmoji: null,
            },
          },
        ],
    },
    id: FeatureId.TeamTemplates,
    isActive: result.isActive,
    updatedAt: new Date().getTime()
  }
}

async function getTeamTiers(): Promise<Feature<TeamTiersData>> {
  let tiersData: RawTeamTiers = { tiers: {} }

  try {
    const res = await axios.get(`${config.app.piecesHost}/${config.app.rootDomain}/tiers_v2.json`, {
      headers: {
        // TODO: Adding header to request causes 403 response from cloudfront. commented this as per discussion here. https://tech9.slack.com/archives/C06B5FDT3GC/p1708442498981989
        // 'Cache-Control': 'no-cache',
        // Pragma: 'no-cache',
        // Expires: '0',
      },
    })

    tiersData = res.data
  } catch (error) {
    if (error.message !== 'Network Error') {
      logger.error('Failed to get team tiers v2', error)
    }
  }

  return deserializeTeamTiers(tiersData)
}

type RawTeamTier = {
  available: boolean
  costPerUserPerPeriod?: number
  costPerPeriod?: number
  sizeID: string
  frequencyID: string
  inStripe?: boolean
  levelID: string
  oldTierID?: string
  pricingID?: string
  shortDesc: string
  sliderText?: string
}
type RawTeamTiers = {
  tiers: { [tierId: string]: RawTeamTier }
}
function deserializeTeamTiers(data: RawTeamTiers): Feature<TeamTiersData> {
  return {
    data: {
      tiers: Object.entries(data.tiers).reduce<TeamTiers>((acc, [tierId, data]) => {
        acc[tierId] = {
          cost: data.costPerUserPerPeriod ?? data.costPerPeriod ?? 0,
          description: data.shortDesc,
          sizeId: data.sizeID as SpaceSize,
          frequencyId: data.frequencyID as BillingFrequency,
          id: tierId,
          isAvailable: data.available,
          isInStripe: data.inStripe ?? false,
          levelId: data.levelID as PlanLevel,
          pricingId: (data.pricingID ?? 'NONE') as PricingType,
          sliderText: data.sliderText ?? '(empty)',
          v1Id: data.oldTierID,
        }

        return acc
      }, {}),
    },
    id: FeatureId.TeamTiers,
    isActive: true,
    updatedAt: new Date().getTime(),
  }
}
