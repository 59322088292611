import { Action } from '@reduxjs/toolkit'
import * as api from 'modules/api/moments'
import { TRANSCRIPTION_UPLOAD_CHUNK_MS } from 'modules/constants'
import logger from 'modules/logger'
import { CreateMomentResult } from 'modules/types/moments'
import { Upload } from 'modules/types/uploads'
import { EventChannel, eventChannel } from 'redux-saga'
import { call, delay, fork, put, race, SagaGenerator, select, take } from 'typed-redux-saga'
import {
  actions,
  CreateUploadSuccessAction,
  CreateUploadSuccessPayload,
  selector,
  RequestStopAction,
} from '../slice'

/*
  # How to do transcriptions with Deepgram on web/desktop

  - User starts a recording.
  - We start a separate audio stream since Deepgram only deals with audio.
  - While we get upload information from the back end, we start buffering audio.
  - Once we have upload information, we create a WebSocket to Deepgram and start sending data.
  - The WebSocket message event contains transcribed data which we hold onto until the volley is complete.
  - When the volley is complete, we tell Deepgram that we're done sending data by sending an empty binary message.
    Deepgram will close the WebSocket after they send the last message.
  - After transcriptions are complete, we upload them to the back end.
  - Once transcriptions & segments are uploaded, we can close the moment.

  ## Caveats

  - Order matters when processing transcriptions so we're really going out of our way to make an async FIFO processing
    queue.
  - If the chunks of audio data are too big, Deepgram rejects WebSocket packets. They don't say how big is too big but
    appears to be ~1000 seconds of data.
  - If the transcriptions fail for whatever reason, the back end will attempt to do another pass which is why we're not
    really doing any retrying.
 */

type DeepgramMessageEvent = Event & { data: string }
type TranscriptionEvents =
  | CloseEvent
  | DeepgramMessageEvent
  | Event
type TranscriptionEventChannel = EventChannel<TranscriptionEvents>

export type TranscriptionSocket = {
  channel: TranscriptionEventChannel
  ws: WebSocket
}
const transcriptionSockets: { [uploadId: string]: TranscriptionSocket } = {}
export function* onTranscriptionUploadCreated(action: CreateUploadSuccessAction): SagaGenerator<void> {
  const { uploadId, transcription } = action.payload
  const state = yield* select(selector.select)
  const upload = state.uploads[uploadId]

  if (!upload.transcription || !transcription?.wssUrl || !transcription?.authMethod || !transcription?.creds) {
    return
  }

  try {
    const ws = api.createTranscriptionWebSocket(transcription.wssUrl, transcription.authMethod, transcription.creds)
    const channel = createEventChannel(ws)
    transcriptionSockets[uploadId] = { ws, channel }
    yield* fork(() => watchWebSocketEvents(uploadId, channel))
    yield* fork(() => processTranscriptionAudioSegments(uploadId))
  } catch (error) {
    logger.error('Error initializing transcription WebSocket', error)
    yield* put(actions.transcriptionFailed({ uploadId }))
  }
}

function createEventChannel(ts: WebSocket): TranscriptionEventChannel {
  return eventChannel<TranscriptionEvents>(emit => {
    const eventHandler = (event: Event) => {
      emit(event)
    }

    ts.addEventListener('close', eventHandler)
    ts.addEventListener('error', eventHandler)
    ts.addEventListener('message', eventHandler)

    return () => {
      ts.removeEventListener('close', eventHandler)
      ts.removeEventListener('error', eventHandler)
      ts.removeEventListener('message', eventHandler)
    }
  })
}

export function* watchWebSocketEvents(uploadId: string, channel: TranscriptionEventChannel): SagaGenerator<void> {
  try {
    while (true) {
      const event = yield* take(channel)

      switch (event.type) {
        case 'message':
          const { data } = event as DeepgramMessageEvent
          yield* put(actions.addTranscribedSegment({ uploadId, segment: data }))

          break
        case 'error':
          logger.warn('Transcription web socket emitted an error event')

          break
        case 'close':
          const { code: statusCode, reason, wasClean } = event as CloseEvent
          const state = yield* select(selector.select)
          const upload = state.uploads[uploadId]

          // 1000 = WebSocket version of HTTP 200
          if (statusCode === 1000) {
            yield* finalizeTranscriptions(upload)
          } else {
            // we often get an error from the web socket when we've canceled the volley
            if (!upload || upload.isCanceled) {
              break
            }

            const hasFinishedTranscription = !!upload
              && !!upload.stopRequested
              && !!upload.transcription?.transcribedSegments.length
              && upload.transcription.transcribedSegments[upload.transcription.transcribedSegments.length - 1]?.includes('transaction_key')
            logger.warn('Transcription web socket closed with unexpected code', {
              hasFinishedTranscription,
              reason,
              statusCode,
              wasClean,
            })
            // If we have stopped recording and have all transcribed segments upload anyway
            if (hasFinishedTranscription) {
              yield* finalizeTranscriptions(upload)
            } else {
              yield* put(actions.transcriptionFailed({ uploadId }))
            }
          }

          break
        default:
          logger.error('Transcription web socket emitted an unknown event type')
          yield* put(actions.transcriptionFailed({ uploadId }))
          channel.close()

          break
      }
    }
  } finally {
    logger.debug('Transcriptions event channel closed')
    channel.close()
  }
}

function* finalizeTranscriptions(upload: Upload | undefined): SagaGenerator<void> {
  const canUploadTranscriptions = !!upload?.transcription?.uploadUrl
    && !upload.transcription.isComplete
    && !upload.transcription.isFailed
    && !!upload.transcription.transcribedSegments.length
  if (!canUploadTranscriptions || !upload?.transcription?.uploadUrl) {
    return
  }

  try {
    yield* call(api.uploadTranscription, upload.transcription.uploadUrl, upload.transcription.transcribedSegments)
    yield* put(actions.transcriptionComplete({ uploadId: upload.id }))
  } catch (error) {
    // Catching this and moving on. The back end can have another go at it.
    logger.error('Error uploading completed transcription', error, { momentId: upload?.momentId })
    yield* put(actions.transcriptionFailed({ uploadId: upload.id }))
  }
}

function* processTranscriptionAudioSegments(uploadId: string): SagaGenerator<void> {
  while (true) {
    const ts = transcriptionSockets[uploadId]
    const state = yield* select(selector.select)
    const uploadState = state.uploads[uploadId]

    const canProcessAnySegments = !!uploadState?.transcription
      && !uploadState.transcription.isFailed
      && !uploadState.transcription.isComplete
      && !uploadState.isCanceled
    if (!canProcessAnySegments || !uploadState?.transcription) {
      return
    }

    const canProcessNextSegment = ts
      && ts.ws.readyState === ts.ws.OPEN
      && !uploadState.transcription.isProcessing
      && uploadState.transcription.audioSegments.length
    if (canProcessNextSegment) {
      // Set that we're processing so we don't pull off any more segments until we're done processing this next segment
      //  so that we're enforcing our FIFO queue.
      yield* put(actions.setIsProcessingTranscriptionAudioSegment({ uploadId }))
      yield* race({
        upload: call(uploadTranscriptionSegment, uploadId, uploadState.transcription.audioSegments[0]),
        cancel: take((action: Action) => actions.cancelUpload.match(action) && action.payload.uploadId === uploadId),
      })
    } else {
      yield* race({
        // still connecting to WebSocket, waiting for segments, or waiting for things to process
        delay: delay(TRANSCRIPTION_UPLOAD_CHUNK_MS),
        // segment came through, let's short circuit the delay
        upload: take((action: Action) => actions.uploadTranscriptionAudioSegment.match(action)
          && action.payload.uploadId === uploadId),
        // whole thing is canceled, bail
        cancel: take((action: Action) => actions.cancelUpload.match(action) && action.payload.uploadId === uploadId)
      })
    }
  }
}

export function* uploadTranscriptionSegment(uploadId: string, data: Blob | null, socket?: TranscriptionSocket): SagaGenerator<void> {
  const ts = socket ?? transcriptionSockets[uploadId]

  if (!ts || ts.ws.readyState !== ts.ws.OPEN) {
    logger.warn('Attempting to upload segment to an inactive transcription web socket')
    return
  }

  try {
    // We tell Deepgram that we're not sending any more data by sending an empty Uint8Array
    ts.ws.send(data ?? new Uint8Array(0))
    yield* put(actions.uploadTranscriptionAudioSegmentSuccess({ uploadId }))
  } catch (error) {
    logger.error('Error sending data to transcription web socket', error)
    yield* put(actions.transcriptionFailed({ uploadId }))
  }
}

export function* onStopTranscriptionRequested({ payload: { uploadId } }: RequestStopAction): SagaGenerator<void> {
  const state = yield* select(selector.select)
  const upload = state.uploads[uploadId]

  if (upload?.transcription) {
    yield* delay(TRANSCRIPTION_UPLOAD_CHUNK_MS) // let any remaining segments queue up
    yield* put(actions.uploadTranscriptionAudioSegment({ uploadId, data: null }))
  }
}

export function onCloseTranscription(uploadId: string, socket?: TranscriptionSocket): void {
  const ts = socket ?? transcriptionSockets[uploadId]

  if (!ts || ts.ws.readyState !== ts.ws.OPEN) {
    return
  }

  try {
    ts.ws.close()
  } catch (error) {
    logger.error('Error closing transcription web socket', error)
  } finally {
    delete transcriptionSockets[uploadId]
  }
}

export function addTranscriptionUploadParams(createMomentResult: CreateMomentResult, putParams: CreateUploadSuccessPayload): CreateUploadSuccessPayload {
  const supportsTranscriptions = createMomentResult.transcriptionAuthMethod
    && createMomentResult.transcriptionEnterpriseCreds
    && createMomentResult.transcriptionEnterpriseWssUrl
    && createMomentResult.transcriptionUploadUrl

  return supportsTranscriptions
    ? {
      ...putParams,
      transcription: {
        authMethod: createMomentResult.transcriptionAuthMethod,
        creds: createMomentResult.transcriptionEnterpriseCreds,
        isComplete: false,
        uploadUrl: createMomentResult.transcriptionUploadUrl,
        wssUrl: createMomentResult.transcriptionEnterpriseWssUrl,
      }
    }
    : putParams
}
