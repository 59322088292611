import { Action } from '@reduxjs/toolkit'
import * as api from 'modules/api/tokens'
import logger from 'modules/logger'
import { ConversationType } from 'modules/types/conversations'
import { TokenParams, TokenType } from 'modules/types/tokens'
import { all, call, delay, put, race, SagaGenerator, select, take, takeEvery } from 'typed-redux-saga'
import { actions as alertsActions } from '../alerts/slice'
import { actions as analyticsActions } from '../analytics/slice'
import { selector as conversationsSelector } from '../conversations/slice'
import { actions as usersActions, selector as usersSelector } from '../users/slice'
import { actions as teamsActions } from '../teams/slice'
import {
  actions,
  ApplyTokenAction,
  CreateTokenSuccessAction,
  FetchTokenAction,
  selector,
  SendEmailInviteAction,
  ToggleTtmovAction,
  UpdateTokenPayload,
} from './slice'

export default function* main(): SagaGenerator<void> {
  yield* takeEvery(actions.updateToken, action => updateToken(action.payload))
  yield* takeEvery(actions.createToken, action => createToken(action.payload))
  yield* takeEvery(actions.createTokenSuccess, createTokenSuccess)
  yield* takeEvery(actions.applyToken, applyToken)
  yield* takeEvery(actions.sendEmailInvite, sendEmailInvite)
  yield* takeEvery(actions.toggleTtmov, toggleTtmov)
}

export function* unauthenticatedMain(): SagaGenerator<void> {
  yield* takeEvery(actions.fetchToken, fetchToken)
}

export function* fetchToken(action: FetchTokenAction): SagaGenerator<void> {
  try {
    const token = yield* call(api.getToken, { id: action.payload.id })
    if (token) {
      yield* put(actions.fetchTokenSuccess(token))
    } else {
      yield* put(actions.fetchTokenFailure({ id: action.payload.id, error: 'Token was not found' }))
    }
  } catch (error) {
    logger.error('Error getting token', error, { id: action.payload.id })
    yield* put(actions.fetchTokenFailure({ error: error?.message ?? error?.toString(), id: action.payload.id }))
  }
}

export function* updateToken(params: UpdateTokenPayload): SagaGenerator<void> {
  const { id } = params

  try {
    const state = yield* select(selector.select)
    const token = state.tokens[id]

    yield* call(api.updateToken, params)
    yield* put(actions.updateTokenSuccess({ id, type: (token?.token && token.token.type) ?? null }))
  } catch (error) {
    logger.error('Error updating token', error, { id })
    yield* put(actions.updateTokenFailure({ error: error?.message ?? error?.toString(), id }))
  }
}

export function* createToken(params: TokenParams): SagaGenerator<string | null> {
  try {
    const token = yield* call(api.createToken, params)

    if (params.type === TokenType.PublicMoment && params.data.copyToClipboard) {
      yield* call([navigator.clipboard, navigator.clipboard.writeText], token.landingUrl)
      yield* put(alertsActions.pushAlert({ severity: 'success', message: 'Link copied to clipboard' }))
    }
    yield* put(actions.createTokenSuccess({ params, token }))

    return token.id
  } catch (error) {
    logger.error('Error creating token', error, { ...params })
    if (params.type === TokenType.PublicMoment && params.data.copyToClipboard) {
      yield* put(alertsActions.pushAlert({ severity: 'error', message: 'Error generating link. Please try again.' }))
    }

    yield* put(actions.createTokenFailure({ error: error?.message ?? error?.toString(), params }))

    return null
  }
}

function* createTokenSuccess(action: CreateTokenSuccessAction): SagaGenerator<void> {
  const { type: vType, vData } = action.payload.token
  if (vType === TokenType.Slack) {
    window.open(vData.redirectURL)
  }
}

export function* applyToken(action: ApplyTokenAction): SagaGenerator<void> {
  const { id: tokenId } = action.payload

  const { currentUser } = yield* select(usersSelector.select)
  const state = yield* select(selector.select)
  const tokenResult = state.tokens[tokenId]

  if (!currentUser || currentUser.isVisitor) {
    return
  }
  if (!tokenResult || !tokenResult.token || tokenResult.error) {
    yield* put(actions.applyTokenFailure({ error: tokenResult?.error ?? 'Token not found', id: tokenId }))
    return
  }

  try {
    const { conversationId, teamId: appliedTeamId } = yield* call(api.applyToken, tokenId)

    yield* race({
      sleep: delay(3000),
      user: all([
        take(usersActions.updateCurrentUserSuccess),
        race(appliedTeamId
          ? [
            take((action: Action) =>
              teamsActions.refetchTeamSuccess.match(action) && action.payload.team.id === appliedTeamId),
            take((action: Action) =>
              teamsActions.fetchTeamsSuccess.match(action) && Object.keys(action.payload.teams).some(id => id === appliedTeamId)),
          ]
          : []),
      ]),
    })

    const teamId = appliedTeamId ? appliedTeamId : yield* getConversationTeamId(conversationId)
    if (teamId) {
      yield* put(analyticsActions.teamJoin({ teamId, tokenId }))
    }
    if (conversationId) {
      yield* put(analyticsActions.conversationJoin({ conversationId, teamId: teamId ?? '', tokenId }))
    }
    if (tokenResult.token.type === TokenType.SellerCoupon && tokenResult.token.vData.applyMessageText !== '') {
      yield* put(alertsActions.pushAlert({ message: `${tokenResult.token.vData.applyMessageText ?? 'Discount code'} has been applied`, severity: 'success' }))
    }
    if (tokenResult.token.type === TokenType.Referral && tokenResult.token.vData.applyMessageText !== '') {
      yield* put(alertsActions.pushAlert({ message: tokenResult.token.vData.applyMessageText, severity: 'success' }))
    }

    yield* put(actions.applyTokenSuccess({ conversationId, teamId, token: tokenResult.token }))
  } catch (error) {
    logger.error('Error applying token', error, { tokenId })
    yield* put(actions.applyTokenFailure({ error: error.message, id: tokenId }))
  }
}

function* getConversationTeamId(conversationId?: string): SagaGenerator<string | undefined> {
  if (!conversationId) {
    return undefined
  }

  const { conversations } = yield* select(conversationsSelector.select)
  if (!conversations[conversationId]) {
    return undefined
  }

  return conversations[conversationId].teamId
}

function* sendEmailInvite(action: SendEmailInviteAction): SagaGenerator<void> {
  const { teamName, url } = action.payload

  const subject = `Join the "${teamName}" space on Volley`
  const body = `I'm inviting you to join the "${teamName}" space on Volley. ${url}`
  const mailToLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`

  window.open(mailToLink, '_blank', 'noopener')
}

export function* toggleTtmov(action: ToggleTtmovAction): SagaGenerator<void> {
  const { conversationId, type } = action.payload

  const { currentUser } = yield* select(usersSelector.select)
  if (!currentUser) {
    return
  }

  const ttmovData = Object.values(currentUser.talkToMeOnVolleyData).find(d => d.vType === type)
  const existingTokenId = ttmovData?.tokenId ?? null
  let tokenId: string | null = null

  if (existingTokenId) {
    yield* updateToken({ disable: true, id: existingTokenId })
    tokenId = null
  } else {
    tokenId = yield* createToken({ data: { conversationId }, type: type === ConversationType.TalkToMeOnVolley ? TokenType.User : TokenType.PremiumUser })
  }

  yield* put(actions.toggleTtmovSuccess({ conversationId, tokenId }))
}
