import styled from '@emotion/styled'
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'
import React, { ReactNode } from 'react'

import Button from '../Button'
import CloseXIcon from 'assets/close-x.svg'
import LeftArrowIcon from 'assets/left-arrow.svg'
import IconButton from '../IconButton'
import { OnCloseEvent } from './index'

const VolHeader = styled(DialogTitle)`
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 11px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`
const TitleContainer = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
`
export const Title = styled.h1`
  text-align: center;
  font-size: 15px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-bottom: 0;
`
export const Subtitle = styled.div`
  text-align: center;
  font-size: 13px;
  color: #3d3d3f;
  overflow: hidden;
  text-overflow: ellipsis;
`
export type Placement = 'left' | 'right'
export const ActionButton = styled(Button, { shouldForwardProp: propName => propName !== 'placement' }) <{ placement: Placement }>`
  margin-right: ${({ placement }) => placement === 'right' ? '-11px' : undefined};
  margin-left: ${({ placement }) => placement === 'left' ? '-11px' : undefined};
`

export type HeaderActionEvent = () => void
type HeaderActionProps = {
  ariaLabel?: string
  disabled?: boolean
  onAction?: HeaderActionEvent
}
export const CloseButton = ({ disabled, onAction, ariaLabel }: HeaderActionProps): React.ReactElement => (
  <IconButton aria-label={ariaLabel || 'close'} disabled={disabled} onClick={onAction} src={CloseXIcon} />
)
export const BackButton = ({ disabled, onAction, ariaLabel }: HeaderActionProps): React.ReactElement => (
  <IconButton aria-label={ariaLabel || 'back'} disabled={disabled} onClick={onAction} src={LeftArrowIcon} />
)

type ActionContainerProps = {
  left?: boolean
}
const ActionContainer = styled.div<ActionContainerProps>(({ left }) => ({
  display: 'flex',
  justifyContent: left ? 'flex-start' : 'flex-end',
  flexBasis: '80px',
}))

type HeaderProps = DialogTitleProps & {
  ariaLabeledById: string
  leftActionElement?: ReactNode
  rightActionElement?: ReactNode
  onClose?: OnCloseEvent
}
const Header = (props: HeaderProps): React.ReactElement => {
  const { ariaLabeledById, children, leftActionElement, onClose, rightActionElement, ...rest } = props

  return (
    <VolHeader {...rest}>
      <ActionContainer left>
        {leftActionElement}
      </ActionContainer>
      <TitleContainer>
        {React.Children.map(children, child =>
          typeof child === 'string' ? <Title id={ariaLabeledById}>{child}</Title> : child
        )}
      </TitleContainer>
      <ActionContainer>
        {!rightActionElement ? (onClose && <CloseButton onAction={onClose} />) : rightActionElement}
      </ActionContainer>
    </VolHeader>
  )
}
export default Header
