// eslint-disable-next-line @typescript-eslint/ban-types
export type LogArgument = object | Error

type Level = 'debug' | 'info' | 'warn' | 'error'
export class Logger {
  debug(msg: string, ...args: LogArgument[]): void {
    Logger.log('debug', msg, ...args)
  }
  info(msg: string, ...args: LogArgument[]): void {
    Logger.log('info', msg, ...args)
  }
  warn(msg: string, ...args: LogArgument[]): void {
    Logger.log('warn', msg, ...args)
  }
  error(msg: string, ...args: LogArgument[]): void {
    Logger.log('error', msg, ...args)
  }

  private static log(level: Level, msg: string, ...args: LogArgument[]): void {
    if (process.env.NODE_ENV === 'test') {
      return
    }

    if (args.length) {
      console[level](msg, args.reduce((acc, arg) =>
        arg instanceof Error
          ? { ...acc, error: arg }
          : { ...acc, ...arg }, {}))
    } else {
      console[level](msg)
    }

    window.Rollbar?.[level](msg, ...args)
  }
}

const logger = new Logger()

export default logger
