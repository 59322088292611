import axios from 'axios'
import config from 'modules/config'
import logger from 'modules/logger'
import { VolleyStatus } from 'modules/types/application'

export async function getVolleyStatus(): Promise<VolleyStatus> {
  try {
    const res = await axios.get(`${config.app.statusHost}/${config.env}/status.json`)

    return res.data
  } catch (error) {
    if (error.message !== 'Network Error') {
      logger.error('Failed to get volley status', error)
    }

    return {
      bucketRegion: '',
      isUp: true,
    }
  }
}
