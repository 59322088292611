import logger from 'modules/logger'
import { EntityType, SubscriptionsAction, SubscriptionsBody, SubscriptionsMessage } from 'modules/types/subscriptions'

export function createWebSocket(url: string, token: string): WebSocket {
  return new WebSocket(url, ['Authorization', token])
}

export function sendKeepAlive(socket: WebSocket): void {
  socket.send(JSON.stringify({ action: SubscriptionsAction.KeepAlive }))
}

export function reauthenticateWebSocket(socket: WebSocket, token: string): void {
  const message = {
    action: SubscriptionsAction.Reauthenticate,
    body: JSON.stringify({ accessToken: token }),
  }

  socket.send(JSON.stringify(message))
}

type RawSubscriptionsMessage = {
  action: SubscriptionsAction
  body: string
  statusCode: number
}

export function deserializeSubscriptionsMessage(rawMessage: string): SubscriptionsMessage {
  try {
    const msg: RawSubscriptionsMessage = JSON.parse(rawMessage)

    return {
      action: msg.action,
      body: deserializeMessageBody(msg),
      statusCode: msg.statusCode,
    }
  } catch (error) {
    logger.error('Failed to deserialize subscription message', error, { message: rawMessage })
    return {
      action: SubscriptionsAction.Unknown,
      body: {
        actor: 'unknown',
        entityId: 'unknown',
        entityType: EntityType.Unknown,
      },
      statusCode: 500,
    }
  }
}

function deserializeMessageBody(message: RawSubscriptionsMessage): SubscriptionsBody {
  try {
    const parsed = JSON.parse(message.body)

    const body: SubscriptionsBody = {
      actor: parsed.actor,
      entityId: parsed.entityID ?? '(none)',
      entityType: parsed.entityType ?? '(none)',
    }
    if (parsed.message) {
      body.message = parsed.message
    }
    if (parsed.parentID) {
      body.parentId = parsed.parentID
    }

    return body
  } catch (error) {
    logger.error('Failed to deserialize subscription message body', error, { message })
    return {
      actor: 'unknown',
      entityId: 'unknown',
      entityType: EntityType.Unknown,
    }
  }
}

export function requestSubscriptionToEntity(socket: WebSocket, entityId: string): void {
  const message = {
    action: SubscriptionsAction.SubscribeToEntity,
    body: JSON.stringify({ entityID: entityId }),
  }
  socket.send(JSON.stringify(message))
}
