import data from '@emoji-mart/data/sets/14/apple.json'
import { FrequentlyUsed, init, SearchIndex } from 'emoji-mart'
import * as localStorageFacade from './local-storage'

export const EMOJI_PER_LINE = 5
export const MAX_FREQUENT_ROWS = 4
// Setting count is unfortunate, but required to create the desired order
const DEFAULT_EMOJI_SET = {
  ['+1']: 4,
  fire: 1,
  joy: 3,
  slightly_smiling_face: 2,
  v: 5,
}
const DEFAULT_FREQUENT_EMOJI = ['v', '+1', 'joy', 'slightly_smiling_face', 'fire']

let hasInitialized = false
if (!hasInitialized) {
  // Emoji-Mart has a pretty bad api for customizing frequently used emoji
  //  so we inject straight into the localStorage item at load time
  if (!localStorageFacade.getItem('emoji-mart.frequently')) {
    localStorageFacade.setItem('emoji-mart.frequently', JSON.stringify(DEFAULT_EMOJI_SET))
    // initialize FrequentlyUsed index with what was just added to localStorage
    getFrequentEmoji()
  }

  void init({ data })
  hasInitialized = true
}

export function getFrequentEmoji(): string[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let frequentlyUsed: string[] = (FrequentlyUsed as any).get({
    maxFrequentRows: MAX_FREQUENT_ROWS,
    perLine: EMOJI_PER_LINE,
  })
  frequentlyUsed = [...frequentlyUsed, ...DEFAULT_FREQUENT_EMOJI]

  return frequentlyUsed.slice(0, 5)
}

export function getNativeFromEmojiId(emojiId: string): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emoji = (SearchIndex as any).get(emojiId)
  if (!emoji) {
    return '🤷‍'
  }

  return emoji.skins[0].native
}
