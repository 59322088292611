import { partition } from 'lodash-es'
import * as api from 'modules/api/moments'
import logger from 'modules/logger'
import {
  Moment,
  MomentLinkPreviewDictionary,
  MomentsDictionary,
  MomentState,
  MomentTextParagraphs,
  MomentType,
} from 'modules/types/moments'
import { EntityType } from 'modules/types/subscriptions'
import { call, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga'
import { actions as alertsActions } from '../alerts/slice'
import { actions as analyticsActions } from '../analytics/slice'
import { actions as applicationActions, selector as applicationSelector } from '../application/slice'
import { selector as conversationsSelector } from '../conversations/slice'
import { actions as modalsActions } from '../modals/slice'
import { actions as streamUploadsActions } from '../stream-uploads/slice'
import { actions as subscriptionsActions, UpdateEntityAction } from '../subscriptions/slice'
import { selector as teamsSelector } from '../teams/slice'
import { actions as textsActions, selector as textsSelector, SendTextAction } from '../texts/slice'
import { actions as usersActions, selector as usersSelector, UpdateCurrentUserAction } from '../users/slice'
import {
  actions,
  DeleteMomentAction,
  FetchLinkPreviewsAction,
  FetchMomentsAction,
  FetchTranscriptionsAction,
  ForwardMomentsAction,
  PrepareDownloadAction,
  ReactToMomentAction,
  RefreshDownloadUrlAction,
  selector,
  StartViewingMomentAction,
} from './slice'
import { getLinksFromText, getParagraphsWithPreviews, getPlaceholderPreviews } from './utils'

export default function* main(): SagaGenerator<void> {
  yield* takeEvery(actions.fetchMoments, fetchMoments)
  yield* takeEvery(actions.refetchMoments, action => refetchMoments(action.payload.ids))
  yield* takeEvery(actions.fetchLinkPreviews, fetchLinkPreviews)
  yield* takeEvery(actions.fetchTranscriptions, fetchTranscriptions)
  yield* takeEvery(actions.deleteMoment, deleteMoment)
  yield* takeEvery(actions.forwardMoments, forwardMoments)
  yield* takeEvery(actions.startViewingMoment, startViewingMoment)
  yield* takeEvery(actions.reactToMoment, reactToMoment)
  yield* takeEvery(actions.refreshDownloadUrl, refreshDownloadUrl)
  yield* takeEvery(usersActions.blockedUsersChanged, handleBlockedUsersChanged)
  yield* takeEvery(actions.prepareDownload, prepareDownload)
  yield* takeEvery(actions.setConversationIsStale, action => markCachedConversationMomentsAsStale(action.payload.conversationId))
  yield* takeEvery(applicationActions.selectConversation, action => markCachedConversationMomentsAsStale(action.payload.conversationId))
  yield* takeEvery(applicationActions.selectMoment, action => markCachedConversationMomentsAsStale(action.payload.conversationId))
  yield* takeEvery(subscriptionsActions.updateEntity, handleSubscriptionsUpdateEntity)
  yield* takeEvery(streamUploadsActions.closeUpload, () => processViewedMoments())
  yield* takeEvery(textsActions.sendText, handleSendText)
  yield* takeEvery(usersActions.updateCurrentUser, handleMarkConversationAsViewed)
}

export function* fetchMoments(action: FetchMomentsAction): SagaGenerator<void> {
  try {
    const [serverIds, nonServerIds] = partition(action.payload.ids, isServerMoment)
    if (nonServerIds.length)
      logger.error('Attempt to fetch non-server moments', nonServerIds)

    const { missingIds, refetchIds, success: moments } = yield* call(api.getMoments, serverIds)
    if (refetchIds.length) {
      yield* put(actions.fetchMoments({ ids: refetchIds }))
    }
    if (missingIds.length) {
      logger.warn('Moments not found', { ids: missingIds })
      yield* put(actions.fetchMomentsFailure({ ids: missingIds, reason: 'Not found' }))
    }

    if (Object.keys(moments).length) {
      yield* put(actions.fetchMomentsSuccess({ moments }))
    }
  } catch (err) {
    logger.error('Error getting moments', err)
    yield* put(actions.fetchMomentsFailure({ ids: action.payload.ids, reason: err?.message ?? 'Error thrown' }))
  }
}

function isServerMoment(momentId: string): boolean {
  return momentId.endsWith('-m')
}

export function* fetchLinkPreviews(action: FetchLinkPreviewsAction): SagaGenerator<void> {
  const { momentId } = action.payload

  try {
    if (!isServerMoment(momentId)) {
      logger.error('Attempt to fetch non-server moment link previews', { momentId })
      return
    }

    const state = yield* select(selector.select)
    const moment = state.moments[momentId]
    if (!moment) {
      logger.warn('Attempt to fetch link previews before getting moment', { momentId })
      yield* put(actions.fetchMoments({ ids: [momentId] }))
      return
    }

    if (moment.vType !== MomentType.Text || !moment.textContent) {
      logger.warn('Attempt to fetch link previews for a non-text moment', { momentId })
      return
    }

    const { textContent, textParagraphs } = moment
    if (textParagraphs?.initialized) {
      return
    }

    let previews = getPreviews(textContent.text, textParagraphs)
    if (Object.keys(previews).length) {
      previews = yield* call(api.getLinkPreviews, previews)
    }
    const paragraphs = getParagraphsWithPreviews(textContent.text, previews)

    yield* put(actions.fetchLinkPreviewsSuccess({ momentId, paragraphs, previews }))
  } catch (error) {
    logger.error('Error fetching link previews', error, { momentId })
    yield* put(actions.fetchLinkPreviewsFailure({ momentId, reason: error?.message ?? 'Error thrown' }))
  }
}

function getPreviews(text: string, textParagraphs?: MomentTextParagraphs): MomentLinkPreviewDictionary {
  return textParagraphs?.previews ?? getPlaceholderPreviews(getLinksFromText(text))
}

export function* fetchTranscriptions(action: FetchTranscriptionsAction): SagaGenerator<void> {
  const { momentId } = action.payload
  try {
    if (!isServerMoment(momentId)) {
      logger.error('Attempt to fetch non-server moment transcription', { momentId })
      return
    }

    const state = yield* select(selector.select)
    const moment = state.moments[momentId]
    if (!moment) {
      logger.warn('Attempt to fetch transcriptions before getting moment', { momentId })
      yield* put(actions.fetchMoments({ ids: [momentId] }))
      return
    }

    const transcriptionData = yield* call(api.fetchTranscriptions, moment.transcriptionDownloadUrls)
    yield* put(actions.fetchTranscriptionsSuccess({ momentId, transcriptionData }))
  } catch (error) {
    logger.error('Error fetching transcriptions', error, { momentId })
    yield* put(actions.fetchTranscriptionsFailure({ momentId, reason: error?.message ?? 'Error thrown' }))
  }
}

function* refetchMoments(momentIds: string[]): SagaGenerator<void> {
  try {
    const [serverIds, nonServerIds] = partition(momentIds, isServerMoment)
    if (nonServerIds.length)
      logger.error('Attempt to re-fetch non-server moments', nonServerIds)

    const { refetchIds, missingIds, success: moments } = yield* call(api.getMoments, serverIds)
    if (refetchIds.length) {
      yield* refetchMoments(refetchIds)
    }
    if (missingIds.length) {
      logger.warn('Missing moments on re-fetch', { missingIds })
    }

    let updatedMoments: MomentsDictionary = {}
    for (const moment of Object.values(moments)) {
      updatedMoments = {
        ...updatedMoments,
        [moment.id]: yield* mergeMomentUpdates(moment),
      }
    }
    yield* put(actions.refetchMomentsSuccess({ moments: updatedMoments }))
  } catch (error) {
    logger.error('Error re-fetching moments', error, { momentIds })
  }
}

function* mergeMomentUpdates(updatedMoment: Moment): SagaGenerator<Moment> {
  const { moments } = yield* select(selector.select)
  const existingMoment = moments[updatedMoment.id]

  return existingMoment
    ? {
      ...updatedMoment,
      textParagraphs: existingMoment.textParagraphs,
      transcriptionData: existingMoment.transcriptionData,
    }
    : updatedMoment
}

let previousConversationId: string | null = null
export function* markCachedConversationMomentsAsStale(conversationId: string | null): SagaGenerator<void> {
  if (previousConversationId === conversationId) {
    return
  }

  if (previousConversationId) {
    const state = yield* select(selector.select)
    const cachedMoments = Object.values(state.moments).filter(m => m.conversationId === previousConversationId)
    if (cachedMoments.length) {
      yield* put(actions.setIsStale({ ids: cachedMoments.map(m => m.id) }))
    }
  }

  previousConversationId = conversationId
}

export function* deleteMoment(action: DeleteMomentAction): SagaGenerator<void> {
  const { conversationId, momentId, selectNextMoment, type } = action.payload

  try {
    yield* call(api.deleteMoment, momentId)
    if (selectNextMoment) {
      const { selectedMomentId } = yield* select(applicationSelector.select)

      if (selectedMomentId) {
        yield* put(applicationActions.selectConversation({ conversationId }))
      }
    }

    yield* put(actions.deleteMomentSuccess({ conversationId, momentId, type }))
  } catch (error) {
    logger.error('Error deleting moment', error, { id: momentId })
  }
}

export function* forwardMoments(action: ForwardMomentsAction): SagaGenerator<void> {
  const { conversationId, conversationName, momentIds, teamId } = action.payload
  try {
    yield* call(api.forwardMoments, momentIds, conversationId)
    yield* put(actions.forwardMomentsSuccess({ momentIds }))
    yield* put(alertsActions.pushAlert({
      message: [
        conversationName ? `Forwarded to ${conversationName}. ` : 'Forwarded successfully. ',
        { conversationId, teamId, text: 'Go there >' },
      ],
      severity: 'success',
    }))
  } catch (error) {
    logger.error('Error forwarding volley', error)
    yield* put(actions.forwardMomentsFailure({ momentIds }))
    yield* put(alertsActions.pushAlert({
      message: error.message.includes('does not have permission')
        ? `You do not have permission to post in ${conversationName}`
        : 'An error occurred while forwarding the volley',
      severity: 'error'
    }))
  }
}

export function* startViewingMoment(action: StartViewingMomentAction): SagaGenerator<void> {
  const { momentId } = action.payload

  if (!isServerMoment(momentId)) {
    logger.error('Attempt to start viewing non-server moment', { momentId })
    return
  }

  yield* processViewedMoments(momentId)
}

export function* handleMarkConversationAsViewed(action: UpdateCurrentUserAction): SagaGenerator<void> {
  const { catchUpConversationIds } = action.payload
  const { selectedConversationId } = yield* select(applicationSelector.select)
  if (!selectedConversationId || !catchUpConversationIds?.includes(selectedConversationId)) {
    return
  }

  yield* processViewedMoments()
}

export function* processViewedMoments(viewedMomentId?: string): SagaGenerator<void> {
  const { inViewStaticMoments, moments } = yield* select(selector.select)
  if (!viewedMomentId && !inViewStaticMoments.length) {
    return
  }

  const viewedMoment = (viewedMomentId && moments[viewedMomentId]) ?? null
  const { remaining, viewed } = inViewStaticMoments.reduce<{ remaining: string[], viewed: Moment[] }>((acc, id) => {
    const momentToView = moments[id]
    if (!isServerMoment(id) || viewedMomentId === id || !momentToView) {
      return acc
    }

    if (!viewedMoment || viewedMoment.createdOn > momentToView.createdOn) {
      acc.viewed.push(momentToView)
    } else {
      acc.remaining.push(id)
    }

    return acc
  }, { viewed: viewedMoment ? [viewedMoment] : [], remaining: [] })

  try {
    yield* call(api.startViewingMoment, viewed.sort((a, b) => a.createdOn > b.createdOn ? 1 : -1).map(m => m.id))

    if (viewed.length === 1 && viewed[0] === viewedMoment && !inViewStaticMoments.includes(viewedMoment.id)) {
      return
    }

    yield* put(actions.clearInViewStaticMoments({ remainingMomentIds: remaining }))
  } catch (error) {
    logger.error('Error while calling start view moment', error, { momentIds: viewed })
  }
}

function* handleSendText(action: SendTextAction): SagaGenerator<void> {
  const { textId } = action.payload

  const { texts } = yield* select(textsSelector.select)
  if (!texts[textId] || texts[textId].isEditing) {
    return
  }

  yield* processViewedMoments()
}

export function* reactToMoment(action: ReactToMomentAction): SagaGenerator<void> {
  const { id, reaction, userId } = action.payload

  try {
    yield* call(api.reactToMoment, id, reaction)
    yield* put(actions.reactToMomentSuccess({ id, reaction, userId }))
  } catch (error) {
    logger.error('Error reacting to moment', error)
    yield* put(alertsActions.pushAlert({ message: 'Failed to save reaction to volley', severity: 'error' }))
  }
}

export function* refreshDownloadUrl(action: RefreshDownloadUrlAction): SagaGenerator<void> {
  const { id } = action.payload
  try {
    const result = yield* call(api.refreshDownloadUrl, id)
    if (result) {
      yield* put(actions.refreshDownloadUrlSuccess({ ...result, id }))
    }
  } catch (error) {
    logger.error('Failed to refresh download url', error)
    yield* put(alertsActions.pushAlert({ message: 'Failed to download volley', severity: 'error' }))
  }
}

export function* handleBlockedUsersChanged(): SagaGenerator<void> {
  const { selectedConversationId } = yield* select(applicationSelector.select)
  if (selectedConversationId) {
    yield* markCachedConversationMomentsAsStale(selectedConversationId)
  }
}

export function* prepareDownload(action: PrepareDownloadAction): SagaGenerator<void> {
  const { momentId } = action.payload

  try {
    yield* call(api.updateMoment, { momentId, prepareDownload: true })
    const { currentUser } = yield* select(usersSelector.select)
    yield* put(analyticsActions.volleyTriggerDownloadEmail({ momentId }))
    if (currentUser) {
      yield* put(modalsActions.showConfirmDialog({
        ariaLabeledById: 'processing-for-download-modal',
        confirmText: 'Okay',
        content: `We'll send an email to ${currentUser.email} when your volley is ready for download.`,
        title: 'Processing For Download',
      }))
    }
  } catch (error) {
    logger.error('Error starting download', error, { momentId })
    yield* put(alertsActions.pushAlert({
      message: 'There was an error preparing your download. Please try again.',
      severity: 'error'
    }))
  }
}

export function* handleSubscriptionsUpdateEntity(action: UpdateEntityAction): SagaGenerator<void> {
  const { entityId, entityType, parentId } = action.payload
  if (entityType !== EntityType.Moment) {
    return
  }

  const { moments } = yield* select(selector.select)
  const { selectedConversationId } = yield* select(applicationSelector.select)
  if (selectedConversationId !== parentId && (!moments[entityId] || moments[entityId].vState === MomentState.Complete)) {
    const { currentUser } = yield* select(usersSelector.select)
    const { conversations } = yield* select(conversationsSelector.select)
    const conversation = parentId ? conversations[parentId] : null
    const { teams } = yield* select(teamsSelector.select)

    if ((!currentUser || !Object.keys(currentUser.talkToMeOnVolleyData).find(id => id === parentId))
      && (!conversation || !teams[conversation.teamId]
        || (teams[conversation.teamId].welcomeConversationId !== parentId
          && teams[conversation.teamId].premiumSeedConversationId !== parentId))) {
      return
    }
  }

  yield* refetchMoments([entityId])
}
