import { Offer, OfferType } from './offers'
import { Purchase, PurchaseState } from './purchases'
import { TierLimits } from './tier-limits'
import { Participant } from './users'

export enum TeamAccessLevel {
  Full = 'FULL',
  None = 'NONE',
  Super = 'SUPER',
  Visitor = 'VISITOR',
}
export enum TeamState {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Pending = 'PENDING', // GROW team has not finished stripe setup
}
export type TeamsDictionary = { [teamId: string]: Team }
export type Team = {
  id: string
  accessLevel: TeamAccessLevel
  billingUrl?: string
  channels: TeamChannelsDictionary
  conversationIds: string[]
  createChannelByAdminOnly: boolean
  createdOn: string | null
  creatorUserId: string
  disableDownloading: boolean
  disableForwarding: boolean
  disableSharing: boolean
  guestConversations: GuestConversationsDictionary
  guestIds: string[]
  inviteByAdminOnly: boolean
  isAdhoc: boolean
  isPremiumActive: boolean
  isPublic: boolean
  name: string
  offers: Offer[]
  premiumSeedConversationId: string | null
  premiumLandingTokenId: string | null
  purchases: Purchase[]
  sellerData: SellerData | null
  teamAdminIds: string[]
  thumbEol: number | null
  thumbUpdatedOn: string | null
  thumbUrl: string | null
  tierEol: string | null
  tierId: string
  tierLimits: TierLimits
  tierDescription: string
  tierWillRenew: boolean
  tokensCreated: string[]
  userIds: string[]
  vState: TeamState
  welcomeConversationId: string | null
}
export type TeamChannelsDictionary = {
  [channelId: string]: TeamChannel
}
export type TeamChannel = {
  description: string | null
  id: string
  isAutoJoin: boolean
  isEveryone: boolean
  name: string
  preventDeletion: boolean
  preventUsersLeaving: boolean
  thumbEmoji: string | null
  thumbEol: number | null
  thumbUrl: string | null
  userCountString: string
}
export type GuestConversationsDictionary = {
  [conversationId: string]: string[] // guest IDs
}
export type SellerData = {
  analyticsUrl: string
}
export type TemplateTeamAdminControls = {
  createChannelByAdminOnly?: boolean
  disableDownloading?: boolean
  disableForwarding?: boolean
  disableSharing?: boolean
  inviteByAdminOnly?: boolean
}
export type TemplateTeamChannel = {
  description: string
  isAutoJoin: boolean
  isEveryoneChannel?: boolean
  name: string
  preventUsersLeaving: boolean
  restrictPosting: boolean
  thumbEmoji: string
}
export type TemplateTeam = {
  adminControls: TemplateTeamAdminControls
  channels: TemplateTeamChannel[]
  name: string
  thumbB64: string | null
  thumbEmoji: string | null
}
export type TeamRosterMember = Omit<Participant, 'thumbUrl'> & {
  billingCycleEndsOn: string
  email: string
  isTeamAdmin: boolean
  joinedOn: string
  offerType: OfferType
  purchaseId: string
  purchaseState: PurchaseState
}
export type TeamRosterMembersDictionary = { [id: string]: TeamRosterMember }
export type TeamRoster = {
  roster: TeamRosterMembersDictionary
  rosterCsv?: string
}
