import styled from '@emotion/styled'

import ActionIconButton from './ActionIconButton'
import ActionToggleSwitch from './ActionToggleSwitch'

export { ActionIconButton, ActionToggleSwitch }

export const HeadingLabel = styled.h2`
  height: 36px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0 0;
  padding-left: 16px;
  padding-right: 16px;
`

type ActionContainerProps = {
  hideBorderBottom?: boolean
  hideBorderTop?: boolean
}
export const ActionContainer = styled.div<ActionContainerProps>`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  border-top: ${({ hideBorderTop, theme }) => !hideBorderTop ? `1px solid ${theme.palette.divider}` : 'none'};
  border-bottom: ${({ hideBorderBottom, theme }) => !hideBorderBottom ? `1px solid ${theme.palette.divider}` : 'none'};
  padding: 0 16px;
`

export const TextFieldContainer = styled.div`
  padding: 8px;
`
