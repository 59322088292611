import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import config from 'modules/config'
import { getDefaultFeatures } from 'modules/api/features'
import {
  ALL_FEATURE_IDS,
  DesktopConfigData,
  DesktopFeatureId,
  Feature,
  FeatureId,
  FeaturesDictionary,
} from 'modules/types/features'
import { AppState } from '../reducers'
import Features from './utils'

type FeaturesState = {
  features: FeaturesDictionary
  initialized: boolean
}
const initialState: FeaturesState = {
  features: getDefaultFeatures(ALL_FEATURE_IDS),
  initialized: false,
}

function mergeFeatures(featuresToAdd: FeaturesDictionary, existingFeatures: FeaturesDictionary): FeaturesDictionary {
  return {
    ...existingFeatures,
    ...Object.entries(featuresToAdd).reduce<FeaturesDictionary>((acc, [id, featureToAdd]) => {
      const existingFeature = existingFeatures[id]
      const configFeature = config.features.find(cf => cf.id === id)

      const mergedFeature = {
        ...existingFeature,
        ...featureToAdd,
        ...configFeature,
        data: Object.assign({}, existingFeature?.data ?? {}, featureToAdd.data ?? {}, configFeature?.data ?? {}, {}),
      }
      acc[featureToAdd.id] = mergedFeature

      Features.add(mergedFeature)

      return acc
    }, {}),
  }
}

export type FetchFeaturesAction = PayloadAction<{ ids: FeatureId[] }>
type FetchFeaturesSuccessAction = PayloadAction<{ features: FeaturesDictionary }>
const fetchFeaturesSuccess: CaseReducer<FeaturesState, FetchFeaturesSuccessAction> = (state, action) => {
  Object.values(action.payload.features).forEach(f => Features.add(f))

  return {
    ...state,
    features: mergeFeatures(action.payload.features, state.features),
    initialized: true
  }
}

type SetIsActiveAction = PayloadAction<{ id: DesktopFeatureId, isActive: boolean }>
const setIsActive: CaseReducer<FeaturesState, SetIsActiveAction> = (state, action) => {
  const desktopConfigFeature = (state.features[FeatureId.DesktopConfig] as Feature<DesktopConfigData>)

  return {
    ...state,
    features: {
      ...state.features,
      [FeatureId.DesktopConfig]: {
        ...desktopConfigFeature,
        data: {
          ...desktopConfigFeature.data,
          features: {
            ...desktopConfigFeature.data.features,
            [action.payload.id]: action.payload.isActive
          }
        }
      }
    }
  }
}

const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    fetchFeatures: (state, _action: FetchFeaturesAction) => state,
    fetchFeaturesFailure: fetchFeaturesSuccess,
    fetchFeaturesSuccess,

    setIsActive,
  }
})

export const actions = featuresSlice.actions
export const selector = {
  name: featuresSlice.name,
  select: (state: AppState): FeaturesState => state.features,
}
export default featuresSlice.reducer
