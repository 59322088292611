import * as api from 'modules/api/offers'
import logger from 'modules/logger'
import { ConversationType } from 'modules/types/conversations'
import { call, put, SagaGenerator, takeEvery } from 'typed-redux-saga'
import { actions as alertsActions } from '../alerts/slice'
import { actions as conversationsActions, UpdateConversationSuccessAction } from '../conversations/slice'
import { actions, CreateOfferAction, FetchOffersAction, OfferIdAction, UpdateOfferAction } from './slice'

export default function*(): SagaGenerator<void> {
  yield* takeEvery(actions.createOffer, createOffer)
  yield* takeEvery(actions.fetchOffers, fetchOffers)
  yield* takeEvery(actions.updateOffer, updateOffer)
  yield* takeEvery(actions.deleteOffer, deleteOffer)
  yield* takeEvery(conversationsActions.refetchConversationSuccess, handleRefetchConversation)
}

export function* createOffer(action: CreateOfferAction): SagaGenerator<void> {
  try {
    const newOffer = yield* call(api.createOffer, action.payload)
    yield* put(actions.createOfferSuccess({ offer: newOffer }))
  } catch (error) {
    logger.error('Error creating offer', error, { ...action.payload })
    yield* put(alertsActions.pushAlert({ message: 'Failed to create offer', severity: 'error' }))
    yield* put(actions.createOfferFailure({ error: error.message }))
  }
}

export function* fetchOffers(action: FetchOffersAction): SagaGenerator<void> {
  const ids = action.payload?.ids

  try {
    const { missingIds, refetchIds, success: offers } = yield* call(api.getOffers, ids)

    if (refetchIds.length) {
      yield* put(actions.fetchOffers({ ids: refetchIds }))
    }
    if (missingIds.length) {
      logger.warn('Offers not found', { ids: missingIds })
      yield* put(actions.fetchOffersFailure({ ids: missingIds, reason: 'Not found' }))
    }

    if (Object.keys(offers).length || !ids?.length) {
      yield* put(actions.fetchOffersSuccess({ offers, wasFetchingById: !!ids?.length }))
    }
  } catch (error) {
    logger.error('Error getting offers', error, { ids })
    yield* put(actions.fetchOffersFailure({ ids, reason: error.message }))
  }
}

export function* updateOffer(action: UpdateOfferAction): SagaGenerator<void> {
  try {
    const offer = yield* call(api.updateOffer, action.payload)
    yield* put(actions.updateOfferSuccess({ offer }))
  } catch (error) {
    logger.error('Failed to update offer', error, { ...action.payload })
    yield* put(alertsActions.pushAlert({ message: 'Failed to update offer', severity: 'error' }))
    yield* put(actions.updateOfferFailure({ id: action.payload.id }))
  }
}

export function* deleteOffer(action: OfferIdAction): SagaGenerator<void> {
  const { id } = action.payload

  try {
    yield* call(api.deleteOffer, id)
    yield* put(actions.deleteOfferSuccess({ id }))
  } catch (error) {
    logger.error('Failed to delete offer', error, { id })
    yield* put(alertsActions.pushAlert({ message: 'Failed to delete offer', severity: 'error' }))
    yield* put(actions.deleteOfferFailure({ id }))
  }
}

export function* handleRefetchConversation(action: UpdateConversationSuccessAction): SagaGenerator<void> {
  const { conversation } = action.payload
  if (conversation.vType !== ConversationType.PremiumSeed) {
    return
  }

  yield* put(actions.fetchOffers())
}
