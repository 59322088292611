import { Action } from '@reduxjs/toolkit'
import { eventChannel, EventChannel } from 'redux-saga'
import {
  createWebSocket,
  deserializeSubscriptionsMessage,
  reauthenticateWebSocket,
  requestSubscriptionToEntity,
  sendKeepAlive
} from 'modules/api/subscriptions'
import config from 'modules/config'
import { delay, put, race, SagaGenerator, select, spawn, take, takeEvery, takeLatest } from 'typed-redux-saga'
import { actions, selector } from './slice'
import { actions as applicationActions, selector as applicationSelector } from '../application/slice'
import {
  actions as authActions,
  RefreshIdentitySessionSuccessAction,
  selector as authSelector,
} from '../authentication/slice'
import { actions as deviceSettingsActions, selector as deviceSettingsSelector } from '../device-settings/slice'
import { actions as conversationActions, FetchConversationsSuccessAction } from '../conversations/slice'
import { actions as teamsActions, selector as teamsSelector, FetchTeamsSuccessAction } from '../teams/slice'
import { actions as usersActions } from '../users/slice'
import { SubscriptionsAction, SubscriptionsMessage } from 'modules/types/subscriptions'
import { TeamAccessLevel } from 'modules/types/teams'
import logger from 'modules/logger'
import { differenceInSeconds } from 'date-fns'

export const KEEP_ALIVE_INTERVAL_MS = 8 * 60 * 1000
const RETRY_KEEP_ALIVE_INTERVAL_MS = 30 * 1000
const RECONNECT_DELAY_MS = 15 * 1000
const RECONNECT_MIN_DELAY_MS = 2000

let webSocket: WebSocket
let subscriptionsChannel: SubscriptionsEventChannel
let reconnectAttempts = 0
let shouldReconnect = false
let subscribedEntityIds: Set<string>

type SubscriptionMessageEvent = Event & { data: string }
type SubscriptionsEvent =
  | CloseEvent
  | Event
  | SubscriptionMessageEvent
export type SubscriptionsEventChannel = EventChannel<SubscriptionsEvent>

export default function*(): SagaGenerator<void> {
  yield* takeEvery(usersActions.fetchCurrentUserSuccess, initializeSubscriptionsWebSocket)
  yield* takeLatest(authActions.refreshIdentitySessionSuccess, reauthenticateSubscriptionsWebSocket)
  yield* takeEvery(conversationActions.fetchConversationsSuccess, handleFetchConversationsSuccess)
  yield* takeEvery(teamsActions.fetchTeamsSuccess, handleFetchTeamsSuccess)
}

function* initializeSubscriptionsWebSocket(): SagaGenerator<void> {
  if (isWebSocketConnecting(webSocket) || isWebSocketConnected(webSocket)) {
    return
  }

  try {
    const { accessToken } = yield* select(authSelector.select)
    if (!accessToken) {
      return
    }

    logger.debug('Initializing subscriptions web socket')
    webSocket = createWebSocket(config.app.subscriptionsHost, accessToken.token)
    subscriptionsChannel = createEventChannel(webSocket)
    subscribedEntityIds = new Set()

    yield* spawn(() => watchWebSocketEvents(webSocket, subscriptionsChannel))
    yield* spawn(() => keepWebSocketConnectionAlive(webSocket))
  } catch (error) {
    logger.error('Error in subscriptions web socket', error)
  }
}

function createEventChannel(ws: WebSocket): SubscriptionsEventChannel {
  return eventChannel<SubscriptionsEvent>(emit => {
    const eventHandler = (event: Event) => {
      emit(event)
    }

    ws.addEventListener('close', eventHandler)
    ws.addEventListener('error', eventHandler)
    ws.addEventListener('message', eventHandler)
    ws.addEventListener('open', eventHandler)

    return () => {
      ws.removeEventListener('close', eventHandler)
      ws.removeEventListener('error', eventHandler)
      ws.removeEventListener('message', eventHandler)
      ws.removeEventListener('open', eventHandler)
    }
  })
}

export function* watchWebSocketEvents(ws: WebSocket, channel: SubscriptionsEventChannel): SagaGenerator<void> {
  try {
    while (true) {
      const event = yield* take(channel)
      const timestamp = new Date().toISOString()

      switch (event.type) {
        case 'message':
          const { data } = event as SubscriptionMessageEvent
          const message = deserializeSubscriptionsMessage(data)
          yield* handleMessage(message, timestamp, channel)

          break
        case 'error':
          logger.info('Subscriptions web socket emitted an error event', { timestamp })

          break
        case 'open':
          reconnectAttempts = 0
          shouldReconnect = false
          logger.debug('Subscriptions web socket open', { timestamp })

          const { isConnected, lostConnectionAt } = yield* select(selector.select)
          yield* put(actions.connectionOpened({
            disconnectedSeconds: !isConnected && lostConnectionAt
              ? differenceInSeconds(Date.parse(timestamp), lostConnectionAt)
              : undefined
          }))

          break
        case 'close':
          const { code: statusCode, reason, wasClean } = event as CloseEvent
          logger.warn('Subscriptions web socket closed', { reason, statusCode, timestamp, wasClean })
          shouldReconnect = true
          channel.close()

          break
        default:
          logger.error('Subscriptions web socket emitted unknown event type', { event, timestamp })
          shouldReconnect = true
          channel.close()

          break
      }
    }
  } finally {
    logger.debug(`Subscriptions event channel closed.${shouldReconnect ? ' Will reconnect...' : ''}`)
    channel.close()
    ws.close()

    yield* put(actions.connectionClosed())

    if (shouldReconnect) {
      yield* waitAndEnsureNetworkIsConnected()
      yield* initializeSubscriptionsWebSocket()
    }
  }
}

function* waitAndEnsureNetworkIsConnected(): SagaGenerator<void> {
  while (true) {
    const { didRefreshToken, isOnline, isVolleyUp } = yield* race({
      didRefreshToken: take((action: Action) => authActions.refreshIdentitySessionSuccess.match(action)),
      isOnline: take((action: Action) =>
        deviceSettingsActions.networkStatusChanged.match(action) && action.payload.networkStatus === 'online'),
      isVolleyUp: take((action: Action) =>
        applicationActions.fetchVolleyStatusSuccess.match(action) && action.payload.isUp),
      sleep: delay(getReconnectDelay(reconnectAttempts++)),
    })

    if (didRefreshToken || isOnline || isVolleyUp) {
      reconnectAttempts = 0
      break
    }

    const { networkStatus } = yield* select(deviceSettingsSelector.select)
    const { volleyStatus } = yield* select(applicationSelector.select)
    if (networkStatus === 'online' && volleyStatus.isUp) {
      break
    }

    logger.debug('Waiting to reconnect subscriptions web socket', { networkStatus, timestamp: new Date().toISOString() })
  }
}

function getReconnectDelay(attempts: number): number {
  return attempts
    ? attempts * RECONNECT_DELAY_MS + Math.trunc(Math.random() * 10000) // Up to 10 seconds random jitter
    : RECONNECT_MIN_DELAY_MS
}

function* handleMessage(message: SubscriptionsMessage, timestamp: string, channel: SubscriptionsEventChannel): SagaGenerator<void> {
  const { action, body, statusCode } = message
  const { actor, entityId, entityType } = body

  try {
    switch (action) {
      case SubscriptionsAction.Add:
      case SubscriptionsAction.Update:
        yield* put(actions.updateEntity(body))
        logger.debug(`EVENT ${action} ${entityType}: ${entityId}`, { actor, timestamp })
        break
      case SubscriptionsAction.Remove:
        // No-op when removing an entity
        // If the user performed the removal, we should be optimistically updating state
        // If the update is external, it could trigger a re-fetch by an in-view component,
        // so we'll leave it alone and let the update to the parent convo (if it's a
        // moment) or user (if it's a convo) take care of removing it from state
        logger.debug(`EVENT ${action} ${entityType}: ${entityId}`, { actor, timestamp })
        break
      default:
        logger.debug(`EVENT ${action}: ${statusCode}`, { body, timestamp })
        if (statusCode !== 200) {
          logger.warn('Non 200 status in subscriptions message', { message })
          shouldReconnect = true
          channel.close()
        }

        break
    }
  } catch (error) {
    logger.error('Failed to handle subscriptions message', error, { message, timestamp })
  }
}

export function* keepWebSocketConnectionAlive(ws: WebSocket): SagaGenerator<void> {
  let didKeepAliveSucceed = true

  while (true) {
    yield* delay(didKeepAliveSucceed ? KEEP_ALIVE_INTERVAL_MS : RETRY_KEEP_ALIVE_INTERVAL_MS)
    if (!isWebSocketConnected(ws)) {
      break
    }

    try {
      sendKeepAlive(ws)
      didKeepAliveSucceed = true
    } catch (error) {
      logger.error('Failed to send keep alive message to subscriptions web socket', error)
      didKeepAliveSucceed = false
    }
  }
}

function isWebSocketConnecting(ws: WebSocket): boolean {
  return !!ws && ws.readyState === ws.CONNECTING
}
function isWebSocketConnected(ws: WebSocket): boolean {
  return !!ws && ws.readyState === ws.OPEN
}
function* waitForWebSocketToConnect(ws: WebSocket): SagaGenerator<string> {
  let attempts = 0
  while (attempts++ < 30) {
    if (!isWebSocketConnecting(ws)) {
      break
    }

    yield* delay(100)
  }

  switch (ws.readyState) {
    case ws.CLOSED:
      return 'closed'
    case ws.CLOSING:
      return 'closing'
    case ws.CONNECTING:
      return 'connecting'
    case ws.OPEN:
      return 'open'
    default:
      return 'unknown'
  }
}

function* reauthenticateSubscriptionsWebSocket(action: RefreshIdentitySessionSuccessAction): SagaGenerator<void> {
  if (!isWebSocketConnected(webSocket)) {
    if (!isWebSocketConnecting(webSocket)) {
      return
    }

    const state = yield* waitForWebSocketToConnect(webSocket)
    if (!isWebSocketConnected(webSocket)) {
      logger.error('Attempting to re-auth disconnected subscriptions web socket', { state })
      return
    }
  }

  const { accessToken } = action.payload

  try {
    reauthenticateWebSocket(webSocket, accessToken)
  } catch (error) {
    logger.error('Failed to re-auth subscriptions web socket', error)
  }
}

function* handleFetchConversationsSuccess(action: FetchConversationsSuccessAction): SagaGenerator<void> {
  const conversations = action.payload.conversations
  const { teams } = yield* select(teamsSelector.select)

  for (const conversation of Object.values(conversations)) {
    if (teams[conversation.teamId]?.accessLevel === TeamAccessLevel.Visitor) {
      yield* subscribeToEntity(webSocket, subscribedEntityIds, conversation.id)
    }
  }
}

function* handleFetchTeamsSuccess(action: FetchTeamsSuccessAction): SagaGenerator<void> {
  const teams = action.payload.teams
  for (const team of Object.values(teams)) {
    if (team.accessLevel === TeamAccessLevel.Visitor) {
      yield* subscribeToEntity(webSocket, subscribedEntityIds, team.id)
    }
  }
}

export function* subscribeToEntity(ws: WebSocket, entityIds: Set<string>, entityId: string): SagaGenerator<void> {
  if (!isWebSocketConnected(ws)) {
    if (!isWebSocketConnecting(ws)) {
      return
    }

    const state = yield* waitForWebSocketToConnect(ws)
    if (!isWebSocketConnected(ws)) {
      logger.error('Attempting to create backend subscription on disconnected web socket', { state })
      return
    }
  }

  if (entityIds.has(entityId)) {
    return
  }

  try {
    entityIds.add(entityId)
    requestSubscriptionToEntity(ws, entityId)
  } catch (error) {
    logger.error('Failed to send subscription request for entity over web socket', error)
  }
}
