import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'
import { UpdateNotificationPreferences, UpdateUserParams } from 'modules/api/users'
import { MomentType } from 'modules/types/moments'
import {
  ConversationData,
  ConversationDataDictionary,
  CreateUserParams,
  NotificationPreferencesDictionary,
  ParticipantsDictionary,
  SellerData,
  Settings,
  User,
} from 'modules/types/users'
import { Alert } from '../alerts/slice'
import { AppState } from '../reducers'
import { actions as teamsActions, CreateTeamSuccessAction } from '../teams/slice'
import { RefetchUserAction } from '../application/slice'

export type IsFetchingParticipantsDictionary = { [userId: string]: boolean }
type FetchParticipantsFailedDictionary = { [userId: string]: string }
export type UsersState = {
  createAccountError?: string
  currentUserId?: string
  currentUser?: User
  fetchCurrentUserFailed: boolean
  fetchParticipantsFailed: FetchParticipantsFailedDictionary
  initialized: boolean
  isCreatingUser: boolean
  isFetchingParticipants: IsFetchingParticipantsDictionary
  isFetchingUser: boolean
  isUpdatingCurrentUser: boolean
  participants: ParticipantsDictionary
  updateCurrentUserFailed: boolean
  visitorTeamIds: string[]
}
export const initialState: UsersState = {
  currentUserId: undefined,
  currentUser: undefined,
  fetchCurrentUserFailed: false,
  fetchParticipantsFailed: {},
  initialized: false,
  isCreatingUser: false,
  isFetchingParticipants: {},
  isFetchingUser: false,
  isUpdatingCurrentUser: false,
  participants: {},
  updateCurrentUserFailed: false,
  visitorTeamIds: [],
}

export type CreateUserAction = PayloadAction<CreateUserParams>
const createUser: CaseReducer<UsersState, CreateUserAction> = (state, _) => ({
  ...state,
  createAccountError: undefined,
  isCreatingUser: true,
})
type CreateUserFailureAction = PayloadAction<{ error: string } | undefined>
const createUserFailure: CaseReducer<UsersState, CreateUserFailureAction> = (state, action) => ({
  ...state,
  createAccountError: action.payload?.error,
  isCreatingUser: false,
})

export type FetchCurrentUserAction = PayloadAction<{ userId: string }>
const fetchCurrentUser: CaseReducer<UsersState, FetchCurrentUserAction> = (state, _) => ({
  ...state,
  isFetchingUser: true
})
export type FetchCurrentUserSuccessAction = PayloadAction<{ user: User }>
const fetchCurrentUserSuccess: CaseReducer<UsersState, FetchCurrentUserSuccessAction> = (state, action) => ({
  ...state,
  currentUserId: action.payload.user.id,
  currentUser: action.payload.user,
  fetchCurrentUserFailed: false,
  initialized: true,
  isCreatingUser: false,
  isFetchingUser: false,
  participants: {
    ...state.participants,
    [action.payload.user.id]: action.payload.user,
  },
})
type FetchCurrentUserFailurePayload = {
  error?: string
  hasCreateParams?: boolean
  isAnonymousSignIn?: boolean
  isInitializing: boolean
  userId: string
}
export type FetchCurrentUserFailureAction = PayloadAction<FetchCurrentUserFailurePayload>
const fetchCurrentUserFailure: CaseReducer<UsersState, FetchCurrentUserFailureAction> = (state, action) => ({
  ...state,
  fetchCurrentUserFailed: true,
  initialized: action.payload.isInitializing || state.initialized,
  isCreatingUser: !!action.payload.hasCreateParams,
  isFetchingUser: false
})

const isFetchingParticipantsReduceFn = (dict: IsFetchingParticipantsDictionary, id: string) => {
  dict[id] = true
  return dict
}
export type FetchParticipantsAction = PayloadAction<{ userIds: string[] }>
const fetchParticipants: CaseReducer<UsersState, FetchParticipantsAction> = (state, action) => ({
  ...state,
  isFetchingParticipants: {
    ...state.isFetchingParticipants,
    ...action.payload.userIds.reduce(isFetchingParticipantsReduceFn, {}),
  },
})
export type FetchParticipantsSuccessAction = PayloadAction<{ participants: ParticipantsDictionary }>
const fetchParticipantsSuccess: CaseReducer<UsersState, FetchParticipantsSuccessAction> = (state, action) => {
  const addedParticipantIds = Object.keys(action.payload.participants)

  return {
    ...state,
    fetchParticipantsFailed: Object.entries(state.fetchParticipantsFailed)
      .filter(([id]) => !addedParticipantIds.includes(id))
      .reduce<FetchParticipantsFailedDictionary>((dict, [id, reason]) => {
        dict[id] = reason
        return dict
      }, {}),
    isFetchingParticipants: Object.keys(state.isFetchingParticipants)
      .filter(id => !addedParticipantIds.includes(id))
      .reduce(isFetchingParticipantsReduceFn, {}),
    participants: {
      ...state.participants,
      ...action.payload.participants,
    },
  }
}
export type FetchParticipantsFailureAction = PayloadAction<{ ids: string[], reason: string }>
const fetchParticipantsFailure: CaseReducer<UsersState, FetchParticipantsFailureAction> = (state, action) => ({
  ...state,
  fetchParticipantsFailed: {
    ...state.fetchParticipantsFailed,
    ...action.payload.ids.reduce<FetchParticipantsFailedDictionary>((dict, id) => {
      dict[id] = action.payload.reason
      return dict
    }, {})
  },
  isFetchingParticipants: Object.keys(state.isFetchingParticipants)
    .filter(id => !action.payload.ids.includes(id))
    .reduce<IsFetchingParticipantsDictionary>((dict, id) => {
      dict[id] = true
      return dict
    }, {})
})

export type FollowUp = {
  conversationId: string
  createdOn: string
  creatorUserId: string
  momentId: string
  teamId: string
  type: MomentType
}
type ViewedFollowUp = {
  momentId: string
  type: MomentType
}
export type FollowUpsPayload = {
  addFollowUps?: FollowUp[]
  removeFollowUps?: string[]
  viewedFollowUps?: ViewedFollowUp[]
}
export type UpdateCurrentUserPayload = Omit<UpdateUserParams,
  | 'client'
  | 'isSilenceNotificationsEnabled'
  | 'setUpStripeSeller'
  | 'updateFollowUps'
  | 'updateNotificationPreferences'
  | 'updateSettings'
  | 'updateTeamIds'
  | 'updateVanityUrl'>
  & {
    followUps?: FollowUpsPayload
    notificationPreferences?: UpdateNotificationPreferences
    settings?: Settings
    successAlert?: Alert
    teamIds?: string[]
    vanityUrlPath?: string
  }
export type UpdateCurrentUserAction = PayloadAction<UpdateCurrentUserPayload>
const updateCurrentUser: CaseReducer<UsersState, UpdateCurrentUserAction> = (state, action) => {
  const {
    catchUpConversationIds,
    catchUpConversationsInTeamIds,
    catchUpThreadsInConversationIds,
    clearSellerAnalyticsBadge,
    notificationPreferences,
    suppressConsultOffersFromSellerSearch,
  } = action.payload

  let updatedNotificationPreferences: NotificationPreferencesDictionary | null = null
  if (state.currentUser && notificationPreferences) {
    updatedNotificationPreferences = {
      ...Object.entries(state.currentUser.notificationPreferences)
        .filter(([id, _preference]) => action.payload.notificationPreferences?.[id] !== null)
        .reduce<NotificationPreferencesDictionary>((acc, [id, preference]) => {
          acc[id] = preference
          return acc
        }, {}),
      ...Object.entries(notificationPreferences)
        .reduce<NotificationPreferencesDictionary>((acc, [id, preference]) => {
          if (preference) {
            acc[id] = preference
          }

          return acc
        }, {})
    }
  }

  let updatedConversationData: ConversationDataDictionary | null = null
  if (state.currentUser
    && (catchUpConversationIds || catchUpThreadsInConversationIds || catchUpConversationsInTeamIds)) {
    updatedConversationData = {
      ...Object.entries(state.currentUser.conversationData).reduce<ConversationDataDictionary>((acc, [id, data]) => {
        let updatedData: ConversationData = { ...data }
        if (catchUpConversationIds?.includes(id)) {
          updatedData = {
            ...updatedData,
            sortToTop: undefined,
            unviewedCount: 0,
          }
        }
        if (catchUpThreadsInConversationIds?.includes(id)) {
          updatedData = {
            ...updatedData,
            threadUnviewedCount: 0,
          }
        }
        if (catchUpConversationsInTeamIds?.includes(data.teamId)) {
          updatedData = {
            ...updatedData,
            sortToTop: undefined,
            unviewedCount: 0,
            threadUnviewedCount: 0,
          }
        }

        acc[id] = updatedData
        return acc
      }, {}),
    }
  }

  let updatedSellerData: SellerData | null = null
  if (state.currentUser?.sellerData && clearSellerAnalyticsBadge) {
    updatedSellerData = {
      ...state.currentUser.sellerData,
      showSellerAnalyticsBadge: false,
    }
  }

  return {
    ...state,
    currentUser: state.currentUser
      && (updatedNotificationPreferences || updatedConversationData || updatedSellerData
        || suppressConsultOffersFromSellerSearch !== undefined)
      ? {
        ...state.currentUser,
        conversationData: updatedConversationData ?? state.currentUser.conversationData,
        notificationPreferences: updatedNotificationPreferences ?? state.currentUser.notificationPreferences,
        sellerData: updatedSellerData ?? state.currentUser.sellerData,
        suppressConsultOffersFromSellerSearch: suppressConsultOffersFromSellerSearch
          ?? state.currentUser.suppressConsultOffersFromSellerSearch,
      }
      : state.currentUser,
    isUpdatingCurrentUser: true,
    updateCurrentUserFailed: false,
  }
}
type UpdateCurrentUserSuccessPayload = {
  fromReconnect?: boolean
  user: User
  updateParams?: UpdateCurrentUserPayload
}
export type UpdateCurrentUserSuccessAction = PayloadAction<UpdateCurrentUserSuccessPayload>
const updateCurrentUserSuccess: CaseReducer<UsersState, UpdateCurrentUserSuccessAction> = (state, action) => ({
  ...state,
  currentUser: action.payload.user,
  isUpdatingCurrentUser: false
})

const refetchUser: CaseReducer<UsersState, RefetchUserAction> = (state, _action) => ({
  ...state,
  isUpdatingCurrentUser: true,
})
const updateCurrentUserFailure: CaseReducer<UsersState, PayloadAction> = (state, _action) => ({
  ...state,
  isUpdatingCurrentUser: false,
  updateCurrentUserFailed: true,
})
const addVisitorTeamId: CaseReducer<UsersState, PayloadAction<{ teamId: string }>> = (state, action) => ({
  ...state,
  visitorTeamIds: state.visitorTeamIds.concat(action.payload.teamId),
})
const removeVisitorTeamId: CaseReducer<UsersState, PayloadAction<{ teamId: string }>> = (state, action) => ({
  ...state,
  visitorTeamIds: state.visitorTeamIds.filter(id => id !== action.payload.teamId),
})

const handleUpdateUserOnCreateTeamSuccess: CaseReducer<UsersState, CreateTeamSuccessAction> = (state, action) => {
  const { createdOn, id: teamId } = action.payload.team
  if (!state.currentUser || state.currentUser.teamIds.includes(teamId)) {
    return
  }

  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      teamIds: [...state.currentUser.teamIds, teamId],
      teamInfo: {
        ...state.currentUser.teamInfo,
        [teamId]: { joinedOn: createdOn ?? undefined }
      },
    },
  }
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addVisitorTeamId,

    blockedUsersChanged: (state, _: PayloadAction) => state,

    createUser,
    createUserFailure,

    fetchCurrentUser,
    fetchCurrentUserFailure,
    fetchCurrentUserSuccess,

    fetchParticipants,
    fetchParticipantsFailure,
    fetchParticipantsSuccess,

    removeVisitorTeamId,

    updateCurrentUser,
    refetchUser,
    updateCurrentUserFailure,
    updateCurrentUserSignedOut: (state: UsersState, _: PayloadAction) => state,
    updateCurrentUserSuccess,
  },
  extraReducers: builder => {
    builder.addCase(teamsActions.createTeamSuccess, handleUpdateUserOnCreateTeamSuccess)
  },
})

export const actions = usersSlice.actions
export const selector = {
  name: usersSlice.name,
  select: (state: AppState): UsersState => state.users,
  isVisitor: (state: AppState): boolean => {
    const { currentUser, visitorTeamIds } = state.users
    const { selectedTeamId } = state.application

    return !!currentUser
      && (currentUser.isVisitor
        || (currentUser.teamIds.includes(selectedTeamId) && visitorTeamIds.includes(selectedTeamId)))
  }
}
export default usersSlice.reducer
