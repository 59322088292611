export const ADHOC_TEAM_ID = 'adhoc-tm'
export const ALL_TEAMS_ID = 'all'
export const AUDIO_BITS_PER_SECOND = 48000
export const AUDIO_TRANSCRIPTION_BITS_PER_SECOND = 16000
export const DEBOUNCE_DELAY = 300
export const DEFAULT_AUDIO_MIME_TYPE = 'audio/webm'
export const DEFAULT_MIME_TYPE = 'video/webm'
export const DEFAULT_PLAYBACK_RATE = 1
export const DEFAULT_SEARCH_EXPIRATION = 30 * 60 * 1000
export const LOADING_TITLE = 'Loading...'
export const MEDIA_TYPES = [
  `${DEFAULT_MIME_TYPE};codecs="vp8,opus"`,
  `${DEFAULT_MIME_TYPE};codecs=h264`,
  `${DEFAULT_MIME_TYPE};codecs=vp9`,
  DEFAULT_MIME_TYPE,
]
export const MODAL_QUERY_PARAM = 'modal'
export const MOMENT_UPLOAD_CHUNK_MS = 4000
export const MY_NETWORK_OVERVIEW_VIDEO_URL = 'https://pieces.volley.app/videos/my-network-tutorial/index.m3u8'
export const NEW_CONVERSATION_TITLE = 'New Conversation' // keep for legacy convs that dont have a name, controls italics mostly
export const NO_ACCESS_QUERY_PARAM = 'na'
export const PLAYER_SKIP_SECONDS = 5
export const PREMIUM_CONSULT_HOW_TO_LINK = 'https://help.volleyapp.com/help/how-to-set-up-consult-with-me'
export const PREMIUM_SPACE_HOW_TO_LINK = 'https://help.volleyapp.com/help/how-to-set-up-your-grow-space'
export const THREAD_TITLE = 'Thread'
export const TRANSCRIPTION_UPLOAD_CHUNK_MS = 1000
export const WELCOME_TO_VOLLEY_VIDEO_URL = 'https://pieces.volley.app/videos/welcome-to-volley/index.m3u8'
export const WORKSPACE_OVERVIEW_VIDEO_URL = 'https://pieces.volley.app/videos/workspace-tutorial/index.m3u8'

export const VOLLEY_PREFERENCE_PREFIX = 'volleyPreference__'
export const APP_PROMPT_DELAY_PREFERENCE = 'appPromptDelay'
export const AUTO_LAUNCH_PREFERENCE = 'autoLaunch'
export const CAMERA_SLEEP_PREFERENCE = 'cameraSleep'
export const CONSULT_HOW_TO_VIEWED_PREFERENCE = 'consultHowToViewed'
export const CONSULT_PROMO_DISMISSED_PREFERENCE = 'consultPromoDismissed'
export const CAPTIONS_PREFERENCE = 'captions'
export const CLOSE_TO_TRAY_PREFERENCE = 'closeToTray'
export const CREATE_USER_PARAMS_PREFERENCE = 'createAccount'
export const GROW_HOW_TO_VIEWED_PREFERENCE = 'growHowToViewed'
export const GROW_PROMO_DISMISSED_PREFERENCE = 'growPromoDismissed'
export const LAST_TEAM_PREFERENCE = 'lastTeam'
export const PLAYBACK_RATE_PREFERENCE = 'playbackRate'
export const POST_VERIFY_DESTINATION_PREFERENCE = 'postVerifyDestination'
export const PREFERRED_AUDIO_DEVICE_PREFERENCE = 'preferredAudioDevice'
export const PREFERRED_VIDEO_DEVICE_PREFERENCE = 'preferredVideoDevice'
export const REVIEW_BEFORE_SEND_PREFERENCE = 'reviewBeforeSend'

export const MAX_FILES_PASTE_DROP = 5

export const UNVIEWABLE_WITH_NAV_BREAKPOINT = 760
export const UNVIEWABLE_WITH_NAV_AND_SIDEBAR_BREAKPOINT = 1100
export const MOBILE_BREAKPOINT = 500

export const MIN_VIEW_DURATION = 5 * 1000
export const MAX_VIEW_DURATION = 30 * 1000
export const INDIVIDUAL_LEVEL_ID = 'INDIVIDUAL'
export const INDIVIDUAL_FREE_LEVEL_ID = 'INDIVIDUAL_FREE'
export const FREQUENCY_MONTHLY = 'MONTH'
export const FREQUENCY_YEARLY = 'YEAR'
export const AVIALABLE_YEAR_PLANS = ['SPACES_YEAR_S10','SPACES_YEAR_S25','SPACES_YEAR_S100','SPACES_YEAR_S500']
export const INDIVIDUAL_YEAR_PLAN = 'INDIVIDUAL_YEAR_D1'
export const INDIVIDUAL_MONTH_PLAN = 'INDIVIDUAL_MONTH_D1'
