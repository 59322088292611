import styled from '@emotion/styled'
import { alpha } from '@mui/material'
import MuiCircularProgress from '@mui/material/CircularProgress'
import DevLogo from 'assets/dev-logo.svg'
import VolleyLogo from 'assets/volley-logo.svg'
import config from 'modules/config'
import theme from 'modules/theme'
import React from 'react'
import SVG from 'react-inlinesvg'

const SIZE = 90
const LOGO_SIZE = 45

const Container = styled.div`
  display: grid;
  grid:
    1fr ${SIZE}px 1fr /
    1fr ${SIZE}px 1fr;
  height: 100vh;
  width: 100vw;
`
const Gradient = styled.svg`
  grid-area: 2 / 2;
  height: ${SIZE}px;
  width: ${SIZE}px;
`
const Logo = styled(SVG)`
  grid-area: 2 / 2;
  place-self: center;
  height: ${LOGO_SIZE}px;
  width: ${LOGO_SIZE}px;
`
const Animated = styled(MuiCircularProgress)`
  grid-area: 2 / 2;
  place-self: center;

  & .MuiCircularProgress-circle {
    stroke: url(#full-page-loading-gradient);
    stroke-linecap: round;
  }
  & .MuiCircularProgress-circleIndeterminate {
    stroke-dasharray: 50px 40px;
  }
`

const FullPageLoading = (): React.ReactElement => (
  <Container>
    <Gradient>
      <linearGradient id="full-page-loading-gradient" x1={0} y1={0} x2={1} y2={1}>
        <stop offset="10%" stopColor={alpha(theme.palette.volleyGray05, 0)} />
        <stop offset="20%" stopColor={alpha(theme.palette.volleyGray05, 0)} />
        <stop offset="60%" stopColor={theme.palette.volleyGray05} />
        <stop offset="100%" stopColor={theme.palette.volleyGray05} />
      </linearGradient>
    </Gradient>
    <Logo src={config.env === 'prod' ? VolleyLogo : DevLogo} />
    <Animated
      disableShrink
      size={SIZE}
      thickness={1.5}
      variant="indeterminate"
    />
  </Container>
)

export default FullPageLoading
