import { MomentState, MomentType } from './moments'
import { Offer } from './offers'
import { Purchase } from './purchases'
import { TierLimits } from './tier-limits'

export type ConversationsDictionary = { [id: string]: Conversation }
export type Conversation = {
  id: string
  activeNow: ActiveNow[]
  adminIds: string[]
  allowedPosterIds: string[]
  billingUrl?: string
  createdOn: string
  creatorUserId: string
  description: string
  extendedAt: string
  guestIds: string[]
  hasMoments: boolean
  hideThreadsTab: boolean
  isAdhoc: boolean
  isAutoJoin: boolean
  isDepleted: boolean
  isEveryoneChannel: boolean
  isPremiumActive: boolean
  isThumbStatic: boolean
  isWelcome: boolean
  momentsExpireBefore: string
  momentThreads: MomentThreadsDictionary
  name: string | null
  offer: Offer | null
  parentConversationId: string | null
  parentMomentId: string | null
  preventDeletion: boolean
  preventThreading: boolean
  preventUsersLeaving: boolean
  purchases: Purchase[]
  recentlyCompletedMomentIds: string[]
  recentlyDeletedMomentIds: string[]
  restrictPosting: boolean
  sortedOfferIds?: string[]
  subName: string | null
  teamId: string
  tierLimits: TierLimits
  totalPremiumSecondsPurchased: number | null
  totalPremiumSecondsRecorded: number
  thumbEmoji: string | null
  thumbUpdatedOn: string | null
  thumbUrl: string | null
  userData: UserDataDictionary
  userIds: string[]
  vType: ConversationType
  wipeNameOnJoin: boolean
}
export type ActiveNow = {
  momentId: string
  createdOn: string
  updatedAt: string
  vType: MomentType
  vState: MomentState
  userId: string
}
export type MomentThreadsDictionary = { [momentId: string]: MomentThread }
export type MomentThread = {
  momentCount: number
  momentId: string
  newestMomentTimestamp: string
  threadId: string
  unviewedCount: number
  userIsMember: boolean
}
export type RecentMomentDictionary = { [conversationId: string]: RecentMoment[] }
export type RecentMoment = {
  isExpired?: boolean
  isLocal?: boolean
  momentId: string
  momentTimestamp: string
  sortDate: number
  type: MomentType
}
export type UserDataDictionary = { [userId: string]: UserData }
export type UserData = {
  newestViewedMomentId: string | null
  newestViewedMomentTimestamp: string | null
}
export enum ConversationType {
  Channel = 'OPEN',
  ConsultSeed = 'CONSULT_SEED',
  PremiumSeed = 'PREMIUM_SEED',
  Group = 'CLOSED',
  OneOnOne = 'FIXED',
  PremiumOneOnOne = 'PREMIUM_DM',
  TalkToMeOnVolley = 'TTMOV',
  TeamWelcome = 'TEAM_WELCOME',
  Thread = 'THREAD',
}
