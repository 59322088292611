import { getConversation } from 'modules/api/conversations'
import { createPurchase } from 'modules/api/purchases'
import { getTeam, updateTeam } from 'modules/api/teams'
import { updateUser } from 'modules/api/users'
import logger from 'modules/logger'
import Features from 'modules/store/features/utils'
import { FeatureId, TeamTiersData } from 'modules/types/features'
import { StripeConnect } from 'modules/types/users'
import { call, put, SagaGenerator, takeEvery } from 'typed-redux-saga'
import { actions as alertsActions } from '../alerts/slice'
import {
  actions,
  DownGradeIndividualPlanAction,
  FetchBillingUrlAction,
  FetchIndividualPlanUpgradeUrlAction,
  FetchPlanUpgradeUrlAction,
  
} from './slice'

export default function*(): SagaGenerator<void> {
  yield* takeEvery(actions.fetchPurchaseUrl, fetchPurchaseUrl)
  yield* takeEvery(actions.fetchConsultSellerSetupUrl, action => fetchSellerSetupUrl(action.payload.entityId, StripeConnect.Consult))
  yield* takeEvery(actions.fetchGrowSellerSetupUrl, action => fetchSellerSetupUrl(action.payload.entityId, StripeConnect.Grow))
  yield* takeEvery(actions.fetchPlanBillingUrl, fetchPlanBillingUrl)
  yield* takeEvery(actions.fetchPlanUpgradeUrl, fetchPlanUpgradeUrl)
  yield* takeEvery(actions.fetchIndividualPlanBillingUrl, fetchIndividualPlanBillingUrl)
  yield* takeEvery(actions.fetchIndividualPlanUpgradeUrl, fetchIndividualPlanUpgradeUrl)
  yield* takeEvery(actions.fetchSellerDashboardUrl, fetchSellerDashboardUrl)
  yield* takeEvery(actions.fetchConsultBillingHistoryUrl, fetchConsultBillingHistoryUrl)
  yield* takeEvery(actions.fetchTeamBillingHistoryUrl, fetchTeamBillingHistoryUrl)
  yield* takeEvery(actions.downGradeIndividualPlan, downGradeIndividualPlan)
  yield* takeEvery(actions.downGradeTeamPlan, downGradeTeamPlan)
}

export function* fetchPurchaseUrl(action: FetchBillingUrlAction): SagaGenerator<void> {
  const { entityId, isFromSearch } = action.payload

  try {
    const redirectUrl = yield* call(createPurchase, entityId, isFromSearch)
    if (redirectUrl) {
      yield* put(actions.fetchRedirectUrlSuccess({ entityId, url: redirectUrl }))
    } else {
      yield* put(actions.fetchBillingUrlFailure({ entityId }))
    }
  } catch (error) {
    logger.error('Failed to get purchase redirect url', error, { entityId })
    yield* put(actions.fetchBillingUrlFailure({ entityId }))
  }
}

export function* fetchSellerSetupUrl(entityId: string, type: StripeConnect): SagaGenerator<void> {
  try {
    const { billingUrl } = yield* call(updateUser, { setUpStripeSeller: type })
    if (billingUrl) {
      yield* put(actions.fetchRedirectUrlSuccess({ entityId, url: billingUrl }))
    } else {
      yield* put(actions.fetchBillingUrlFailure({ entityId }))
    }
  } catch (error) {
    logger.error('Failed to get seller setup url', error, { entityId, type })
    yield* put(actions.fetchBillingUrlFailure({ entityId }))
  }
}

export function* fetchPlanBillingUrl(action: FetchBillingUrlAction): SagaGenerator<void> {
  const { entityId } = action.payload

  try {
    const { billingUrl } = yield* call(updateTeam, { getBillingUrl: true, teamId: entityId })
    if (billingUrl) {
      yield* put(actions.fetchBillingUrlSuccess({ entityId, url: billingUrl }))
    } else {
      yield* put(actions.fetchBillingUrlFailure({ entityId }))
    }
  } catch (error) {
    logger.error('Failed to get plan billing management url', error, { teamId: entityId })
    yield* put(actions.fetchBillingUrlFailure({ entityId }))
  }
}

export function* fetchIndividualPlanBillingUrl(action: FetchBillingUrlAction): SagaGenerator<void> {
  const { entityId } = action.payload

  try {
    const { billingUrl } = yield* call(updateUser, { getBillingUrl: true })
    if (billingUrl) {
      yield* put(actions.fetchBillingUrlSuccess({ entityId, url: billingUrl }))
    } else {
      yield* put(actions.fetchBillingUrlFailure({ entityId }))
    }
  } catch (error) {
    logger.error('Failed to get individual billing management url', error, { userId: entityId })
    yield* put(actions.fetchBillingUrlFailure({ entityId }))
  }
}


export function* fetchPlanUpgradeUrl(action: FetchPlanUpgradeUrlAction): SagaGenerator<void> {
  const { existingTierId, teamId, tierId } = action.payload
  

  try {
    const tiersFeature = Features.get<TeamTiersData>(FeatureId.TeamTiers)
    const tiers = tiersFeature.data.tiers
    const tier = tiers[tierId] ?? Object.values(tiers).find(t => t.v1Id && t.v1Id === tierId) ?? null
    const existingTier = tiers[existingTierId]
      ?? Object.values(tiers).find(t => t.v1Id && t.v1Id === existingTierId)
      ?? null
    if (!tier || !existingTier) {
      // this should never happen
      throw new Error('Unable to find selected plan tier')
    }

    const { upgradeUrl } = yield* call(updateTeam, { teamId, tierId })
    if (!upgradeUrl) {
      if (!tier.isInStripe || (tier.isInStripe && existingTier.isInStripe)) {
        yield* put(alertsActions.pushAlert({
          message: 'Successfully updated the plan for your space',
          severity: 'success',
        }))
      } else {
        throw new Error('No upgrade redirect url returned from api')
      }
    }

    yield* put(actions.fetchRedirectUrlSuccess({ entityId: teamId, url: upgradeUrl }))
  } catch (error) {
    logger.error('Failed to get plan upgrade redirect url', error, { teamId, tierId })
    yield* put(alertsActions.pushAlert({ message: 'Failed to get upgrade redirect', severity: 'error' }))
    yield* put(actions.fetchBillingUrlFailure({ entityId: teamId }))
  }
}


export function* fetchIndividualPlanUpgradeUrl(action: FetchIndividualPlanUpgradeUrlAction): SagaGenerator<void> {
  const { existingTierId, tierId, userId } = action.payload
  try {
    const tiersFeature = Features.get<TeamTiersData>(FeatureId.TeamTiers)
    const tiers = tiersFeature.data.tiers
    const tier = tiers[tierId] ?? Object.values(tiers).find(t => t.v1Id && t.v1Id === tierId) ?? null
    const existingTier = tiers[existingTierId]
      ?? Object.values(tiers).find(t => t.v1Id && t.v1Id === existingTierId)
      ?? null
    if (!tier || !existingTier) {
      // this should never happen
      throw new Error('Unable to find selected plan tier')
    }

    const { upgradeUrl } = yield* call(updateUser, { tierId })
    if (!upgradeUrl) {
      if (!tier.isInStripe || (tier.isInStripe && existingTier.isInStripe)) {
        yield* put(alertsActions.pushAlert({
          message: 'Successfully updated the plan for your user',
          severity: 'success',
        }))
      } else {
        throw new Error('No upgrade redirect url returned from api')
      }
    }

    yield* put(actions.fetchIndividualRedirectUrlSuccess({ entityId: userId, url: upgradeUrl }))
  } catch (error) {
    logger.error('Failed to get plan upgrade redirect url', error, { userId, tierId })
    yield* put(alertsActions.pushAlert({ message: 'Failed to get upgrade redirect', severity: 'error' }))
    yield* put(actions.fetchBillingUrlFailure({ entityId: userId }))
  }
}

export function* downGradeIndividualPlan(action: DownGradeIndividualPlanAction): SagaGenerator<void> {
  const { entityId } = action.payload
  try {
    const { upgradeUrl } = yield* call(updateUser, { cancelSubscription: true })
    if (!upgradeUrl) {
        yield* put(alertsActions.pushAlert({
          message: 'Successfully updated the plan for your user',
          severity: 'success',
        }))
      } else {
        throw new Error('No upgrade redirect url returned from api')
      }
    yield* put(actions.downGradeIndividualPlanSuccess({ entityId, url: upgradeUrl }))
  } catch (error) {
    logger.error('Failed to cancel subscription', error, { userId: entityId })
    yield* put(alertsActions.pushAlert({ message: 'Failed to cancel subscription', severity: 'error' }))
    yield* put(actions.fetchBillingUrlFailure({ entityId }))
    yield* put(actions.downGradeIndividualPlanFailure({ entityId }))
  }
}

export function* downGradeTeamPlan(action: DownGradeIndividualPlanAction): SagaGenerator<void> {
  const { entityId } = action.payload
  try {
    const { upgradeUrl } = yield* call(updateTeam, { teamId:entityId,cancelSubscription: true })
    if (!upgradeUrl) {
        yield* put(alertsActions.pushAlert({
          message: 'Successfully updated the plan for your team',
          severity: 'success',
        }))
      } else {
        throw new Error('No upgrade redirect url returned from api')
      }
    yield* put(actions.downGradeTeamPlanSuccess({ entityId, url: upgradeUrl }))
  } catch (error) {
    logger.error('Failed to cancel subscription', error, { userId: entityId })
    yield* put(alertsActions.pushAlert({ message: 'Failed to cancel subscription', severity: 'error' }))
    yield* put(actions.fetchBillingUrlFailure({ entityId }))
    yield* put(actions.downGradeTeamPlanFailure({ entityId }))
  }
}

export function* fetchSellerDashboardUrl(action: FetchBillingUrlAction): SagaGenerator<void> {
  const { entityId } = action.payload

  try {
    const { billingUrl } = yield* call(updateUser, { getStripeConnectDashboardUrl: true })
    if (billingUrl) {
      yield* put(actions.fetchBillingUrlSuccess({ entityId, url: billingUrl }))
    } else {
      yield* put(actions.fetchBillingUrlFailure({ entityId }))
    }
  } catch (error) {
    logger.error('Failed to get seller dashboard url', error)
    yield* put(actions.fetchBillingUrlFailure({ entityId }))
  }
}

export function* fetchConsultBillingHistoryUrl(action: FetchBillingUrlAction): SagaGenerator<void> {
  const { entityId } = action.payload

  try {
    const conversation = yield* call(getConversation, entityId, true)
    if (conversation?.billingUrl) {
      yield* put(actions.fetchBillingUrlSuccess({ entityId, url: conversation?.billingUrl }))
    } else {
      yield* put(actions.fetchBillingUrlFailure({ entityId }))
    }
  } catch (error) {
    logger.error('Failed to get conversation billing url', error, { conversationId: entityId })
    yield* put(actions.fetchBillingUrlFailure({ entityId }))
  }
}

export function* fetchTeamBillingHistoryUrl(action: FetchBillingUrlAction): SagaGenerator<void> {
  const { entityId } = action.payload

  try {
    const team = yield* call(getTeam, entityId, true)
    if (team?.billingUrl) {
      yield* put(actions.fetchBillingUrlSuccess({ entityId, url: team.billingUrl }))
    } else {
      yield* put(actions.fetchBillingUrlFailure({ entityId }))
    }
  } catch (error) {
    logger.error('Failed to get team billing url', error, { teamId: entityId })
    yield* put(actions.fetchBillingUrlFailure({ entityId }))
  }
}
