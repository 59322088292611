import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from '../reducers'
import { ConnectedSlackChannel } from '../../types/integrations'
import { DeleteSlackParams, SlackIntegrationData } from '../../api/slack'

type IntegrationState = 'Loading' | 'Loaded' | 'Error' | 'Linking'
type IntegrationsStatus = { status: IntegrationState }
type IntegrationsFailed = { status: 'Error' }
export type SlackIntegration = SlackIntegrationData & IntegrationsStatus

type SlackDictionary = { [id: string]: SlackIntegration | IntegrationsFailed }

type IntegrationsState = {
  slack: SlackDictionary
}

const initialState: IntegrationsState = {
  slack: {}
}

export type FetchSlackAction = PayloadAction<{ teamId: string, conversationId?: string }>
export type FetchSlackActionFailure = FetchSlackAction
export type FetchSlackActionSuccess = PayloadAction<{ integration: SlackIntegrationData, teamId: string }>
const fetchSlackSuccess: CaseReducer<IntegrationsState, FetchSlackActionSuccess> = (state, action) => ({
  ...state,
  slack: {
    ...state.slack,
    [action.payload.teamId]: {
      ...action.payload.integration,
      conversationConnectionInfo: {
        ...((state.slack[action.payload.teamId] as SlackIntegration)?.conversationConnectionInfo ?? {}),
        ...(action.payload.integration.conversationConnectionInfo ?? {})
      },
      status: 'Loaded'
    }
  }
})

const fetchSlackFailure: CaseReducer<IntegrationsState, FetchSlackActionFailure> = (state, action) => ({
  ...state,
  slack: {
    ...state.slack,
    [action.payload.teamId]: {
      status: 'Error'
    }
  }
})

function updateSlackStatus (state: IntegrationsState, teamId: string, status: IntegrationState): IntegrationsState {
  if (!state.slack[teamId]) {
    return state
  }

  return <IntegrationsState>{
    ...state,
    slack: {
      ...state.slack,
      [teamId]: {
        ...state.slack[teamId],
        status
      }
    }
  }
}

export type ConnectSlackAction = PayloadAction<ConnectedSlackChannel>
export type ConnectSlackActionSuccess = ConnectSlackAction
export type ConnectSlackActionFailure = ConnectSlackAction

const connectSlack = (state: IntegrationsState, action: ConnectSlackAction) => {
  return updateSlackStatus(state, action.payload.teamId, 'Linking')
}

const connectSlackSuccess = (state: IntegrationsState, action: ConnectSlackActionSuccess) => {
  const { teamId } = action.payload
  if (!state.slack[teamId]) {
    return state
  }

  return <IntegrationsState>{
    ...state,
    slack: {
      ...state.slack,
      [teamId]: {
        ...state.slack[teamId],
        status: 'Loaded'
      }
    }
  }
}

const connectSlackFailure = (state: IntegrationsState, action: ConnectSlackActionFailure) => {
  return updateSlackStatus(state, action.payload.teamId, 'Error')
}

export type DisconnectSlackAction = PayloadAction<DeleteSlackParams>
export type DisconnectSlackActionSuccess = DisconnectSlackAction
export type DisconnectSlackActionFailure = DisconnectSlackAction

const disconnectSlackSuccess: CaseReducer<IntegrationsState, DisconnectSlackActionSuccess> = (state, action) => {
  const { teamId } = action.payload
  if (!state.slack[teamId]) {
    return state
  }

  return <IntegrationsState>{
    ...state,
    slack: {
      ...state.slack,
      [teamId]: {
        ...state.slack[teamId],
        status: 'Loaded'
      }
    }
  }
}

const disconnectSlackFailure = (state: IntegrationsState, action: DisconnectSlackActionFailure) => {
  return updateSlackStatus(state, action.payload.teamId, 'Error')
}

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    fetchSlack: (state, _: FetchSlackAction) => state,
    fetchSlackSuccess,
    fetchSlackFailure,
    disconnectSlack:  (state, _: DisconnectSlackAction) => state,
    disconnectSlackSuccess,
    disconnectSlackFailure,
    connectSlack,
    connectSlackSuccess,
    connectSlackFailure,
  }
})

export const actions = integrationsSlice.actions
export const selector = {
  name: integrationsSlice.name,
  select(state: AppState): IntegrationsState {
    return state.integrations
  }
}
export default integrationsSlice.reducer
