import { getUserSearchIndex, getUserSearchIndexData, getExpertSearch } from 'modules/api/search'
import { DEFAULT_SEARCH_EXPIRATION } from 'modules/constants'
import logger from 'modules/logger'
import { SearchType } from 'modules/types/search'
import { call, delay, put, SagaGenerator, select, takeEvery, takeLatest } from 'typed-redux-saga'
import {
  actions as conversationsActions,
  DeleteConversationAction,
  UpdateConversationAction,
} from '../conversations/slice'
import { actions as initializeActions } from '../initialize/slice'
import { actions as teamsActions, UpdateTeamAction } from '../teams/slice'
import { actions as usersActions, selector as usersSelector, UpdateCurrentUserSuccessAction } from '../users/slice'
import { actions, SearchConversationsAction, SearchExpertsAction, SearchUsersAction, selector } from './slice'
import { getConversationSearchKey, getSearchConversations, getSearchUsers, getUserSearchKey } from './utils'

export default function*(): SagaGenerator<void> {
  yield* takeLatest(initializeActions.requestApp, fetchUserSearchIndex)
  yield* takeLatest(actions.fetchUserSearchIndex, fetchUserSearchIndex)
  yield* takeEvery(actions.searchConversations, handleSearchConversations)
  yield* takeEvery(actions.searchExperts, handleSearchExperts)
  yield* takeEvery(actions.searchUsers, handleSearchUsers)
  yield* takeEvery(usersActions.updateCurrentUserSuccess, handleUpdateCurrentUser)

  // optimistic updates
  yield* takeEvery(teamsActions.updateTeam, handleRemoveTeam)
  yield* takeEvery(conversationsActions.updateConversation, handleLeaveConversation)
  yield* takeEvery(conversationsActions.deleteConversation, handleDeleteConversation)
}

export function* fetchUserSearchIndex(): SagaGenerator<void> {
  const { currentUser } = yield* select(usersSelector.select)
  if (currentUser?.isVisitor) {
    yield* put(actions.fetchUserSearchIndexFailure({ error: 'Skipping search index for visitor user' }))
    return
  }

  try {
    const downloadUrl = yield* call(fetchUserSearchIndexDownloadUrl)
    if (!downloadUrl) {
      yield* put(actions.fetchUserSearchIndexFailure({ error: 'Failed to get search index download url' }))
      return
    }

    const userSearchIndex = yield* call(getUserSearchIndexData, downloadUrl)
    yield* put(actions.fetchUserSearchIndexSuccess(userSearchIndex))
  } catch (error) {
    if (error.message !== 'Network Error') {
      logger.error('Failed to get user search index', error)
    }

    yield* put(actions.fetchUserSearchIndexFailure({ error: error.message }))
  }
}

const MAX_ATTEMPTS = 3
export function* fetchUserSearchIndexDownloadUrl(): SagaGenerator<string | null> {
  let attempt = 0
  let downloadUrl: string | null = null
  let fetchError: Error | null = null
  do {
    attempt++

    try {
      downloadUrl = yield* call(getUserSearchIndex)
    } catch (error) {
      fetchError = error
      if (attempt < MAX_ATTEMPTS) {
        yield* delay(attempt * 3000)
      }
    }
  } while (!downloadUrl && attempt < MAX_ATTEMPTS)

  if (!downloadUrl && fetchError) {
    throw fetchError
  }

  return downloadUrl
}

export function* handleSearchConversations(action: SearchConversationsAction): SagaGenerator<void> {
  const { teamId, text, type } = action.payload

  const { cachedConversationResults, index } = yield* select(selector.select)

  const now = new Date().getTime()
  const shouldFetchConversationResults = !cachedConversationResults[text]
    || cachedConversationResults[text].cachedAt + DEFAULT_SEARCH_EXPIRATION < now
    || (index.createdAt && index.createdAt > cachedConversationResults[text].cachedAt)
  if (!shouldFetchConversationResults) {
    return
  }

  const results = getSearchConversations(index.items, { search: text, teamId, type })
  const key = getConversationSearchKey(text, teamId, type)
  yield* put(actions.searchConversationsSuccess({ key, nextPage: null, results }))
}

export function* handleSearchExperts(action: SearchExpertsAction): SagaGenerator<void> {
  const { page, text } = action.payload

  const { cachedExpertResults } = yield* select(selector.select)

  const now = new Date().getTime()
  const cache = cachedExpertResults[text]
  const isExpired = !!cache && cache.cachedAt + DEFAULT_SEARCH_EXPIRATION <= now
  if (cache && ((page === 0 && !isExpired)
    || (cache?.nextPage && cache.nextPage > page && !isExpired))) {
    return
  }

  const pageToGet = isExpired && page > 0
    ? 0
    : page

  try {
    const { nextPage, results } = yield* call(getExpertSearch, text, pageToGet)
    yield* put(actions.searchExpertsSuccess({
      key: text,
      nextPage,
      results: pageToGet === 0 ? results : [...(cache?.results ?? []), ...results],
    }))
  } catch (err) {
    logger.error('Error searching for experts', err, { text })
    yield* put(actions.searchExpertsSuccess({ key: text, nextPage: null, results: [] }))
  }
}

export function* handleSearchUsers(action: SearchUsersAction): SagaGenerator<void> {
  const { shouldIncludeGuests, teamId, text } = action.payload

  const { cachedUserResults, index } = yield* select(selector.select)

  const now = new Date().getTime()
  const shouldFetchUserResults = !cachedUserResults[text]
    || cachedUserResults[text].cachedAt + DEFAULT_SEARCH_EXPIRATION < now
    || (index.createdAt && index.createdAt > cachedUserResults[text].cachedAt)
  if (!shouldFetchUserResults) {
    return
  }

  const results = getSearchUsers(index.items, { search: text, shouldIncludeGuests, teamId })
  const key = getUserSearchKey(text, teamId, shouldIncludeGuests)
  yield* put(actions.searchUsersSuccess({ key, nextPage: null, results }))
}

export function* handleUpdateCurrentUser(action: UpdateCurrentUserSuccessAction): SagaGenerator<void> {
  const { user } = action.payload
  const { index } = yield* select(selector.select)

  if (user.searchIndexCreatedAt && index.createdAt && user.searchIndexCreatedAt <= index.createdAt) {
    return
  }

  yield* put(actions.fetchUserSearchIndex())
}

export function* handleRemoveTeam(action: UpdateTeamAction): SagaGenerator<void> {
  const { removeUserIds, teamId } = action.payload
  if (!removeUserIds || !removeUserIds.length) {
    return
  }

  const { currentUserId } = yield* select(usersSelector.select)
  if (!currentUserId || !removeUserIds.includes(currentUserId)) {
    return
  }

  const { index } = yield* select(selector.select)
  yield* put(actions.updateUserSearchIndexSuccess({
    items: index.items.filter(i => i.id !== teamId && !(i.type === SearchType.Conversation && i.teamId === teamId))
  }))
}

function* handleLeaveConversation(action: UpdateConversationAction): SagaGenerator<void> {
  const { conversationId, removeUserIds } = action.payload
  if (!removeUserIds) {
    return
  }

  const { currentUser } = yield* select(usersSelector.select)
  if (!currentUser || !removeUserIds.includes(currentUser.id)) {
    return
  }

  yield* handleRemoveConversation(conversationId)
}

function* handleDeleteConversation(action: DeleteConversationAction): SagaGenerator<void> {
  const { conversationId } = action.payload

  yield* handleRemoveConversation(conversationId)
}

export function* handleRemoveConversation(conversationId: string): SagaGenerator<void> {
  const { index } = yield* select(selector.select)
  yield* put(actions.updateUserSearchIndexSuccess({ items: index.items.filter(i => i.id !== conversationId) }))
}
