import React, { useRef, useState } from 'react'
import { FallbackProps } from 'react-error-boundary'
import GlobalErrorModal from '../GlobalErrorModal'

const FallbackError = ({ error }: FallbackProps): React.ReactElement => {
  const wasReported = useRef<boolean>(false)
  const [isGlobalErrorModalOpen, setIsGlobalErrorModalOpen] = useState(true)

  if (!wasReported.current) {
    window.Rollbar?.error(`[Unexpected error]: ${error?.message}`, error)
    wasReported.current = true
  }

  return (
    <GlobalErrorModal
      error={error}
      isOpen={isGlobalErrorModalOpen}
      onClose={() => setIsGlobalErrorModalOpen(false)}
    />
  )
}

export default FallbackError
