import { actions as alerts } from './alerts/slice'
import { actions as analytics } from './analytics/slice'
import { actions as application } from './application/slice'
import { actions as authentication } from './authentication/slice'
import { actions as billings } from './billings/slice'
import { actions as conversations } from './conversations/slice'
import { actions as deviceSettings } from './device-settings/slice'
import { actions as gigs } from './gigs/slice'
import { actions as features } from './features/slice'
import { actions as integrations } from './integrations/slice'
import { actions as modals } from './modals/slice'
import { actions as moments } from './moments/slice'
import { actions as notifications } from './notifications/slice'
import { actions as offers } from './offers/slice'
import { actions as purchases } from './purchases/slice'
import { actions as search } from './search/slice'
import { actions as streamUploads } from './stream-uploads/slice'
import { actions as subscriptions } from './subscriptions/slice'
import { actions as teams } from './teams/slice'
import { actions as texts } from './texts/slice'
import { actions as tokens } from './tokens/slice'
import { actions as users } from './users/slice'

export const Actions = {
  alerts,
  analytics,
  application,
  authentication,
  billings,
  conversations,
  deviceSettings,
  gigs,
  features,
  integrations,
  modals,
  moments,
  notifications,
  offers,
  purchases,
  search,
  streamUploads,
  subscriptions,
  teams,
  texts,
  tokens,
  users,
}
