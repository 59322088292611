import * as api from 'modules/api/teams'
import { ADHOC_TEAM_ID } from 'modules/constants'
import logger from 'modules/logger'
import { EntityType } from 'modules/types/subscriptions'
import { call, delay, put, SagaGenerator, select, takeEvery, takeLatest } from 'typed-redux-saga'
import { actions as alertsActions } from '../alerts/slice'
import {
  actions as applicationActions,
  SelectTeamAction,
  selector as applicationSelector,
  RefetchTeamAction
} from '../application/slice'
import { selector as conversationsSelector } from '../conversations/slice'
import { actions as initializeActions } from '../initialize/slice'
import { actions as modalsActions } from '../modals/slice'
import { actions as subscriptionsActions, UpdateEntityAction } from '../subscriptions/slice'
import { actions as usersActions, selector as usersSelector, UpdateCurrentUserSuccessAction } from '../users/slice'
import {
  actions,
  CreateTeamAction,
  DeleteTeamAction,
  FetchRosterAction,
  FetchTeamsAction,
  selector,
  UpdateTeamAction
} from './slice'

export default function* main(): SagaGenerator<void> {
  yield* takeEvery(initializeActions.requestTeams, fetchCurrentUserTeams)
  yield* takeEvery(actions.fetchTeams, fetchTeams)
  yield* takeEvery(actions.createTeam, createTeam)
  yield* takeEvery(actions.updateTeam, updateTeam)
  yield* takeEvery(actions.refetchTeam, refetchTeam)
  yield* takeLatest(usersActions.updateCurrentUserSuccess, updateUserTeams)
  yield* takeLatest(usersActions.fetchCurrentUserSuccess, updateUserTeams)
  yield* takeEvery(subscriptionsActions.updateEntity, handleSubscriptionsUpdateEntity)
  yield* takeEvery(applicationActions.selectTeam, handleSelectTeam)
  yield* takeEvery(actions.deleteTeam, deleteTeam)
  yield* takeEvery(actions.fetchRoster, fetchRoster)
}

export function* fetchTeams(action: FetchTeamsAction): SagaGenerator<void> {
  const { initialize, teamIds } = action.payload

  try {
    const { missingIds, refetchIds, success: teams } = yield* call(api.getTeams, teamIds)

    if (refetchIds.length) {
      yield* put(actions.fetchTeams({ teamIds: refetchIds }))
    }
    if (missingIds.length) {
      logger.error('Teams not found', { ids: missingIds })
      yield* put(actions.fetchTeamsFailure({ teamIds: missingIds, reason: 'Not found' }))
    }

    if (initialize || Object.keys(teams).length) {
      yield* put(actions.fetchTeamsSuccess({ initialized: initialize, teams }))
    }
  } catch (error) {
    logger.error('Error getting teams', error)
  }
}

export function* createTeam(action: CreateTeamAction): SagaGenerator<void> {
  const { isGrowTeam, ...params } = action.payload
  const { name } = params

  let newTeamId
  try {
    newTeamId = yield* call(api.createTeam, params, isGrowTeam)
    const newTeam = yield* call(api.getTeam, newTeamId)

    if (!newTeam) {
      logger.error('New team was not found', { teamId: newTeamId })
      yield* put(actions.createTeamFailure({ name, newTeamId }))
    } else {
      yield* put(actions.createTeamSuccess({ team: newTeam }))
      yield* put(applicationActions.selectTeam({ teamId: newTeamId }))
      yield* put(alertsActions.pushAlert({ message: 'Your space has been created', severity: 'success' }))
    }
  } catch (error) {
    logger.error('Error creating team', error, { name })
    yield* put(actions.createTeamFailure({ name, newTeamId }))
  }
}

export function* updateTeam(action: UpdateTeamAction): SagaGenerator<void> {
  const {
    actionRemoveAllNonAdminUsers,
    addAdminIds,
    addUserIds,
    createChannelByAdminOnly,
    disableDownloading,
    disableForwarding,
    disableSharing,
    inviteByAdminOnly,
    name,
    removeAdminIds,
    removeUserIds,
    teamId,
    thumbB64,
  } = action.payload
  const state = yield* select(selector.select)
  if (!state.teams[teamId]) {
    yield* put(actions.updateTeamFailure({ teamId }))
    return
  }

  try {
    const { sellerData } = yield* call(api.updateTeam, action.payload)

    const team = state.teams[teamId]
    const teamAdminIds = [
      ...team.teamAdminIds.filter(id => !removeAdminIds?.includes(id)),
      ...(addAdminIds || []),
    ]
    const updatedTeam = {
      ...team,
      createChannelByAdminOnly: createChannelByAdminOnly !== undefined ? createChannelByAdminOnly : team.createChannelByAdminOnly,
      disableDownloading: disableDownloading !== undefined ? disableDownloading : team.disableDownloading,
      disableForwarding: disableForwarding !== undefined ? disableForwarding : team.disableForwarding,
      disableSharing: disableSharing !== undefined ? disableSharing : team.disableSharing,
      inviteByAdminOnly: inviteByAdminOnly !== undefined ? inviteByAdminOnly : team.inviteByAdminOnly,
      name: name || team.name,
      sellerData: team.sellerData || sellerData,
      teamAdminIds,
      thumbUrl: thumbB64 || team.thumbUrl,
      userIds: [
        ...(actionRemoveAllNonAdminUsers
          ? teamAdminIds
          : team.userIds.filter(id => !removeUserIds?.includes(id))),
        ...(addUserIds || []),
      ],
    }
    yield* put(actions.updateTeamSuccess({ team: updatedTeam, updateParams: action.payload }))
  } catch (error) {
    logger.error('Error updating team', error, { ...action.payload })
    yield* put(actions.updateTeamFailure({ teamId }))
  }
}

export function* fetchCurrentUserTeams(): SagaGenerator<void> {
  const { currentUser: user, currentUserId } = yield* select(usersSelector.select)
  if (!user) {
    logger.error('Current user teams requested before current user')
    return
  }

  try {
    const idsToFetch = [
      ...(!user.isVisitor ? [ADHOC_TEAM_ID] : []),
      ...user.teamIds,
      ...Object.keys(user.guestTeamConversations)
    ]
    yield* put(actions.fetchTeams({ initialize: true, teamIds: idsToFetch }))
  } catch (error) {
    logger.error('Error getting current user teams', error, { userId: currentUserId })
  }
}

export function* refetchTeam(action: RefetchTeamAction): SagaGenerator<void> {
  const { teamId } = action.payload
  try {
    const team = yield* call(api.getTeam, teamId)
    if (team) {
      yield* put(actions.refetchTeamSuccess({ team }))
    }
    else {
      yield* put(actions.updateTeamSuccessNoFilter({}))
    }

    const { rosters } = yield* select(selector.select)
    if (rosters[teamId]) {
      yield* put(actions.fetchRoster({ teamId }))
    }
  } catch (error) {
    logger.error('Error on team re-fetch', error, { teamId })
  }
}

export function* updateUserTeams(action: UpdateCurrentUserSuccessAction): SagaGenerator<void> {
  const { user } = action.payload

  const teamsState = yield* select(selector.select)
  if (!teamsState.initialized) {
    return
  }

  const existingTeamIds = Object.keys(teamsState.teams)
  const newTeamIds = user.teamIds.filter(id => id !== ADHOC_TEAM_ID && !existingTeamIds.includes(id))
  if (newTeamIds.length) {
    yield* put(actions.fetchTeams({ teamIds: newTeamIds }))
  }
}

export function* handleSubscriptionsUpdateEntity(action: UpdateEntityAction): SagaGenerator<void> {
  const { entityId, entityType } = action.payload
  if (entityType !== EntityType.Team) {
    return
  }

  yield* put(actions.refetchTeam({ teamId: entityId }))
}

export function* handleSelectTeam(action: SelectTeamAction): SagaGenerator<void> {
  const { teamId } = action.payload

  const { currentUser } = yield* select(usersSelector.select)
  if (!currentUser || !currentUser.teamInfo[teamId] || !!currentUser.teamInfo[teamId].viewedWelcomeOn) {
    return
  }

  const { teams } = yield* select(selector.select)
  const team = teams[teamId]
  if (!team?.welcomeConversationId) {
    return
  }

  const { conversations } = yield* select(conversationsSelector.select)
  const conversation = conversations[team.welcomeConversationId]
  if (!conversation || (!conversation.description && !conversation.hasMoments)) {
    return
  }

  yield* delay(1000)
  yield* put(modalsActions.showTeamIntro({ isFromTeamJoin: true, teamId }))
}

export function* deleteTeam(action: DeleteTeamAction): SagaGenerator<void> {
  const { teamId } = action.payload
  try {
    yield* call(api.deleteTeam, teamId)
    const { selectedTeamId } = yield* select(applicationSelector.select)
    if (selectedTeamId === teamId) {
      yield* put(applicationActions.selectTeam({ teamId: ADHOC_TEAM_ID }))
    }
    yield* put(actions.deleteTeamSuccess({ teamId }))
  } catch (error) {
    logger.error('Failed to delete team', error, { teamId })
    yield* put(alertsActions.pushAlert({ message: 'Failed to delete space', severity: 'error' }))
    yield* put(actions.deleteTeamFailure({ teamId }))
  }
}

export function* fetchRoster(action: FetchRosterAction): SagaGenerator<void> {
  const { getCsv, teamId } = action.payload

  try {
    const result = yield* call(api.getTeamRoster, teamId, getCsv)
    yield* put(actions.fetchRosterSuccess(getCsv && result.rosterCsv
      ? { isCsv: true, roster: result.rosterCsv, teamId }
      : { isCsv: false, roster: result.roster, teamId }))
  } catch (error) {
    logger.error('Failed to get team roster', { getCsv, teamId })
    yield* put(actions.fetchRosterFailure(action.payload))
  }
}
