import { isAfter } from 'date-fns'
import { createNotification, NotificationTarget } from 'modules/notification'
import { RecentNotification } from 'modules/types/users'
import { END, EventChannel } from 'redux-saga'
import { call, select, put, takeEvery, SagaGenerator, takeLatest, take, spawn } from 'typed-redux-saga'
import { actions as applicationActions } from '../application/slice'
import { actions as deviceSettingsActions, NetworkStatusChangedAction } from '../device-settings/slice'
import { actions as usersActions, UpdateCurrentUserSuccessAction } from '../users/slice'
import { selector, actions } from './slice'

export default function* main(): Generator {
  yield* takeLatest(usersActions.updateCurrentUserSuccess, showNotifications)
  yield* takeEvery(deviceSettingsActions.networkStatusChanged, handleNetworkDisconnect)
}

export function* showNotifications({ payload: { user } }: UpdateCurrentUserSuccessAction): SagaGenerator<void> {
  const { latestNotificationTimestamp, shouldCollapseNotifications } = yield* select(selector.select)

  let didSend = false
  const notificationsToRun = getNotificationsToRun(latestNotificationTimestamp, user.recentNotifications)

  if (!shouldCollapseNotifications) {
    for (const notification of notificationsToRun) {
      const notificationOptions = {
        body: notification.message,
        conversationId: notification.vData.conversationId,
        momentId: notification.vData.momentId,
        teamId: notification.vData.teamId,
        title: notification.title,
      }

      const webNotificationChannel = yield* call(createNotification, notificationOptions)
      if (webNotificationChannel) {
        yield* spawn(watchWebNotificationClick, webNotificationChannel)
      }
      didSend = true
    }
  } else if (user.approxUnviewed) {
    const notificationOptions = {
      body: 'Come see what you missed while you were away',
      title: `You have ${user.approxUnviewed} unviewed volleys`,
    }

    yield* call(createNotification, notificationOptions)
    didSend = true
  }

  if (didSend) {
    // notifications are ordered oldest to newest from the API
    const [latestNotification] = notificationsToRun.slice(-1)
    const timestamp = latestNotification
      ? latestNotification.createdOn
      : new Date().toISOString()
    yield* put(actions.setLatestNotificationTimestamp({ timestamp }))
  }
}

function getNotificationsToRun(latestNotificationTimestamp: string | null, notifications: RecentNotification[]): RecentNotification[] {
  if (!latestNotificationTimestamp) {
    return notifications
  }

  const afterTimestamp = Date.parse(latestNotificationTimestamp)
  return notifications.filter(n => isAfter(Date.parse(n.createdOn), afterTimestamp))
}

function* watchWebNotificationClick(channel: EventChannel<NotificationTarget>): SagaGenerator<void> {
  while (true) {
    const event = yield* take(channel)
    if (event === END) {
      channel.close()
      break
    }

    const { conversationId, momentId, teamId } = event
    if (conversationId && momentId) {
      yield* put(applicationActions.selectMoment({ conversationId, momentId }))
    } else if (conversationId) {
      yield* put(applicationActions.selectConversation({ conversationId }))
    } else if (teamId) {
      yield* put(applicationActions.selectTeam({ teamId }))
    }
  }
}

export function* handleNetworkDisconnect(action: NetworkStatusChangedAction): SagaGenerator<void> {
  const { networkStatus } = action.payload
  if (networkStatus === 'online') {
    return
  }

  yield* put(actions.collapseNextNotifications())
}
