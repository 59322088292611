const { version } = require('version.json')
import { ElectronClientInfo, getElectronClientInfo, getElectronPlatform, isElectron } from './electron-utils'

export type ClientInfoParams = {
  clientInfo: string
}

interface ClientInfo {
  browser: {
    userAgent: string
  }
  currentPlatform: string
  electron?: ElectronClientInfo,
  version: string
}

let clientInfo: string

// TODO: move to modules/api after invitation/token api calls are migrated
export default async function getClientInfo(): Promise<string> {
  if (!clientInfo) {
    const clientInfoBuilder: ClientInfo = {
      browser: {
        userAgent: navigator.userAgent
      },
      currentPlatform: 'web',
      version,
    }

    if (isElectron()) {
      const [platform, electronClientInfo] = await Promise.all([getElectronPlatform(), getElectronClientInfo()])
      clientInfoBuilder.currentPlatform = platform
      clientInfoBuilder.electron = electronClientInfo ?? undefined
    }

    clientInfo = JSON.stringify(clientInfoBuilder)
  }

  return clientInfo || ''
}
