import { call, SagaGenerator } from 'typed-redux-saga'
import { sortBy, uniqBy } from 'lodash-es'
import { PREFERRED_AUDIO_DEVICE_PREFERENCE, PREFERRED_VIDEO_DEVICE_PREFERENCE } from 'modules/constants'
import { MediaDevice } from './slice'
import { readPreference } from 'modules/hooks/preferences'

export function getSavedMediaDevicePreferences(type: 'audio' | 'video'): string[] {
  const key = type === 'audio' ? PREFERRED_AUDIO_DEVICE_PREFERENCE : PREFERRED_VIDEO_DEVICE_PREFERENCE

  return readPreference<string[]>(key, [])
}

export function pickBestDevice(availableDevices: MediaDevice[], idsInPreferenceOrder: string[]): MediaDevice | undefined {
  const sortedCandidates = sortBy(availableDevices, (device: MediaDevice) => {
    const indexInPreference = idsInPreferenceOrder.indexOf(device.id)

    if (indexInPreference < 0) {
      return 999999
    } else {
      return indexInPreference
    }
  })

  return sortedCandidates[0]
}

export type AvailableMediaDevices = {
  video: MediaDevice[]
  audio: MediaDevice[]
}
export function* getMediaDevices(): SagaGenerator<AvailableMediaDevices> {
  if (typeof navigator.mediaDevices !== 'object') {
    console.error('No video or audio devices could be found. navigator.mediaDevices is unavailable')
    return { video: [], audio: [] }
  }

  const devices = uniqBy(yield* call([navigator.mediaDevices, navigator.mediaDevices.enumerateDevices]), 'deviceId')
  const videoDevices = devices
    .filter(device => device.kind === 'videoinput' && device.label)
    .map(parseMediaDevice)
  const audioDevices = devices
    .filter(device => device.kind === 'audioinput' && device.label && !device.label.startsWith('Monitor of'))
    .map(parseMediaDevice)

  return { video: videoDevices, audio: audioDevices }
}

function parseMediaDevice(info: MediaDeviceInfo): MediaDevice {
  return { id: info.deviceId, label: info.deviceId === 'default' ? 'Same as System' : info.label }
}
