// in this file we're just doing analytics, so it's not critical. Each handler
// has a try/catch, so if we (by some weirdness) can't find a Moment or
// something, we just let it blow.

import * as analytics from 'modules/api/analytics'
import { getConversation as getConversationFromApi } from 'modules/api/conversations'
import firebase from 'modules/api/firebase'
import { getMoments as getMomentsFromApi } from 'modules/api/moments'
import { getToken as getTokenFromApi } from 'modules/api/tokens'
import logger from 'modules/logger'
import { Actions } from 'modules/store'
import { Conversation, ConversationType } from 'modules/types/conversations'
import { KnownMimeType, Moment, MomentType } from 'modules/types/moments'
import { Token, TokenType } from 'modules/types/tokens'
import { call, delay, put, SagaGenerator, select, take, takeEvery, takeLatest } from 'typed-redux-saga'
import { SelectMomentAction } from '../application/slice'
import {
  actions as authenticationActions,
  selector as authenticationSelector,
  SignInIdentitySuccessAction,
} from '../authentication/slice'
import {
  CreateConversationSuccessAction,
  DeleteConversationSuccessAction,
  selector as conversationsSelector,
  UpdateConversationSuccessAction
} from '../conversations/slice'
import { actions as initializeActions } from '../initialize/slice'
import { ConnectSlackAction, DisconnectSlackAction } from '../integrations/slice'
import { DeleteMomentSuccessAction, selector as momentsSelector } from '../moments/slice'
import {
  CancelUploadAction,
  CreateUploadSuccessAction,
  selector as streamUploadsSelector,
} from '../stream-uploads/slice'
import { getMomentTypeFromMimeType } from '../stream-uploads/utils'
import { CreateTeamSuccessAction } from '../teams/slice'
import { CancelTextAction, selector as textsSelector, SendTextSuccessAction } from '../texts/slice'
import {
  ApplyTokenSuccessAction,
  CreateTokenSuccessAction,
  selector as tokensSelector,
  UpdateTokenSuccessAction,
} from '../tokens/slice'
import { selector as usersSelector, UpdateCurrentUserSuccessAction } from '../users/slice'
import {
  actions,
  ButtonPressAction,
  ConversationJoinAction,
  ConversationSearchOpenAction,
  ConversationSearchResultViewAction,
  CreatedAccountAction,
  DeepLinkActivatedAction,
  DeepLinkActivatedPayload,
  EmbeddedTextVideoViewAction,
  InviteSentAction,
  PromoteOneOnOneToGroupConvoAction,
  ScreenViewAction,
  selector,
  SignInAction,
  TeamJoinAction,
  VideoViewAction,
  VolleyCopyTranscriptAction,
  VolleyOverSizeLimitAction,
  VolleyPublicLinkSentAction,
  VolleyTriggerDownloadEmailAction,
} from './slice'

export default function* main(): SagaGenerator<void> {
  yield* takeEvery(Actions.application.selectMoment, momentStartedPlaying) // volleyView
  yield* takeEvery(Actions.moments.deleteMomentSuccess, momentDeleted) // volleyDelete

  yield* takeEvery(Actions.streamUploads.requestStop, action => newUploadCreated(action.payload.uploadId)) // volleyCreate
  yield* takeEvery(Actions.streamUploads.createUploadSuccess, newFileOrIntroMomentCreated) // volleyCreate
  yield* takeEvery(Actions.texts.sendTextSuccess, newTextMomentCreated) // volleyCreate

  yield* takeEvery(Actions.streamUploads.cancelUpload, streamUploadCanceled) // volleyCancel
  yield* takeEvery(Actions.texts.cancelText, textCanceled) // volleyCancel

  yield* takeEvery(Actions.conversations.createConversationSuccess, createConversationSuccess) // conversationCreate, channelCreate, conversationAddUser
  yield* takeEvery(Actions.analytics.conversationJoin, conversationJoin) // conversationJoin

  yield* takeEvery(Actions.analytics.teamJoin, teamJoin) // teamJoin, happens on link followed
  yield* takeEvery(Actions.teams.createTeamSuccess, createTeamSuccess) // teamCreate, needs inviterId and tokenId

  yield* takeEvery(Actions.analytics.createdAccount, createdAccountSuccess) // accountCreate, needs inviterId and tokenId
  yield* takeEvery(Actions.users.updateCurrentUserSuccess, updateCurrentUserSuccess) // markForFollowUpCreate & markForFollowUpView

  yield* takeEvery(Actions.conversations.updateConversationSuccess, conversationUpdated) // conversationAddUser, channelDelete, channelLeave, channelPromotedConversation, removePosterFromConversation, addPosterToConversation, changeRestrictPosting
  yield* takeEvery(Actions.conversations.deleteConversationSuccess, conversationDeleted) // channelDelete

  yield* takeEvery(Actions.analytics.conversationSearchOpen, handleConversationSearchOpen) // conversationSearchOpen
  yield* takeEvery(Actions.analytics.conversationSearchResultView, handleConversationSearchResultView) // conversationSearchResultView

  yield* takeEvery(Actions.analytics.talkToMeOnVolleyEmbedLinkCopied, talkToMeOnVolleyEmbedLinkCopied) //ttmovEmbedLinkCopy
  yield* takeEvery(Actions.analytics.talkToMeOnVolleyIntroCreated, talkToMeOnVolleyIntroCreated) //ttmovIntroCreate
  yield* takeEvery(Actions.tokens.updateTokenSuccess, talkToMeOnVolleyLinkDisabled) //ttmovLinkDisable
  yield* takeEvery(Actions.tokens.createTokenSuccess, talkToMeOnVolleyLinkEnabled) //ttmovLinkEnable
  yield* takeEvery(Actions.analytics.talkToMeOnVolleyPreviewViewed, talkToMeOnVolleyPreviewViewed) //ttmovPreviewPageView

  yield* takeEvery(Actions.tokens.createTokenSuccess, slackConnectRequest) // slackConnectRequest
  yield* takeEvery(Actions.integrations.connectSlack, slackChannelLinked) // slackChannelLinked
  yield* takeEvery(Actions.integrations.connectSlack, slackChannelUnlinked) // slackChannelUnlinked

  yield* takeEvery(Actions.analytics.videoView, handleVideoView) // videoView

  yield* takeEvery(Actions.analytics.deepLinkActivated, handleDeepLinkActivated) // deepLinkActivated
  yield* takeEvery(Actions.tokens.applyTokenSuccess, handleDeepLinkActivatedViaToken) // deepLinkActivated

  yield* takeEvery(Actions.analytics.volleyPublicLinkSent, handleVolleyPublicLinkSent) // volleyPublicLinkSent

  yield* takeEvery(Actions.analytics.volleyOverSizeLimit, handleVolleyOverSizeLimit) // volleyOverSizeLimit

  yield* takeEvery(Actions.analytics.inviteSent, handleInviteSent) // inviteSent

  yield* takeEvery(Actions.analytics.promoteOneOnOneToGroupConvo, handlePromoteOneOnOneToGroupConvo) // conversationPromoteToGroup

  yield* takeEvery(Actions.analytics.volleyCopyTranscript, handleVolleyCopyTranscript) // volleyCopyTranscript

  yield* takeEvery(Actions.analytics.volleyTriggerDownloadEmail, handleVolleyTriggerDownloadEmail) // volleyTriggerDownloadEmail

  yield* takeEvery(Actions.analytics.embeddedTextVideoView, handleEmbeddedTextVideoView)

  yield* takeEvery(Actions.analytics.giphyCanceled, handleGiphyCanceled) // giphyCancelled
  yield* takeEvery(Actions.streamUploads.createGiphyUpload, handleGiphySelected) // giphySelected
}

export function* unauthenticatedMain(): SagaGenerator<void> {
  yield* takeLatest(initializeActions.requestAnalytics, initializeAnonymousId)
  yield* takeLatest(authenticationActions.signInIdentitySuccess, identifyAnalyticsUser)
  yield* takeEvery(actions.createdAccountDelayed, applyAccountCreationOnceLoggedIn)

  yield* takeLatest(actions.signIn, signIn) // signIn
  yield* takeEvery(actions.screenView, handleScreenView) // screenView
  yield* takeEvery(actions.buttonPress, handleButtonPress) // buttonPress
}

function* initializeAnonymousId(): SagaGenerator<void> {
  const url = new URL(window.location.href)
  let anonymousId = url.searchParams.get('anon_id') ?? undefined
  if (anonymousId) {
    url.searchParams.delete('anon_id')
    window.history.replaceState(null, '', url.toString())
  } else {
    let attempts = 0
    while (!analytics.segment() && attempts < 20) {
      attempts++
      yield* delay(100)
    }

    anonymousId = analytics.segment()?.user?.().anonymousId?.()
  }

  // If we're coming from a landing page, we'll usually have an anon_id param that we need to send to Segment
  //  to connect the logged out page to the logged in page and then pray that we're not scumbags with this
  //  anonymous data.
  yield* put(actions.initializeAnonymousId({ id: anonymousId }))
}

function* identifyAnalyticsUser(action: SignInIdentitySuccessAction): SagaGenerator<void> {
  const { userId } = action.payload

  try {
    firebase.analytics.identifyUser(userId)
  } catch (error) {
    logger.info('Firebase analytics initialize failed', error, { userId })
  }

  try {
    const { anonymousId } = yield* select(selector.select)
    if (anonymousId) {
      analytics.segment()?.identify(userId, {}, { anonymousId })
    } else {
      analytics.segment()?.identify(userId)
    }

    yield* put(actions.initializeUser())
  } catch (error) {
    logger.info('Segment analytics initialize failed', error, { userId })
  }
}

function* doEmitEvent(eventName: string, action: () => SagaGenerator<void>): SagaGenerator<void> {
  try {
    yield* action()
  } catch (error) {
    logger.warn('Failed to emit analytics event', error, { eventName })
  }
}

function* getUserId(): SagaGenerator<string> {
  const { currentUserId } = yield* select(usersSelector.select)
  if (!currentUserId) {
    throw new Error('Current user id not found')
  }

  return currentUserId
}

function* getToken(tokenId: string): SagaGenerator<Token> {
  const { tokens } = yield* select(tokensSelector.select)
  let token = tokens[tokenId]?.token
  if (!token) {
    token = yield* call(getTokenFromApi, { id: tokenId })
  }

  if (!token) {
    throw new Error(`Token ${tokenId} not found`)
  }

  return token
}

function* getConversation(conversationId: string): SagaGenerator<Conversation> {
  const { conversations } = yield* select(conversationsSelector.select)
  let conversation: Conversation | null = conversations[conversationId] ?? null
  if (!conversation) {
    conversation = yield* call(getConversationFromApi, conversationId)
  }

  if (!conversation) {
    throw new Error(`Conversation ${conversationId} not found`)
  }

  return conversation
}

function* getMoment(momentId: string): SagaGenerator<Moment> {
  if (!momentId.endsWith('-m')) {
    throw new Error(`Non-server moment id ${momentId}`)
  }

  const { moments } = yield* select(momentsSelector.select)
  let moment: Moment | null = moments[momentId] ?? null
  if (!moment) {
    const { success } = yield* call(getMomentsFromApi, [momentId])
    moment = success[momentId]
  }

  if (!moment) {
    throw new Error(`Moment ${momentId} not found`)
  }

  return moment
}

function* getIsAnonymous(): SagaGenerator<boolean> {
  const { accessToken } = yield* select(authenticationSelector.select)
  return !accessToken || accessToken.isAnonymous
}

function* signIn(action: SignInAction): SagaGenerator<void> {
  const { authProvider } = action.payload

  const { initialized } = yield* select(selector.select)
  if (!initialized) {
    yield* take(actions.initializeUser)
  }

  function* doSignIn() {
    const userId = yield* getUserId()
    analytics.emitSignIn({ authProvider, userId })
  }

  yield* doEmitEvent('signIn', doSignIn)
}

function* applyAccountCreationOnceLoggedIn(action: CreatedAccountAction): SagaGenerator<void> {
  // While we're not logged in, just hang out. As soon as we're logged in, we
  // can process the creation analytic event.
  while (authenticationSelector.appLoggedInState(yield* select()) !== 'loggedIn') {
    yield* take('*')
  }

  yield* put(actions.createdAccount(action.payload))
}

function* teamJoin(action: TeamJoinAction) {
  const { teamId, tokenId } = action.payload

  function* doTeamJoin() {
    const userId = yield* getUserId()
    const token = yield* getToken(tokenId)

    analytics.emitTeamJoinEvent({
      inviterId: token.creator.id,
      teamId,
      tokenId,
      tokenType: token.type,
      userId,
    })
  }

  yield* doEmitEvent('teamJoin', doTeamJoin)
}

function* createTeamSuccess(action: CreateTeamSuccessAction) {
  const { team } = action.payload

  function* doTeamCreate() {
    const userId = yield* getUserId()
    analytics.emitTeamCreateEvent({ teamId: team.id, userId })
  }

  yield* doEmitEvent('teamCreate', doTeamCreate)
}

function* conversationJoin(action: ConversationJoinAction) {
  const { conversationId, teamId, tokenId } = action.payload

  function* doConversationJoin() {
    const userId = yield* getUserId()
    const conversation = yield* getConversation(conversationId)
    const token = yield* getToken(tokenId)

    analytics.emitConversationJoinEvent({
      conversationId,
      conversationType: conversation.vType,
      inviterId: token.creator.id,
      teamId,
      tokenId,
      tokenType: token.type,
      userId,
    })
  }

  yield* doEmitEvent('conversationJoin', doConversationJoin)
}

function* createConversationSuccess(action: CreateConversationSuccessAction) {
  const { conversation } = action.payload

  function* doConversationCreate() {
    const userId = yield* getUserId()
    const baseData = {
      conversationId: conversation.id,
      teamId: conversation.teamId,
      userId: conversation.creatorUserId,
    }

    analytics.emitConversationCreateEvent({ ...baseData, conversationType: conversation.vType })

    conversation.userIds.forEach(id => {
      if (id === userId) {
        return
      }

      analytics.emitConversationAddUserEvent({ ...baseData, addedUserId: id, conversationType: conversation.vType })
    })
  }

  yield* doEmitEvent('conversationCreate', doConversationCreate)
}

export function* createdAccountSuccess(action: CreatedAccountAction): SagaGenerator<void> {
  const { authProvider, promotedFromVisitor, token } = action.payload

  function* doAccountCreate() {
    const userId = yield* getUserId()

    analytics.emitAccountCreateEvent({
      authProvider,
      inviterId: token?.creator.id,
      promotedFromVisitor,
      tokenId: token?.id,
      tokenType: token?.type,
      userId,
    })
  }

  yield* doEmitEvent('accountCreate', doAccountCreate)
}

function* newUploadCreated(uploadId: string): SagaGenerator<void> {
  function* doUploadCreated() {
    const { uploads } = yield* select(streamUploadsSelector.select)
    const upload = uploads[uploadId]
    if (!upload.momentId) {
      return
    }

    const userId = yield* getUserId()
    const moment = yield* getMoment(upload.momentId)
    const conversation = yield* getConversation(upload.conversationId)

    analytics.emitVolleyCreateEvent({
      conversationId: conversation.id,
      conversationType: conversation.vType,
      duration: upload.duration ?? moment.duration,
      momentId: upload.momentId,
      teamId: upload.teamId,
      userId,
      volleyType: upload.type,
    })
  }

  yield* doEmitEvent('volleyCreate', doUploadCreated)
}

function* newTextMomentCreated(action: SendTextSuccessAction): SagaGenerator<void> {
  function* doTextCreated() {
    const { texts } = yield* select(textsSelector.select)
    const text = texts[action.payload.textId]
    if (!text.momentId || !text.isEditing) {
      return
    }

    const userId = yield* getUserId()
    const moment = yield* getMoment(text.momentId)
    const conversation = yield* getConversation(text.conversationId)

    analytics.emitVolleyCreateEvent({
      conversationId: conversation.id,
      conversationType: conversation.vType,
      duration: moment.duration,
      momentId: moment.id,
      teamId: text.teamId,
      userId,
      volleyType: moment.vType,
    })
  }

  yield* doEmitEvent('volleyCreate (text)', doTextCreated)
}

function* newFileOrIntroMomentCreated(action: CreateUploadSuccessAction) {
  const { conversationId, teamId, type, uploadId } = action.payload
  if (type === MomentType.File || type === MomentType.Image || type === MomentType.Movie) {
    yield* newUploadCreated(uploadId)
    return
  }

  function* doIntroVolleyCreated() {
    const conversation = yield* getConversation(conversationId)
    if (conversation.vType !== ConversationType.TeamWelcome
      && conversation.vType !== ConversationType.TalkToMeOnVolley
      && conversation.vType !== ConversationType.PremiumSeed) {
      return
    }

    const userId = yield* getUserId()
    const moment = yield* getMoment(action.payload.momentId)

    analytics.emitVolleyCreateEvent({
      conversationId: conversation.id,
      conversationType: conversation.vType,
      duration: moment.duration,
      momentId: moment.id,
      teamId,
      userId,
      volleyType: moment.vType,
    })
  }

  yield* doEmitEvent('volleyCreate (intro)', doIntroVolleyCreated)
}

function* momentStartedPlaying(action: SelectMomentAction): SagaGenerator<void> {
  const { conversationId, momentId } = action.payload
  if (!momentId) {
    return
  }

  function* doVolleyViewed() {
    const userId = yield* getUserId()
    const isAnonymous = yield* getIsAnonymous()
    const conversation = yield* getConversation(conversationId)
    const moment = yield* getMoment(momentId)

    analytics.emitVolleyViewEvent({
      conversationId: conversation.id,
      conversationType: conversation.vType,
      isAnonymous,
      momentId,
      teamId: conversation.teamId,
      userId,
      volleyType: moment.vType,
    })
  }

  yield* doEmitEvent('volleyView', doVolleyViewed)
}

function* momentDeleted(action: DeleteMomentSuccessAction) {
  const { conversationId, momentId, type } = action.payload

  function* doVolleyDeleted() {
    const userId = yield* getUserId()
    const conversation = yield* getConversation(conversationId)

    analytics.emitVolleyDeleteEvent({
      conversationId: conversation.id,
      conversationType: conversation.vType,
      momentId,
      teamId: conversation.teamId,
      userId,
      volleyType: type,
    })
  }

  yield* doEmitEvent('volleyDelete', doVolleyDeleted)
}

function* streamUploadCanceled(action: CancelUploadAction): SagaGenerator<void> {
  const { momentId, uploadId } = action.payload

  function* doUploadCanceled() {
    const { uploads } = yield* select(streamUploadsSelector.select)

    const upload = uploads[uploadId]
    if (!upload) {
      throw new Error(`Upload ${uploadId} for moment ${momentId} not found`)
    }
    const userId = yield* getUserId()
    const conversation = yield* getConversation(upload.conversationId)

    analytics.emitVolleyCancelEvent({
      conversationId: conversation.id,
      conversationType: conversation.vType,
      momentId,
      teamId: conversation.teamId,
      userId,
      volleyType: upload.type,
    })
  }

  yield* doEmitEvent('volleyCancel (upload)', doUploadCanceled)
}

function* textCanceled(action: CancelTextAction): SagaGenerator<void> {
  const { textId } = action.payload

  function* doTextCanceled() {
    const { texts } = yield* select(textsSelector.select)

    const text = texts[textId]
    if (!text) {
      throw new Error(`Text ${textId} not found`)
    }
    if (text.isEditing) {
      return
    }
    const userId = yield* getUserId()
    const conversation = yield* getConversation(text.conversationId)

    analytics.emitVolleyCancelEvent({
      conversationId: conversation.id,
      conversationType: conversation.vType,
      momentId: text.momentId,
      teamId: conversation.teamId,
      userId,
      volleyType: MomentType.Text,
    })
  }

  yield* doEmitEvent('volleyCancel (text)', doTextCanceled)
}

function* updateCurrentUserSuccess(action: UpdateCurrentUserSuccessAction) {
  const { updateParams } = action.payload
  if (!updateParams?.followUps) {
    return
  }

  function* doAddFollowUps() {
    const userId = yield* getUserId()
    const newFollowUps = updateParams?.followUps?.addFollowUps ?? []

    newFollowUps.forEach(({ type }) => {
      analytics.emitMarkForFollowUpCreateEvent({ userId, volleyType: type })
    })
  }
  function* doViewFollowUps() {
    const userId = yield* getUserId()
    const viewedFollowUps = updateParams?.followUps?.viewedFollowUps ?? []

    viewedFollowUps.forEach(({ type }) => {
      analytics.emitMarkForFollowUpViewEvent({ userId, volleyType: type })
    })
  }

  yield* doEmitEvent('markForFollowUpCreate', doAddFollowUps)
  yield* doEmitEvent('markForFollowUpView', doViewFollowUps)
}

function* conversationUpdated(action: UpdateConversationSuccessAction) {
  const { conversation, updateParams } = action.payload

  function* doConversationUpdates() {
    if (!updateParams) {
      return
    }

    const userId = yield* getUserId()
    const basePayload = { conversationId: conversation.id, teamId: conversation.teamId, userId: updateParams.userId }

    updateParams.addUserIds?.forEach(id => {
      analytics.emitConversationAddUserEvent({ ...basePayload, addedUserId: id, conversationType: conversation.vType })
    })

    if (updateParams.userId === userId && updateParams.removeUserIds?.includes(userId)) {
      analytics.emitConversationLeaveEvent({ ...basePayload, conversationType: conversation.vType })
    }

    if (updateParams.vType === ConversationType.Channel) {
      analytics.emitChannelPromoteConversationEvent(basePayload)
    }

    if (conversation.vType !== ConversationType.Channel) {
      return
    }

    if (updateParams.removePosterIds?.length) {
      analytics.emitRemovePosterFromConversation({
        ...basePayload,
        allowedPosterCount: conversation.allowedPosterIds.length,
        restrictPosting: conversation.restrictPosting,
      })
    }
    if (updateParams.addPosterIds?.length) {
      analytics.emitAddPosterToConversation({
        ...basePayload,
        allowedPosterCount: conversation.allowedPosterIds.length,
        restrictPosting: conversation.restrictPosting,
      })
    }
    if (updateParams.restrictPosting !== undefined) {
      analytics.emitChangeRestrictPosting({
        ...basePayload,
        allowedPosterCount: conversation.allowedPosterIds.length,
        restrictPosting: conversation.restrictPosting,
      })
    }
  }

  yield* doEmitEvent('conversationUpdates', doConversationUpdates)
}

function* conversationDeleted(action: DeleteConversationSuccessAction) {
  const { conversationId, teamId } = action.payload

  function* doConversationDeleted() {
    const userId = yield* getUserId()
    analytics.emitChannelDeleteEvent({ conversationId, teamId, userId })
  }

  yield* doEmitEvent('channelDelete', doConversationDeleted)
}

function* handleConversationSearchOpen({ payload: { teamId } }: ConversationSearchOpenAction): SagaGenerator<void> {
  function* doSearchOpened() {
    const userId = yield* getUserId()

    analytics.emitConversationSearchOpenEvent({ teamId, userId })
  }

  yield* doEmitEvent('conversationSearchOpen', doSearchOpened)
}

function* handleConversationSearchResultView(action: ConversationSearchResultViewAction): SagaGenerator<void> {
  const { teamId, conversationId } = action.payload

  function* doSearchResultView() {
    const userId = yield* getUserId()
    analytics.emitConversationSearchResultViewEvent({ conversationId, teamId, userId })
  }

  yield* doEmitEvent('conversationSearchResultView', doSearchResultView)
}

function* talkToMeOnVolleyEmbedLinkCopied() {
  function* doTtmovEmbedLinkCopied() {
    const userId = yield* getUserId()
    analytics.emitTalkToMeOnVolleyEmbedLinkCopyEvent({ userId })
  }

  yield* doEmitEvent('talkToMeOnVolleyEmbedLinkCopy', doTtmovEmbedLinkCopied)
}

function* talkToMeOnVolleyIntroCreated() {
  function* doTtmovIntroCreated() {
    const userId = yield* getUserId()
    analytics.emitTalkToMeOnVolleyIntroCreateEvent({ userId })
  }

  yield* doEmitEvent('ttmovIntroCreate', doTtmovIntroCreated)
}

function* talkToMeOnVolleyLinkDisabled(action: UpdateTokenSuccessAction) {
  const { type } = action.payload
  if (type === TokenType.User) {
    return
  }

  function* doTtmovDisabled() {
    const userId = yield* getUserId()
    analytics.emitTalkToMeOnVolleyLinkDisableEvent({ userId })
  }

  yield* doEmitEvent('ttmovLinkDisable', doTtmovDisabled)
}

function* talkToMeOnVolleyLinkEnabled(action: CreateTokenSuccessAction) {
  const { token } = action.payload
  if (token.type === TokenType.User) {
    return
  }

  function* doTtmovEnabled() {
    const userId = yield* getUserId()
    analytics.emitTalkToMeOnVolleyLinkEnableEvent({ userId })
  }

  yield* doEmitEvent('ttmovLinkEnable', doTtmovEnabled)
}

function* talkToMeOnVolleyPreviewViewed() {
  function* doTtmovPreviewed() {
    const userId = yield* getUserId()
    analytics.emitTalkToMeOnVolleyPreviewPageViewEvent({ userId })
  }

  yield* doEmitEvent('ttmovPreviewPageView', doTtmovPreviewed)
}

function* slackConnectRequest(action: CreateTokenSuccessAction) {
  const { token } = action.payload
  if (token.type !== TokenType.Slack) {
    return
  }

  function* doSlackConnect() {
    const userId = yield* getUserId()
    analytics.emitSlackConnectRequestEvent({ teamId: token.vData.teamID, userId })
  }

  yield* doEmitEvent('slackConnectRequest', doSlackConnect)
}

function* slackChannelLinked(action: ConnectSlackAction) {
  const { conversationId, teamId } = action.payload

  function* doSlackChannelLinked() {
    const userId = yield* getUserId()
    analytics.emitSlackChannelLinkedEvent({ conversationId, teamId, userId })
  }

  yield* doEmitEvent('clackChannelLinked', doSlackChannelLinked)
}

function* slackChannelUnlinked(action: DisconnectSlackAction) {
  const { conversationId, teamId } = action.payload

  function* doSlackChannelUnlinked() {
    const userId = yield* getUserId()
    if (conversationId) {
      analytics.emitSlackChannelUnlinkedEvent({ conversationId, teamId, userId })
    } else {
      // TODO: implement when a full disconnect is allowed from the volley side
    }
  }

  yield* doEmitEvent('slackChannelUnlinked', doSlackChannelUnlinked)
}

function* handleScreenView(action: ScreenViewAction): SagaGenerator<void> {
  function* doScreenView() {
    const userId = yield* getUserId()
    const isAnonymous = yield* getIsAnonymous()

    analytics.emitScreenViewEvent({ ...action.payload, isAnonymous, userId })
  }

  yield* doEmitEvent('screenView', doScreenView)
}

function* handleButtonPress(action: ButtonPressAction): SagaGenerator<void> {
  function* doButtonPress() {
    const userId = yield* getUserId()
    const isAnonymous = yield* getIsAnonymous()

    analytics.emitButtonPressEvent({ ...action.payload, isAnonymous, userId })
  }

  yield* doEmitEvent('buttonPress', doButtonPress)
}

function* handleVideoView(action: VideoViewAction): SagaGenerator<void> {
  function* doVideoView() {
    const userId = yield* getUserId()
    const isAnonymous = yield* getIsAnonymous()

    analytics.emitVideoViewEvent({ ...action.payload, isAnonymous, userId })
  }

  yield* doEmitEvent('videoView', doVideoView)
}

function* executeDeepLinkActivated(payload: DeepLinkActivatedPayload): SagaGenerator<void> {
  const { conversationId, inviterId, momentId, search, teamId, tokenType, type } = payload

  function* doDeepLink() {
    const { anonymousId } = yield* select(selector.select)
    const userId = yield* getUserId()
    const isAnonymous = yield* getIsAnonymous()
    const searchParams = new URLSearchParams(search)

    analytics.emitDeepLinkActivatedEvent({
      anonId: anonymousId,
      conversationId,
      inviterId,
      isAnonymous,
      momentId,
      teamId,
      tokenType,
      type,
      userId,
      utmCampaign: searchParams.get('utm_campaign') ?? undefined,
      utmMedium: searchParams.get('utm_medium') ?? undefined,
      utmSource: searchParams.get('utm_source') ?? undefined,
    })
  }

  yield* doEmitEvent('deepLinkActivated', doDeepLink)
}

function* handleDeepLinkActivatedViaToken(action: ApplyTokenSuccessAction): SagaGenerator<void> {
  const { conversationId, teamId, token } = action.payload

  yield* executeDeepLinkActivated({
    conversationId,
    inviterId: token.creator.id,
    teamId,
    tokenType: token.type,
    type: 'token',
  })
}

function* handleDeepLinkActivated(action: DeepLinkActivatedAction): SagaGenerator<void> {
  yield* executeDeepLinkActivated(action.payload)
}

function* handleEmbeddedTextVideoView(action: EmbeddedTextVideoViewAction): SagaGenerator<void> {
  function* doEmbeddedVideoView() {
    const userId = yield* getUserId()
    analytics.emitEmbeddedTextVideoViewEvent({ ...action.payload, userId })
  }

  yield* doEmitEvent('embeddedTextVideoView', doEmbeddedVideoView)
}

function* handleVolleyPublicLinkSent(action: VolleyPublicLinkSentAction): SagaGenerator<void> {
  function* doVolleyPublicLinkSent() {
    const userId = yield* getUserId()
    analytics.emitVolleyPublicLinkSent({ ...action.payload, shareDestination: 'copy', userId })
  }

  yield* doEmitEvent('volleyPublicLinkSent', doVolleyPublicLinkSent)
}

function* handleVolleyOverSizeLimit({ payload }: VolleyOverSizeLimitAction): SagaGenerator<void> {
  const { conversationId, file, teamId } = payload

  function* doVolleyOverSizeLimit() {
    const mimeType = file.type as KnownMimeType
    const volleyType = getMomentTypeFromMimeType(mimeType)
    const userId = yield* getUserId()

    analytics.emitVolleyOverSizeLimit({
      conversationId,
      mimeType,
      size: Math.round(file.size / 1000 / 1000),
      teamId,
      userId,
      volleyType,
    })
  }

  yield* doEmitEvent('volleyOverSizeLimit', doVolleyOverSizeLimit)
}

function* handleInviteSent(action: InviteSentAction): SagaGenerator<void> {
  function* doInviteSent() {
    const userId = yield* getUserId()
    analytics.emitInviteSent({ ...action.payload, userId })
  }

  yield* doEmitEvent('inviteSent', doInviteSent)
}

function* handlePromoteOneOnOneToGroupConvo(action: PromoteOneOnOneToGroupConvoAction): SagaGenerator<void> {
  function* doPromoteOneOnOne() {
    const userId = yield* getUserId()
    analytics.emitConversationPromoteToGroup({ ...action.payload, userId })
  }

  yield* doEmitEvent('conversationPromoteToGroup', doPromoteOneOnOne)
}

function* handleVolleyCopyTranscript(action: VolleyCopyTranscriptAction): SagaGenerator<void> {
  function* doCopyTranscript() {
    const userId = yield* getUserId()
    analytics.emitVolleyCopyTranscript({ ...action.payload, userId })
  }

  yield* doEmitEvent('volleyCopyTranscript', doCopyTranscript)
}

function* handleVolleyTriggerDownloadEmail(action: VolleyTriggerDownloadEmailAction): SagaGenerator<void> {
  const { momentId } = action.payload

  function* doVolleyDownloaded() {
    const userId = yield* getUserId()
    const moment = yield* getMoment(momentId)
    const conversation = yield* getConversation(moment.conversationId)

    analytics.emitVolleyTriggerDownloadEmailEvent({
      conversationId: conversation.id,
      conversationType: conversation.vType,
      momentId,
      teamId: moment.teamId,
      userId,
      volleyType: moment.vType,
    })
  }

  yield* doEmitEvent('volleyTriggerDownloadEmail', doVolleyDownloaded)
}

function* handleGiphyCanceled(): SagaGenerator<void> {
  function* doGiphyCanceled() {
    const userId = yield* getUserId()

    analytics.emitGiphyCanceled({ userId })
  }

  yield* doEmitEvent('giphyCancelled', doGiphyCanceled)
}

function* handleGiphySelected(): SagaGenerator<void> {
  function* doGiphySelected() {
    const userId = yield* getUserId()

    analytics.emitGiphySelected({ userId })
  }

  yield* doEmitEvent('giphySelected', doGiphySelected)
}
