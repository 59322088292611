import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GigData, Gigs, GigsDictionary } from 'modules/types/gigs'
import { AppState } from '../reducers'

export type GigsState = {
  fetchedOn: string | null
  gigs: GigsDictionary
  error: string | null
  initialized: boolean
  isLoading: boolean
}

const initialState: GigsState = {
  fetchedOn: null,
  error: null,
  gigs: {},
  initialized: false,
  isLoading: false,
}

const fetchGigs: CaseReducer<GigsState, PayloadAction> = (state, _action) => ({
  ...state,
  isLoading: true,
})

export type FetchGigsSuccessAction = PayloadAction<Gigs>
const fetchGigsSuccess: CaseReducer<GigsState, FetchGigsSuccessAction> = (state, action) => ({
  ...state,
  fetchedOn: action.payload.fetchedOn,
  error: null,
  gigs: action.payload.gigs,
  initialized: true,
  isLoading: false,
})

type FetchGigsFailureAction = PayloadAction<{ error: string }>
const fetchGigsFailure: CaseReducer<GigsState, FetchGigsFailureAction> = (state, action) => ({
  ...state,
  initialized: true,
  isLoading: false,
  error: action.payload.error
})

type UpdateGig = { gigId: string }
export type UpdateGigAction = PayloadAction<UpdateGig>

export type UpdateGigSuccessAction = PayloadAction<{ gig: GigData }>
export const updateGigSuccess: CaseReducer<GigsState, UpdateGigSuccessAction> = (state, action) => {
  const updatedGig = action.payload.gig
  return {
    ...state,
    gigs: {
      ...state.gigs,
      [updatedGig.gigId]: updatedGig
    }
  }
}

type UpdateGigsFailureAction = PayloadAction<{ error: string }>
const updateGigsFailure: CaseReducer<GigsState, UpdateGigsFailureAction> = (state, action) => ({
  ...state,
  error: action.payload.error
})

export type LaunchOnGigAction = PayloadAction<UpdateGig & { launchOn: string }>


const gigsSlice = createSlice({
  name: 'gigs',
  initialState,
  reducers: {
    cancelGig: (state, _: UpdateGigAction) => state,
    cancelGigSuccess: updateGigSuccess,
    cancelGigFailure: updateGigsFailure,

    fetchGigs,
    fetchGigsSuccess,
    fetchGigsFailure,

    launchNow: (state, _: UpdateGigAction) => state,
    launchNowSuccess: updateGigSuccess,
    launchNowFailure: updateGigsFailure,

    launchOn: (state, _: LaunchOnGigAction) => state,
    launchOnSuccess: updateGigSuccess,
    launchOnFailure: updateGigsFailure,
  },
})

export const actions = gigsSlice.actions
export const selector = {
  name: gigsSlice.name,
  select: (state: AppState): GigsState => state.gigs,
  isStale: (state: AppState): boolean => {
    return state.gigs.initialized && state.gigs.fetchedOn && state.users.currentUser?.gigsUpdatedOn
      ? state.gigs.fetchedOn < state.users.currentUser.gigsUpdatedOn
      : false
  }
}
export default gigsSlice.reducer
