import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { compact } from 'lodash-es'
import { UpdateTeamArgs } from 'modules/api/teams'
import { ADHOC_TEAM_ID } from 'modules/constants'
import { Team, TeamRoster, TeamRosterMembersDictionary, TeamsDictionary, TemplateTeam } from 'modules/types/teams'
import { AppState } from '../reducers'

type IsWorkingDictionary = { [teamId: string]: boolean }
type IsErrorDictionary = { [teamId: string]: string }
type TeamRosterDictionary = { [teamId: string]: TeamRoster }
export type CreateTeamFailure = {
  name: string
  newTeamId?: string
}
export type TeamsState = {
  createTeamFailed?: CreateTeamFailure
  fetchTeamsFailed: IsErrorDictionary
  initialized: boolean
  isCreatingTeam: boolean
  isFetchingTeam: IsWorkingDictionary
  isFetchingRoster: IsWorkingDictionary
  isUpdatingTeam: boolean
  newTeamId: string | null
  rosters: TeamRosterDictionary
  teams: TeamsDictionary
  updateTeamFailed: { teamId?: string }
}
export const initialState: TeamsState = {
  createTeamFailed: undefined,
  fetchTeamsFailed: {},
  initialized: false,
  isCreatingTeam: false,
  isFetchingTeam: {},
  isFetchingRoster: {},
  isUpdatingTeam: false,
  newTeamId: null,
  rosters: {},
  teams: {},
  updateTeamFailed: {},
}

type CreateTeamPayload = TemplateTeam & { isGrowTeam?: boolean }
export type CreateTeamAction = PayloadAction<CreateTeamPayload>
const createTeam: CaseReducer<TeamsState, CreateTeamAction> = (state, _) => ({
  ...state,
  createTeamFailed: undefined,
  isCreatingTeam: true,
})
export type CreateTeamSuccessAction = PayloadAction<{ team: Team }>
const createTeamSuccess: CaseReducer<TeamsState, CreateTeamSuccessAction> = (state, action) => ({
  ...state,
  createTeamFailed: undefined,
  isCreatingTeam: false,
  newTeamId: action.payload.team.id,
  teams: {
    ...state.teams,
    [action.payload.team.id]: action.payload.team
  },
})
export type CreateTeamFailureAction = PayloadAction<CreateTeamFailure>
const createTeamFailure: CaseReducer<TeamsState, CreateTeamFailureAction> = (state, action) => ({
  ...state,
  createTeamFailed: action.payload,
  isCreatingTeam: false,
})

const clearNewTeam: CaseReducer<TeamsState, PayloadAction> = (state, _action) => ({
  ...state,
  newTeamId: null,
})

export type DeleteTeamAction = PayloadAction<{ teamId: string }>
const deleteTeam: CaseReducer<TeamsState, DeleteTeamAction> = (state, _action) => ({
  ...state,
  isUpdatingTeam: true,
})
const deleteTeamFailure: CaseReducer<TeamsState, DeleteTeamAction> = (state, action) => ({
  ...state,
  isUpdatingTeam: false,
  updateTeamFailed: { teamId: action.payload.teamId },
})
const deleteTeamSuccess: CaseReducer<TeamsState, DeleteTeamAction> = (state, action) => ({
  ...state,
  teams: Object.entries(state.teams)
    .filter(([id, _team]) => id !== action.payload.teamId)
    .reduce<TeamsDictionary>((acc, [id, team]) => {
      acc[id] = team
      return acc
    }, {}),
    isUpdatingTeam: false,
})

export type FetchRosterAction = PayloadAction<{ getCsv?: boolean, teamId: string }>
const fetchRoster: CaseReducer<TeamsState, FetchRosterAction> = (state, action) => ({
  ...state,
  isFetchingRoster: {
    ...state.isFetchingRoster,
    [action.payload.teamId]: true,
  },
})
const fetchRosterFailure: CaseReducer<TeamsState, FetchRosterAction> = (state, action) => {
  const roster = state.rosters[action.payload.teamId] ?? { roster: [] }

  return {
    ...state,
    isFetchingRoster: Object.keys(state.isFetchingRoster)
      .filter(id => action.payload.teamId !== id)
      .reduce(isWorkingReduceFn, {}),
    rosters: {
      ...state.rosters,
      [action.payload.teamId]: {
        roster: !action.payload.getCsv ? {} : roster.roster,
        rosterCsv: action.payload.getCsv ? '(empty)' : roster.rosterCsv,
      },
    },
  }
}
type FetchTeamRosterSuccessPayload =
  | { isCsv: false, roster: TeamRosterMembersDictionary, teamId: string }
  | { isCsv: true, roster: string, teamId: string }
export type FetchRosterSuccessAction = PayloadAction<FetchTeamRosterSuccessPayload>
const fetchRosterSuccess: CaseReducer<TeamsState, FetchRosterSuccessAction> = (state, action) => {
  const roster = state.rosters[action.payload.teamId] ?? { roster: [] }

  return {
    ...state,
    isFetchingRoster: Object.keys(state.isFetchingRoster)
      .filter(id => action.payload.teamId !== id)
      .reduce(isWorkingReduceFn, {}),
    rosters: {
      ...state.rosters,
      [action.payload.teamId]: {
        roster: !action.payload.isCsv ? action.payload.roster : roster.roster,
        rosterCsv: action.payload.isCsv ? action.payload.roster : roster.rosterCsv,
      },
    },
  }
}

const isWorkingReduceFn = (dict: IsWorkingDictionary, id: string) => {
  dict[id] = true
  return dict
}

export type FetchTeamsAction = PayloadAction<{ initialize?: boolean, teamIds: string[] }>

const fetchTeams: CaseReducer<TeamsState, FetchTeamsAction> = (state, action) => ({
  ...state,
  isFetchingTeam: {
    ...state.isFetchingTeam,
    ...action.payload.teamIds.reduce(isWorkingReduceFn, {})
  },
})
export type FetchTeamsFailureAction = PayloadAction<{ teamIds: string[], reason: string }>
const fetchTeamsFailure: CaseReducer<TeamsState, FetchTeamsFailureAction> = (state, action) => ({
  ...state,
  fetchTeamsFailed: {
    ...state.fetchTeamsFailed,
    ...action.payload.teamIds.reduce<IsErrorDictionary>((dict, id) => {
      dict[id] = action.payload.reason
      return dict
    }, {})
  },
  isFetchingTeam: Object.keys(state.isFetchingTeam)
    .filter(id => !action.payload.teamIds.includes(id))
    .reduce(isWorkingReduceFn, {}),
})
export type FetchTeamsSuccessAction = PayloadAction<{ teams: TeamsDictionary, initialized?: boolean }>
const fetchTeamsSuccess: CaseReducer<TeamsState, FetchTeamsSuccessAction> = (state, action) => {
  const addedTeamIds = Object.keys(action.payload.teams)

  return {
    ...state,
    fetchTeamsFailed: Object.entries(state.fetchTeamsFailed)
      .filter(([id, _reason]) => !addedTeamIds.includes(id))
      .reduce<IsErrorDictionary>((dict, [id, reason]) => {
        dict[id] = reason
        return dict
      }, {}),
    initialized: state.initialized || !!action.payload.initialized,
    isFetchingTeam: Object.keys(state.isFetchingTeam)
      .filter(id => !action.payload.teams[id])
      .reduce(isWorkingReduceFn, {}),
    teams: {
      ...state.teams,
      ...action.payload.teams,
    }
  }
}

type UpdateTeamPayload = Omit<UpdateTeamArgs, 'getBillingPortalUrl' | 'tierId'>
export type UpdateTeamAction = PayloadAction<UpdateTeamPayload>
const updateTeam: CaseReducer<TeamsState, UpdateTeamAction> = (state, _) => ({
  ...state,
  isUpdatingTeam: true,
  updateTeamFailed: {},
})
export type UpdateTeamSuccessAction = PayloadAction<{ team: Team, updateParams?: UpdateTeamArgs }>
const updateTeamSuccess: CaseReducer<TeamsState, UpdateTeamSuccessAction> = (state, action) => ({
  ...state,
  isUpdatingTeam: false,
  teams: Object.entries(state.teams).reduce<TeamsDictionary>((acc, [id, team]) => {
    acc[id] = id === action.payload.team.id ? action.payload.team : team
    return acc
  }, {}),
  updateTeamFailed: {},
})
export type UpdateTeamSuccessNoFilterAction = PayloadAction<{  updateParams?: UpdateTeamArgs }>
const updateTeamSuccessNoFilter: CaseReducer<TeamsState, UpdateTeamSuccessNoFilterAction> = (state, action) => ({
  ...state,
  isUpdatingTeam: false,
  updateTeamFailed: {},
})

export type UpdateTeamFailureAction = PayloadAction<{ teamId: string }>
const updateTeamFailure: CaseReducer<TeamsState, UpdateTeamFailureAction> = (state, action) => ({
  ...state,
  isUpdatingTeam: false,
  updateTeamFailed: { teamId: action.payload.teamId },
})

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    clearNewTeam,

    createTeam,
    createTeamFailure,
    createTeamSuccess,

    deleteTeam,
    deleteTeamFailure,
    deleteTeamSuccess,

    fetchRoster,
    fetchRosterFailure,
    fetchRosterSuccess,

    fetchTeams,
    fetchTeamsFailure,
    fetchTeamsSuccess,

    refetchTeam: updateTeam,
    refetchTeamSuccess: updateTeamSuccess,
    updateTeamSuccessNoFilter,

    updateTeam,
    updateTeamFailure,
    updateTeamSuccess,
  }
})

const currentUserTeams = (state: AppState): Team[] => {
  return !state.users.currentUser || !state.teams.initialized
    ? []
    : compact([
      ...(!state.users.currentUser.isVisitor ? [state.teams.teams[ADHOC_TEAM_ID]] : []),
      ...state.users.currentUser.teamIds
        .filter(id => !state.users.visitorTeamIds.includes(id))
        .map(id => state.teams.teams[id])
    ])
}
const selectedTeam = (state: AppState): Team | undefined => {
  return state.teams.initialized && state.application.selectedTeamId
    ? state.teams.teams[state.application.selectedTeamId]
    : undefined
}

export const actions = teamsSlice.actions
export const selector = {
  name: teamsSlice.name,
  select: (state: AppState): TeamsState => state.teams,
  currentUserTeams,
  selectedTeam,
}
export default teamsSlice.reducer
