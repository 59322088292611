import { MomentType, KnownMimeType } from 'modules/types/moments'
import { DEFAULT_AUDIO_MIME_TYPE, DEFAULT_MIME_TYPE } from 'modules/constants'

const KNOWN_MIME_TYPE_TO_MOMENT_TYPE: { [mimeType in KnownMimeType]: MomentType } = {
  'audio/mp4': MomentType.Audio,
  [DEFAULT_AUDIO_MIME_TYPE]: MomentType.Audio,
  'image/jpeg': MomentType.Image,
  'image/png': MomentType.Image,
  'image/gif': MomentType.Image,
  'video/mp4': MomentType.Movie,
  'video/quicktime': MomentType.Movie,
  [DEFAULT_MIME_TYPE]: MomentType.Movie,
}

export function getMomentTypeFromMimeType(mimeType: KnownMimeType): MomentType {
  return KNOWN_MIME_TYPE_TO_MOMENT_TYPE[mimeType] || MomentType.File
}
