import { END, eventChannel, EventChannel } from 'redux-saga'
import config from './config'
import { isElectron } from './electron-utils'
import logger from './logger'

export type NotificationTarget = {
  conversationId?: string
  momentId?: string
  teamId?: string
}
type VolNotificationOptions = NotificationTarget & {
  body: string
  title: string
}
export async function createNotification(options: VolNotificationOptions): Promise<EventChannel<NotificationTarget> | null> {
  if (isElectron() && window.ipcRenderer) {
    await window.ipcRenderer.invoke('show-notification', options)
    return null
  }

  try {
    const { conversationId, momentId, teamId, title, ...webOptions } = options
    const notification = new Notification(title, {
      ...webOptions,
      icon: `https://pieces.volley.app/icons/web-notification${config.env !== 'prod' ? '-dev' : ''}.png`,
    })

    return eventChannel(emit => {
      const handleClick = () => {
        emit({ conversationId, momentId, teamId })
      }
      const handleClose = () => {
        emit(END)
      }

      notification.addEventListener('click', handleClick)
      notification.addEventListener('close', handleClose)

      return () => {
        notification.removeEventListener('click', handleClick)
        notification.removeEventListener('close', handleClose)
      }
    })
  } catch (error) {
    logger.warn('Failed to create web notification', error)
    return null
  }
}
