import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BillingDictionary } from 'modules/types/billings'
import { AppState } from '../reducers'


type IsWorkingDictionary = { [id: string]: boolean }
export type BillingsState = {
  billings: BillingDictionary
  fetchFailedId?: string
  isFetching: IsWorkingDictionary
}
export const initialState: BillingsState = {
  billings: {},
  isFetching: {},
}

const removeIsWorking = (dict: IsWorkingDictionary, fetchId: string): IsWorkingDictionary => {
  return Object.keys(dict)
    .filter(id => id !== fetchId)
    .reduce<IsWorkingDictionary>((acc, id) => {
      acc[id] = true
      return acc
    }, {})
}

export type FetchBillingUrlAction = PayloadAction<{ entityId: string, isFromSearch?: boolean }>
const fetchBillingUrl: CaseReducer<BillingsState, FetchBillingUrlAction> = (state, action) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    [action.payload.entityId]: true,
  }
})
const fetchBillingUrlFailure: CaseReducer<BillingsState, FetchBillingUrlAction> = (state, action) => ({
  ...state,
  fetchFailedId: action.payload.entityId,
  isFetching: removeIsWorking(state.isFetching, action.payload.entityId),
})
type FetchBillingUrlSuccessAction = PayloadAction<{ entityId: string, url: string }>
const fetchBillingUrlSuccess: CaseReducer<BillingsState, FetchBillingUrlSuccessAction> = (state, action) => ({
  ...state,
  billings: {
    ...state.billings,
    [action.payload.entityId]: {
      ...state.billings[action.payload.entityId],
      managementUrl: action.payload.url,
    },
  },
  fetchFailedId: state.fetchFailedId === action.payload.entityId ? undefined : state.fetchFailedId,
  isFetching: removeIsWorking(state.isFetching, action.payload.entityId),
})

export type FetchPlanUpgradeUrlAction = PayloadAction<{ teamId: string, existingTierId: string, tierId: string,isDowngrading?: boolean  }>
const fetchPlanUpgradeUrl: CaseReducer<BillingsState, FetchPlanUpgradeUrlAction> = (state, action) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    [action.payload.teamId]: true,
    isDowngrading: action.payload.isDowngrading || false,

  },
})
type FetchPlanUpgradeUrlSuccessAction = PayloadAction<{ entityId: string, url?: string }>
const fetchRedirectUrlSuccess: CaseReducer<BillingsState, FetchPlanUpgradeUrlSuccessAction> = (state, action) => ({
  ...state,
  billings: {
    ...state.billings,
    [action.payload.entityId]: {
      ...state.billings[action.payload.entityId],
      redirectUrl: action.payload.url

    },
  },
  fetchFailedId: state.fetchFailedId === action.payload.entityId ? undefined : state.fetchFailedId,
  isFetching: { ...removeIsWorking(state.isFetching, action.payload.entityId), isDowngrading: false },
})

export type FetchIndividualPlanUpgradeUrlAction = PayloadAction<{ userId: string, existingTierId: string, tierId: string }>
const fetchIndividualPlanUpgradeUrl: CaseReducer<BillingsState, FetchIndividualPlanUpgradeUrlAction> = (state, action) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    [action.payload.userId]: true,
  },
})
type FetchIndividualPlanUpgradeUrlSuccessAction = PayloadAction<{ entityId: string, url?: string }>
const fetchIndividualRedirectUrlSuccess: CaseReducer<BillingsState, FetchIndividualPlanUpgradeUrlSuccessAction> = (state, action) => ({
  ...state,
  billings: {
    ...state.billings,
    [action.payload.entityId]: {
      ...state.billings[action.payload.entityId],
      redirectUrl: action.payload.url,
    },
  },
  fetchFailedId: state.fetchFailedId === action.payload.entityId ? undefined : state.fetchFailedId,
  isFetching: removeIsWorking(state.isFetching, action.payload.entityId),
})

type CloseDowngradeIndividualPlanAction = PayloadAction<{ entityId: string }>
const closeDowngradeIndividualModal: CaseReducer<BillingsState, CloseDowngradeIndividualPlanAction> = (state, action) => ({
  ...state,
  billings: {
    ...state.billings,
    [action.payload.entityId]: {
      ...state.billings[action.payload.entityId],
      showDowngrade: false
    },
  },
})

export type DownGradeIndividualPlanAction = PayloadAction<{ entityId: string }>
const downGradeIndividualPlan: CaseReducer<BillingsState, DownGradeIndividualPlanAction> = (state, action) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    [action.payload.entityId]: true,
    isDowngrading: true,
  },
})

type DownGradeIndividualPlanSuccessAction = PayloadAction<{ entityId: string, url?: string }>
const downGradeIndividualPlanSuccess: CaseReducer<BillingsState, DownGradeIndividualPlanSuccessAction> = (state, action) => ({
  ...state,
  billings: {
    ...state.billings,
    [action.payload.entityId]: {
      ...state.billings[action.payload.entityId],
      redirectUrl: action.payload.url,
    },
  },
  fetchFailedId: state.fetchFailedId === action.payload.entityId ? undefined : state.fetchFailedId,
  isFetching: {
    ...removeIsWorking(state.isFetching, action.payload.entityId),
    isDowngrading: false,    
  },
})

type DownGradeIndividualPlanFailureAction = PayloadAction<{ entityId: string}>
const downGradeIndividualPlanFailure: CaseReducer<BillingsState, DownGradeIndividualPlanFailureAction> = (state, action) => ({
  ...state,
  billings: {
    ...state.billings,
    [action.payload.entityId]: {
      ...state.billings[action.payload.entityId],
      isDowngrading: false,
    },
  },
  fetchFailedId: state.fetchFailedId === action.payload.entityId ? undefined : state.fetchFailedId,
  isFetching: removeIsWorking(state.isFetching, action.payload.entityId),
})

export type DownGradeTeamPlanAction = PayloadAction<{ entityId: string }>
const downGradeTeamPlan: CaseReducer<BillingsState, DownGradeTeamPlanAction> = (state, action) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    [action.payload.entityId]: true,
    isDowngrading: true,
  },
})

type DownGradeTeamPlanSuccessAction = PayloadAction<{ entityId: string, url?: string }>
const downGradeTeamPlanSuccess: CaseReducer<BillingsState, DownGradeTeamPlanSuccessAction> = (state, action) => ({
  ...state,
  billings: {
    ...state.billings,
    [action.payload.entityId]: {
      ...state.billings[action.payload.entityId],
      redirectUrl: action.payload.url,
    },
  },
  fetchFailedId: state.fetchFailedId === action.payload.entityId ? undefined : state.fetchFailedId,
  isFetching: {
    ...removeIsWorking(state.isFetching, action.payload.entityId),
    isDowngrading: false,    
  },
})

type DownGradeTeamPlanFailureAction = PayloadAction<{ entityId: string}>
const downGradeTeamPlanFailure: CaseReducer<BillingsState, DownGradeTeamPlanFailureAction> = (state, action) => ({
  ...state,
  billings: {
    ...state.billings,
    [action.payload.entityId]: {
      ...state.billings[action.payload.entityId],
      isDowngrading: false,
    },
  },
  fetchFailedId: state.fetchFailedId === action.payload.entityId ? undefined : state.fetchFailedId,
  isFetching: removeIsWorking(state.isFetching, action.payload.entityId),
})

type RemoveBillingUrlAction = PayloadAction<{ entityId: string }>
const removeBillingUrl: CaseReducer<BillingsState, RemoveBillingUrlAction> = (state, action) => ({
  ...state,
  billings: {
    [action.payload.entityId]: {
      ...state.billings[action.payload.entityId],
      managementUrl: undefined,
    }
  }
})

const billingsSlice = createSlice({
  name: 'billings',
  initialState,
  reducers: {
    fetchBillingUrlFailure,
    fetchBillingUrlSuccess,
    fetchConsultBillingHistoryUrl: fetchBillingUrl,
    fetchConsultSellerSetupUrl: fetchBillingUrl,
    fetchGrowSellerSetupUrl: fetchBillingUrl,
    fetchPlanBillingUrl: fetchBillingUrl,
    fetchPlanUpgradeUrl,
    fetchIndividualPlanBillingUrl: fetchBillingUrl,
    fetchIndividualPlanUpgradeUrl,
    fetchPurchaseUrl: fetchBillingUrl,
    fetchRedirectUrlSuccess,
    fetchIndividualRedirectUrlSuccess,
    fetchSellerDashboardUrl: fetchBillingUrl,
    fetchTeamBillingHistoryUrl: fetchBillingUrl,
    removeBillingUrl,
    downGradeIndividualPlan,
    downGradeIndividualPlanSuccess,
    downGradeIndividualPlanFailure,
    closeDowngradeSuccessModal: closeDowngradeIndividualModal,
    downGradeTeamPlan,
    downGradeTeamPlanSuccess,
    downGradeTeamPlanFailure,
  }
})

export const actions = billingsSlice.actions
export const selector = {
  name: billingsSlice.name,
  select: (state: AppState): BillingsState => state.billings,
}
export default billingsSlice.reducer
