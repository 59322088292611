import { Action } from '@reduxjs/toolkit'
import { all, fork, put, race, SagaGenerator, select, take } from 'typed-redux-saga'
import analytics, { unauthenticatedMain as analyticsUnauthenticated } from './analytics/sagas'
import application from './application/sagas'
import { actions as applicationActions, selector as applicationSelector } from './application/slice'
import authentication from './authentication/sagas'
import { selector as authenticationSelector } from './authentication/slice'
import billings from './billings/sagas'
import conversations from './conversations/sagas'
import deviceSettings from './device-settings/sagas'
import features from './features/sagas'
import { actions as featuresActions } from './features/slice'
import gigs from './gigs/sagas'
import initialize from './initialize/sagas'
import { actions as initializeActions } from './initialize/slice'
import integrations from './integrations/sagas'
import moments from './moments/sagas'
import notifications from './notifications/sagas'
import offers from './offers/sagas'
import purchases from './purchases/sagas'
import search from './search/sagas'
import streamUploads from './stream-uploads/sagas'
import subscriptions from './subscriptions/sagas'
import teams from './teams/sagas'
import texts from './texts/sagas'
import tokens, { unauthenticatedMain as tokensUnauthenticated } from './tokens/sagas'
import users, { unauthenticatedMain as usersUnauthenticated } from './users/sagas'
import { actions as usersActions } from './users/slice'

export default function* root(): SagaGenerator<void> {
  const { hasFeatures } = yield* startUnauthenticatedSagas()

  const appLoggedInState = yield* select(authenticationSelector.appLoggedInState)
  if (appLoggedInState !== 'loggedIn') {
    yield* take(usersActions.fetchCurrentUserSuccess)
  }

  yield* startAuthenticatedSagas(hasFeatures)
}

function* startUnauthenticatedSagas(): SagaGenerator<{ hasFeatures: boolean }> {
  yield* fork(authentication)
  yield* fork(application)
  yield* fork(initialize)
  yield* fork(features)
  yield* fork(analyticsUnauthenticated)

  yield* put(initializeActions.requestAnalytics())
  yield* put(initializeActions.requestFeatures())
  yield* put(initializeActions.requestVolleyStatus())

  const { featuresLoaded, volleyStatus } = yield* all({
    featuresLoaded: race({
      failure: take(featuresActions.fetchFeaturesFailure),
      success: take(featuresActions.fetchFeaturesSuccess),
    }),
    volleyStatus: take(applicationActions.fetchVolleyStatusSuccess),
  })

  yield* fork(usersUnauthenticated)
  yield* fork(tokensUnauthenticated)
  yield* fork(subscriptions)

  yield* put(initializeActions.requestIdentity())

  if (!volleyStatus.payload.isUp) {
    yield* take((action: Action) => applicationActions.fetchVolleyStatusSuccess.match(action) && action.payload.isUp)
  }

  return { hasFeatures: !!featuresLoaded.success }
}

function* startAuthenticatedSagas(hasFeatures: boolean): SagaGenerator<void> {
  yield* fork(teams)
  yield* fork(conversations)
  yield* fork(moments)
  yield* fork(users)
  yield* fork(tokens)
  yield* fork(billings)

  const { hasAuthenticatedRoute } = yield* select(applicationSelector.select)
  if (!hasAuthenticatedRoute) {
    yield* race([
      take(applicationActions.selectTeam),
      take(applicationActions.selectConversation),
      take(applicationActions.selectMoment),
    ])
  }

  yield* put(initializeActions.requestTeams())
  yield* put(initializeActions.requestConversations())

  yield* fork(streamUploads)
  yield* fork(texts)
  yield* fork(notifications)
  yield* fork(gigs)
  yield* fork(search)
  yield* fork(offers)
  yield* fork(purchases)
  yield* fork(integrations)
  yield* fork(deviceSettings)
  yield* fork(analytics)

  yield* put(initializeActions.requestApp({ hasFeatures }))
}
