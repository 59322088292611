import algolia, { SearchIndex as AlgoliaSearchIndex } from 'algoliasearch'
import axios from 'axios'
import gql from 'graphql-tag'
import getClientInfo from 'modules/clientInfo'
import logger from 'modules/logger'
import Features from 'modules/store/features/utils'
import { ConversationType } from 'modules/types/conversations'
import { ExpertSearchData, FeatureId } from 'modules/types/features'
import { OfferCategory } from 'modules/types/offers'
import {
  BaseSearchIndex,
  Role,
  SearchConversation,
  SearchExpert,
  SearchIndexItem,
  SearchTeam,
  SearchType,
  SearchUser,
  UserSearchIndex
} from 'modules/types/search'
import { checkForErrors, mutateGraphQl } from './graph-utils'

type GetUserSearchIndexParams = {
  clientInfo: string
}
type GetUserSearchIndexResult = {
  getUserSearchIndex: string | null
}
type AssociationMap = {
  [id: string]: {
    srcRole: Role
    srcType: SearchType
  }
}
type SearchIndex_Data = {
  assocMap?: AssociationMap
  familyName?: string
  givenName?: string
  name?: string
  sortDate?: string
  srcID: string
  srcType: SearchType
  teamID?: string
  text: string
  vType?: ConversationType
  role?: Role
  thumbURL?: string
}
type GetUserSearchIndex_Data = {
  createdOn: string | null
  searchIndex: SearchIndex_Data[]
}

const getUserSearchIndexMutation = gql`
  mutation GetUserSearchIndex($inlineResponse: Boolean, $clientInfo: String) {
    getUserSearchIndex(inlineResponse: $inlineResponse, clientInfo: $clientInfo)
  }
`

export async function getUserSearchIndex(): Promise<string | null> {
  const result = await mutateGraphQl<GetUserSearchIndexResult, GetUserSearchIndexParams>({
    mutation: getUserSearchIndexMutation,
    variables: {
      clientInfo: await getClientInfo()
    }
  })

  checkForErrors('getUserSearchIndex', result)

  const { downloadURL } = JSON.parse(result.data?.getUserSearchIndex ?? '{}')

  return downloadURL ?? null
}

function deserializeUserSearchIndex(data: GetUserSearchIndex_Data): UserSearchIndex {
  return {
    createdAt: data.createdOn ? new Date(data.createdOn).getTime() : new Date().getTime(),
    items: data.searchIndex.map((idx: SearchIndex_Data) => {
      const { assocMap, familyName, givenName, name, role, sortDate, srcID, srcType, teamID, text, thumbURL, vType } = idx
      const base: BaseSearchIndex = {
        id: srcID,
        terms: text,
        type: srcType,
      }

      if (assocMap) {
        return {
          ...(base as SearchUser),
          associations: Object.entries(assocMap as AssociationMap).map(([id, assoc]) => ({
            id,
            role: assoc.srcRole,
            type: assoc.srcType,
          })),
          familyName: familyName || '',
          givenName: givenName || '',
          thumbUrl: thumbURL || null,
        }
      }

      if (role && teamID) {
        return {
          ...(base as SearchConversation),
          name: name || '',
          role,
          sortDate: sortDate || new Date().toISOString(),
          teamId: teamID,
          vType: vType || ConversationType.Group,
        }
      }

      if (role) {
        return {
          ...(base as SearchTeam),
          role,
          name: name || '',
        }
      }

      logger.warn('Found search index record that is neither user, convo, or team', { id: srcID, type: srcType, terms: text })
      return base as SearchIndexItem
    }),
  }
}

export async function getUserSearchIndexData(url: string): Promise<UserSearchIndex> {
  const res = await axios.get(url)
  return deserializeUserSearchIndex(res.data)
}

let algoliaIndex: AlgoliaSearchIndex

function getAlgoliaIndex(): AlgoliaSearchIndex | null {
  if (!algoliaIndex) {
    const expertSearchFeature = Features.get<ExpertSearchData>(FeatureId.ExpertSearch)
    if (!expertSearchFeature.data?.appId && expertSearchFeature.data?.apiKey) {
      logger.error('Failed to get algolia search config', { data: expertSearchFeature.data })
      return null
    }

    const algoliaClient = algolia(expertSearchFeature.data.appId, expertSearchFeature.data.apiKey)
    algoliaIndex = algoliaClient.initIndex(expertSearchFeature.data.searchIndex)
  }

  return algoliaIndex
}

type SearchResult<T> = {
  nextPage: number | null
  results: T[]
}
type GetExpert = {
  landingPageURL: string
  objectID: string
  subtitle: string
  thumbURL?: string
  title: string
  vType: OfferCategory
}

export async function getExpertSearch(text: string, page = 0, limit = 20): Promise<SearchResult<SearchExpert>> {
  const index = getAlgoliaIndex()
  if (!index) {
    return { nextPage: null, results: [] }
  }

  const { hits } = await index.search<GetExpert>(text, { hitsPerPage: limit, page })

  return {
    nextPage: hits.length < limit ? null : page + 1,
    results: deserializeSearchExpert(hits),
  }
}

function deserializeSearchExpert(hits: GetExpert[]): SearchExpert[] {
  return hits.map(h => ({
    id: h.objectID,
    landingPageUrl: h.landingPageURL,
    subtitle: h.subtitle,
    thumbUrl: h.thumbURL ?? null,
    title: h.title,
    type: h.vType,
  }))
}
