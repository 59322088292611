import styled from '@emotion/styled'
import { alpha } from '@mui/material/styles'
import { MOBILE_BREAKPOINT } from 'modules/constants'
import { isElectron } from 'modules/electron-utils'
import React, { useEffect, useState } from 'react'
import Button from '../Button'
import Dialog, { OnCloseEvent } from '../Dialog'
import { Emoji } from '../Emoji'
import Tooltip from '../Tooltip'

const DialogBody = styled(Dialog.Body)`
  width: 490px;

  @media(max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`
const EmojiContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 80px;
  width: 80px;
`
const List = styled.ul`
  & > li:not(:last-of-type) {
    margin-bottom: 4px;
  }
  & a:first-of-type {
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`
const Note = styled.p`
  color: ${({ theme }) => alpha(theme.palette.common.black, 0.75)};
  font-size: 13px;
  margin: 4px 0 0;
`
const LinkButton = styled(Button)`
  padding: 0;
  font-size: 1em;
  vertical-align: baseline;
`

type ErrorModalProps = {
  error?: Error
  isOpen: boolean
  onClose: OnCloseEvent
}
const GlobalErrorModal = ({ error, isOpen, onClose }: ErrorModalProps): React.ReactElement => {
  const [isCopyTooltipOpen, setIsCopyTooltipOpen] = useState(false)
  const [emoji, setEmoji] = useState(getRandomEmoji())
  const errorText = getErrorText(error)

  useEffect(() => {
    if (!isCopyTooltipOpen) {
      return
    }

    const timeout = window.setTimeout(() => setIsCopyTooltipOpen(false), 3000)
    return () => clearTimeout(timeout)
  }, [isCopyTooltipOpen])

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(errorText)
    setIsCopyTooltipOpen(true)
  }

  return (
    <Dialog ariaLabeledById="error-modal" disableBackdropClick disableEscapeKeyDown isOpen={isOpen} onClose={onClose}>
      <Dialog.Header ariaLabeledById="error-modal">I Am Error</Dialog.Header>
      <DialogBody>
        <TitleContainer>
          <EmojiContainer onClick={() => setEmoji(getRandomEmoji())}>
            <Emoji nativeEmoji={emoji} size={80} />
          </EmojiContainer>
          <span>Well that was unexpected.</span>
        </TitleContainer>
        <p>Unfortunately, something went wrong and it&apos;s totally our fault. Here are your options:</p>
        <List>
          <li><LinkButton variant="link" onClick={() => window.location.reload()}>Refresh</LinkButton> the current view and try again.</li>
          <li><a href="/">Restart</a> the application.</li>
          <li><LinkButton variant="link" href="/sign_out">Sign out</LinkButton> and see if logging back in helps.</li>
          <li>Contact support at <a href="mailto:support@volleyapp.com" target="_blank" rel="noopener noreferrer">support@volleyapp.com</a> and let us know what happened.
            {errorText && <Note>You can click <Tooltip isOpen={isCopyTooltipOpen} title="Copied to clipboard"><LinkButton variant="link" onClick={handleCopyLink}>here</LinkButton></Tooltip> to copy technical details to your clipboard and send them to us.</Note>}
          </li>
          <li><LinkButton variant="link" onClick={onClose}>Ignore</LinkButton> and hope for the best?</li>
          <li>Close and reopen the {isElectron() ? 'app' : 'browser tab'}.</li>
        </List>
        <p>Sorry for the inconvenience. We hope one of these resolves the issue for you.</p>
      </DialogBody>
    </Dialog>
  )
}

export default GlobalErrorModal

function getErrorText(error?: Error): string {
  return error
    ? `
Name: ${error.name}
Message: ${error.message}
Stack: ${error.stack}
`
    : ''
}

const EMOJI = ['😳', '💣', '😱', '☹️', '😕', '😬', '🙀', '💥']
function getRandomEmoji(): string {
  return EMOJI[Math.floor(Math.random() * EMOJI.length)]
}
