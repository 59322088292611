import { AuthProvider } from './auth'
import { ConversationType } from './conversations'
import { TierLimits } from './tier-limits'

export enum NotificationPreferenceType {
  Conversation = 'conversation',
  Team = 'team',
}
export enum UserState {
  Active = 'ACTIVE',
  RemovedByUserRequest = 'REMOVED_BY_USER_REQUEST',
}

export type User = {
  approxUnviewed: number
  blockedUsers: BlockedUsersDictionary
  conversationData: ConversationDataDictionary
  conversationIds: string[]
  disableDownloading: boolean
  disableForwarding: boolean
  disableSharing: boolean
  email: string
  familyName: string
  followUpMoments: FollowUpMomentsDictionary
  gigsUpdatedOn: string | null
  givenName: string
  guestTeamConversations: GuestTeamConversations
  id: string
  isSuperAdmin: boolean
  isVisitor: boolean
  notificationPreferences: NotificationPreferencesDictionary
  phone: string | undefined
  premiumUserFee: PremiumUserFee
  recentNotifications: RecentNotification[]
  sellerData: SellerData | null
  settings: Settings
  searchIndexCreatedAt: number | null
  suppressConsultOffersFromSellerSearch: boolean
  teamIds: string[]
  teamInfo: TeamInfoDictionary
  talkToMeOnVolleyData: TalkToMeOnVolleyDataDictionary
  thumbEol: string | null
  thumbUpdatedOn: string | null
  thumbUrl: string | null
  tierId: string
  tierEol: string | null
  tierWillRenew?: boolean | null
  hasFreeProWithSpacePro?: boolean | null
  tierLimits: TierLimits
  vanityUrl: string | null
  vState: UserState
  welcomeConversationId: string | null
}
export type SellerData = {
  analyticsEol?: string
  analyticsUrl?: string
  showSellerAnalyticsBadge: boolean
  slaText: string | null
}
export type ParticipantsDictionary = { [id: string]: Participant }
export type Participant = {
  familyName: string
  givenName: string
  id: string
  thumbUrl: string | null
}
export type PremiumUserFee = {
  discountEol?: string
  percentage: number
}
export type BlockedUsersDictionary = { [userId: string]: { blockedOn: string } }
export type ConversationDataDictionary = { [conversationId: string]: ConversationData }
export type ConversationData = {
  conversationId: string
  conversationType: ConversationType // vType
  hasNewContent?: boolean
  justJoined?: boolean
  showNewBadge?: boolean
  sortDate: string
  sortToTop?: boolean
  teamId: string
  threadUnviewedCount: number
  unviewedCount: number
  userIds: string[]
}
export type GuestTeamConversations = { [teamId: string]: string[] }
export type TeamInfoDictionary = { [teamId: string]: TeamInfo }
type TeamInfo = {
  joinedOn?: string
  viewedWelcomeOn?: string
}
export type RecentNotification = {
  createdOn: string
  message: string
  title: string
  vData: NotificationData
}
type NotificationData = {
  action?: string
  conversationId?: string
  momentId?: string
  teamId?: string
  thumbUrl?: string
  videoUrl?: string
}
export type FollowUpMomentsDictionary = { [momentId: string]: FollowUpMoment }
export type FollowUpMoment = {
  conversationId: string
  createdOn: string
  creatorUserId: string
  id: string
  markedOn: string
  teamId: string
}
export type UserOrigin = 'unknown' | 'convoLink' | 'workspace'
export type WorkspaceTutorial = {
  inviteUsers?: boolean
  recordedAVolley?: boolean
  setupChannels?: boolean
  showedNUXSuccess?: boolean
}
export type WorkspaceTutorials = { [workspaceId: string]: WorkspaceTutorial }
export type TutorialStatuses = {
  linkFollowBackstop?: 'dismissed' | undefined
  recordedAVolley?: boolean
  setupTeamConvList?: 'dismissed' | undefined
  showedAboutMyNetwork?: boolean
  showedNUXSuccess?: boolean
  showWelcomeScreen?: boolean
  startedAConvo?: boolean
  startedAConvoPrompt?: boolean
  viewedAVolley?: boolean
  viewedContextMenu?: boolean
  viewedOwnVolley?: boolean
  workspaceTutorials: WorkspaceTutorials
}
export type Settings = {
  textVolleyBackgroundColor?: string
}
type TalkToMeOnVolleyData = {
  conversationId: string
  needsAttention: boolean
  tokenId: string
  vType: ConversationType
}
export type TalkToMeOnVolleyDataDictionary = { [conversationId: string]: TalkToMeOnVolleyData }
export type NotificationPreference = {
  suppressAll?: boolean
  suppressChannelsAll?: boolean
  suppressThreadsAll?: boolean
  vType: NotificationPreferenceType
}
// the id here can be a conversation ID, team ID, etc
export type NotificationPreferencesDictionary = { [id: string]: NotificationPreference }

export type CreateUserParams = {
  authProvider: AuthProvider
  email: string
  familyName: string
  givenName: string
  phoneNumber?: string
  thumbB64: string | null
}

export enum StripeConnect {
  Consult = 'consult',
  Grow = 'space',
}
