import { put, SagaGenerator, select, takeEvery } from 'typed-redux-saga'

import { AUTO_LAUNCH_PREFERENCE, CLOSE_TO_TRAY_PREFERENCE } from 'modules/constants'
import { isElectron } from 'modules/electron-utils'
import { readPreference, writePreference } from 'modules/hooks/preferences'
import { actions, InitializeAppAction } from './slice'
import { actions as alertActions } from '../alerts/slice'
import { selector as usersSelector } from '../users/slice'

export default function*(): SagaGenerator<void> {
  yield* takeEvery(actions.requestApp, initializeApp)
}

function* initializeApp(action: InitializeAppAction): SagaGenerator<void> {
  const { hasFeatures } = action.payload

  if (!hasFeatures) {
    yield* put(alertActions.pushAlert({
      severity: 'error',
      message: 'There was an error initializing the app. Please close or refresh the app to try again.'
    }))
    return
  }

  yield* select()

  sendInitialOptionsToElectron()
  yield* addDevToolsToElectron()
}

function sendInitialOptionsToElectron() {
  if (!isElectron()) {
    return
  }

  let autoLaunch = readPreference<boolean | null>(AUTO_LAUNCH_PREFERENCE, null)
  if (autoLaunch === null) {
    // We're going to default to autoLaunch for new and existing installations.
    writePreference(AUTO_LAUNCH_PREFERENCE, true)
    autoLaunch = true
  }

  let closeToTray = readPreference<boolean | null>(CLOSE_TO_TRAY_PREFERENCE, true)
  if (closeToTray === null) {
    // We're going to default to close to tray for new and existing installations.
    writePreference(CLOSE_TO_TRAY_PREFERENCE, true)
    closeToTray = true
  }

  window.ipcRenderer?.send('set-options', { autoLaunch, closeToTray })
}

function* addDevToolsToElectron() {
  if (!isElectron()) {
    return
  }

  const { currentUser } = yield* select(usersSelector.select)
  if (!currentUser?.isSuperAdmin) {
    return
  }

  window.ipcRenderer?.send('add-dev-tools')
}
