import * as api from 'modules/api/purchases'
import logger from 'modules/logger'
import { call, put, SagaGenerator, takeEvery } from 'typed-redux-saga'
import { actions, CancelPurchaseAction, FetchPurchasesAction } from './slice'

export default function*(): SagaGenerator<void> {
  yield* takeEvery(actions.fetchPurchases, fetchPurchases)
  yield* takeEvery(actions.cancelPurchase, cancelPurchase)
}

export function* fetchPurchases(action: FetchPurchasesAction): SagaGenerator<void> {
  const ids = action.payload?.ids

  try {
    const { missingIds, refetchIds, success: purchases } = yield* call(api.getPurchases, ids)

    if (refetchIds.length) {
      yield* put(actions.fetchPurchases({ ids: refetchIds }))
    }
    if (missingIds.length) {
      logger.warn('Purchases not found', { ids: missingIds })
      yield* put(actions.fetchPurchasesFailure({ ids: missingIds, reason: 'Not found' }))
    }

    if (Object.keys(purchases).length) {
      yield* put(actions.fetchPurchasesSuccess({ purchases, wasFetchingById: !!ids?.length }))
    }
  } catch (error) {
    logger.error('Error getting purchases', error, { ids })
    yield* put(actions.fetchPurchasesFailure({ ids, reason: error.message }))
  }
}

export function* cancelPurchase(action: CancelPurchaseAction): SagaGenerator<void> {
  const { conversationId, teamId } = action.payload
  const id = conversationId ?? teamId ?? '(empty)'

  if (!conversationId && !teamId) {
    logger.error('Attempted to cancel purchase without conversation or team id')
    yield* put(actions.cancelPurchaseFailure({ id }))
    return
  }

  try {
    yield* call(api.cancelPurchase, conversationId, teamId)
    yield* put(actions.cancelPurchaseSuccess({ id }))
  } catch (error) {
    logger.error('Failed to cancel purchase', error, { id })
    yield* put(actions.cancelPurchaseFailure({ id }))
  }
}
