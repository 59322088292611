import React, { Suspense } from 'react'
import FallbackError from './FallbackError'
import FullPageLoading from '../FullPageLoading'
import AppDownloadModal from '../AppDownloadModal'

const RoutedApp = React.lazy(() => import('./RoutedApp'))

const App = (): React.ReactElement => (
  <Suspense fallback={<FullPageLoading />}>
    <RoutedApp />
    <AppDownloadModal/>
  </Suspense>
)

export default App

export {
  FallbackError
}
