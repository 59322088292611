import { TemplateTeam } from './teams'

export enum FeatureId {
  DesktopConfig = 'desktop-config',
  ExpertSearch = 'marketplace-search-algolia',
  FuneralPyre = 'funeral-pyre-banners',
  MomentCountThreshold = 'disabling-moment-count',
  NoAccountForYou = 'funeral-pyre-accounts',
  ShutItDown = 'funeral-pyre-purchases',
  Sso = 'sso',
  TeamTemplates = 'team-templates',
  TeamTiers = 'team-tiers',
}

export enum DesktopFeatureId {
  JoshDemo = 'josh-demo',
}

export const ALL_FEATURE_IDS = Object.keys(FeatureId)
  .filter(key => Number.isNaN(+key))
  .map(key => FeatureId[key as keyof typeof FeatureId])

export type FeaturesDictionary = { [id: string]: Feature }
export type Feature<T = unknown> = {
  data: T
  id: FeatureId
  isActive: boolean
  updatedAt: number
}

type SlackIntegrationSettings = {
  retryAttempts: number
  retrySleepMs: number
  slackAppVersion: number
}
export type DesktopConfigData = {
  features: {
    [DesktopFeatureId.JoshDemo]: boolean
  }
  slackIntegration: SlackIntegrationSettings
}

export type ExpertSearchData = {
  apiKey: string
  appId: string
  searchIndex: string
}

export type FuneralPyreData = {
  learnMore: string
  shutdownOn: string
}

export type MomentCountThresholdData = {
  maxConversationMomentCount: number
  maxThreadMomentCount: number
}

export type SsoData = {
  failedRegexMessage: string
  isGracePeriodActive: boolean
  passwordRegex: string
}

export type TeamTemplateMetadata = {
  description: string
  icon: string
  name: string
}
type TeamTemplate = {
  metadata: TeamTemplateMetadata
  team: TemplateTeam
}
export type TeamTemplatesData = {
  templates: TeamTemplate[]
}

export type PlanLevel = 'CUSTOM' | 'FREE' | 'GROW' | 'PRO_V1' | 'SPACES' | 'INDIVIDUAL_FREE' | 'FREE_V2'
export type BillingFrequency = 'MONTH' | 'PERM' | 'YEAR'
export type VolleyExpiration = 'D90' | 'D180' | 'N' | 'Y1' | 'Y2'
export type PricingType = 'FLAT' | 'NONE' | 'VOLUME_USER'
export type SpaceSize = 'S10' | 'S25' | 'S100' | 'S500'
export type TeamTier = {
  cost: number
  description: string
  sizeId: SpaceSize
  frequencyId: BillingFrequency
  id: string
  isAvailable: boolean
  isInStripe: boolean
  levelId: PlanLevel
  pricingId: PricingType
  sliderText: string
  v1Id?: string
}
export type TeamTiers = { [tierId: string]: TeamTier }
export type TeamTiersData = {
  tiers: TeamTiers
}
