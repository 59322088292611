import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from '../reducers'
import { SubscriptionsBody } from 'modules/types/subscriptions'

export type SubscriptionsState = {
  isConnected: boolean
  lostConnectionAt: number | null
}
const initialState: SubscriptionsState = {
  isConnected: false,
  lostConnectionAt: null,
}

const connectionClosed: CaseReducer<SubscriptionsState, PayloadAction> = (state, _action) => ({
  isConnected: false,
  lostConnectionAt: state.lostConnectionAt || new Date().getTime(),
})
export type ConnectionOpenedAction = PayloadAction<{ disconnectedSeconds?: number }>
const connectionOpened: CaseReducer<SubscriptionsState, ConnectionOpenedAction> = (_state, _action) => ({
  isConnected: true,
  lostConnectionAt: null,
})

export type UpdateEntityAction = PayloadAction<SubscriptionsBody>

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    connectionClosed,
    connectionOpened,

    updateEntity: (state, _: UpdateEntityAction) => state,
  }
})

export const actions = subscriptionsSlice.actions
export const selector = {
  name: subscriptionsSlice.name,
  select: (state: AppState): SubscriptionsState => state.subscriptions,
}
export default subscriptionsSlice.reducer
