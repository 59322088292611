import { partition, uniq } from 'lodash-es'
import * as api from 'modules/api/conversations'
import logger from 'modules/logger'
import {
  Conversation,
  ConversationsDictionary,
  ConversationType,
  MomentThreadsDictionary,
} from 'modules/types/conversations'
import { Moment, MomentState, MomentType } from 'modules/types/moments'
import { EntityType } from 'modules/types/subscriptions'
import { Team } from 'modules/types/teams'
import { User } from 'modules/types/users'
import { call, put, SagaGenerator, select, takeEvery, takeLatest } from 'typed-redux-saga'
import { actions as alertsActions } from '../alerts/slice'
import { actions as applicationActions, selector as applicationSelector } from '../application/slice'
import { actions as initializeActions } from '../initialize/slice'
import { actions as modalsActions } from '../modals/slice'
import {
  actions as momentsActions,
  DeleteMomentAction,
  FetchMomentsSuccessAction,
  selector as momentsSelector,
  StartViewingMomentAction,
} from '../moments/slice'
import {
  actions as streamUploadsActions,
  CreateUploadSuccessAction,
  selector as streamUploadsSelector,
  RequestStopAction,
} from '../stream-uploads/slice'
import { actions as subscriptionsActions, UpdateEntityAction } from '../subscriptions/slice'
import { actions as teamsActions, selector as teamsSelector, CreateTeamSuccessAction } from '../teams/slice'
import { actions as textsActions, SendTextSuccessAction, selector as textsSelector } from '../texts/slice'
import {
  actions as usersActions,
  selector as usersSelector,
  UpdateCurrentUserAction,
  UpdateCurrentUserSuccessAction,
} from '../users/slice'
import {
  actions,
  CreateConversationAction,
  DeleteConversationAction,
  FetchConversationsAction,
  FetchMomentsForConversationAction,
  selector,
  TalkToUserAction,
  UpdateConversationAction,
  UpdateConversationSuccessAction,
} from './slice'
import { getOrderedConversationData } from './utils'

export default function*(): SagaGenerator<void> {
  yield* takeEvery(initializeActions.requestConversations, fetchRecentConversations)
  yield* takeEvery(teamsActions.fetchTeamsSuccess, action => fetchWelcomeConversations(Object.values(action.payload.teams)))
  yield* takeEvery(actions.fetchConversations, fetchConversations)
  yield* takeEvery(actions.createConversation, createConversation)
  yield* takeEvery(actions.deleteConversation, deleteConversation)
  yield* takeEvery(actions.updateConversation, updateConversation)
  yield* takeEvery(teamsActions.createTeamSuccess, action => fetchWelcomeConversations([action.payload.team]))
  yield* takeEvery(teamsActions.createTeamSuccess, addTeamChannels)

  yield* takeEvery(actions.fetchMomentsForConversation, fetchMomentsForConversation)
  yield* takeLatest(actions.refetchConversationSuccess, handleRefetchConversation)

  yield* takeEvery(applicationActions.selectTeam, () => handleConversationChanged(null))
  yield* takeEvery(applicationActions.selectConversation, action => handleConversationChanged(action.payload.conversationId, action.payload.teamId))
  yield* takeEvery(applicationActions.selectMoment, action => handleConversationChanged(action.payload.conversationId, action.payload.teamId, action.payload.momentId))
  yield* takeEvery(usersActions.updateCurrentUserSuccess, refetchConversationsOnSubscriptionsReconnect)
  yield* takeEvery(actions.talkToUser, talkToUser)

  yield* takeEvery(streamUploadsActions.requestStop, handleUploadCompleted)
  yield* takeEvery(streamUploadsActions.createUploadSuccess, handleFileVolleyCreated)
  yield* takeEvery(textsActions.sendTextSuccess, handleTextVolleyCreated)
  yield* takeEvery(subscriptionsActions.updateEntity, handleSubscriptionsUpdateEntity)

  // optimistic updates
  yield* takeEvery(momentsActions.startViewingMoment, handleStartViewingMoment)
  yield* takeEvery(usersActions.updateCurrentUser, handleUpdateCurrentUser)
  yield* takeEvery(streamUploadsActions.requestStop, action => handleSendStreamedUpload(action.payload.uploadId, action.payload.reviewBeforeSend))
  yield* takeEvery(streamUploadsActions.closeUpload, action => handleSendStreamedUpload(action.payload.uploadId))
  yield* takeEvery(streamUploadsActions.createUploadSuccess, action => handleSendFileUpload(action.payload.uploadId))
  yield* takeEvery(textsActions.sendText, action => handleSendText(action.payload.textId))
  yield* takeEvery(textsActions.sendTextSuccess, action => handleSendText(action.payload.textId))
  yield* takeEvery(momentsActions.fetchMomentsSuccess, handleFetchMoments)
  yield* takeEvery(momentsActions.deleteMoment, handleDeleteMoment)
}

export function* createConversation(action: CreateConversationAction): SagaGenerator<void> {
  const { shouldSelectOnCreate, ...params } = action.payload

  try {
    const newConversation = yield* call(api.createConversation, params)

    yield* put(actions.createConversationSuccess({ conversation: newConversation }))
    if (shouldSelectOnCreate) {
      yield* put(applicationActions.selectConversation({ conversationId: newConversation.id }))
    }
  } catch (error) {
    const { thumbEmoji, thumbB64: thumbImageB64, ...rest } = action.payload
    logger.error('Error creating conversation', error, { ...rest })
    yield* put(actions.createConversationFailure({ error: error.message }))
  }
}

export function* deleteConversation(action: DeleteConversationAction): SagaGenerator<void> {
  const { conversationId } = action.payload

  try {
    const { conversations } = yield* select(selector.select)
    const conversation = conversations[conversationId]
    yield* call(api.deleteConversation, conversationId)

    yield* put(actions.deleteConversationSuccess({ conversationId, teamId: conversation.teamId }))
  } catch (error) {
    logger.error('Error deleting conversation', error, { conversationId })
    yield* put(actions.deleteConversationFailure({ conversationId }))
    yield* put(alertsActions.pushAlert({ message: 'Failed to delete conversation. Please try again.', severity: 'error' }))
  }
}

export function* fetchConversations(action: FetchConversationsAction): SagaGenerator<ConversationsDictionary> {
  const { ids, initialize } = action.payload

  try {
    const { missingIds, refetchIds, success: conversations } = yield* call(api.getConversations, ids)

    if (refetchIds.length) {
      yield* put(actions.fetchConversations({ ids: refetchIds }))
    }
    if (missingIds.length) {
      logger.warn('Conversations not found', { ids: missingIds })
      yield* put(actions.fetchConversationsFailure({ ids: missingIds, reason: 'Not found' }))
    }

    if (initialize || Object.keys(conversations).length) {
      yield* put(actions.fetchConversationsSuccess({ conversations, initialized: initialize }))
    }

    return conversations
  } catch (error) {
    logger.error('Error getting conversations', error, { ids })
    return {}
  }
}

export function* fetchRecentConversations(): SagaGenerator<void> {
  const { currentUser: user, currentUserId } = yield* select(usersSelector.select)
  if (!user) {
    logger.error('Current user conversations requested before current user')
    return
  }

  try {
    const userConversationIds = getMostRecentUserConversationIds(user)
    yield* put(actions.fetchConversations({ ids: userConversationIds.slice(0, 20), initialize: true }))
  } catch (error) {
    logger.error('Error getting user conversations', error, { userId: currentUserId })
  }
}

function* refetchConversation(conversationId: string): SagaGenerator<void> {
  try {
    const conversation = yield* call(api.getConversation, conversationId)
    if (conversation) {
      yield* put(actions.refetchConversationSuccess({ conversation }))
    }
  } catch (error) {
    logger.error(`Error on conversation re-fetch`, error, { conversationId })
  }
}

export function* fetchWelcomeConversations(teams: Team[]): SagaGenerator<void> {
  const { currentUserId } = yield* select(usersSelector.select)
  if (!currentUserId) {
    return
  }

  const conversationIds = teams.reduce<string[]>((acc, team) => {
    if (team?.welcomeConversationId) {
      acc.push(team.welcomeConversationId)
    }
    if (team.teamAdminIds.includes(currentUserId) && team?.premiumSeedConversationId) {
      acc.push(team.premiumSeedConversationId)
    }

    return acc
  }, [])

  if (!conversationIds.length) {
    return
  }

  const { conversations } = yield* select(selector.select)
  const convosToFetch = conversationIds.filter(id => !conversations[id])
  if (!convosToFetch.length) {
    return
  }

  yield* put(actions.fetchConversations({ ids: convosToFetch }))
}

// This may be unnecessary because the user or conversation subscriptions might get to it first.
// Doing it anyway in case we have a dud subscription or whatever.
export function* addTeamChannels(action: CreateTeamSuccessAction): SagaGenerator<void> {
  const { team } = action.payload

  const teamChannels = Object.values(team.channels)
  if (!teamChannels.length) {
    return
  }

  const { conversations } = yield* select(selector.select)
  const newChannels = teamChannels.filter(tc => tc.id && !conversations[tc.id])
  if (!newChannels.length) {
    return
  }

  yield* put(actions.fetchConversations({ ids: newChannels.map(c => c.id) }))
}

export function* refetchConversationsOnSubscriptionsReconnect(action: UpdateCurrentUserSuccessAction): SagaGenerator<void> {
  const { fromReconnect, user } = action.payload
  if (!fromReconnect) {
    return
  }

  const userRecentConversationIds = getMostRecentUserConversationIds(user)
  yield* put(actions.fetchConversations({ ids: userRecentConversationIds }))
}

export function* handleConversationChanged(conversationId: string | null, teamId?: string, momentId?: string): SagaGenerator<void> {
  if (!conversationId) {
    return
  }

  const conversationsState = yield* select(selector.select)
  if (conversationsState.conversations[conversationId] || conversationsState.isFetching[conversationId]) {
    return
  }

  try {
    const conversation = yield* call(api.getConversation, conversationId)
    if (conversation) {
      yield* put(actions.fetchConversationsSuccess({ conversations: { [conversation.id]: conversation } }))

      let selectedTeamId = teamId
      if (!selectedTeamId) {
        ({ selectedTeamId } = yield* select(applicationSelector.select))
      }

      if (selectedTeamId !== conversation.teamId) {
        if (!momentId) {
          yield* put(applicationActions.selectConversation({ conversationId, teamId: conversation.teamId }))
        } else {
          yield* put(applicationActions.selectMoment({ conversationId, teamId: conversation.teamId, momentId }))
        }
      }
    } else {
      yield* put(actions.fetchConversationsFailure({ ids: [conversationId], reason: 'Not found' }))
      yield* put(applicationActions.exitConversation())
    }
  } catch (error) {
    logger.error('Error selecting conversation', error, { conversationId })
  }
}

export function* fetchMomentsForConversation(action: FetchMomentsForConversationAction): SagaGenerator<void> {
  const { conversationId, limit } = action.payload

  const { fullMoments } = yield* select(selector.select)
  const convoFullMoments = fullMoments[conversationId]
  if (!limit && !convoFullMoments.canLoadMore) {
    return
  }

  try {
    const result = yield* call(api.getMomentsForConversation, conversationId, !limit ? convoFullMoments.nextTimestamp : undefined, limit)
    if (result) {
      const { canLoadMore, moments } = result

      yield* put(actions.fetchMomentsForConversationSuccess({ canLoadMore, conversationId, moments }))
    } else {
      yield* put(actions.fetchMomentsForConversationFailure({ conversationId, reason: 'Missing moments' }))
    }
  } catch (error) {
    logger.error('Error fetching conversation with moments', error, { conversationId })
    yield* put(actions.fetchMomentsForConversationFailure({ conversationId, reason: error.message }))
  }
}

export function* handleRefetchConversation(action: UpdateConversationSuccessAction): SagaGenerator<void> {
  const { conversation } = action.payload

  const { fullMoments } = yield* select(selector.select)
  const convoFullMoments = fullMoments[conversation.id]
  if (!convoFullMoments) {
    return
  }

  const momentsToRemove = convoFullMoments.moments.reduce<string[]>((acc, { momentId }) => {
    if (conversation.recentlyDeletedMomentIds.includes(momentId)) {
      acc.push(momentId)
    }

    return acc
  }, [])
  const momentsToAdd = conversation.recentlyCompletedMomentIds.reduce<string[]>((acc, momentId) => {
    const existingMoment = convoFullMoments.moments.find(m => m.momentId === momentId)
    if (!existingMoment || existingMoment.isLocal) {
      acc.push(momentId)
    }

    return acc
  }, [])

  if (momentsToRemove.length) {
    yield* put(actions.removeRecentMoments({
      conversationId: conversation.id,
      momentIds: momentsToRemove,
    }))
  }
  if (momentsToAdd.length) {
    yield* put(momentsActions.fetchMoments({ ids: momentsToAdd }))
  }
}

export function* updateConversation(action: UpdateConversationAction): SagaGenerator<void> {
  const { payload } = action
  const { successAlert, userId, ...updateParams } = payload
  const {
    addAdminIds,
    addPosterIds,
    addUserIds,
    conversationId,
    removePosterIds,
    removeUserIds,
    ...changes
  } = updateParams

  try {
    yield* call(api.updateConversation, updateParams)
    const state = yield* select(selector.select)
    const conversation = state.conversations[conversationId]
    if (conversation) {
      const membershipChanges = yield* getConversationMembershipChanges({
        addAdminIds,
        addUserIds,
        conversation,
        removeUserIds,
      })
      const permissionChanges = getConversationPermissionChanges({
        addPosterIds,
        conversation,
        removePosterIds,
        removeUserIds,
      })
      const updatedConversation = { ...conversation, ...changes, ...membershipChanges, ...permissionChanges }
      yield* put(actions.updateConversationSuccess({ conversation: updatedConversation, updateParams: payload }))
    } else {
      const updatedConversation = yield* call(api.getConversation, conversationId)
      if (!updatedConversation) {
        logger.error('Conversation not found after update', { conversationId })
        yield* put(actions.updateConversationFailure({ conversationId }))
        return
      }

      yield* put(actions.updateConversationSuccess({ conversation: updatedConversation, updateParams: payload }))
    }

    if (successAlert) {
      yield* put(alertsActions.pushAlert(successAlert))
    }
  } catch (error) {
    const { thumbEmoji, ...params } = payload
    logger.error('Error updating conversation', error, { ...params })
    yield* put(actions.updateConversationFailure({ conversationId }))
  }
}

type MembershipChanges = {
  adminIds: string[]
  guestIds: string[]
  userIds: string[]
}
type GetConversationMembershipChangesParams = {
  addAdminIds?: string[]
  addUserIds?: string[]
  conversation: Conversation
  removeUserIds?: string[]
}

function* getConversationMembershipChanges(params: GetConversationMembershipChangesParams): SagaGenerator<MembershipChanges> {
  const { addAdminIds, addUserIds, conversation, removeUserIds } = params

  let addTeamUserIds: string[] = []
  let addGuestUserIds: string[] = []
  if (addUserIds?.length) {
    const teamsState = yield* select(teamsSelector.select)
    const team = teamsState.teams[conversation.teamId]

    const [teamIds, guestIds] = partition(addUserIds, id => team.userIds.includes(id))
    addTeamUserIds = teamIds
    addGuestUserIds = guestIds
  }

  return {
    adminIds: uniq([...conversation.adminIds.filter(u => !(removeUserIds || []).includes(u)), ...(addAdminIds ?? [])]),
    guestIds: uniq([...conversation.guestIds.filter(u => !(removeUserIds || []).includes(u)), ...addGuestUserIds]),
    userIds: uniq([...conversation.userIds.filter(u => !(removeUserIds || []).includes(u)), ...addTeamUserIds]),
  }
}

type GetConversationPermissionChangesParams = {
  addPosterIds?: string[] | undefined
  conversation: Conversation
  removePosterIds?: string[] | undefined
  removeUserIds: string[] | undefined
}
function getConversationPermissionChanges(params: GetConversationPermissionChangesParams): { allowedPosterIds: string[] } {
  const { addPosterIds, conversation, removePosterIds, removeUserIds } = params

  return {
    allowedPosterIds: uniq([
      ...conversation.allowedPosterIds.filter(id => {
        return !(removePosterIds || []).includes(id) && !(removeUserIds || []).includes(id)
      }),
      ...(addPosterIds ?? [])
    ])
  }
}

function getMostRecentUserConversationIds(user: User): string[] {
  return getOrderedConversationData({ user }).slice(0, 20).map(c => c.conversationId)
}

export function* talkToUser({ payload: { teamId, targetUserId } }: TalkToUserAction): SagaGenerator<void> {
  const { currentUser } = yield* select(usersSelector.select)
  if (!currentUser) {
    return
  }

  const [existingConversationId] = Object.entries(currentUser.conversationData).find(([_id, data]) => {
    return data.conversationType === ConversationType.OneOnOne
      && data.teamId === teamId // in the given team
      && data.userIds.filter(id => id !== currentUser.id).length === 1 // has other user that is not current user
      && data.userIds.some(id => id === targetUserId) // other user matches target
  }) ?? []

  if (existingConversationId) {
    yield* put(applicationActions.selectConversation({ conversationId: existingConversationId }))
  } else {
    yield* put(actions.createConversation({
      addUserIds: [targetUserId],
      teamId,
      vType: ConversationType.OneOnOne,
      shouldSelectOnCreate: true
    }))
  }
}

export function* handleUploadCompleted(action: RequestStopAction): SagaGenerator<void> {
  const { conversationId } = action.payload

  yield* openAddParticipantsAfterFirstVolley(conversationId)
}

function* handleTextVolleyCreated(action: SendTextSuccessAction): SagaGenerator<void> {
  const { momentId, textId } = action.payload

  const { texts } = yield* select(textsSelector.select)
  const text = texts[textId]
  if (!text) {
    logger.warn('Moment was not found in state after sending text', { momentId, textId })
    return
  }

  yield* openAddParticipantsAfterFirstVolley(text.conversationId)
}

function* handleFileVolleyCreated(action: CreateUploadSuccessAction): SagaGenerator<void> {
  const { conversationId, type } = action.payload
  if (type !== MomentType.Image && type !== MomentType.Movie && type !== MomentType.File) {
    return
  }

  yield* openAddParticipantsAfterFirstVolley(conversationId)
}

export function* openAddParticipantsAfterFirstVolley(conversationId: string): SagaGenerator<void> {
  const { selectedConversationId } = yield* select(applicationSelector.select)
  if (conversationId !== selectedConversationId) {
    return // user must have already moved to another conversation
  }

  const { conversations } = yield* select(selector.select)
  const conversation = conversations[selectedConversationId]
  const shouldSkipShowAddParticipants = conversation.vType === ConversationType.Channel
    || conversation.vType === ConversationType.OneOnOne
    || conversation.vType === ConversationType.Thread
    || (conversation.userIds.length + conversation.guestIds.length > 1)
    || conversation.hasMoments
  if (shouldSkipShowAddParticipants) {
    return
  }

  yield* put(modalsActions.showConversationDetails({ conversationId, startingState: 'add-participants' }))
}

export function* handleSubscriptionsUpdateEntity(action: UpdateEntityAction): SagaGenerator<void> {
  const { entityId, entityType } = action.payload
  if (entityType !== EntityType.Conversation) {
    return
  }

  yield* refetchConversation(entityId)
}

export function* handleStartViewingMoment(action: StartViewingMomentAction): SagaGenerator<void> {
  const { conversationId, momentId } = action.payload

  const { moments } = yield* select(momentsSelector.select)
  const { currentUserId } = yield* select(usersSelector.select)
  const moment = moments[momentId]
  if (!moment || !currentUserId) {
    return
  }

  const { conversations } = yield* select(selector.select)
  const conversation = conversations[conversationId]
  if (!conversation) {
    logger.error('Conversation not found when viewing moment', { conversationId, momentId })
    return
  }

  // TODO: newestViewedMomentTimestamp will not match moment.createdOn in the future
  const convoUserData = conversation.userData[currentUserId]
  if (convoUserData?.newestViewedMomentTimestamp && convoUserData.newestViewedMomentTimestamp >= moment.createdOn) {
    return
  }

  yield* put(actions.updateConversationSuccess({
    conversation: {
      ...conversation,
      userData: {
        ...conversation.userData,
        [currentUserId]: { newestViewedMomentId: moment.id, newestViewedMomentTimestamp: moment.createdOn },
      },
    },
  }))

  if (conversation.vType !== ConversationType.Thread || !conversation.parentConversationId) {
    return
  }

  const parentConversation = conversations[conversation.parentConversationId]
  if (!parentConversation) {
    logger.error('Parent conversation not found when viewing moment', {
      conversationId,
      momentId,
      parentConversationId: conversation.parentConversationId,
    })
    return
  }

  const { didUpdate, momentThreads } = getUpdatedMomentThreads(parentConversation.momentThreads, conversation.id)
  if (didUpdate) {
    yield* put(actions.updateConversationSuccess({ conversation: { ...parentConversation, momentThreads } }))
  }
}

export function* handleUpdateCurrentUser(action: UpdateCurrentUserAction): SagaGenerator<void> {
  const { catchUpConversationIds, catchUpThreadsInConversationIds } = action.payload
  if (!catchUpConversationIds?.length && !catchUpThreadsInConversationIds?.length) {
    return
  }

  const { selectedConversationId } = yield* select(applicationSelector.select)
  const { currentUserId } = yield* select(usersSelector.select)
  if (!currentUserId || !selectedConversationId) {
    return
  }

  let conversationIdToUpdate = catchUpConversationIds?.includes(selectedConversationId)
    ? selectedConversationId
    : null
  let parentConversationIdToUpdate = catchUpThreadsInConversationIds?.includes(selectedConversationId)
    ? selectedConversationId
    : null

  const { conversations } = yield* select(selector.select)
  if (!conversationIdToUpdate && !parentConversationIdToUpdate) {
    catchUpThreadsInConversationIds?.forEach(id => {
      const parentConversation = conversations[id]
      const foundThread = Object.values(parentConversation.momentThreads)
        .find(mt => mt.userIsMember && mt.threadId === selectedConversationId)
      if (foundThread) {
        conversationIdToUpdate = foundThread.threadId
        parentConversationIdToUpdate = parentConversation.id
      }
    })
  }

  if (!conversationIdToUpdate && !parentConversationIdToUpdate) {
    return
  }

  const conversation = conversationIdToUpdate ? conversations[conversationIdToUpdate] : null
  const parentConversation = parentConversationIdToUpdate && parentConversationIdToUpdate !== conversationIdToUpdate
    ? conversations[parentConversationIdToUpdate]
    : null
  if (!conversation && !parentConversation) {
    logger.error('Conversation(s) not found when marking all as viewed', {
      conversationId: conversationIdToUpdate,
      parentConversationId: parentConversationIdToUpdate,
    })

    return
  }

  if (parentConversation) {
    const { didUpdate, momentThreads } = getUpdatedMomentThreads(parentConversation.momentThreads)
    if (didUpdate) {
      const updatedParentConversation = { ...parentConversation, momentThreads }

      yield* put(actions.updateConversationSuccess({ conversation: updatedParentConversation }))
    }
  }

  if (conversation) {
    let updatedConversation = {
      ...conversation,
      userData: {
        ...conversation.userData,
        [currentUserId]: {
          newestViewedMomentId: conversation.userData[currentUserId]?.newestViewedMomentId ?? null,
          newestViewedMomentTimestamp: new Date().toISOString(),
        },
      },
    }

    const { didUpdate, momentThreads } = getUpdatedMomentThreads(updatedConversation.momentThreads)
    if (parentConversationIdToUpdate && !parentConversation && didUpdate) {
      updatedConversation = { ...updatedConversation, momentThreads }
    }

    yield* put(actions.updateConversationSuccess({ conversation: updatedConversation }))
  }
}

type UpdatedMomentThreads = {
  didUpdate: boolean
  momentThreads: MomentThreadsDictionary
}
function getUpdatedMomentThreads(momentThreads: MomentThreadsDictionary, threadId?: string): UpdatedMomentThreads {
  return Object.entries(momentThreads).reduce<UpdatedMomentThreads>((acc, [id, mt]) => {
    const shouldUpdate = mt.userIsMember && mt.unviewedCount > 0 && (!threadId || mt.threadId === threadId)
    if (shouldUpdate) {
      acc.didUpdate = true
      acc.momentThreads[id] = { ...mt, unviewedCount: !!threadId ? mt.unviewedCount - 1 : 0 }
    } else {
      acc.momentThreads[id] = mt
    }

    return acc
  }, { didUpdate: false, momentThreads: {} })
}

function* handleSendStreamedUpload(uploadId: string, reviewBeforeSend?: boolean): SagaGenerator<void> {
  const { uploads } = yield* select(streamUploadsSelector.select)
  const upload = uploads[uploadId]
  if (!upload || upload.scheduledDate || reviewBeforeSend) {
    return
  }

  yield* handleSendVolley({
    conversationId: upload.conversationId,
    createdAt: upload.createdAt,
    momentId: upload.momentId,
    type: upload.type,
  })
}

function* handleSendFileUpload(uploadId: string): SagaGenerator<void> {
  const { uploads } = yield* select(streamUploadsSelector.select)
  const upload = uploads[uploadId]
  if (!upload
    || (upload.type !== MomentType.Image && upload.type !== MomentType.Movie && upload.type !== MomentType.File)) {
    return
  }

  yield* handleSendVolley({
    conversationId: upload.conversationId,
    createdAt: upload.createdAt,
    momentId: upload.momentId,
    type: upload.type,
  })
}

function* handleSendText(textId: string): SagaGenerator<void> {
  const { texts } = yield* select(textsSelector.select)
  const text = texts[textId]
  if (!text || text.scheduledDate || text.isEditing) {
    return
  }

  yield* handleSendVolley({
    conversationId: text.conversationId,
    createdAt: text.createdAt,
    momentId: text.momentId,
    type: MomentType.Text,
  })
}

type HandleSendVolleyParams = {
  conversationId: string
  createdAt: number
  momentId?: string
  type: MomentType
}
export function* handleSendVolley(params: HandleSendVolleyParams): SagaGenerator<void> {
  const { conversationId, createdAt, momentId, type } = params

  const { currentUserId } = yield* select(usersSelector.select)
  if (!currentUserId) {
    return
  }

  const { conversations } = yield* select(selector.select)
  const conversation = conversations[conversationId]
  if (!conversation) {
    logger.error('Conversation not found when sending volley', { conversationId, momentId })
    return
  }

  if (momentId) {
    yield* put(actions.addRecentMoments({
      conversationId,
      recentMoments: [{
        isLocal: true,
        momentId,
        momentTimestamp: new Date(createdAt).toISOString(),
        sortDate: createdAt,
        type,
      }],
    }))
  }

  if (conversation.userData[currentUserId]?.newestViewedMomentId !== momentId) {
    yield* put(actions.updateConversationSuccess({
      conversation: {
        ...conversation,
        userData: {
          ...conversation.userData,
          [currentUserId]: {
            newestViewedMomentId: momentId ?? conversation.userData[currentUserId]?.newestViewedMomentId,
            newestViewedMomentTimestamp: new Date(createdAt).toISOString(),
          },
        },
      },
    }))
  }
}

export function* handleFetchMoments(action: FetchMomentsSuccessAction): SagaGenerator<void> {
  const momentsByConversation = Object.values(action.payload.moments)
    .reduce<{ [conversationId: string]: Moment[] }>((acc, moment) => {
      if (moment.vState === MomentState.Complete) {
        if (!acc[moment.conversationId]) {
          acc[moment.conversationId] = [moment]
        } else {
          acc[moment.conversationId].push(moment)
        }
      }

      return acc
    }, {})

  const { fullMoments } = yield* select(selector.select)
  for (const [conversationId, moments] of Object.entries(momentsByConversation)) {
    const convoMoments = fullMoments[conversationId]
    if (!convoMoments) {
      continue
    }

    yield* put(actions.fetchMomentsForConversationSuccess({
      canLoadMore: convoMoments.canLoadMore,
      conversationId,
      moments,
    }))
  }
}

export function* handleDeleteMoment(action: DeleteMomentAction): SagaGenerator<void> {
  const { conversationId, momentId } = action.payload

  yield* put(actions.removeRecentMoments({ conversationId, momentIds: [momentId] }))
}
