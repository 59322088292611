import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
// import { useMediaQuery } from 'react-responsive'
// import { MOBILE_BREAKPOINT } from 'modules/constants'
import Dialog from '../Dialog'
import Button from '../Button'
import * as localStorageFacade from 'modules/local-storage'
// import { getFrequentEmoji } from "../../emoji-util";

const Body = styled(Dialog.Body)`
  width: 320px;
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  button {
    width: 120px;
  }
`
const StyledText = styled.div`
  font-size: 15px;
  display: flex;
  text-align: center;
  padding: 10px 20px;
`
declare global {
  interface Window { opera: string; }
}
const presentedMobileAppModal = 'volleyPresentedMobileAppDownloadModal'
const AppDownloadModal = (): React.ReactElement => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as Window).opera

  let link = ''
  let isMobile = false

  if (userAgent) {
    isMobile = /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i.test(userAgent)
    if (/android/i.test(userAgent)) {
      link = 'https://play.google.com/store/apps/details?id=com.volleyapp.volley'
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      link = 'https://apps.apple.com/us/app/volley-video-messaging/id1519314478'
    }
  }
  const shouldShow = isMobile && localStorageFacade.getItem(presentedMobileAppModal) !== 'true'

  // const isMobileSize = useMediaQuery({ query: `(max-width: ${MOBILE_BREAKPOINT}px)` })
  const [isOpen, setIsOpen] = useState<boolean>(/* isMobileSize && */ shouldShow)

  const handleModalClose = useCallback(() => {
    setIsOpen(false)
    localStorageFacade.setItem(presentedMobileAppModal, 'true')
  }, [])

  if (!isMobile) {
    return <></>
  }

  return (
    <Dialog ariaLabeledById="download-app-modal" isOpen={isOpen} onClose={handleModalClose}>
      <Dialog.Header onClose={handleModalClose} ariaLabeledById="download-app-modal">
        App available
      </Dialog.Header>
      <Body>
        <StyledText>
          Get the app to receive notifications and an all-around better experience.
        </StyledText>
        <Button
          size="small"
          onClick={() => {
            window && window.open(link, '_blank')
          }}
        >
          Go to App
        </Button>
      </Body>
    </Dialog>
  )
}

export default AppDownloadModal
