import isOnline from 'is-online'
import { compact, uniq, xorBy } from 'lodash-es'
import {
  CAMERA_SLEEP_PREFERENCE,
  MEDIA_TYPES,
  PREFERRED_AUDIO_DEVICE_PREFERENCE,
  PREFERRED_VIDEO_DEVICE_PREFERENCE,
} from 'modules/constants'
import { isElectron } from 'modules/electron-utils'
import { readPreference, writePreference } from 'modules/hooks/preferences'
import logger from 'modules/logger'
import { EventChannel, eventChannel } from 'redux-saga'
import { call, put, SagaGenerator, take, select, fork, takeEvery, delay, takeLatest } from 'typed-redux-saga'
import { actions as alertsActions } from '../alerts/slice'
import { selector as authenticationSelector } from '../authentication/slice'
import { actions as initializeActions } from '../initialize/slice'
import { createElectronEventChannel } from '../utils'
import {
  actions,
  CAMERA_SLEEP_DEFAULT,
  CAMERA_SLEEP_NEVER,
  DeviceSettingsState,
  MediaDevice,
  selector,
  SetCameraSleepAction,
  UpdateSelectedAudioDeviceAction,
  ValidateMediaSourcesAction,
} from './slice'
import { AvailableMediaDevices, getMediaDevices, getSavedMediaDevicePreferences, pickBestDevice } from './util'

export default function* main(): SagaGenerator<void> {
  yield* takeLatest(initializeActions.requestApp, initializeDeviceSettings)
  yield* takeLatest(actions.initializeSettings, initializeDeviceSettings)

  yield* takeEvery(actions.updateSelectedAudioDevice, handleUpdateSelectedMediaDevice('audio'))
  yield* takeEvery(actions.updateSelectedVideoDevice, handleUpdateSelectedMediaDevice('video'))
  yield* takeEvery(actions.validateMediaSources, validateMediaSources)
  yield* takeEvery(actions.setCameraSleep, setCameraSleep)

  yield* fork(watchAvailableDevicesChange)
  yield* fork(watchNetworkStatusChange)
  yield* fork(watchOnSuspendWakeElectronEvent)
}

export function* initializeDeviceSettings(): SagaGenerator<void> {
  const { accessToken } = yield* select(authenticationSelector.select)
  let availableDevices = yield* getMediaDevices()
  const isMediaRecorderSupported = getIsMediaRecorderSupported()
  const savedVideoDevicePreferences = getSavedMediaDevicePreferences('video')
  const savedAudioDevicePreferences = getSavedMediaDevicePreferences('audio')

  let selectedVideo = pickBestDevice(availableDevices.video, savedVideoDevicePreferences)
  let selectedAudio = pickBestDevice(availableDevices.audio, savedAudioDevicePreferences)

  if ((!selectedAudio || !selectedVideo) && isMediaRecorderSupported && accessToken && !accessToken.isAnonymous) {
    let micPermissions = yield* getPermissionsFor('microphone')
    let camPermissions = yield* getPermissionsFor('camera')

    if (micPermissions.state === 'prompt' || camPermissions.state === 'prompt') {
      try {
        yield* call([navigator.mediaDevices, navigator.mediaDevices.getUserMedia], { audio: true, video: true })
      } catch (error) {
        logger.warn('Error accessing A/V', error)
      }
      micPermissions = yield* getPermissionsFor('microphone')
      camPermissions = yield* getPermissionsFor('camera')
      availableDevices = yield* getMediaDevices()
      selectedVideo = pickBestDevice(availableDevices.video, savedVideoDevicePreferences)
      selectedAudio = pickBestDevice(availableDevices.audio, savedAudioDevicePreferences)
    }

    if (!selectedVideo || !selectedAudio) {
      if (micPermissions.state === 'denied' || camPermissions.state === 'denied') {
        yield* put(alertsActions.pushAlert({
          message: 'Volley was unable to find both a microphone & camera. Some functionality will be unavailable. Please grant permission to use your microphone & camera for full functionality.',
          severity: 'warning',
        }))
      } else {
        yield* put(alertsActions.pushAlert({
          message: 'Volley was unable to find both a microphone & camera. Some functionality will be unavailable.',
          severity: 'warning',
        }))
      }
    }
  }

  yield* put(actions.initializeSettingsSuccess({
    availableAudioDevices: availableDevices.audio,
    availableVideoDevices: availableDevices.video,
    cameraSleep: getSavedCameraSleepPreference(),
    isMediaRecorderSupported,
    selectedAudioDevice: selectedAudio,
    selectedVideoDevice: selectedVideo,
  }))
}

function* getPermissionsFor(name: 'microphone' | 'camera'): SagaGenerator<PermissionStatus | { state: PermissionState }> {
  try {
    // Not all browsers support querying media devices (e.g. Firefox) and will throw
    return yield* call([navigator.permissions, navigator.permissions.query], { name })
  } catch (error) {
    logger.info(`Browser does not support querying ${name} permissions. Attempting to re-prompt.`)
    return { state: 'prompt' }
  }
}

function getIsMediaRecorderSupported(): boolean {
  return typeof window.MediaRecorder !== 'undefined'
    && window.MediaRecorder?.isTypeSupported
    && MEDIA_TYPES.some(window.MediaRecorder.isTypeSupported)
}

export function handleUpdateSelectedMediaDevice(type: 'audio' | 'video'): (action: UpdateSelectedAudioDeviceAction) => SagaGenerator<void> {
  const storageKey = type === 'audio' ? PREFERRED_AUDIO_DEVICE_PREFERENCE : PREFERRED_VIDEO_DEVICE_PREFERENCE
  const action = type === 'audio' ? actions.updateSelectedAudioDeviceSuccess : actions.updateSelectedVideoDeviceSuccess

  return function* updateSelectedMediaDevice({ payload: { id, label } }) {
    const savedDevicePreferences = getSavedMediaDevicePreferences(type)
    const updatedDevicePreferences = uniq(compact([id, ...savedDevicePreferences]))
    writePreference(storageKey, updatedDevicePreferences)

    yield* put(action({ id, label }))
  }
}

export function* validateMediaSources(action: ValidateMediaSourcesAction): SagaGenerator<void> {
  const { streamAudioId, streamVideoId } = action.payload
  if (!streamAudioId && !streamVideoId) {
    return
  }

  const {
    availableAudioDevices,
    availableVideoDevices,
    selectedAudioDevice,
    selectedVideoDevice
  } = yield* select(selector.select)

  const unavailableDevices: string[] = []
  if (streamAudioId && streamAudioId !== selectedAudioDevice?.id) {
    unavailableDevices.push(selectedAudioDevice ? selectedAudioDevice.label : 'microphone')
  }
  if (streamVideoId && streamVideoId !== selectedVideoDevice?.id) {
    unavailableDevices.push(selectedVideoDevice ? selectedVideoDevice.label : 'camera')
  }
  for (const device of unavailableDevices) {
    yield* put(alertsActions.pushAlert({
      message: `"${device}" unavailable. Please make sure "${device}" is not in use by another application.`,
      severity: 'warning',
    }))
  }

  if (unavailableDevices.length) {
    yield* delay(250)
    // TODO: Once we stop seeing this error in logging for some period of time, we can probably get rid of this whole
    //  method since honestly, this class of problem should be handled before we get here.
    logger.error('Inconsistent selected devices found', {
      availableAudioDevices,
      availableVideoDevices,
      streamAudioId,
      streamVideoId,
      selectedAudioDevice,
      selectedVideoDevice,
    })

    const audioDevice = availableAudioDevices.find(x => x.id === streamAudioId)
    const videoDevice = availableVideoDevices.find(x => x.id === streamVideoId)
    if (audioDevice) {
      yield* put(actions.updateSelectedAudioDevice(audioDevice))
    }
    if (videoDevice) {
      yield* put(actions.updateSelectedVideoDevice(videoDevice))
    }
  }
}

function getSavedCameraSleepPreference(): number {
  let value = readPreference(CAMERA_SLEEP_PREFERENCE, CAMERA_SLEEP_DEFAULT)

  // TODO: remove after clients have been updated for a few months
  if (value < 10) {
    value *= 60
    writePreference(CAMERA_SLEEP_PREFERENCE, value)
  }
  if (value?.toString() === 'never') {
    value = CAMERA_SLEEP_NEVER
    writePreference(CAMERA_SLEEP_PREFERENCE, CAMERA_SLEEP_NEVER)
  }

  return value
}

export function* setCameraSleep(action: SetCameraSleepAction): SagaGenerator<void> {
  writePreference(CAMERA_SLEEP_PREFERENCE, action.payload.seconds)
}

function* watchNetworkStatusChange(): SagaGenerator<void> {
  if (isElectron()) {
    // navigator.onLine and its associated events are largely useless on web (hence the is-online package) but are
    //  instrumented better in Electron. Can't say how reliable they are, but they appear to work.
    const channel = yield* call(createNetworkStatusChangeChannel)

    while (true) {
      try {
        const online = yield* take(channel)
        const { networkStatus } = yield* select(selector.select)
        if (networkStatus === 'suspended') {
          return // if we're suspended, ignore network status until we're awake
        }

        const newNetworkStatus = online ? 'online' : 'offline'
        if (networkStatus !== newNetworkStatus) {
          yield* put(actions.networkStatusChanged({ networkStatus: newNetworkStatus }))
        }
      } catch (error) {
        logger.info('Error watching network changes', error)
      }
    }
  } else {
    while (true) {
      yield* delay(5000)
      const { networkStatus } = yield* select(selector.select)
      if (networkStatus === 'suspended') {
        return // if we're suspended, ignore network status until we're awake
      }

      try {
        const online = yield* call(isOnline)
        const newNetworkStatus = online ? 'online' : 'offline'
        if (networkStatus !== newNetworkStatus) {
          yield* put(actions.networkStatusChanged({ networkStatus: newNetworkStatus }))
        }
      } catch (error) {
        logger.info('Error checking network status', error)
      }
    }
  }
}

function createNetworkStatusChangeChannel(): EventChannel<boolean> {
  return eventChannel(emitter => {
    const handler = () => {
      emitter(navigator.onLine)
    }

    window.addEventListener('online', handler)
    window.addEventListener('offline', handler)

    return () => {
      window.removeEventListener('online', handler)
      window.removeEventListener('offline', handler)
    }
  })
}

function* watchOnSuspendWakeElectronEvent(): SagaGenerator<void> {
  const channel = createElectronEventChannel<{ isAwake: boolean }>('on-suspend-awake')
  if (!channel) {
    return
  }

  while (true) {
    const { isAwake } = yield* take(channel)
    const navigatorOnlineStatus = navigator.onLine ? 'online' : 'offline'
    yield* put(actions.networkStatusChanged({ networkStatus: isAwake ? navigatorOnlineStatus : 'suspended' }))
  }
}

function* watchAvailableDevicesChange(): SagaGenerator<void> {
  const channel = yield* call(createDeviceChangeChannel)

  while (true) {
    try {
      yield* take(channel)
      yield* handleAvailableDevicesChanged()
    } catch (error) {
      logger.info('Error watching devices change', error)
    }
  }
}

function createDeviceChangeChannel(): EventChannel<unknown> {
  return eventChannel(emitter => {
    const handler = (e: Event) => {
      emitter(e)
    }

    if (typeof navigator.mediaDevices !== 'object') {
      // we already notify in initialization about media devices being missing so return un-subscriber and bail
      return () => null
    }

    navigator.mediaDevices.addEventListener('devicechange', handler)

    return () => navigator.mediaDevices.removeEventListener('devicechange', handler)
  })
}

export function* handleAvailableDevicesChanged(): SagaGenerator<void> {
  const state = yield* select(selector.select)
  const availableDevices = yield* getMediaDevices()

  if (areSelectedDevicesAvailable(state, availableDevices)) {
    // Sometimes systems fire this event when things change that we don't care about. Since our selected devices aren't
    //  changing, just need to see if the available devices changed before firing our own event.
    const availableDevicesChanged = xorBy(availableDevices.video, state.availableVideoDevices, 'id').length
      || xorBy(availableDevices.audio, state.availableAudioDevices, 'id').length

    if (availableDevicesChanged) {
      yield* put(actions.availableMediaDevicesChanged({
        availableAudioDevices: availableDevices.audio,
        availableVideoDevices: availableDevices.video,
        selectedAudioDevice: state.selectedAudioDevice,
        selectedVideoDevice: state.selectedVideoDevice,
      }))
    }
  } else {
    const savedAudioDevicePreferences = getSavedMediaDevicePreferences('audio')
    const savedVideoDevicePreferences = getSavedMediaDevicePreferences('video')

    const selectedAudio = pickBestDevice(availableDevices.audio, savedAudioDevicePreferences)
    const selectedVideo = pickBestDevice(availableDevices.video, savedVideoDevicePreferences)

    yield* put(actions.availableMediaDevicesChanged({
      availableAudioDevices: availableDevices.audio,
      availableVideoDevices: availableDevices.video,
      selectedAudioDevice: selectedAudio,
      selectedVideoDevice: selectedVideo,
    }))

    yield* inferSuspendStatusForWeb(state, availableDevices, selectedAudio)

    // TODO: Cleanup post v2 launch
    /**
     * This is commented as a part of VOL-116 story https://technine.atlassian.net/browse/VOL-116
     * */
    // if (!selectedAudio && state.selectedAudioDevice) {
    //   yield* warnDeviceDisconnected({ isCamera: false, label: state.selectedAudioDevice?.label })
    // } else if (selectedAudio && selectedAudio.id !== state.selectedAudioDevice?.id) {
    //   yield* notifySelectedDeviceChanged({ isCamera: false, label: selectedAudio.label })
    // }
    //
    // if (!selectedVideo && state.selectedVideoDevice) {
    //   yield* warnDeviceDisconnected({ isCamera: true, label: state.selectedVideoDevice?.label })
    // } else if (selectedVideo && selectedVideo.id !== state.selectedVideoDevice?.id) {
    //   yield* notifySelectedDeviceChanged({ isCamera: true, label: selectedVideo.label })
    // }

    yield* put(actions.selectedDeviceDisconnected())
  }
}

function areSelectedDevicesAvailable(state: DeviceSettingsState, availableDevices: AvailableMediaDevices): boolean {
  return !!state.selectedAudioDevice
    && availableDevices.audio.some(a => a.id === state.selectedAudioDevice?.id)
    && !!state.selectedVideoDevice
    && availableDevices.video.some(v => v.id === state.selectedVideoDevice?.id)
}

function* inferSuspendStatusForWeb(state: DeviceSettingsState, availableDevices: AvailableMediaDevices, selectedAudio?: MediaDevice): SagaGenerator<void> {
  if (isElectron()) {
    return
  }

  // We have more reliable means of checking for a suspended computer in Electron-land but for web, we kind of need
  //  to infer this. At least on Apple devices, when the laptop is suspended (e.g. closed), all available video
  //  devices are removed so we can lean on that as a possible way to determine if the computer is suspended or not.
  if (selectedAudio && !availableDevices.video.length && state.networkStatus === 'online') {
    yield* put(actions.networkStatusChanged({ networkStatus: 'suspended' }))
  } else if (selectedAudio && availableDevices.video.length && state.networkStatus === 'suspended') {
    yield* put(actions.networkStatusChanged({ networkStatus: navigator.onLine ? 'online' : 'offline' }))
  }
}

// TODO: Cleanup post v2 launch
/**
 * This is commented as a part of VOL-116 story https://technine.atlassian.net/browse/VOL-116
 * */
// type DeviceAlertParams = { label?: string, isCamera: boolean }
// function* warnDeviceDisconnected({ isCamera, label }: DeviceAlertParams): SagaGenerator<void> {
//   yield* put(alertsActions.pushAlert({
//     message: `${isCamera ? 'Camera' : 'Microphone'}${label ? ` "${label}"` : ''} is no longer connected`,
//     severity: 'warning',
//   }))
// }
// function* notifySelectedDeviceChanged({ isCamera, label }: DeviceAlertParams): SagaGenerator<void> {
//   yield* put(alertsActions.pushAlert({
//     message: `The selected ${isCamera ? 'camera' : 'microphone'} has changed${label ? ` to "${label}"` : ''}`,
//     severity: 'info',
//   }))
// }
