import { eventChannel, EventChannel } from 'redux-saga'
import { isElectron } from '../electron-utils'

export function createElectronEventChannel<T>(channel: string): EventChannel<T> | null {
  if (!isElectron() || !window.ipcRenderer) {
    return null
  }

  return eventChannel(emit => {
    const handler = (event: unknown, args: T) => {
      emit(args)
    }

    window.ipcRenderer?.on(channel, handler)

    return () => {
      window.ipcRenderer?.removeListener(channel, handler)
    }
  })
}
