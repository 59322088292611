import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ADHOC_TEAM_ID, DEFAULT_PLAYBACK_RATE } from 'modules/constants'
import logger from 'modules/logger'
import { VolleyStatus } from 'modules/types/application'
import { AppState } from '../reducers'

export type NavbarState = 'open' | 'closed' | 'hover'
export type ViewerHeaderState = 'idle' | 'editing-title'
export type SidebarState = 'closed' | 'threads' | 'transcripts'
type Playback = {
  captions: boolean
  rate: number
}
export type ApplicationState = {
  hasAuthenticatedRoute: boolean
  isDroppingConfetti: boolean
  isPlaying: boolean
  isFullScreen: boolean
  isSearchingExperts: boolean
  navbar: NavbarState
  playback: Playback
  reviewBeforeSend: boolean
  selectedConversationId: string | null
  selectedExpertId: string | null
  selectedMomentAt?: number
  selectedMomentId: string | null
  selectedTeamId: string
  sidebar: SidebarState
  viewerHeader: ViewerHeaderState
  volleyStatus: VolleyStatus
}
export const initialState: ApplicationState = {
  hasAuthenticatedRoute: false,
  isDroppingConfetti: false,
  isPlaying: false,
  isFullScreen: false,
  isSearchingExperts: false,
  navbar: 'open',
  playback: {
    captions: false,
    rate: DEFAULT_PLAYBACK_RATE,
  },
  reviewBeforeSend: true,
  selectedConversationId: null,
  selectedExpertId: null,
  selectedMomentId: null,
  selectedTeamId: ADHOC_TEAM_ID,
  sidebar: 'closed',
  viewerHeader: 'idle',
  volleyStatus: {
    bucketRegion: '',
    isUp: true
  }
}

export type SelectConversationAction = PayloadAction<{ conversationId: string, teamId?: string }>
const selectConversation: CaseReducer<ApplicationState, SelectConversationAction> = (state, action) => ({
  ...state,
  hasAuthenticatedRoute: true,
  isPlaying: false,
  isSearchingExperts: false,
  navbar: state.navbar === 'hover' ? 'closed' : state.navbar,
  selectedConversationId: action.payload.conversationId,
  selectedExpertId: null,
  selectedMomentAt: undefined,
  selectedMomentId: null,
  selectedTeamId: action.payload.teamId ?? state.selectedTeamId,
})
export type SelectMomentPayload = {
  conversationId: string
  momentId: string
  startAtSeconds?: number
  teamId?: string
  startPlaying?: boolean
}
type SelectExpertSearchAction = PayloadAction<{ id: string } | undefined>
const selectExpertSearch: CaseReducer<ApplicationState, SelectExpertSearchAction> = (state, action) => ({
  ...state,
  hasAuthenticatedRoute: true,
  isPlaying: false,
  isSearchingExperts: true,
  navbar: 'open',
  selectedConversationId: null,
  selectedExpertId: action.payload?.id ?? null,
  selectedMomentAt: undefined,
  selectedMomentId: null,
})
export type SelectMomentAction = PayloadAction<SelectMomentPayload>
const selectMoment: CaseReducer<ApplicationState, SelectMomentAction> = (state, action) => ({
  ...state,
  hasAuthenticatedRoute: true,
  isPlaying: !!action.payload.startPlaying,
  isSearchingExperts: false,
  navbar: state.navbar === 'hover' ? 'closed' : state.navbar,
  selectedConversationId: action.payload.conversationId,
  selectedExpertId: null,
  selectedMomentAt: action.payload.startAtSeconds,
  selectedMomentId: action.payload.momentId,
  selectedTeamId: action.payload.teamId ?? state.selectedTeamId,
})
export type SelectTeamAction = PayloadAction<{ teamId: string }>
export type RefetchTeamAction = PayloadAction<{ teamId: string }>
const selectTeam: CaseReducer<ApplicationState, SelectTeamAction> = (state, action) => ({
  ...state,
  hasAuthenticatedRoute: true,
  isPlaying: false,
  isSearchingExperts: false,
  navbar: 'open',
  selectedConversationId: null,
  selectedExpertId: null,
  selectedMomentAt: undefined,
  selectedMomentId: null,
  selectedTeamId: action.payload.teamId,
})

export type RefetchUserAction = PayloadAction<{ userId: string,fromReconnect?: boolean }>

const exitConversation: CaseReducer<ApplicationState, PayloadAction> = (state, _) => ({
  ...state,
  isPlaying: false,
  navbar: 'open',
  selectedConversationId: null,
  selectedMomentAt: undefined,
  selectedMomentId: null,
})

const exitMoment: CaseReducer<ApplicationState, PayloadAction> = (state, _) => ({
  ...state,
  isPlaying: false,
  selectedMomentAt: undefined,
  selectedMomentId: null,
})

export type MomentFinishedPlayingAction = PayloadAction<{ conversationId: string, momentId: string }>

export type SetPlayPauseAction = PayloadAction<{ isPaused: boolean, resumeSeconds?: number }>
const setPlayPause: CaseReducer<ApplicationState, SetPlayPauseAction> = (state, action) => {
  if (!state.selectedMomentId) {
    logger.warn('Attempt to set play/pause state without a moment selected', { ...state })
    return state
  }

  return {
    ...state,
    isPlaying: !action.payload.isPaused,
    selectedMomentAt: action.payload.resumeSeconds,
  }
}

export type setIsDroppingConfettiAction = PayloadAction<{ isDroppingConfetti: boolean }>
const setIsDroppingConfetti: CaseReducer<ApplicationState, setIsDroppingConfettiAction> = (state, action) => ({
  ...state,
  isDroppingConfetti: action.payload.isDroppingConfetti,
})

export type SetIsEditingTitleAction = PayloadAction<{ isEditing: boolean }>
const setIsEditingTitle: CaseReducer<ApplicationState, SetIsEditingTitleAction> = (state, action) => ({
  ...state,
  viewerHeader: action.payload.isEditing ? 'editing-title' : 'idle',
})

export type SetIsFullScreenAction = PayloadAction<{ isFullScreen: boolean }>
const setIsFullScreen: CaseReducer<ApplicationState, SetIsFullScreenAction> = (state, action) => ({
  ...state,
  isFullScreen: action.payload.isFullScreen,
})

export type SetSidebarAction = PayloadAction<{ sidebar: SidebarState }>
const setSidebar: CaseReducer<ApplicationState, SetSidebarAction> = (state, action) => ({
  ...state,
  sidebar: action.payload.sidebar,
})

export type SetConversationListAction = PayloadAction<{ navbar: NavbarState }>
const setNavbar: CaseReducer<ApplicationState, SetConversationListAction> = (state, action) => ({
  ...state,
  navbar: action.payload.navbar,
})

type SetHasAuthenticatedRouteAction = PayloadAction<{ hasAuthenticatedRoute: boolean }>
const setHasAuthenticatedRoute: CaseReducer<ApplicationState, SetHasAuthenticatedRouteAction> = (state, action) => ({
  ...state,
  hasAuthenticatedRoute: action.payload.hasAuthenticatedRoute,
})

export type SetPlaybackAction = PayloadAction<{ captions?: boolean, rate?: number }>
const setPlayback: CaseReducer<ApplicationState, SetPlaybackAction> = (state, action) => ({
  ...state,
  playback: {
    ...state.playback,
    ...action.payload,
  },
})

export type SetReviewBeforeSendAction = PayloadAction<{ reviewBeforeSend: boolean }>
const setReviewBeforeSend: CaseReducer<ApplicationState, SetReviewBeforeSendAction> = (state, action) => ({
  ...state,
  reviewBeforeSend: action.payload.reviewBeforeSend,
})

export type SetVolleyStatusAction = PayloadAction<VolleyStatus>
const fetchVolleyStatusSuccess: CaseReducer<ApplicationState, SetVolleyStatusAction> = (state, action) => ({
  ...state,
  volleyStatus: action.payload
})

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    fetchVolleyStatusSuccess,

    exitConversation,
    exitMoment,

    momentFinishedPlaying: (state, _: MomentFinishedPlayingAction) => state,

    selectConversation,
    selectExpertSearch,
    selectMoment,
    selectTeam,

    setHasAuthenticatedRoute,
    setIsDroppingConfetti,
    setIsEditingTitle,
    setIsFullScreen,
    setNavbar,
    setPlayback,
    setPlayPause,
    setReviewBeforeSend,
    setSidebar,
  }
})

export const actions = applicationSlice.actions
export const selector = {
  name: applicationSlice.name,
  select: (state: AppState): ApplicationState => state.application,
  isEditingTitle: (state: AppState): boolean => state.application.viewerHeader === 'editing-title',
}
export default applicationSlice.reducer
