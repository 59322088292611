import React, { useEffect, useRef, useState } from 'react'
import theme from '../../theme'
import { Placement } from './index'

export const ARROW_SIZE = 14

type Rect = {
  left: number
  top: number
  width: number
  height: number
}
type ArrowProps = {
  anchorRect: Rect
  backgroundColor?: string
  borderColor?: string
  placement: Placement
}
const Arrow: React.FC<ArrowProps> = ({ anchorRect, backgroundColor, borderColor, placement }) => {
  const arrowRef = useRef<HTMLDivElement>(null)
  const [arrowPosition, setArrowPosition] = useState<ArrowPosition>({ left: '50%', top: 0 })

  useEffect(() => {
    if (!arrowRef?.current?.parentElement) return

    const nextArrowPosition = getArrowLeft({
      anchorRect,
      arrowRect: arrowRef.current.getBoundingClientRect(),
      popoverRect: arrowRef.current.parentElement.getBoundingClientRect(),
      placement
    })
    if (nextArrowPosition.left !== arrowPosition.left || nextArrowPosition.top !== arrowPosition.top)
      setArrowPosition(nextArrowPosition)
  }, [anchorRect, arrowPosition.left, placement, arrowRef, arrowPosition.top])

  return (
    <div ref={arrowRef} style={{
      position: 'absolute',
      marginRight: '-0.71em',
      bottom: placement === 'top' ? 0 : undefined,
      top: arrowPosition.top,
      left: arrowPosition.left,
      right: '50%',
      width: ARROW_SIZE,
      height: ARROW_SIZE,
      backgroundColor: backgroundColor ?? theme.palette.background.popover,
      transform: 'translate(-50%, 50%) rotate(135deg)',
      ...getBorderStyles(placement, borderColor),
    }} />
  )
}

type ArrowHorizPositionParams = {
  anchorRect: Rect
  arrowRect: Rect
  popoverRect: Rect
  placement: Placement
}
type ArrowPosition = {
  left: number | string
  top: number | string | undefined
}
function getArrowLeft(params: ArrowHorizPositionParams): ArrowPosition {
  let left
  let top

  if (params.placement === 'right') {
    left = 0
    top = `calc(50% - ${ARROW_SIZE}px)`

    const anchorMid = params.anchorRect.top + (params.anchorRect.height / 2)
    const popoverMid = params.popoverRect.top + (params.popoverRect.height / 2)

    // the browser will nail it better than we can with 50%, just get within a pixel for the test
    const midPointDifference = Math.floor(Math.abs(anchorMid - popoverMid))

    if (midPointDifference) {
      top = anchorMid < popoverMid
        ? Math.max((params.popoverRect.height / 2) - (popoverMid - anchorMid) - ARROW_SIZE, 0) // rubbing up against the top
        : Math.min((params.popoverRect.height / 2) + (anchorMid - popoverMid) - ARROW_SIZE, params.popoverRect.height - ARROW_SIZE - ARROW_SIZE) // rubbing up against the bottom
    }
  } else {
    left = '50%'
    top = params.placement === 'bottom' ? -ARROW_SIZE : undefined

    const anchorMid = params.anchorRect.left + (params.anchorRect.width / 2)
    const popoverMid = params.popoverRect.left + (params.popoverRect.width / 2)

    // the browser will nail it better than we can with 50%, just get within a pixel for the test
    const midPointDifference = Math.floor(Math.abs(anchorMid - popoverMid))

    if (midPointDifference) {
      left = anchorMid < popoverMid
        ? (params.popoverRect.width / 2) - (popoverMid - anchorMid) // rubbing up against the left side
        : (params.popoverRect.width / 2) + (anchorMid - popoverMid) // rubbing up against the right side
    }
  }
  return { left, top }
}

function getBorderStyles(placement: Placement, borderColor?: string): React.CSSProperties {
  const color = borderColor ?? theme.palette.popoverDivider

  if (placement === 'bottom') {
    return {
      borderBottom: `1px solid ${color}`,
      borderLeft: `1px solid ${color}`,
    }
  }
  if (placement === 'right') {
    return {
      borderBottom: `1px solid ${color}`,
      borderRight: `1px solid ${color}`,
    }
  }
  return {
    borderTop: `1px solid ${color}`,
    borderRight: `1px solid ${color}`,
  }
}

export default Arrow
