import { Global, ThemeProvider } from '@emotion/react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
// import { initializeSegment } from 'modules/api/analytics' //todo tech9 segment
import App, { FallbackError } from 'modules/components/App'
import store from 'modules/store'
import theme, { GlobalStyles } from 'modules/theme'
import React from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'

import 'normalize.css'

// initializeSegment() // todo tech9 re enable segment, needs new keys

const Bootstrap = () => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <ErrorBoundary FallbackComponent={FallbackError}>
        <Provider store={store}>
          <App />
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  </MuiThemeProvider>
)

ReactDOM.render(<Bootstrap />, document.getElementById('app'))
