import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { partition } from 'lodash-es'
import { KnownMimeType, MomentType } from 'modules/types/moments'
import { Transcription, UploadsDictionary } from 'modules/types/uploads'
import { AppState } from '../../reducers'
import { getMomentTypeFromMimeType } from '../utils'
import {
  createDesktopUpload,
  CreateDesktopUploadAction,
  prepareToRecordScreen,
  prepareToRecordScreenCanceled,
  recordScreenCountdownFinished,
} from './desktop'
import {
  addTranscribedSegment,
  AddTranscribedSegmentAction,
  IsProcessingTranscriptionAudioSegmentAction,
  setIsProcessingTranscriptionAudioSegment,
  transcriptionComplete,
  TranscriptionCompleteAction,
  transcriptionFailed,
  TranscriptionFailedAction,
  uploadTranscriptionAudioSegment,
  UploadTranscriptionAudioSegmentAction,
  uploadTranscriptionAudioSegmentSuccess,
  UploadTranscriptionAudioSegmentSuccessAction,
} from './transcription'

export type {
  AddTranscribedSegmentAction,
  CreateDesktopUploadAction,
  IsProcessingTranscriptionAudioSegmentAction,
  StreamUploadsState,
  TranscriptionCompleteAction,
  TranscriptionFailedAction,
  UploadTranscriptionAudioSegmentAction,
  UploadTranscriptionAudioSegmentSuccessAction,
}

type StreamUploadsState = {
  isPreparingToRecordScreen: boolean
  uploads: UploadsDictionary
}

export const initialState: StreamUploadsState = {
  isPreparingToRecordScreen: false,
  uploads: {},
}

export type CreateUploadPayload = {
  conversationId: string
  teamId: string
  uploadId: string
}
type CreateVideoUploadPayload = CreateUploadPayload & { noTranscription?: boolean, reviewBeforeSend: boolean }
export type CreateVideoUploadAction = PayloadAction<CreateVideoUploadPayload>
const createVideoUpload: CaseReducer<StreamUploadsState, CreateVideoUploadAction> = (state, action) => ({
  ...state,
  uploads: {
    ...state.uploads,
    [action.payload.uploadId]: {
      conversationId: action.payload.conversationId,
      createdAt: new Date().getTime(),
      id: action.payload.uploadId,
      reviewBeforeSend: action.payload.reviewBeforeSend,
      segments: [],
      teamId: action.payload.teamId,
      transcription: !action.payload.noTranscription
        ? { audioSegments: [], isComplete: false, transcribedSegments: [] }
        : undefined,
      type: MomentType.Video,
    },
  },
})

type CreateAudioUploadPayload = CreateUploadPayload & { reviewBeforeSend: boolean }
export type CreateAudioUploadAction = PayloadAction<CreateAudioUploadPayload>
const createAudioUpload: CaseReducer<StreamUploadsState, CreateAudioUploadAction> = (state, action) => ({
  ...state,
  uploads: {
    ...state.uploads,
    [action.payload.uploadId]: {
      conversationId: action.payload.conversationId,
      createdAt: new Date().getTime(),
      id: action.payload.uploadId,
      reviewBeforeSend: action.payload.reviewBeforeSend,
      segments: [],
      teamId: action.payload.teamId,
      transcription: { audioSegments: [], isComplete: false, transcribedSegments: [] },
      type: MomentType.Audio,
    },
  },
})

// TODO: Do we really need to pass in the thumbUrl, or can the saga generate that for us?
type CreateFileUploadPayload = CreateUploadPayload & { file: File, thumbUrl?: string }
export type CreateFileUploadAction = PayloadAction<CreateFileUploadPayload>
const createFileUpload: CaseReducer<StreamUploadsState, CreateFileUploadAction> = (state, action) => ({
  ...state,
  uploads: {
    ...state.uploads,
    [action.payload.uploadId]: {
      conversationId: action.payload.conversationId,
      createdAt: new Date().getTime(),
      id: action.payload.uploadId,
      segments: [],
      teamId: action.payload.teamId,
      thumbUrl: action.payload.thumbUrl,
      type: getMomentTypeFromMimeType(action.payload.file.type as KnownMimeType),
    },
  },
})

type CreateGiphyUploadPayload = CreateUploadPayload & {
  name: string
  thumbUrl: string
  url: string
}
export type CreateGiphyUploadAction = PayloadAction<CreateGiphyUploadPayload>
const createGiphyUpload: CaseReducer<StreamUploadsState, CreateGiphyUploadAction> = (state, action) => ({
  ...state,
  uploads: {
    ...state.uploads,
    [action.payload.uploadId]: {
      conversationId: action.payload.conversationId,
      createdAt: new Date().getTime(),
      id: action.payload.uploadId,
      segments: [],
      teamId: action.payload.teamId,
      thumbUrl: action.payload.thumbUrl,
      type: MomentType.Image,
    },
  },
})

function removeUploadFromState(uploadId: string, state: StreamUploadsState): UploadsDictionary {
  return Object.entries(state.uploads).reduce<UploadsDictionary>((dict, [id, upload]) => {
    if (id !== uploadId) {
      dict[id] = upload
    }

    return dict
  }, {})
}

type RemoveUploadAction = PayloadAction<{ uploadId: string }>
const removeUpload: CaseReducer<StreamUploadsState, RemoveUploadAction> = (state, action) => ({
  ...state,
  uploads: removeUploadFromState(action.payload.uploadId, state)
})

export type CreateUploadSuccessPayload = {
  conversationId: string
  createdOn: string
  duration?: number
  filename?: string
  firstSegmentUrl?: string
  mimeType: string
  momentId: string
  parentConversationId?: string
  reviewBeforeSend: boolean
  teamId: string
  transcription?: Omit<Transcription, 'audioSegments' | 'transcribedSegments'>
  type: MomentType
  uploadId: string
}
export type CreateUploadSuccessAction = PayloadAction<CreateUploadSuccessPayload>
const createUploadSuccess: CaseReducer<StreamUploadsState, CreateUploadSuccessAction> = (state, action) => {
  const { conversationId, teamId, type, uploadId } = action.payload

  let upload = state.uploads[uploadId]
  if (!upload) {
    upload = { conversationId, createdAt: new Date().getTime(), id: uploadId, segments: [], teamId, type }
  }

  return {
    ...state,
    uploads: {
      ...state.uploads,
      [uploadId]: {
        ...upload,
        duration: action.payload.duration,
        firstSegmentUrl: action.payload.firstSegmentUrl,
        mimeType: action.payload.mimeType,
        momentId: action.payload.momentId,
        parentConversationId: action.payload.parentConversationId,
        reviewBeforeSend: action.payload.reviewBeforeSend,
        transcription: upload.transcription ? { ...upload.transcription, ...action.payload.transcription } : undefined,
      },
    },
  }
}

export type UploadSegmentPayload = {
  data: Blob
  index: number
  isFinalSegment: boolean
  thumbUrl?: string
  uploadId: string
}
export type UploadSegmentAction = PayloadAction<UploadSegmentPayload>
const uploadSegment: CaseReducer<StreamUploadsState, UploadSegmentAction> = (state, action) => {
  const { data, index, isFinalSegment, thumbUrl, uploadId } = action.payload
  const upload = state.uploads[uploadId] ?? { id: uploadId, segments: [] }

  const [currentSegment, rest] = partition(upload.segments, s => s.index === index)
  const segments = [
    ...rest,
    {
      ...currentSegment,
      data,
      index,
      isFinalSegment,
      isUploaded: false,
      loaded: 0,
      size: 0,
    },
  ]

  let downloadUrl: string | undefined = upload.downloadUrl
  if (isFinalSegment && !downloadUrl) {
    if (upload.type === MomentType.Image && upload.mimeType !== 'image/gif') {
      downloadUrl = thumbUrl
    } else if (upload.type === MomentType.File || upload.type === MomentType.Image || upload.type === MomentType.Movie) {
      downloadUrl = URL.createObjectURL(data)
    } else {
      const blob = new Blob(segments.map(s => s.data), { type: upload.mimeType })
      downloadUrl = URL.createObjectURL(blob)
    }
  }

  return {
    ...state,
    uploads: {
      ...state.uploads,
      [uploadId]: {
        ...upload,
        downloadUrl,
        segments,
        thumbUrl: thumbUrl ?? upload.thumbUrl,
      },
    },
  }
}
export type UploadSegmentProgressAction = PayloadAction<{ index: number, loaded: number, size: number, uploadId: string }>
const uploadSegmentProgress: CaseReducer<StreamUploadsState, UploadSegmentProgressAction> = (state, action) => {
  const { index, loaded, uploadId, size } = action.payload
  const upload = state.uploads[uploadId]

  return upload
    ? {
      ...state,
      uploads: {
        ...state.uploads,
        [uploadId]: {
          ...upload,
          segments: upload.segments.map(s => s.index === index ? { ...s, loaded, size } : s)
        },
      },
    }
    : state
}
export type UploadSegmentSuccessAction = PayloadAction<{ index: number, isFinalSegment: boolean, uploadId: string }>
const uploadSegmentSuccess: CaseReducer<StreamUploadsState, UploadSegmentSuccessAction> = (state, action) => {
  const { index, isFinalSegment, uploadId } = action.payload
  const upload = state.uploads[uploadId]

  // Fallback for volleys with hard stop (isFinalSegment set after upload)
  let downloadUrl: string | undefined = upload.downloadUrl
  if (isFinalSegment && !downloadUrl
    && (upload.type === MomentType.Audio || upload.type === MomentType.Desktop || upload.type === MomentType.Video)) {
    const blob = new Blob(upload.segments.map(s => s.data), { type: upload.mimeType })
    downloadUrl = URL.createObjectURL(blob)
  }

  return upload
    ? {
      ...state,
      uploads: {
        ...state.uploads,
        [action.payload.uploadId]: {
          ...upload,
          downloadUrl,
          segments: upload.segments.map(s =>
            s.index === index
              ? { ...s, isFinalSegment: s.isFinalSegment || isFinalSegment, isUploaded: true }
              : s
          ),
        },
      },
    }
    : state
}
export type UploadFailureAction = PayloadAction<{ uploadId: string, momentId?: string, type: MomentType }>
const uploadFailure: CaseReducer<StreamUploadsState, UploadFailureAction> = (state, action) => ({
  ...state,
  uploads: removeUploadFromState(action.payload.uploadId, state),
})

export type CancelUploadAction = PayloadAction<{ uploadId: string, momentId?: string }>
const cancelUpload: CaseReducer<StreamUploadsState, CancelUploadAction> = (state, action) => {
  const upload = state.uploads[action.payload.uploadId]

  return upload
    ? {
      ...state,
      uploads: {
        ...state.uploads,
        [action.payload.uploadId]: {
          ...upload,
          isCanceled: true,
          thumbUrl: undefined,
        },
      },
    }
    : state
}

type StopRequestedPayload = {
  conversationId: string
  hardStop?: boolean
  reviewBeforeSend: boolean
  teamId: string
  uploadId: string
}
export type RequestStopAction = PayloadAction<StopRequestedPayload>
const requestStop: CaseReducer<StreamUploadsState, RequestStopAction> = (state, action) => {
  const endedAt = new Date().getTime()
  const upload = state.uploads[action.payload.uploadId]

  return {
    ...state,
    uploads: {
      ...state.uploads,
      [action.payload.uploadId]: {
        ...upload,
        duration: (endedAt - upload?.createdAt ?? 0) / 1000,
        endedAt,
        reviewBeforeSend: action.payload.reviewBeforeSend,
        stopRequested: { hardStop: action.payload.hardStop },
      },
    },
  }
}

export type CloseUploadAction = PayloadAction<{ scheduledDate?: string, uploadId: string }>
const closeUpload: CaseReducer<StreamUploadsState, CloseUploadAction> = (state, action) => ({
  ...state,
  uploads: {
    ...state.uploads,
    [action.payload.uploadId]: {
      ...state.uploads[action.payload.uploadId],
      scheduledDate: action.payload.scheduledDate,
      closeRequested: true,
    },
  },
})
export type CloseUploadSuccessAction = PayloadAction<{ momentId: string, scheduledDate?: string, uploadId: string }>
const closeUploadSuccess: CaseReducer<StreamUploadsState, CloseUploadSuccessAction> = (state, action) => ({
  ...state,
  uploads: {
    ...state.uploads,
    [action.payload.uploadId]: {
      ...state.uploads[action.payload.uploadId],
      closeCompleted: true,
    },
  },
})

const streamUploads = createSlice({
  name: 'stream-uploads',
  initialState,
  reducers: {
    addTranscribedSegment,

    cancelUpload,
    cancelUploadSuccess: removeUpload,

    closeUpload,
    closeUploadFailure: uploadFailure,
    closeUploadSuccess,

    createVideoUpload,
    createDesktopUpload,
    createAudioUpload,
    createFileUpload,
    createGiphyUpload,
    createUploadFailure: removeUpload,
    createUploadSuccess,

    prepareToRecordScreen,
    prepareToRecordScreenCanceled,
    recordScreenCountdownFinished,

    removeUpload,

    setIsProcessingTranscriptionAudioSegment,

    requestStop,

    transcriptionFailed,
    transcriptionComplete,

    uploadSegment,
    uploadSegmentProgress,
    uploadSegmentSuccess,
    uploadSegmentFailure: uploadFailure,
    uploadTranscriptionAudioSegment,
    uploadTranscriptionAudioSegmentSuccess,
  },
})

const isRecording = (appState: AppState): boolean => {
  return Object.values(appState.streamUploads.uploads)
    .some(upload => upload.type !== MomentType.Image
      && upload.type !== MomentType.Movie
      && upload.type !== MomentType.File
      && !upload.isCanceled
      && !upload.stopRequested)
}
const isScreenRecording = (appState: AppState): boolean => {
  return appState.streamUploads.isPreparingToRecordScreen
    || Object.values(appState.streamUploads.uploads)
      .some(upload => upload.type === MomentType.Desktop && !upload.isCanceled && !upload.stopRequested)
}
const isUploading = (appState: AppState): boolean => {
  return Object.values(appState.streamUploads.uploads).some(u => !u.closeCompleted)
}

export const actions = streamUploads.actions
export const selector = {
  name: streamUploads.name,
  select: (appState: AppState): StreamUploadsState => appState.streamUploads,
  isRecording,
  isScreenRecording,
  isUploading,
}
export default streamUploads.reducer
