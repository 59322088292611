import { Feature } from './types/features'


type AppConfig = {
  desktopHost: string
  hiHost: string
  host: string
  piecesHost: string
  rootDomain: string
  statusHost: string
  subscriptionsHost: string
  talkHost: string
}
type BrowserPlatform = 'Windows' | 'Mac' | 'Linux'
type DeployEnvironment = 'local' | 'dev' | 'alpha' | 'prod'
type FirebaseConfig = { apiKey: string, appId: string, authDomain: string, measurementId: string, projectId: string }
type IFramelyConfig = { apiKey: string, host: string }
type SegmentConfig = { apiKey: string }
type WebConfig = {
  help: string
  host: string
}
interface IConfig {
  readonly app: AppConfig
  readonly browserPlatform: BrowserPlatform
  readonly env: DeployEnvironment
  readonly features: Feature[]
  readonly firebase: FirebaseConfig
  readonly iFramely: IFramelyConfig
  readonly segment: SegmentConfig
  readonly web: WebConfig
}

const DOMAINS: { [key in DeployEnvironment]: string } = {
  alpha: 'alpha.volley.app',
  dev: 'dev.volley.app',
  local: 'localhost:9000',
  prod: 'volley.app',
}
const ELECTRON_PROTOCOL_SCHEMES: { [key in DeployEnvironment]: string } = {
  alpha: 'volleyalpha',
  dev: 'volleydev',
  local: 'volleylocal',
  prod: 'volleyapp',
}

export class Config implements IConfig {
  readonly app: AppConfig
  readonly browserPlatform: BrowserPlatform
  readonly env: DeployEnvironment // excludes 'local'
  readonly features: Feature[]
  readonly firebase: FirebaseConfig
  readonly iFramely: IFramelyConfig
  readonly isLocal: boolean
  readonly segment: SegmentConfig
  readonly web: WebConfig

  constructor() {
    const localConfig = process.env.LOCAL_CONFIG as unknown as Partial<IConfig> ?? {}
    const env = process.env.DEPLOY_ENV as DeployEnvironment
    const isLocal = process.env.DEPLOY_ENV === 'local'
    const envDomain = DOMAINS[env]
    const localDevOrEnvDomain = isLocal
      ? localConfig.env ? DOMAINS[localConfig.env] : DOMAINS.dev
      : envDomain
    const firebaseConfig = process.env.FIREBASE_CONFIG as unknown as FirebaseConfig ?? {}
    const webDomain = 'volleyapp.com'

    this.app = {
      desktopHost: `${ELECTRON_PROTOCOL_SCHEMES[env]}://${envDomain}`,
      hiHost: `https://hi.${localDevOrEnvDomain}`,
      host: `${isLocal ? 'http://' : 'https://'}${envDomain}`,
      piecesHost: 'https://pieces.volley.app',
      rootDomain: localDevOrEnvDomain,
      statusHost: 'https://status.volley.app',
      subscriptionsHost: `wss://sub.${localDevOrEnvDomain}`,
      talkHost: `https://talk.${localDevOrEnvDomain}`,
      ...localConfig.app,
    }
    this.browserPlatform = localConfig.browserPlatform ?? getBrowserPlatform()
    this.env = localConfig.env ? localConfig.env : isLocal ? 'dev' : env
    this.features = localConfig.features ?? []
    this.firebase = localConfig.firebase ?? firebaseConfig
    this.iFramely = {
      host: 'https://cdn.iframe.ly',
      apiKey: process.env.IFRAMELY_KEY as string, // todo tech 9 get a key to enable this
      ...localConfig.iFramely,
    }
    this.isLocal = isLocal
    this.segment = {
      apiKey: process.env.SEGMENT_KEY as string,
      ...localConfig.segment
    }
    this.web = {
      help: `https://help.${webDomain}`,
      host: `https://${webDomain}`,
    }
  }
}

// pulled from https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
function getBrowserPlatform(): BrowserPlatform {
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']

  return macosPlatforms.includes(window.navigator.platform)
    ? 'Mac'
    : windowsPlatforms.includes(window.navigator.platform)
      ? 'Windows'
      : 'Linux'
}

const config = new Config()
export default config
