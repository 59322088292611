import gql from 'graphql-tag'
import getClientInfo, { ClientInfoParams } from 'modules/clientInfo'
import { Offer, OfferCategory, OffersDictionary, OfferState, OfferType } from 'modules/types/offers'
import { checkForErrors, mutateGraphQl } from './graph-utils'
import { deserializeOffer, Vol_Offering } from './shared'
import { BatchResult, deserializeBatchResults } from './util'

export type CreateOfferParams = {
  accessDays?: number
  accessEol?: string
  category: OfferCategory
  cost: number
  description?: string
  minutes?: number
  name: string
  promoText?: string | null
  teamId?: string
  type: OfferType
}
export type CreateOfferMutationParams = CreateOfferParams & ClientInfoParams
type CreateOfferResult = { createOffering: string | null }
const CreateOffer = gql`
  mutation CreateOffering(
    $accessDays: Int
    $accessEol: AWSDateTime
    $category: String!
    $clientInfo: String
    $cost: Float!
    $description: String
    $promoText: String
    $minutes: Float
    $teamId: ID
    $name: String!
    $type: String!
    ) {
    createOffering(
      accessEOL: $accessEol
      clientInfo: $clientInfo
      cost: $cost
      daysOfAccess: $accessDays
      description: $description
      minutes: $minutes
      name: $name
      promoText: $promoText
      teamID: $teamId
      vCategory: $category
      vType: $type
    )
  }
`
export async function createOffer(params: CreateOfferParams): Promise<Offer> {
  const result = await mutateGraphQl<CreateOfferResult, CreateOfferMutationParams>({
    mutation: CreateOffer,
    variables: {
      ...params,
      clientInfo: await getClientInfo(),
    }
  })

  checkForErrors('createOffering', result, true)
  // we've checked for errors in the previous call
  return deserializeOffer(JSON.parse(result.data?.createOffering ?? '{}'))
}

type GetOffersParams = {
  clientInfo: string
  ids: string[]
}
type GetOffersResult = { getOfferings: string | null }
type GetOfferings_OfferingDictionary = { [id: string]: Vol_Offering }

const GetOffers = gql`
  mutation GetOfferings($clientInfo: String, $ids: [ID]!) {
    getOfferings(clientInfo: $clientInfo, offeringIDs: $ids)
  }
`
export async function getOffers(offerIds?: string[]): Promise<BatchResult<Offer>> {
  const ids = offerIds ?? []

  const clientInfo = await getClientInfo()
  const results = await mutateGraphQl<GetOffersResult, GetOffersParams>({
    mutation: GetOffers,
    variables: { clientInfo, ids }
  })

  checkForErrors('getOfferings', results, true)
  return deserializeBatchResults(results.data?.getOfferings, deserializeOffers)
}

function deserializeOffers(offerings: GetOfferings_OfferingDictionary): OffersDictionary {
  return Object.entries(offerings).reduce<OffersDictionary>((dict, [id, offering]) => {
    dict[id] = deserializeOffer(offering)

    return dict
  }, {})
}

export type UpdateOfferParams = {
  addSeedConversation?: boolean
  accessDays?: number
  accessEol?: string
  cost?: number
  description?: string
  id: string
  minutes?: number
  name?: string
  promoText?: string | null
  state?: OfferState
  suppressFromSellerSearch?: boolean
}
type UpdateOfferMutationParams = UpdateOfferParams & ClientInfoParams
type UpdateOfferResult = { updateOffering: string | null }
const UpdateOffer = gql`
  mutation UpdateOffering(
    $addSeedConversation: Boolean
    $accessDays: Int
    $accessEol: AWSDateTime
    $clientInfo: String
    $cost: Float
    $description: String
    $id: ID!
    $minutes: Float
    $name: String
    $promoText: String
    $state: String
    $suppressFromSellerSearch: Boolean
    ) {
    updateOffering(
      addSeedConversation: $addSeedConversation
      accessEOL: $accessEol
      clientInfo: $clientInfo
      cost: $cost
      daysOfAccess: $accessDays
      description: $description
      minutes: $minutes
      name: $name
      offeringID: $id
      promoText: $promoText
      suppressFromSellerSearch: $suppressFromSellerSearch
      vState: $state
    )
  }
`
export async function updateOffer(params: UpdateOfferParams): Promise<Offer> {
  const result = await mutateGraphQl<UpdateOfferResult, UpdateOfferMutationParams>({
    mutation: UpdateOffer,
    variables: {
      ...params,
      clientInfo: await getClientInfo(),
    }
  })

  checkForErrors('updateOffering', result, true)
  // we've checked for errors in the previous call
  return deserializeOffer(JSON.parse(result.data?.updateOffering ?? '{}'))
}

type DeleteOfferMutationParams = ClientInfoParams & {
  id: string
}
type DeleteOfferResult = { deleteOffering: string | null }
const DeleteOffer = gql`
  mutation DeleteOffering($clientInfo: String, $id: ID!) {
    deleteOffering(clientInfo: $clientInfo, offeringID: $id)
  }
`
export async function deleteOffer(id: string): Promise<void> {
  const result = await mutateGraphQl<DeleteOfferResult, DeleteOfferMutationParams>({
    mutation: DeleteOffer,
    variables: { clientInfo: await getClientInfo(), id }
  })

  checkForErrors('deleteOffering', result, true)
}
