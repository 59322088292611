import styled from '@emotion/styled'
import MuiCircularProgress, { CircularProgressProps as MuiCircularProgressProps } from '@mui/material/CircularProgress'
import React from 'react'

type VolCircularProgressProps = Omit<MuiCircularProgressProps, 'color'> & {
  volColor: string
}
const VolCircularProgress = styled(MuiCircularProgress, { shouldForwardProp: propName => propName !== 'volColor' }) <VolCircularProgressProps>`
  color: ${({ volColor }) => volColor};
`

const SIZES: { [key in SizeOption]: number } = {
  large: 60,
  medium: 40,
  small: 30,
  xSmall: 16,
}
const LINES: { [key in ThicknessOption]: number } = {
  heavy: 4,
  light: 2,
  medium: 3,
}

type SizeOption = 'large' | 'medium' | 'small' | 'xSmall'
type ThicknessOption = 'heavy' | 'medium' | 'light'
type CircularProgressProps = Omit<MuiCircularProgressProps, 'color' | 'size' | 'thickness'> & {
  color?: string
  size?: SizeOption | number
  thickness?: ThicknessOption | number
}
const CircularProgress = (props: CircularProgressProps): React.ReactElement => {
  const { color = '#ffffff', size: sizeProp, thickness: thicknessProp, ...rest } = props
  const size = typeof sizeProp === 'string' ? SIZES[sizeProp] : sizeProp ?? SIZES['medium']
  const thickness = typeof thicknessProp === 'string' ? LINES[thicknessProp] : thicknessProp ?? LINES['medium']

  return (
    <VolCircularProgress
      size={size}
      thickness={thickness}
      volColor={color}
      {...rest}
    />
  )
}

export default CircularProgress
