import { Action } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'
import { setAuthToken } from 'modules/api/apollo'
import firebase from 'modules/api/firebase'
import * as usersApi from 'modules/api/users'
import config from 'modules/config'
import { CREATE_USER_PARAMS_PREFERENCE, POST_VERIFY_DESTINATION_PREFERENCE } from 'modules/constants'
import { readPreference, writePreference } from 'modules/hooks/preferences'
import logger from 'modules/logger'
import { AuthActionMode, AuthProvider, IdentityAction, IdentityUser } from 'modules/types/auth'
import { Feature, FeatureId, SsoData } from 'modules/types/features'
import { call, delay, put, race, SagaGenerator, select, take, takeLatest } from 'typed-redux-saga'
import { actions as alertsActions } from '../alerts/slice'
import { actions as analyticsActions } from '../analytics/slice'
import { selector as applicationSelector } from '../application/slice'
import { actions as deviceSettingsActions } from '../device-settings/slice'
import Features from '../features/utils'
import { actions as initializeActions } from '../initialize/slice'
import { actions as usersActions, UpdateCurrentUserSuccessAction } from '../users/slice'
import {
  actions,
  CreateAccountAction,
  CreateSignInLinkAction,
  EmailPayloadAction,
  RecoverEmailAction,
  ResetPasswordAction,
  selector,
  SetPostVerifyDestinationAction,
  SignInIdentitySuccessAction,
  SignInWithLinkAction,
  SignInWithSsoAction,
  UpdateEmailAction,
  UpdatePasswordAction,
  ValidateAuthActionAction,
  VerifyEmailAction,
  VerifySignInLinkAction,
} from './slice'

const IDENTITY_REFRESH_BUFFER_MS = 2 * 60 * 1000
const IDENTITY_REFRESH_RETRY_INTERVAL_MS = 30 * 1000
let systemOffsetFromServer = 0

export default function*(): SagaGenerator<void> {
  yield* takeLatest(actions.signIn, ({ payload: { email, password } }) => signIn(email, password))
  yield* takeLatest(actions.signInWithLink, signInWithLink)
  yield* takeLatest(actions.signInWithSso, signInWithSso)
  yield* takeLatest(actions.signInIdentitySuccess, refreshIdentitySession)

  yield* takeLatest(initializeActions.requestIdentity, () => initializeIdentityState())
  yield* takeLatest(actions.refreshIdentitySession, action => initializeIdentityState(action.payload?.isAfterVerifyEmail))
  yield* takeLatest(actions.verifyEmailSuccess, () => initializeIdentityState())
  yield* takeLatest(actions.createAccount, createAccount)
  yield* takeLatest(actions.createAccountStartOver, createAccountStartOver)
  yield* takeLatest(actions.resendEmailVerification, resendEmailVerification)
  yield* takeLatest(actions.verifyEmail, verifyEmail)
  yield* takeLatest(actions.setPostVerifyDestination, cachePostVerifyDestination)

  yield* takeLatest(actions.requestForgotPassword, requestForgotPassword)
  yield* takeLatest(actions.resetPassword, resetPassword)
  yield* takeLatest(actions.updatePassword, updatePassword)

  yield* takeLatest(actions.updateEmail, updateEmail)
  yield* takeLatest(actions.validateAuthAction, validateAuthAction)
  yield* takeLatest(actions.createSignInLink, createSignInLink)
  yield* takeLatest(actions.verifySignInLink, verifySignInLink)
  yield* takeLatest(actions.recoverEmail, recoverEmail)

  yield* takeLatest(actions.signOut, action => signOut(action.payload?.destination, action.payload?.preventRedirect))

  yield* takeLatest(usersActions.updateCurrentUserSignedOut, () => signOut())
  yield* takeLatest(usersActions.updateCurrentUserSuccess, handleUpdateCurrentUser)
}

export function* initializeIdentityState(isAfterVerifyEmail?: boolean): SagaGenerator<void> {
  try {
    yield* loadCachedPostVerifyDestination()
    const user = yield* getAuthenticatedUser(Features.get<SsoData>(FeatureId.Sso))

    if (!user) {
      if (isAfterVerifyEmail) {
        yield* put(actions.verifyEmailFailure({ error: 'Signed in user not found' }))
      } else {
        const { hasAuthenticatedRoute } = yield* select(applicationSelector.select)

        if (hasAuthenticatedRoute) {
          try {
            const user = yield* call(firebase.auth.signInAnonymously)
            yield* put(actions.signInIdentitySuccess(user))
            return
          } catch (error) {
            logger.error('Failed to create anonymous firebase user', error)
          }
        }

        yield* put(actions.signInIdentityFailure())
      }
    } else if (user.email && !user.isEmailVerified) {
      yield* put(actions.signInNeedsEmailVerification({ email: user.email }))

      if (isAfterVerifyEmail) {
        yield* put(actions.verifyEmailFailure({ error: 'Email not verified. Check the link and try again.' }))
      }
    } else {
      yield* put(actions.signInIdentitySuccess(user))
    }
  } catch (error) {
    if (isAfterVerifyEmail) {
      logger.error('Failed to re-init identity after email verification', error)
      yield* put(actions.verifyEmailFailure({ error: 'Failed to confirm email verification. Please try again.' }))
    } else {
      yield* put(actions.signInIdentityFailure())
    }
  }
}

function* getAuthenticatedUser(ssoFeature: Feature<SsoData>): SagaGenerator<IdentityUser | null> {
  try {
    const user = yield* call([Auth, Auth.currentUserPoolUser])
    return {
      accessToken: user.signInUserSession.accessToken.jwtToken,
      availableAuthProviders: [AuthProvider.EmailAndPassword],
      email: user.attributes.email,
      expiresOn: user.signInUserSession.accessToken.payload.exp * 1000,
      isAnonymous: false,
      isEmailVerified: true,
      issuedOn: user.signInUserSession.accessToken.payload.iss * 1000,
      provider: AuthProvider.EmailAndPassword,
      source: 'cognito',
      userId: user.attributes.sub,
    }
  } catch (error) {
    // no cognito user
    return ssoFeature.isActive
      ? yield* call(firebase.auth.getCurrentUser)
      : null
  }
}

function* loadCachedPostVerifyDestination(): SagaGenerator<void> {
  const destination = readPreference(POST_VERIFY_DESTINATION_PREFERENCE, null)
  if (!destination) {
    return
  }

  const { postVerifyDestination } = yield* select(selector.select)
  if (postVerifyDestination !== destination) {
    yield* put(actions.setPostVerifyDestination({ destination }))
  }
}

export function* signIn(email: string, password: string): SagaGenerator<void> {
  email = email.trim().toLowerCase()

  const ssoFeature = Features.get<SsoData>(FeatureId.Sso)

  if (ssoFeature.isActive) {
    yield* authenticateWithFirebase(email, password, ssoFeature.data.isGracePeriodActive)
  } else {
    yield* authenticateWithCognito(email, password)
  }
}

function* authenticateWithCognito(email: string, password: string): SagaGenerator<void> {
  try {
    const user = yield* call([Auth, Auth.signIn], email, password)
    yield* put(actions.signInIdentitySuccess({
      accessToken: user.signInUserSession.accessToken.jwtToken,
      availableAuthProviders: [AuthProvider.EmailAndPassword],
      email: user.attributes.email,
      expiresOn: user.signInUserSession.accessToken.payload.exp * 1000,
      isAnonymous: false,
      isEmailVerified: true,
      issuedOn: user.signInUserSession.accessToken.payload.iss * 1000,
      provider: AuthProvider.EmailAndPassword,
      source: 'cognito',
      userId: user.attributes.sub,
    }))

    yield* put(analyticsActions.signIn({ authProvider: AuthProvider.EmailAndPassword }))
  } catch (error) {
    if (error.code === 'UserNotConfirmedException') {
      yield* put(actions.signInNeedsEmailVerification({ email }))
    } else {
      logger.error('Failed to sign in with cognito', error)
      yield* put(actions.signInFailure({
        error: typeof error.message === 'string'
          ? error.message
          : 'Unexpected error occurred. Try again or contact customer support.',
      }))
    }
  }
}

function getAvailableSsoProvider(availableProviders: AuthProvider[]): string {
  return availableProviders.includes(AuthProvider.Google)
    ? 'Google'
    : availableProviders.includes(AuthProvider.LinkedIn)
      ? 'LinkedIn'
      : availableProviders.includes(AuthProvider.Apple)
        ? 'Apple'
        : 'another provider'
}
function* authenticateWithFirebase(email: string, password: string, isGracePeriodActive: boolean): SagaGenerator<void> {
  const availableProviders = yield* call(firebase.auth.getAvailableAuthProviders, email)
  if (availableProviders.length && !availableProviders.includes(AuthProvider.EmailAndPassword)) {
    const provider = getAvailableSsoProvider(availableProviders)
    yield* put(actions.signInFailure({ error: `Sign in with email and password not available. Try signing in with ${provider}.` }))
    return
  }

  try {
    const user = yield* call(firebase.auth.signIn, email, password)
    if (!user.isEmailVerified) {
      yield* put(actions.signInNeedsEmailVerification({ email }))
    } else {
      yield* put(actions.signInIdentitySuccess(user))
    }

    yield* put(analyticsActions.signIn({ authProvider: AuthProvider.EmailAndPassword }))
  } catch (error) {
    if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
      if (isGracePeriodActive) {
        yield* migrateUserLogin(email, password)
      } else {
        yield* put(actions.signInFailure({ error: 'Incorrect email or password' }))
      }

      return
    } else if (error.code === 'auth/too-many-requests') {
      yield* put(actions.signInFailure({ error: 'This account has been temporarily locked due to too many failed sign in attempts. You may reset the password immediately or try again later.' }))
      return
    }

    logger.error('Failed to sign in with firebase', error)
    yield* put(actions.signInFailure({ error: error.message?.replace(/^Firebase\:\s*/, '') ?? 'Unexpected error occurred. Try again or contact customer support.', }))
  }
}

function* migrateUserLogin(email: string, password: string): SagaGenerator<void> {
  try {
    const loginResult = yield* call(usersApi.userIdentity, { action: IdentityAction.LogIn, email, password })
    if (loginResult.error) {
      const loginError = loginResult.error.toLowerCase()

      if (loginError.includes('login directly') || loginError.includes('invalid login attempt')) {
        yield* put(actions.signInFailure({ error: 'Incorrect email or password' }))
      } else if (loginError.includes('password reset required')) {
        yield* put(actions.signInFailure({ error: 'Sign in impossible. Please reset password and try again.' }))
      } else {
        yield* put(actions.signInFailure({ error: 'Unexpected error occurred. Try again or contact customer support.' }))
      }

      return
    }

    yield* authenticateWithFirebase(email, password, false)
  } catch (error) {
    logger.error('Failed to migrate user login', error)
    yield* put(actions.signInFailure({ error: 'Unexpected error occurred. Try again or contact customer support.' }))
  }
}

export function* signInWithLink(action: SignInWithLinkAction): SagaGenerator<void> {
  const { email, link } = action.payload

  try {
    const user = yield* call(firebase.auth.signInWithLink, email, link)
    yield* put(actions.signInIdentitySuccess(user))
  } catch (error) {
    logger.error('Failed to sign in with link', error)
    const isInvalidCode = error.code === 'auth/invalid-action-code'
    const isExpired = error.code === 'auth/id-token-expired'
    const msg = `Failed to sign in with authentication link.${isInvalidCode ? ' Invalid link.' : isExpired ? ' Link expired.' : ''}`
    yield* put(actions.signInFailure({ error: msg }))
  }
}

export function* signInWithSso(action: SignInWithSsoAction): SagaGenerator<void> {
  const { authProvider, isSignUp } = action.payload

  const ssoFeature = Features.get<SsoData>(FeatureId.Sso)

  if (!ssoFeature.isActive) {
    return
  }

  try {
    let user: IdentityUser

    const { accessToken } = yield* select(selector.select)
    if (accessToken?.isAnonymous) {
      try {
        user = yield* call(firebase.auth.promoteAnonymousUserWithSso, authProvider)
      } catch (err) {
        if (err.code !== 'auth/credential-already-in-use') {
          throw err
        }
        user = yield* call(firebase.auth.signInWithSso, authProvider)
      }
    } else {
      user = yield* call(firebase.auth.signInWithSso, authProvider)
    }

    if (user.email && isSignUp) {
      writePreference(CREATE_USER_PARAMS_PREFERENCE, {
        authProvider,
        email: user.email,
        familyName: user.familyName,
        givenName: user.givenName,
        phoneNumber: user.phoneNumber,
      })
    }
    if (user.email && !user.isEmailVerified) {
      yield* call(usersApi.userIdentity, { action: IdentityAction.SendVerifyEmail, email: user.email })
      yield* put(actions.signInNeedsEmailVerification({ email: user.email }))
      return
    }

    yield* put(actions.signInIdentitySuccess(user))
  } catch (error) {
    logger.error('Failed to sign in with sso', error, { authProvider })
    yield* put(actions.signInFailure({ error: 'Failed to sign in with the selected provider' }))
  }
}

function* refreshIdentitySession(action: SignInIdentitySuccessAction): SagaGenerator<void> {
  const { accessToken, source, userId } = action.payload
  let hasFailed = false
  let isNewToken = true

  setAuthToken(accessToken, source)
  const ssoFeature = Features.get<SsoData>(FeatureId.Sso)

  while (true) {
    let delayMs = IDENTITY_REFRESH_RETRY_INTERVAL_MS
    if (!hasFailed) {
      delayMs = yield* getDelayFromToken(isNewToken)
    }
    isNewToken = false

    const { didReconnect } = yield* race({
      didReconnect: take((action: Action) =>
        deviceSettingsActions.networkStatusChanged.match(action) && action.payload.networkStatus === 'online'),
      sleep: delay(delayMs),
    })

    if (didReconnect) {
      // If the machine just came back from sleep or losing network connectivity
      // the token may already be expired, so start the loop over again to reevaluate
      // the expiration and refresh the session or resume looping as necessary
      continue
    }

    try {
      let user = yield* call(refreshCognitoSession)
      if (!user && ssoFeature.isActive) {
        user = yield* call(firebase.auth.getCurrentUser)
      }

      if (!user) {
        throw new Error('Authenticated user not found')
      }

      yield* put(actions.refreshIdentitySessionSuccess(user))
      setAuthToken(user.accessToken, user.source)
      hasFailed = false
      isNewToken = true
    } catch (error) {
      if (error.code === 'NotAuthorizedException' || error.message === 'Authenticated user not found') {
        yield* put(actions.signOut())
        break
      }

      if (error.code !== 'auth/network-request-failed') {
        logger.error('Failed to refresh identity session', error, { userId })
      }
      hasFailed = true
    }
  }
}

function* getDelayFromToken(isNewToken: boolean): SagaGenerator<number> {
  const { accessToken } = yield* select(selector.select)
  const now = new Date().getTime()
  const issuedOn = accessToken?.issuedOn ?? now
  const expiresOn = accessToken?.expiresOn ?? now
  const systemTimeDiff = now - issuedOn

  if (isNewToken && Math.abs(systemTimeDiff) > IDENTITY_REFRESH_BUFFER_MS && !systemOffsetFromServer) {
    systemOffsetFromServer = systemTimeDiff
    yield* put(alertsActions.pushAlert({
      message: 'It appears your system clock may not match your timezone. Dates may be inaccurate and Volley may not function correctly.',
      severity: 'warning',
    }))
  }

  return expiresOn - now + systemOffsetFromServer - IDENTITY_REFRESH_BUFFER_MS
}

function* refreshCognitoSession(): SagaGenerator<IdentityUser | null> {
  try {
    const cognitoUser = yield* call([Auth, Auth.currentAuthenticatedUser])
    return yield* call(refreshCognitoUserSession, cognitoUser, cognitoUser.signInUserSession)
  } catch (error) {
    return null
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function refreshCognitoUserSession(user: any, session: any): Promise<IdentityUser> {
  return new Promise((res, rej) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user.refreshSession(session.refreshToken, (err: Error, newSession: any) => {
      if (err) {
        rej(err)
      } else {
        res({
          accessToken: newSession.accessToken.jwtToken,
          availableAuthProviders: [AuthProvider.EmailAndPassword],
          email: user.attributes.email,
          expiresOn: newSession.accessToken.payload.exp * 1000,
          isAnonymous: false,
          isEmailVerified: true,
          issuedOn: newSession.accessToken.payload.iss * 1000,
          provider: AuthProvider.EmailAndPassword,
          source: 'cognito',
          userId: user.attributes.sub,
        })
      }
    })
  })
}

export function* createAccount(action: CreateAccountAction): SagaGenerator<void> {
  const { authProvider, email: rawEmail, password, ...rest } = action.payload
  const email = rawEmail.trim().toLowerCase()

  try {
    const userId = yield* signUpUser(email, password)
    yield* put(actions.createAccountSuccess({ email, userId }))

    writePreference(CREATE_USER_PARAMS_PREFERENCE, { authProvider, email, ...rest })
  } catch (error) {
    logger.error('Failed to create account', error)
    yield* put(actions.createAccountFailure({
      error: typeof error.message === 'string'
        ? error.message
        : 'Unexpected error occurred. Try again or contact customer support.'
    }))
  }
}

function* signUpUser(email: string, password: string): SagaGenerator<string> {
  const ssoFeature = Features.get<SsoData>(FeatureId.Sso)

  if (ssoFeature.isActive) {
    let userId: string

    const { accessToken } = yield* select(selector.select)
    if (accessToken?.isAnonymous) {
      ({ userId } = yield* call(firebase.auth.promoteAnonymousUser, email, password))
      yield* call(usersApi.userIdentity, { action: IdentityAction.ChangeEmail, email })
    } else {
      const { error } = yield* call(usersApi.userIdentity, { action: IdentityAction.CreateAccount, email, password })
      if (error) {
        throw new Error(error.endsWith('auth/email-already-exists')
          ? 'An account already exists with that email'
          : error)
      }

      ({ userId } = yield* call(firebase.auth.signIn, email, password))
    }

    return userId
  } else {
    const { userSub } = yield* call([Auth, Auth.signUp], { username: email, password })
    return userSub
  }
}

export function* resendEmailVerification(action: EmailPayloadAction): SagaGenerator<void> {
  const { email: rawEmail } = action.payload
  const email = rawEmail.trim().toLowerCase()

  const ssoFeature = Features.get<SsoData>(FeatureId.Sso)

  if (ssoFeature.isActive) {
    yield* call(usersApi.userIdentity, { action: IdentityAction.SendVerifyEmail, email })
  } else {
    yield* call([Auth, Auth.resendSignUp], email)
  }
}

export function* verifyEmail(action: VerifyEmailAction): SagaGenerator<void> {
  const { email, password, verificationCode } = action.payload

  const ssoFeature = Features.get<SsoData>(FeatureId.Sso)

  try {
    if (ssoFeature.isActive) {
      yield* call(firebase.auth.verifyEmail, verificationCode)
      yield* put(actions.verifyEmailSuccess())
    } else {
      if (!email || !password) {
        throw new Error('Email required')
      }
      yield* call([Auth, Auth.confirmSignUp], email.trim().toLowerCase(), verificationCode)
      yield* call(signIn, email, password)
    }
  } catch (error) {
    logger.error('Failed to verify email', error)
    const message = error.code === 'auth/invalid-action-code'
      ? 'Invalid verification code'
      : typeof error.message === 'string'
        ? error.message
        : 'Unexpected error occurred. Try again or contact customer support.'
    yield* put(actions.verifyEmailFailure({ error: message }))
  }
}

export function* requestForgotPassword(action: EmailPayloadAction): SagaGenerator<void> {
  const { email: rawEmail } = action.payload
  const email = rawEmail.trim().toLowerCase()

  const ssoFeature = Features.get<SsoData>(FeatureId.Sso)

  try {
    if (ssoFeature.isActive) {
      yield* call(usersApi.userIdentity, { action: IdentityAction.ResetPassword, email })
    } else {
      yield* call([Auth, Auth.forgotPassword], email)
    }
    yield* put(actions.requestForgotPasswordSuccess({ email }))
  } catch (error) {
    logger.error('Failed to request forgot password code', error)

    let message: string
    if (typeof error.message !== 'string') {
      message = 'Unexpected error occurred. Try again or contact customer support.'
    } else if (error.name === 'UserNotFoundException' || error.name === 'InvalidParameterException') {
      message = 'Account not found with that email'
    } else {
      message = error.message
    }

    yield* put(actions.requestForgotPasswordFailure({ error: message }))
  }
}

export function* resetPassword(action: ResetPasswordAction): SagaGenerator<void> {
  const { email, password, verificationCode } = action.payload

  const ssoFeature = Features.get<SsoData>(FeatureId.Sso)

  try {
    if (ssoFeature.isActive) {
      const email = yield* call(firebase.auth.verifyResetPasswordCode, verificationCode)
      if (!email) {
        yield* put(actions.resetPasswordFailure({ error: 'Invalid code. Check the link and try again.' }))
        return
      }

      yield* call(firebase.auth.resetPassword, verificationCode, password)
    } else {
      if (!email) {
        throw new Error('Email required')
      }

      yield* call([Auth, Auth.forgotPasswordSubmit], email.trim().toLowerCase(), verificationCode, password)
    }

    yield* put(actions.resetPasswordSuccess())
  } catch (error) {
    logger.error('Failed to reset password', error)

    let message: string
    if (typeof error.message !== 'string') {
      message = 'Unexpected error occurred. Try again or contact customer support.'
    } else if (error.name === 'UserNotFoundException') {
      message = 'User or not found'
    } else if (error.name === 'InvalidPasswordException' || error.name === 'InvalidParameterException') {
      message = ssoFeature.data.failedRegexMessage
    } else {
      message = error.message
    }

    yield* put(actions.resetPasswordFailure({ error: message }))
  }
}

export function* updatePassword(action: UpdatePasswordAction): SagaGenerator<void> {
  const { newPassword, oldPassword } = action.payload

  try {
    yield* updateIdentityPassword(newPassword, oldPassword)
    yield* put(actions.updatePasswordSuccess())
    yield* put(alertsActions.pushAlert({ message: 'Successfully updated password', severity: 'success' }))
  } catch (error) {
    if (error.code === 'auth/wrong-password') {
      yield* put(actions.updatePasswordFailure({ error: 'Incorrect password' }))
      return
    }
    if (error.code === 'auth/too-many-attempts') {
      yield* put(actions.updatePasswordFailure({ error: 'This account has been temporarily locked due to too many failed attempts. Try again later.' }))
      return
    }

    logger.error('Failed to update password', error)
    yield* put(actions.updatePasswordFailure({
      error: typeof error.message === 'string'
        ? error.message
        : 'Unexpected error occurred. Try again or contact customer support.'
    }))
  }
}

function* updateIdentityPassword(newPassword: string, oldPassword: string): SagaGenerator<void> {
  const authState = yield* select(selector.select)

  if (authState.accessToken?.source === 'firebase') {
    // should never happen, but we're covering our typescript bases
    if (!authState.email) {
      throw new Error('User not found')
    }

    yield* call(firebase.auth.signIn, authState.email, oldPassword)
    yield* call(firebase.auth.updatePassword, newPassword)
  } else {
    const cognitoUser = yield* call([Auth, Auth.currentAuthenticatedUser])
    // TODO: keep this check (but on the accessToken above) when removing cognito
    if (!cognitoUser) {
      // This should never happen, but let's cover our bases
      yield* put(actions.updatePasswordFailure({ error: 'Unable to update password while not logged in' }))
      return
    }

    yield* call([Auth, Auth.changePassword], cognitoUser, oldPassword, newPassword)
  }
}

export function* updateEmail(action: UpdateEmailAction): SagaGenerator<void> {
  const { email: rawEmail, password } = action.payload
  const email = rawEmail.trim().toLowerCase()
  const authState = yield* select(selector.select)

  try {
    // should never happen, but we're covering our typescript bases
    if (!authState.email) {
      throw new Error('User not found')
    }

    yield* call(firebase.auth.signIn, authState.email, password)
    yield* call(firebase.auth.updateEmail, email)
    yield* call(usersApi.userIdentity, { action: IdentityAction.ChangeEmail, email })
    const user = yield* call(firebase.auth.getCurrentUser)

    if (user?.isEmailVerified) {
      yield* put(actions.updateEmailSuccess({ email }))
      yield* put(alertsActions.pushAlert({ message: 'Successfully updated email', severity: 'success' }))
    } else {
      yield* put(actions.signInNeedsEmailVerification({ email }))
    }
  } catch (error) {
    if (error.code === 'auth/wrong-password') {
      yield* put(actions.updateEmailFailure({ error: 'Incorrect password' }))
      return
    }
    if (error.code === 'auth/too-many-attempts') {
      yield* put(actions.updateEmailFailure({ error: 'This account has been temporarily locked due to too many failed attempts. Try again later.' }))
      return
    }
    if (error.code === 'auth/email-already-exists') {
      yield* put(actions.updateEmailFailure({ error: 'An account already exists with that email' }))
      return
    }

    logger.error('Failed to update email', error)
    yield* put(actions.updateEmailFailure({
      error: typeof error.message === 'string'
        ? error.message
        : 'Unexpected error occurred. Try again or contact customer support.'
    }))
  }
}

export function* validateAuthAction(action: ValidateAuthActionAction): SagaGenerator<void> {
  const { code, mode } = action.payload

  try {
    const res = yield* call(firebase.auth.validateAuthAction, code)
    yield* put(actions.validateAuthActionSuccess(res))
  } catch (error) {
    logger.error('Failed to validate auth action code', error, { mode })
    const message = error.code === 'auth/invalid-action-code'
      ? 'The link may be invalid or expired. Please check your email and try again.'
      : error.message
    yield* put(actions.validateAuthActionFailure({ error: message, mode }))
  }
}

export function* createSignInLink(action: CreateSignInLinkAction): SagaGenerator<void> {
  const { path, search } = action.payload

  try {
    const { accessToken, userId } = yield* select(selector.select)
    if (accessToken?.provider === AuthProvider.Anonymous) {
      return
    }

    if (accessToken && accessToken.provider !== AuthProvider.EmailAndPassword) {
      const link = `${config.app.host}/auth/action?mode=${AuthActionMode.SignInWithSso}&provider=${accessToken.provider}&continueUrl=${path}${search ? encodeURIComponent(search) : ''}&u=${userId}`

      yield* put(actions.createSignInLinkSuccess({ link }))
      return
    }

    const { signInUrl } = yield* call(usersApi.updateUser, {
      getSignInUrl: { path, search },
    })

    if (signInUrl) {
      yield* put(actions.createSignInLinkSuccess({ link: signInUrl }))
    } else {
      logger.error('Sign in link not returned from api', { path, search })
      yield* put(actions.createSignInLinkFailure({ error: 'Sign in link not created' }))
    }
  } catch (error) {
    logger.error('Failed to create sign in link', error, { path, search })
    yield* put(actions.createSignInLinkFailure({ error: 'Failed to create sign in link' }))
  }
}

export function* verifySignInLink(action: VerifySignInLinkAction): SagaGenerator<void> {
  const { link } = action.payload

  try {
    const isValid = yield* call(firebase.auth.verifyAuthLink, link)
    if (isValid) {
      yield* put(actions.verifySignInLinkSuccess({ link }))
    } else {
      yield* put(actions.verifySignInLinkFailure({ error: 'Sign in link not valid' }))
    }
  } catch (error) {
    logger.error('Failed to verify sign in link', error, { link })
    yield* put(actions.verifySignInLinkFailure({ error: error.message }))
  }
}

export function* recoverEmail(action: RecoverEmailAction): SagaGenerator<void> {
  const { code, email } = action.payload

  try {
    yield* call(firebase.auth.recoverEmail, code)
    yield* call(usersApi.userIdentity, { action: IdentityAction.ChangeEmail, email })
    yield* put(actions.recoverEmailSuccess())
  } catch (error) {
    logger.error('Failed to recover email', error)
    const message = error.code === 'auth/invalid-action-code'
      ? 'Could not recover email. Please check the link and try again.'
      : error.message
    yield* put(actions.recoverEmailFailure({ error: message }))
  }
}

function* signOut(destination?: string, preventRedirect?: boolean): SagaGenerator<void> {
  const ssoFeature = Features.get<SsoData>(FeatureId.Sso)

  yield* call([Auth, Auth.signOut])
  yield* call(firebase.auth.signOut)

  if (preventRedirect) {
    return
  }

  const href = `${ssoFeature.isActive ? '/welcome' : '/sign_in'}${destination ? `?destination=${encodeURIComponent(destination)}` : ''}`
  window.location.href = href
}

function* createAccountStartOver(): SagaGenerator<void> {
  yield* put(actions.signOut({ preventRedirect: true }))
  yield* put(actions.createAccountStartOverSuccess())
}

export function* handleUpdateCurrentUser(action: UpdateCurrentUserSuccessAction): SagaGenerator<void> {
  const { user } = action.payload

  const authState = yield* select(selector.select)
  if (user.email === authState.email) {
    return
  }

  try {
    const identityUser = yield* call(firebase.auth.getCurrentUser)
    if (identityUser) {
      return
    }
  } catch (error) {
    // token expired
  }

  yield* signOut()
}

export function* cachePostVerifyDestination(action: SetPostVerifyDestinationAction): SagaGenerator<void> {
  writePreference(POST_VERIFY_DESTINATION_PREFERENCE, action.payload.destination)
}
