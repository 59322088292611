export type CreateMomentResult = {
  createdOn: string
  id: string
  transcriptionAuthMethod?: string
  transcriptionEnterpriseCreds?: string
  transcriptionEnterpriseWssUrl?: string
  transcriptionUploadUrl?: string
  uploadUrl?: string
}

export enum MomentType {
  Audio = 'AUDIO',
  Desktop = 'DESKTOP',
  File = 'FILE',
  Image = 'IMAGE',
  Movie = 'MOVIE',
  Text = 'TEXT',
  Video = 'VIDEO',
}
export const STREAMING_MOMENT_TYPES = [
  MomentType.Audio,
  MomentType.Desktop,
  MomentType.Movie,
  MomentType.Video,
]
export enum MomentAccessLevel {
  Full = 'FULL',
  None = 'NONE',
  Potential = 'POTENTIAL',
  Super = 'SUPER',
  Visitor = 'VISITOR',
}
export enum MomentState {
  Cancelled = 'CANCELLED',
  Closing = 'CLOSING',
  Complete = 'COMPLETE',
  Deleted = 'DELETED',
  Empty = 'EMPTY',
  Failed = 'FAILED',
  Live = 'LIVE',
  Preparing = 'PREPARING',
  Stalled = 'STALLED',
}
export const VALID_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif']
export const VALID_MOVIE_TYPES = ['video/mp4', 'video/quicktime', 'video/webm']
type AudioMimeType = 'audio/mp4' | 'audio/webm'
type ImageMimeType = 'image/jpeg' | 'image/png' | 'image/gif'
type MovieMimeType = 'video/mp4' | 'video/quicktime' | 'video/webm'
export type KnownMimeType = AudioMimeType | ImageMimeType | MovieMimeType

export enum TextVolleyColor {
  Black = '#222222',
  Red = '#d10b0b',
  Orange = '#fe7400',
  Yellow = '#edb000',
  Green = '#1bb161',
  Blue = '#0047d8',
  Indigo = '#2e2f99',
  Purple = '#6e1faf',
}
type TextVolleyColors = { [name: string]: TextVolleyColor }
export const ALL_TEXT_VOLLEY_COLORS = Object.keys(TextVolleyColor)
  .reduce<TextVolleyColors>((acc, key) => {
    // filter out numeric enum keys
    if (!Number.isNaN(+key)) {
      return acc
    }

    return {
      ...acc,
      [key]: TextVolleyColor[key as keyof typeof TextVolleyColor]
    }
  }, {})

export type MomentsDictionary = { [id: string]: Moment }
export type MentionedUserId = {
  length: number
  startLocation: number
  userId: string
}
export type MomentTextContent = {
  backgroundHexColor: string
  text: string
}
export type MomentTextEdit = MomentTextContent & {
  mentionedUserIds: string[]
  replacedOn: string
}
export type MomentActivity = {
  lastViewedTimestamp: string | null
  reaction: string | null
  reactionTimestamp: string | null
  userId: string
}
export type UserMomentActivityDictionary = {
  [userId: string]: MomentActivity
}
export type Moment = {
  accessLevel: MomentAccessLevel
  bytes: number | null
  conversationId: string
  copyHistory: CopyHistory[]
  createdOn: string
  creatorUserId: string
  disableDelete: boolean
  disableDownloading: boolean
  disableForwarding: boolean
  disableSharing: boolean
  downloadEol: number | null
  downloadUrl: string | null
  duration: number
  expired: boolean
  feedUrls: string[] | null
  filename: string | null
  id: string
  isSeed: boolean
  isStale?: boolean
  mentionedUserIds: MentionedUserId[]
  mimeType: string | null
  name: string | null
  sendGigId: string | null
  sharingUrl: string | null
  source: string | null
  symlinkSourceId: string | null
  teamId: string
  textContent: MomentTextContent | null
  textEdits: MomentTextEdit[] | null
  textParagraphs?: MomentTextParagraphs
  threadId: string | null
  thumbUrl: string | null
  transcriptionData?: TranscriptionData
  transcription?: string
  transcriptionDownloadUrls: TranscriptionDownloadUrls
  userData: UserMomentActivityDictionary
  vState: MomentState
  vType: MomentType
}
export type CopyHistory = {
  conversationId: string
  createdOn: string
  creatorUserId: string
  momentId: string
  teamId: string
}

export type MomentLinkPreviewDictionary = { [link: string]: MomentLinkPreview }
export type LinkDictionary = { [linkKey: string]: string }
export type MomentLinkPreview = {
  conversationId?: string
  description?: string
  error?: string
  image?: {
    height: number
    url: string
    width: number
  }
  momentId?: string
  teamId?: string
  title?: string
  tokenId?: string
  type: 'internal' | 'photo' | 'rich' | 'unknown' | 'video'
  url: string
}
export type MomentTextParagraphs = {
  initialized: boolean
  paragraphs: MomentTextParagraph[]
  previews: MomentLinkPreviewDictionary
}
export type MomentTextParagraph = {
  text: string
  previews: MomentLinkPreview[]
  startLocation: number
}
export type TranscriptionPhrase = {
  endSeconds: number
  phrase: string
  startSeconds: number
}
export type TranscriptionData = {
  interactive: TranscriptionPhrase[]
  json: TranscriptionPhrase[]
}
export type TranscriptionDownloadUrls = {
  interactive?: string
  jsonPhrases?: string
  paragraphs?: string
  vttPhrases?: string
}
