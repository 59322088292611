import logger from 'modules/logger'
import * as gigsApi from 'modules/api/gigs'
import { call, put, SagaGenerator, select, takeEvery, takeLatest } from 'typed-redux-saga'
import { actions as alertActions } from '../alerts/slice'
import { actions as initializeActions } from '../initialize/slice'
import { selector as usersSelector } from '../users/slice'
import { actions, LaunchOnGigAction, UpdateGigAction } from './slice'

export default function*(): SagaGenerator<void> {
  yield* takeLatest(initializeActions.requestApp, fetchGigs)
  yield* takeLatest(actions.fetchGigs, fetchGigs)
  yield* takeEvery(actions.cancelGig, cancelGig)
  yield* takeEvery(actions.launchNow, launchNow)
  yield* takeEvery(actions.launchOn, launchOn)
}

export function* fetchGigs(): SagaGenerator<void> {
  const { currentUser } = yield* select(usersSelector.select)
  if (currentUser?.isVisitor) {
    yield* put(actions.fetchGigsFailure({ error: 'Skipping gigs for visitor user' }))
    return
  }

  if (!currentUser) {
    yield* put(actions.fetchGigsFailure({ error: 'Skipping gigs, no currentUser' }))
    return
  }

  try {
    const gigs = yield* call(gigsApi.getGigs, { ownerUserId: currentUser.id })
    yield* put(actions.fetchGigsSuccess(gigs))
  } catch (error) {
    logger.error('Failed to fetch gigs', error, { userId: currentUser?.id })
    yield* put(actions.fetchGigsFailure({ error: error.message }))
  }
}

export function* cancelGig(action: UpdateGigAction): SagaGenerator<void> {
  const { gigId } = action.payload

  try {
    const gig = yield* call(gigsApi.cancelGig, { gigId })
    yield* put(actions.cancelGigSuccess({ gig }))
    yield* put(alertActions.pushAlert({ message: 'Scheduled Volley successfully deleted', severity: 'success' }))
  } catch (error) {
    logger.error('Failed to cancel gig', error, { gigId })
    yield* put(actions.cancelGigFailure({ error: error.message }))
  }
}

export function* launchNow(action: UpdateGigAction): SagaGenerator<void> {
  const { gigId } = action.payload

  try {
    const gig = yield* call(gigsApi.updateGig, { gigId, launchNow: true })
    yield* put(actions.launchNowSuccess({ gig }))
    yield* put(alertActions.pushAlert({
      message: [
        'Volley sent successfully. ',
        { conversationId: gig.conversationId, teamId: gig.teamId, text: 'Go to conversation >' }],
      severity: 'success',
    }))
  } catch (error) {
    logger.error('Failed to launch gig', error, { gigId })
    yield* put(actions.launchNowFailure({ error: error.message }))
  }
}

export function* launchOn(action: LaunchOnGigAction): SagaGenerator<void> {
  const { gigId, launchOn } = action.payload

  try {
    const gig = yield* call(gigsApi.updateGig, { gigId, launchOn })
    yield* put(actions.launchOnSuccess({ gig }))
    yield* put(alertActions.pushAlert({ message: 'Volley scheduled successfully', severity: 'success' }))
  } catch (error) {
    logger.error('Failed to update launchOn for gig', error, { gigId })
    yield* put(actions.fetchGigsFailure({ error: error.message }))
  }
}
