import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PurchasesDictionary } from 'modules/types/purchases'
import { AppState } from '../reducers'

type IsWorkingDictionary = { [purchaseId: string]: boolean }
type IsErrorDictionary = { [purchaseId: string]: string }
export type PurchasesState = {
  createFailedId?: string
  fetchFailed: IsErrorDictionary
  initialized: boolean
  isCanceling: IsWorkingDictionary
  isFetching: IsWorkingDictionary
  purchases: PurchasesDictionary
}
const initialState: PurchasesState = {
  fetchFailed: {},
  initialized: false,
  isCanceling: {},
  isFetching: {},
  purchases: {},
}

const isWorkingReduceFn = (dict: IsWorkingDictionary, id: string) => {
  dict[id] = true
  return dict
}

export type FetchPurchasesAction = PayloadAction<{ ids: string[] } | undefined>
const fetchPurchases: CaseReducer<PurchasesState, FetchPurchasesAction> = (state, action) => {
  const fetchIds = action.payload?.ids ?? ['user']

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      ...fetchIds.reduce(isWorkingReduceFn, {}),
    },
  }
}
type FetchPurchasesFailureAction = PayloadAction<{ ids?: string[], reason: string }>
const fetchPurchasesFailure: CaseReducer<PurchasesState, FetchPurchasesFailureAction> = (state, action) => {
  const failedIds = action.payload?.ids ?? ['user']

  return {
    ...state,
    fetchFailed: {
      ...state.fetchFailed,
      ...failedIds.reduce<IsErrorDictionary>((dict, id) => {
        dict[id] = action.payload.reason
        return dict
      }, {}),
    },
    isFetching: Object.keys(state.isFetching)
      .filter(id => !failedIds.includes(id))
      .reduce(isWorkingReduceFn, {}),
  }
}
export type FetchPurchasesSuccessAction = PayloadAction<{ purchases: PurchasesDictionary, wasFetchingById: boolean }>
const fetchPurchasesSuccess: CaseReducer<PurchasesState, FetchPurchasesSuccessAction> = (state, action) => {
  const fetchIds = [...Object.keys(action.payload), ...(action.payload.wasFetchingById ? [] : ['user'])]

  return {
    ...state,
    fetchFailed: Object.entries(state.fetchFailed)
      .filter(([id]) => !fetchIds.includes(id))
      .reduce<IsErrorDictionary>((dict, [id, reason]) => {
        dict[id] = reason
        return dict
      }, {}),
    initialized: state.initialized || !action.payload.wasFetchingById,
    isFetching: Object.keys(state.isFetching)
      .filter(id => !fetchIds.includes(id))
      .reduce(isWorkingReduceFn, {}),
    purchases: {
      ...state.purchases,
      ...action.payload.purchases,
    },
  }
}

export type CancelPurchaseAction = PayloadAction<{ conversationId?: string, teamId?: string }>
const cancelPurchase: CaseReducer<PurchasesState, CancelPurchaseAction> = (state, action) => ({
  ...state,
  isCanceling: {
    ...state.isCanceling,
    [action.payload.conversationId ?? action.payload.teamId ?? '(empty)']: true,
  },
})
type CancelPurchaseResultAction = PayloadAction<{ id: string }>
const cancelPurchaseFailure: CaseReducer<PurchasesState, CancelPurchaseResultAction> = (state, action) => ({
  ...state,
  isCanceling: Object.keys(state.isCanceling)
    .filter(id => id !== action.payload.id)
    .reduce(isWorkingReduceFn, {}),
})
const cancelPurchaseSuccess: CaseReducer<PurchasesState, CancelPurchaseResultAction> = (state, action) => ({
  ...state,
  isCanceling: Object.keys(state.isCanceling)
    .filter(id => id !== action.payload.id)
    .reduce(isWorkingReduceFn, {}),
  purchases: Object.entries(state.purchases)
    .filter(([id]) => id !== action.payload.id)
    .reduce<PurchasesDictionary>((acc, [id, purchase]) => {
      acc[id] = purchase

      return acc
    }, {}),
})

const purchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    cancelPurchase,
    cancelPurchaseFailure,
    cancelPurchaseSuccess,

    fetchPurchases,
    fetchPurchasesFailure,
    fetchPurchasesSuccess,
  },
})

export const actions = purchasesSlice.actions
export const selector = {
  name: purchasesSlice.name,
  select: (state: AppState): PurchasesState => state.purchases,
}
export default purchasesSlice.reducer
