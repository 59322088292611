import gql from 'graphql-tag'
import getClientInfo from 'modules/clientInfo'
import {
  ActiveNow,
  Conversation,
  ConversationsDictionary,
  ConversationType,
  MomentThreadsDictionary,
  UserDataDictionary,
} from 'modules/types/conversations'
import { Moment, MomentState, MomentType } from 'modules/types/moments'
import { buildMutation, checkForErrors, createMutationMap, mutateGraphQl } from './graph-utils'
import {
  deserializeMoment,
  deserializeOffer,
  deserializePurchase,
  deserializeTierLimits,
  Vol_Moment,
  Vol_Offering,
  Vol_Purchase,
  Vol_TierLimits,
} from './shared'
import { BatchResult, deserializeBatchResults } from './util'

type GetConversationVolParams = {
  clientInfo: string
  ids: string[]
}
type GetConversationVolWithMomentsParams = {
  clientInfo: string
  id: string
  limit?: number
  momentsBeforeTimestamp: string | null
  onlyFields: string[]
}
type GetConversationVolWithBillingUrlParams = {
  clientInfo: string
  id: string
  onlyFields: string[]
}
type GetConversationVolResult = { getConversationVol: string | null, error?: string }
type GetConversationVol_ConversationDictionary = { [id: string]: GetConversationVol_Conversation }
type GetConversationVol_Conversation = {
  id: string
  activeNow: GetConversationVol_ActiveNow[]
  adminIDs: string[]
  allowedPosterIDs?: string[]
  billingPortalURL?: string
  createdOn: string
  creatorUserID: string
  description: string
  extendedAt: string
  guestIDs: string[] | null
  hasMoments?: boolean
  hideThreadsTab: boolean
  isAdhoc: boolean
  isAutoJoin?: boolean
  isDepleted?: boolean
  isEveryoneChannel: boolean
  isPremiumActive?: boolean
  isThumbStatic: boolean
  isWelcome: boolean
  // TODO: deprecated
  momentCount: number
  momentsExpireBefore: string
  momentThreads: GetConversationVol_MomentThreadsDictionary
  name: string | null
  offering?: Vol_Offering,
  parentConversationID?: string | null
  parentMomentID?: string | null
  preventDeletion: boolean
  preventThreading?: boolean
  preventUsersLeaving: boolean
  purchases?: Vol_Purchase[]
  recentlyCompletedMomentIDs?: GetConversationVol_RecentlyUpdatedMoments[]
  recentlyDeletedMomentIDs?: GetConversationVol_RecentlyUpdatedMoments[]
  restrictPosting?: boolean
  sortedOfferingIDs?: string[]
  subname?: string
  teamID: string
  tierLimits: Vol_TierLimits
  totalPremiumMinutesPurchased?: number
  totalPremiumMinutesRecorded?: number
  thumbEmoji?: string
  thumbUpdatedOn?: string
  thumbURL?: string
  userData?: GetConversationVol_UserData
  userIDs: string[] | null
  vType: ConversationType
  wipeNameOnJoin: boolean
}
type GetConversationVol_ActiveNow = {
  momentID: string
  createdOn: string
  updatedAt: string
  vType: MomentType
  vState: MomentState
  userID: string
}
type GetConversationVol_MomentThreadsDictionary = { [momentId: string]: GetConversationVol_MomentThread }
type GetConversationVol_MomentThread = {
  momentCount: number
  newestMomentTS: string
  threadID: string
  unviewedCount: number
  userIsMember: boolean
}
type GetConversationVol_RecentlyUpdatedMoments = {
  createdOn: string
  momentID: string
  updatedOn: string
}
type GetConversationVol_UserData = {
  [userId: string]: GetConversationVol_UserData_Entry
}
type GetConversationVol_UserData_Entry = {
  nvmID?: string | null
  nvmTS?: string | null
}

type GetConversationVol_FullMoments = {
  id: string
  fullMoments: Vol_Moment[]
  momentsExpireBefore: string
}
type ConversationMoments = {
  canLoadMore: boolean
  conversationId: string
  moments: Moment[]
}

const GetConversationVolWithFullMoments = gql`
  mutation GetConversationVol(
      $clientInfo: String
      $id: ID!
      $limit: Int!
      $momentsBeforeTimestamp: AWSDateTime
      $onlyFields: [String]
    ) {
    getConversationVol(
      clientInfo: $clientInfo
      conversationID: $id
      getFullMoments: $limit
      getRecentMoments: false
      momentsBeforeTS: $momentsBeforeTimestamp
      onlyFields: $onlyFields
    )
  }
`
export async function getMomentsForConversation(id: string, momentsBeforeTimestamp?: string, fetchLimit?: number): Promise<ConversationMoments | null> {
  const limit = fetchLimit ?? 20

  const result = await mutateGraphQl<GetConversationVolResult, GetConversationVolWithMomentsParams>({
    mutation: GetConversationVolWithFullMoments,
    variables: {
      clientInfo: await getClientInfo(),
      id,
      limit,
      momentsBeforeTimestamp: momentsBeforeTimestamp ?? null,
      onlyFields: ['fullMoments', 'id'],
    }
  })

  checkForErrors('getConversationVol', result, true)
  return result.data?.getConversationVol
    ? deserializeConversationMoments(JSON.parse(result.data.getConversationVol), limit)
    : null
}

function deserializeConversationMoments(result: GetConversationVol_FullMoments, limit: number): ConversationMoments {
  const moments = deserializeFullMoments(result.fullMoments)

  return {
    canLoadMore: !moments.some(m => m.expired) && moments.length === limit,
    conversationId: result.id,
    moments,
  }
}

function deserializeFullMoments(moments?: Vol_Moment[]): Moment[] {
  if (!moments) {
    return []
  }

  return moments.map(m => deserializeMoment(m))
}

const GetConversationVolWithBillingUrl = gql`
  mutation GetConversationVol(
      $clientInfo: String
      $id: ID!
      $onlyFields: [String]
    ) {
    getConversationVol(
      clientInfo: $clientInfo
      conversationID: $id
      getBillingPortalURL: true
      getRecentMoments: false
      onlyFields: $onlyFields
    )
  }
`
export async function getConversation(id: string, getBillingUrl?: boolean): Promise<Conversation | null> {
  if (getBillingUrl) {
    const clientInfo = await getClientInfo()
    const result = await mutateGraphQl<GetConversationVolResult, GetConversationVolWithBillingUrlParams>({
      mutation: GetConversationVolWithBillingUrl,
      variables: { clientInfo, id, onlyFields: ['billingPortalURL', 'id'] },
    })

    checkForErrors('getConversationVol', result, true)
    return result.data?.getConversationVol
      ? deserializeConversation(JSON.parse(result.data.getConversationVol))
      : null
  }

  const results = await getConversations([id])
  if (results.refetchIds.length) {
    throw new Error(`Failed to get conversation ${id}`)
  }

  return !results.missingIds.length
    ? results.success[id]
    : null
}

const GetConversationVol = gql`
  mutation GetConversationVol($clientInfo: String, $ids: [ID]!) {
    getConversationVol(
      clientInfo: $clientInfo
      conversationIDs: $ids
      getRecentMoments: false
    )
  }
`
export async function getConversations(ids: string[]): Promise<BatchResult<Conversation>> {
  const clientInfo = await getClientInfo()
  const results = await mutateGraphQl<GetConversationVolResult, GetConversationVolParams>({
    mutation: GetConversationVol,
    variables: { clientInfo, ids }
  })

  checkForErrors('getConversationVol', results, true)
  return deserializeBatchResults(results.data?.getConversationVol, deserializeConversations)
}

type UpdateConversationVolResult = { updateConversationVol: string, error?: string }
type UpdateConversationVolParams = {
  addAdminIds?: string[]
  addPosterIds?: string[]
  addUserIds?: string[]
  clientInfo: string
  conversationId: string
  description?: string
  isAutoJoin?: boolean
  name?: string | null
  preventDeletion?: boolean
  preventThreading?: boolean
  preventUsersLeaving?: boolean
  removePosterIds?: string[]
  removeUserIds?: string[]
  restrictPosting?: boolean
  sortedOfferIds?: string[]
  thumbB64?: string | null
  thumbEmoji?: string | null
  vType?: ConversationType
}

const UpdateConversationVolMutation = createMutationMap('updateConversationVol', {
  addAdminIds: { backendKey: 'addAdminIDs', type: '[ID]' },
  addPosterIds: { backendKey: 'addPosterIDs', type: '[ID]' },
  addUserIds: { backendKey: 'addUserIDs', type: '[ID]' },
  clientInfo: 'String',
  conversationId: { backendKey: 'conversationID', type: 'ID!' },
  description: 'String',
  isAutoJoin: 'Boolean',
  name: 'String',
  preventDeletion: 'Boolean',
  preventThreading: 'Boolean',
  preventUsersLeaving: 'Boolean',
  removePosterIds: { backendKey: 'removePosterIDs', type: '[ID]' },
  removeUserIds: { backendKey: 'removeUserIDs', type: '[ID]' },
  restrictPosting: 'Boolean',
  sortedOfferIds: { backendKey: 'sortedOfferingIDs', type: '[ID]' },
  thumbB64: 'String',
  thumbEmoji: 'String',
  vType: 'ConversationType',
})
export type UpdateConversationParams = Omit<UpdateConversationVolParams, 'clientInfo'>
export async function updateConversation(params: UpdateConversationParams): Promise<void> {
  const clientInfo = await getClientInfo()
  const variables = { ...params, clientInfo }
  const res = await mutateGraphQl<UpdateConversationVolResult, UpdateConversationVolParams>({
    mutation: buildMutation(variables, UpdateConversationVolMutation),
    variables
  })

  UpdateConversationVolMutation.checkForErrors(res)
}

type CreateConversationVolResult = { createConversationVol: string, error?: string }
type CreateConversationVolParams = {
  addPosterIds?: string[]
  addUserIds?: string[]
  clientInfo: string
  description?: string
  isAutoJoin?: boolean
  name?: string
  parentMomentId?: string
  preventDeletion?: boolean
  preventThreading?: boolean
  preventUsersLeaving?: boolean
  restrictPosting?: boolean
  teamId: string
  thumbB64?: string | null
  thumbEmoji?: string | null
  vType: ConversationType
  wipeNameOnJoin?: boolean
}
const CreateConversationVolMutation = gql`
  mutation CreateConversationVol(
    $addPosterIds: [ID]
    $addUserIds: [ID]
    $clientInfo: String
    $description: String
    $isAutoJoin: Boolean
    $name: String
    $parentMomentId: ID
    $preventDeletion: Boolean
    $preventThreading: Boolean
    $preventUsersLeaving: Boolean
    $restrictPosting: Boolean
    $teamId: ID!
    $thumbB64: String
    $thumbEmoji: String
    $vType: ConversationType!
    $wipeNameOnJoin: Boolean
  ) {
    createConversationVol(
      addPosterIDs: $addPosterIds
      addUserIDs: $addUserIds
      clientInfo: $clientInfo
      description: $description
      isAutoJoin: $isAutoJoin
      name: $name
      parentMomentID: $parentMomentId
      preventDeletion: $preventDeletion
      preventThreading: $preventThreading
      preventUsersLeaving: $preventUsersLeaving
      restrictPosting: $restrictPosting
      teamID: $teamId
      thumbB64: $thumbB64
      thumbEmoji: $thumbEmoji
      vType: $vType
      wipeNameOnJoin: $wipeNameOnJoin
    )
  }
`
export type CreateConversationParams = Omit<CreateConversationVolParams, 'clientInfo'>
export async function createConversation(params: CreateConversationParams): Promise<Conversation> {
  const clientInfo = await getClientInfo()
  const res = await mutateGraphQl<CreateConversationVolResult, CreateConversationVolParams>({
    mutation: CreateConversationVolMutation,
    variables: { ...params, clientInfo }
  })

  checkForErrors('createConversationVol', res, true)
  const parsed: GetConversationVol_Conversation = JSON.parse(res.data?.createConversationVol ?? '{}')
  return deserializeConversation(parsed)
}

type DeleteConversationVolResult = {
  deleteConversationVol: string | null
  error?: string
}
type DeleteConversationVolParams = {
  clientInfo: string
  conversationId: string
}
const DeleteConversationVolMutation = gql`
  mutation DeleteConversationVol($conversationId: ID!) {
    deleteConversationVol(conversationID: $conversationId)
  }
`
export async function deleteConversation(conversationId: string): Promise<void> {
  const clientInfo = await getClientInfo()
  const res = await mutateGraphQl<DeleteConversationVolResult, DeleteConversationVolParams>({
    mutation: DeleteConversationVolMutation,
    variables: { clientInfo, conversationId }
  })
  checkForErrors('deleteConversationVol', res, true)
}

function deserializeConversations(conversations: GetConversationVol_ConversationDictionary): ConversationsDictionary {
  return Object.entries(conversations).reduce<ConversationsDictionary>((dict, [id, conversation]) => {
    dict[id] = deserializeConversation(conversation)

    return dict
  }, {})
}

function deserializeConversation(conversation: GetConversationVol_Conversation): Conversation {
  return {
    id: conversation.id,
    activeNow: deserializeActiveNow(conversation.activeNow),
    adminIds: conversation.adminIDs ?? [],
    allowedPosterIds: conversation.allowedPosterIDs ?? [],
    billingUrl: conversation.billingPortalURL,
    createdOn: conversation.createdOn,
    creatorUserId: conversation.creatorUserID,
    description: conversation.description,
    extendedAt: conversation.extendedAt,
    guestIds: conversation.guestIDs || [],
    hideThreadsTab: conversation.hideThreadsTab ?? false,
    isAdhoc: conversation.isAdhoc,
    isAutoJoin: conversation.isAutoJoin ?? false,
    isDepleted: conversation.isDepleted ?? false,
    isEveryoneChannel: conversation.isEveryoneChannel,
    isPremiumActive: conversation.isPremiumActive ?? false,
    isThumbStatic: conversation.isThumbStatic,
    isWelcome: conversation.isWelcome,
    hasMoments: !!conversation.hasMoments || conversation.momentCount > 0,
    momentsExpireBefore: conversation.momentsExpireBefore,
    momentThreads: deserializeMomentThreads(conversation.momentThreads),
    name: conversation.name || '',
    offer: conversation.offering ? deserializeOffer(conversation.offering) : null,
    parentConversationId: conversation.parentConversationID ?? null,
    parentMomentId: conversation.parentMomentID ?? null,
    preventDeletion: conversation.preventDeletion,
    preventThreading: !!conversation.preventThreading,
    preventUsersLeaving: conversation.preventUsersLeaving,
    purchases: conversation.purchases ? conversation.purchases.map(p => deserializePurchase(p)) : [],
    recentlyCompletedMomentIds: deserializeRecentlyUpdatedMoments(conversation.recentlyCompletedMomentIDs),
    recentlyDeletedMomentIds: deserializeRecentlyUpdatedMoments(conversation.recentlyDeletedMomentIDs),
    restrictPosting: !!conversation.restrictPosting,
    sortedOfferIds: conversation.sortedOfferingIDs,
    subName: conversation.subname ?? null,
    teamId: conversation.teamID,
    tierLimits: deserializeTierLimits(conversation.tierLimits),
    totalPremiumSecondsPurchased: conversation.totalPremiumMinutesPurchased
      ? Math.ceil((conversation.totalPremiumMinutesPurchased || 0) * 60)
      : null,
    totalPremiumSecondsRecorded: Math.floor((conversation.totalPremiumMinutesRecorded || 0) * 60),
    thumbEmoji: conversation.thumbEmoji ?? null,
    thumbUpdatedOn: conversation.thumbUpdatedOn ?? null,
    thumbUrl: conversation.thumbURL ?? null,
    userData: deserializeUserData(conversation.userData),
    userIds: conversation.userIDs || [],
    vType: conversation.vType,
    wipeNameOnJoin: conversation.wipeNameOnJoin,
  }
}

function deserializeActiveNow(activeNow: GetConversationVol_ActiveNow[]): ActiveNow[] {
  if (!activeNow) {
    return []
  }

  return activeNow.map(a => ({
    momentId: a.momentID,
    createdOn: a.createdOn,
    updatedAt: a.updatedAt,
    vType: a.vType,
    vState: a.vState,
    userId: a.userID,
  }))
}

function deserializeMomentThreads(momentThreads: GetConversationVol_MomentThreadsDictionary): MomentThreadsDictionary {
  if (!momentThreads) {
    return {}
  }

  return Object.entries(momentThreads).reduce<MomentThreadsDictionary>((dict, [id, thread]) => {
    dict[id] = {
      momentId: id,
      momentCount: thread.momentCount,
      newestMomentTimestamp: thread.newestMomentTS,
      threadId: thread.threadID,
      unviewedCount: thread.unviewedCount,
      userIsMember: thread.userIsMember,
    }

    return dict
  }, {})
}

function deserializeRecentlyUpdatedMoments(momentIds?: GetConversationVol_RecentlyUpdatedMoments[]): string[] {
  if (!momentIds) {
    return []
  }

  return momentIds.map(({ momentID }) => momentID)
}

function deserializeUserData(userData?: GetConversationVol_UserData): UserDataDictionary {
  if (!userData) {
    return {}
  }

  return Object.entries(userData).reduce<UserDataDictionary>((dict, [userId, data]) => {
    dict[userId] = {
      newestViewedMomentId: data.nvmID ?? null,
      newestViewedMomentTimestamp: data.nvmTS ?? null,
    }

    return dict
  }, {})
}
