import { combineReducers } from '@reduxjs/toolkit'
import alerts from './alerts/slice'
import analytics from './analytics/slice'
import application from './application/slice'
import authentication from './authentication/slice'
import billings from './billings/slice'
import conversations from './conversations/slice'
import deviceSettings from './device-settings/slice'
import gigs from './gigs/slice'
import features from './features/slice'
import initialize from './initialize/slice'
import integrations from './integrations/slice'
import modals from './modals/slice'
import moments from './moments/slice'
import notifications from './notifications/slice'
import offers from './offers/slice'
import purchases from './purchases/slice'
import search from './search/slice'
import streamUploads from './stream-uploads/slice'
import subscriptions from './subscriptions/slice'
import teams from './teams/slice'
import texts from './texts/slice'
import tokens from './tokens/slice'
import users from './users/slice'

export const appReducer = combineReducers({
  alerts,
  analytics,
  application,
  authentication,
  billings,
  conversations,
  deviceSettings,
  features,
  gigs,
  initialize,
  integrations,
  modals,
  moments,
  notifications,
  offers,
  purchases,
  search,
  streamUploads,
  subscriptions,
  teams,
  texts,
  tokens,
  users,
})

export type AppState = ReturnType<typeof appReducer>
export default appReducer
