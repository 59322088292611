import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Fade from '@mui/material/Fade'
import MuiPopover, { PopoverProps as MuiPopoverProps, PopoverOrigin as MuiPopoverOrigin } from '@mui/material/Popover'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Arrow, { ARROW_SIZE } from './Arrow'

const MARGIN_THRESHOLD = 8
const BACKDROP_CLICK = 'backdropClick'

export type Placement = 'top' | 'right' | 'bottom'

type VolPopoverProps = {
  backgroundColor?: string
  borderColor?: string
  borderRadius?: number
  enableBackdropClickThrough?: boolean
}
const VolPopover = styled(MuiPopover, { shouldForwardProp: propName => propName !== 'backgroundColor' && propName !== 'borderColor' && propName !== 'borderRadius' && propName !== 'enableBackdropClickThrough' }) <VolPopoverProps>`
  ${({ enableBackdropClickThrough }) => enableBackdropClickThrough
    ? css`pointerEvents: none;`
    : undefined}

  & .MuiPopover-paper {
    background-color: ${({ backgroundColor, theme }) => backgroundColor ?? theme.palette.background.popover};
    border: 1px solid ${({ borderColor, theme }) => borderColor ?? theme.palette.popoverDivider};
    border-radius: ${({ borderRadius }) => borderRadius ?? '7px'};
    overflow-x: unset;
    overflow-y: unset;
  }
`

type AnchorPositioning = {
  rect: AnchorRect
  position: AnchorPosition
}

export type PopoverProps = Omit<MuiPopoverProps, 'anchorEl' | 'marginThreshold' | 'open'> & {
  anchorEl?: null | Element | (() => Element)
  backgroundColor?: string
  borderColor?: string
  borderRadius?: number
  children: React.ReactNode
  disableBackdropClick?: boolean
  enableBackdropClickThrough?: boolean
  hideArrow?: boolean
  isOpen: boolean
  offsetX?: number
  placement?: Placement
  // mostly for static popovers, only use if needed as it involves expensive polling
  repositionOnAnchorReposition?: boolean
}
const Popover = (props: PopoverProps): React.ReactElement => {
  const {
    anchorEl,
    backgroundColor,
    borderColor,
    borderRadius,
    children,
    disableBackdropClick,
    enableBackdropClickThrough,
    hideArrow,
    isOpen,
    offsetX = 0,
    onClose,
    placement = 'top',
    repositionOnAnchorReposition,
    ...rest
  } = props

  const [positioning, setPositioning] = useState<AnchorPositioning>({
    rect: { top: 0, left: 0, right: 0, width: 0, height: 0 },
    position: { top: 0, left: offsetX ?? 0 }
  })
  const anchor = useMemo(() => typeof anchorEl === 'function' ? anchorEl() : anchorEl, [anchorEl])
  const handleClose = useCallback((e, reason) => {
    if (!onClose || (disableBackdropClick && reason === BACKDROP_CLICK)) {
      return
    }

    onClose(e, reason)
  }, [disableBackdropClick, onClose])

  useEffect(() => {
    if (!anchor) {
      return
    }

    const rect = anchor.getBoundingClientRect()
    const position = getAnchorPosition(rect, placement, offsetX)
    setPositioning({ rect, position })

    if (!repositionOnAnchorReposition) {
      return
    }

    const interval = setInterval(() => {
      const newRect = anchor.getBoundingClientRect()
      const hasRepositioned = positioning.rect.top !== newRect.top
        || positioning.rect.right !== newRect.right
        || positioning.rect.left !== newRect.left
        || positioning.rect.height !== newRect.height
        || positioning.rect.width !== newRect.width

      if (hasRepositioned) {
        setPositioning(_ => ({ rect: newRect, position: getAnchorPosition(newRect, placement, offsetX) }))
      }
    }, 100)
    return () => clearInterval(interval)
  }, [
    anchor,
    offsetX,
    placement,
    positioning.rect.height,
    positioning.rect.left,
    positioning.rect.right,
    positioning.rect.top,
    positioning.rect.width,
    repositionOnAnchorReposition,
  ])

  return (
    <VolPopover
      anchorPosition={positioning.position}
      anchorReference="anchorPosition"
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      enableBackdropClickThrough={enableBackdropClickThrough}
      marginThreshold={MARGIN_THRESHOLD}
      onClose={handleClose}
      open={isOpen}
      transformOrigin={getTransformOrigin(placement)}
      TransitionComponent={Fade}
      {...rest}
    >
      {!!anchor && !hideArrow && (
        <Arrow
          anchorRect={positioning.rect}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          placement={placement}
        />
      )}
      {children}
    </VolPopover>
  )
}

const HALF_ARROW_SIZE = ARROW_SIZE / 2

type AnchorRect = {
  top: number
  left: number
  right: number
  width: number
  height: number
}
type AnchorPosition = {
  top: number
  left: number
}
function getAnchorPosition(anchorRect: AnchorRect, placement: Placement, offsetX: number): AnchorPosition {
  if (placement === 'bottom') {
    return {
      top: anchorRect.top + anchorRect.height + HALF_ARROW_SIZE,
      left: anchorRect.left + (anchorRect.width / 2) + offsetX,
    }
  }
  if (placement === 'right') {
    return {
      top: anchorRect.top + (anchorRect.height / 2),
      left: anchorRect.right + HALF_ARROW_SIZE + offsetX,
    }
  }
  return {
    top: anchorRect.top - HALF_ARROW_SIZE,
    left: anchorRect.left + (anchorRect.width / 2) + offsetX,
  }
}

function getTransformOrigin(placement: Placement): MuiPopoverOrigin {
  if (placement === 'bottom')
    return { vertical: 'top', horizontal: 'center' }
  if (placement === 'right')
    return { vertical: 'center', horizontal: 'left' }

  return { vertical: 'bottom', horizontal: 'center' }
}

export default Popover
