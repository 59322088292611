import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { DesktopConfigData, DesktopFeatureId, Feature, FeaturesDictionary, FeatureId } from 'modules/types/features'
import { selector } from './slice'

export function useFeature<T = unknown>(featureId: FeatureId): Feature<T> {
  const { features } = useSelector(selector.select)
  return useMemo(() => features[featureId] as Feature<T>, [featureId, features])
}

export function useDesktopFeature(desktopFeatureId: DesktopFeatureId): boolean {
  const desktopConfigFeature = useFeature<DesktopConfigData>(FeatureId.DesktopConfig)
  return desktopConfigFeature.data.features[desktopFeatureId]
}

class Features {
  private features: FeaturesDictionary = {}
  get<T = unknown>(id: FeatureId): Feature<T> {
    return this.features[id] as Feature<T>
  }
  add<T = unknown>(feature: Feature<T>): void {
    this.features[feature.id] = feature
  }
}

const instance = new Features()

export default instance
