import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uniq } from 'lodash-es'
import { RefreshDownloadUrlResult } from 'modules/api/moments'
import {
  MomentLinkPreviewDictionary,
  MomentsDictionary,
  MomentTextParagraph,
  MomentType,
  TranscriptionData,
} from 'modules/types/moments'
import {
  actions as conversationsActions,
  FetchMomentsForConversationSuccessAction,
  UpdateConversationSuccessAction,
} from '../conversations/slice'
import { AppState } from '../reducers'
import { getPlaceholderParagraphs } from './utils'

type IsWorkingDictionary = { [momentId: string]: boolean }
export type FetchFailedDictionary = { [momentId: string]: string }
type MomentsState = {
  fetchMomentsFailed: FetchFailedDictionary
  fetchTranscriptionsFailed: FetchFailedDictionary
  hasRequestedNewUrl: IsWorkingDictionary
  inViewStaticMoments: string[]
  isDeleting: IsWorkingDictionary
  isFetching: IsWorkingDictionary
  isFetchingLinkPreviews: IsWorkingDictionary
  isFetchingTranscriptions: IsWorkingDictionary
  isForwarding: IsWorkingDictionary
  moments: MomentsDictionary
}
export const initialState: MomentsState = {
  fetchMomentsFailed: {},
  fetchTranscriptionsFailed: {},
  hasRequestedNewUrl: {},
  inViewStaticMoments: [],
  isDeleting: {},
  isFetching: {},
  isFetchingLinkPreviews: {},
  isFetchingTranscriptions: {},
  isForwarding: {},
  moments: {},
}

type DeleteMomentPayload = {
  conversationId: string
  momentId: string
  selectNextMoment?: boolean
  type: MomentType
}
export type DeleteMomentAction = PayloadAction<DeleteMomentPayload>
const deleteMoment: CaseReducer<MomentsState, DeleteMomentAction> = (state, action) => ({
  ...state,
  inViewStaticMoments: state.inViewStaticMoments.filter(id => id !== action.payload.momentId),
  isDeleting: {
    ...state.isDeleting,
    [action.payload.momentId]: true,
  },
})
export type DeleteMomentSuccessAction = PayloadAction<{ conversationId: string, momentId: string, type: MomentType }>
const deleteMomentSuccess: CaseReducer<MomentsState, DeleteMomentSuccessAction> = (state, action) => ({
  ...state,
  isDeleting: Object.keys(state.isDeleting)
    .filter(id => action.payload.momentId !== id)
    .reduce(isWorkingReduceFn, {}),
  moments: Object.entries(state.moments).reduce<MomentsDictionary>((moments, [id, moment]) => {
    if (id !== action.payload.momentId)
      moments[id] = moment
    return moments
  }, {}),
})

const isWorkingReduceFn = (dict: IsWorkingDictionary, id: string) => {
  dict[id] = true
  return dict
}

export type FetchMomentsAction = PayloadAction<{ ids: string[] }>
const fetchMoments: CaseReducer<MomentsState, FetchMomentsAction> = (state, action) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    ...action.payload.ids.reduce(isWorkingReduceFn, {})
  },
})
export type FetchMomentsSuccessAction = PayloadAction<{ moments: MomentsDictionary }>
const fetchMomentsSuccess: CaseReducer<MomentsState, FetchMomentsSuccessAction> = (state, action) => ({
  ...state,
  fetchMomentsFailed: Object.entries(state.fetchMomentsFailed)
    .filter(([id]) => !action.payload.moments[id])
    .reduce<FetchFailedDictionary>((dict, [id, reason]) => {
      dict[id] = reason
      return dict
    }, {}),
  isFetching: Object.keys(state.isFetching)
    .filter(id => !action.payload.moments[id])
    .reduce(isWorkingReduceFn, {}),
  moments: {
    ...state.moments,
    ...Object.entries(action.payload.moments).reduce<MomentsDictionary>((acc, [momentId, moment]) => {
      acc[momentId] = {
        ...moment,
        textParagraphs: getPlaceholderParagraphs(moment.textContent?.text),
      }

      return acc
    }, {}),
  },
})

export type FetchMomentsFailureAction = PayloadAction<{ ids: string[], reason: string }>
const fetchMomentsFailure: CaseReducer<MomentsState, FetchMomentsFailureAction> = (state, action) => ({
  ...state,
  fetchMomentsFailed: {
    ...state.fetchMomentsFailed,
    ...action.payload.ids.reduce<FetchFailedDictionary>((dict, id) => {
      dict[id] = action.payload.reason
      return dict
    }, {})
  },
  isFetching: Object.keys(state.isFetching)
    .filter(id => !action.payload.ids.includes(id))
    .reduce(isWorkingReduceFn, {}),
})

export type FetchLinkPreviewsAction = PayloadAction<{ momentId: string }>
const fetchLinkPreviews: CaseReducer<MomentsState, FetchLinkPreviewsAction> = (state, action) => ({
  ...state,
  isFetchingLinkPreviews: {
    ...state.isFetchingLinkPreviews,
    [action.payload.momentId]: true,
  },
})
type FetchLinkPreviewsSuccessPayload = {
  momentId: string
  paragraphs: MomentTextParagraph[]
  previews: MomentLinkPreviewDictionary
}
export type FetchLinkPreviewsSuccessAction = PayloadAction<FetchLinkPreviewsSuccessPayload>
const fetchLinkPreviewsSuccess: CaseReducer<MomentsState, FetchLinkPreviewsSuccessAction> = (state, action) => {
  const moment = state.moments[action.payload.momentId]

  return {
    ...state,
    isFetchingLinkPreviews: Object.keys(state.isFetchingLinkPreviews)
      .filter(id => id !== action.payload.momentId)
      .reduce(isWorkingReduceFn, {}),
    moments: {
      ...state.moments,
      [moment.id]: {
        ...moment,
        textParagraphs: {
          initialized: true,
          paragraphs: action.payload.paragraphs,
          previews: action.payload.previews,
        },
      },
    },
  }
}
export type FetchLinkPreviewsFailureAction = PayloadAction<{ momentId: string, reason: string }>
const fetchLinkPreviewsFailure: CaseReducer<MomentsState, FetchLinkPreviewsFailureAction> = (state, action) => {
  const moment = state.moments[action.payload.momentId]

  return {
    ...state,
    isFetchingLinkPreviews: Object.keys(state.isFetchingLinkPreviews)
      .filter(id => id !== action.payload.momentId)
      .reduce(isWorkingReduceFn, {}),
    moments: {
      ...state.moments,
      [moment.id]: {
        ...moment,
        textParagraphs: {
          initialized: true,
          paragraphs: moment.textParagraphs?.paragraphs ?? [],
          previews: moment.textParagraphs?.previews ?? {},
        },
      },
    },
  }
}

export type FetchTranscriptionsAction = PayloadAction<{ momentId: string }>
const fetchTranscriptions: CaseReducer<MomentsState, FetchTranscriptionsAction> = (state, action) => ({
  ...state,
  isFetchingTranscriptions: {
    ...state.isFetchingTranscriptions,
    [action.payload.momentId]: true,
  },
})
export type FetchTranscriptionsSuccessAction = PayloadAction<{ momentId: string, transcriptionData: TranscriptionData }>
const fetchTranscriptionsSuccess: CaseReducer<MomentsState, FetchTranscriptionsSuccessAction> = (state, action) => {
  const moment = state.moments[action.payload.momentId]

  return {
    ...state,
    fetchTranscriptionsFailed: Object.entries(state.fetchTranscriptionsFailed)
      .filter(([id]) => id !== action.payload.momentId)
      .reduce<FetchFailedDictionary>((dict, [id, reason]) => {
        dict[id] = reason
        return dict
      }, {}),
    isFetchingTranscriptions: Object.keys(state.isFetchingTranscriptions)
      .filter(id => id !== action.payload.momentId)
      .reduce(isWorkingReduceFn, {}),
    moments: {
      ...state.moments,
      [moment.id]: { ...moment, transcriptionData: action.payload.transcriptionData },
    },
  }
}
export type FetchTranscriptionsFailureAction = PayloadAction<{ momentId: string, reason: string }>
const fetchTranscriptionsFailure: CaseReducer<MomentsState, FetchTranscriptionsFailureAction> = (state, action) => ({
  ...state,
  fetchTranscriptionsFailed: {
    ...state.fetchTranscriptionsFailed,
    [action.payload.momentId]: action.payload.reason
  },
  isFetchingTranscriptions: Object.keys(state.isFetchingTranscriptions)
    .filter(id => id !== action.payload.momentId)
    .reduce(isWorkingReduceFn, {}),
})

type ForwardMomentsPayload = {
  conversationId: string
  conversationName?: string
  momentIds: string[]
  teamId: string
}
export type ForwardMomentsAction = PayloadAction<ForwardMomentsPayload>
const forwardMoments: CaseReducer<MomentsState, ForwardMomentsAction> = (state, action) => ({
  ...state,
  isForwarding: {
    ...state.isForwarding,
    ...action.payload.momentIds.reduce<IsWorkingDictionary>(isWorkingReduceFn, {}),
  },
})
const clearForwardingMoments: CaseReducer<MomentsState, PayloadAction<{ momentIds: string[] }>> = (state, action) => ({
  ...state,
  isForwarding: Object.keys(state.isForwarding)
    .filter(id => !action.payload.momentIds.includes(id))
    .reduce(isWorkingReduceFn, {})
})

export type StartViewingMomentAction = PayloadAction<{ conversationId: string, momentId: string, userId: string }>
const startViewingMoment: CaseReducer<MomentsState, StartViewingMomentAction> = (state, action) => {
  const moment = state.moments[action.payload.momentId]
  if (!moment) {
    return state
  }

  const updatedMoment = {
    ...moment,
    userData: {
      ...moment.userData,
      [action.payload.userId]: {
        ...moment.userData[action.payload.userId],
        lastViewedTimestamp: new Date().toISOString(),
      },
    },
  }

  return {
    ...state,
    moments: {
      ...state.moments,
      [action.payload.momentId]: updatedMoment,
    },
  }
}

export type AddInViewStaticMomentAction = PayloadAction<{ momentId: string }>
const addInViewStaticMoment: CaseReducer<MomentsState, AddInViewStaticMomentAction> = (state, action) => ({
  ...state,
  inViewStaticMoments: uniq([...state.inViewStaticMoments, action.payload.momentId]),
})
export type ProcessInViewStaticMomentsSuccessAction = PayloadAction<{ remainingMomentIds: string[] } | undefined>
const clearInViewStaticMoments: CaseReducer<MomentsState, ProcessInViewStaticMomentsSuccessAction> = (state, action) => ({
  ...state,
  inViewStaticMoments: action.payload?.remainingMomentIds ?? [],
})

export type ReactToMomentAction = PayloadAction<{ id: string, reaction: string, userId: string }>
const reactToMoment: CaseReducer<MomentsState, ReactToMomentAction> = (state, action) => {
  const moment = state.moments[action.payload.id]
  if (!moment) {
    return state
  }

  const updatedMoment = {
    ...moment,
    userData: {
      ...moment.userData,
      [action.payload.userId]: {
        ...moment.userData[action.payload.userId],
        reaction: action.payload.reaction,
        reactionTimestamp: new Date().toISOString(),
      },
    },
  }

  return {
    ...state,
    moments: {
      ...state.moments,
      [action.payload.id]: updatedMoment,
    },
  }
}

const refetchMoments: CaseReducer<MomentsState, FetchMomentsAction> = (state, action) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    ...action.payload.ids.reduce(isWorkingReduceFn, {}),
  },
})

export type RefreshDownloadUrlAction = PayloadAction<{ id: string }>
const refreshDownloadUrl: CaseReducer<MomentsState, RefreshDownloadUrlAction> = (state, action) => ({
  ...state,
  hasRequestedNewUrl: {
    ...state.hasRequestedNewUrl,
    [action.payload.id]: true,
  },
})

export type RefreshDownloadUrlSuccessPayload = RefreshDownloadUrlResult & { id: string }
type RefreshDownloadUrlSuccessAction = PayloadAction<RefreshDownloadUrlSuccessPayload>
const refreshDownloadUrlSuccess: CaseReducer<MomentsState, RefreshDownloadUrlSuccessAction> = (state, action) => ({
  ...state,
  hasRequestedNewUrl: Object.keys(state.hasRequestedNewUrl)
    .filter(id => id !== action.payload.id)
    .reduce(isWorkingReduceFn, {}),
  moments: {
    ...state.moments,
    [action.payload.id]: {
      ...state.moments[action.payload.id],
      downloadEol: action.payload.downloadEol,
      downloadUrl: action.payload.downloadUrl,
      feedUrls: action.payload.feedUrls,
      transcriptionDownloadUrls: action.payload.transcriptionDownloadUrls,
    },
  },
})

type SetIsStaleAction = PayloadAction<{ ids: string[] }>
const setIsStale: CaseReducer<MomentsState, SetIsStaleAction> = (state, action) => {
  const staleMomentIds = new Set(action.payload.ids)

  return {
    ...state,
    moments: Object.entries(state.moments).reduce<MomentsDictionary>((moments, [id, moment]) => {
      moments[id] = staleMomentIds.has(id) ? { ...moment, isStale: true } : moment
      return moments
    }, {}),
  }
}
export type SetConversationIsStaleAction = PayloadAction<{ conversationId: string }>

const updateMomentsSuccess: CaseReducer<MomentsState, FetchMomentsSuccessAction> = (state, action) => ({
  ...state,
  fetchMomentsFailed: Object.entries(state.fetchMomentsFailed)
    .filter(([id]) => !action.payload.moments[id])
    .reduce<FetchFailedDictionary>((dict, [id, reason]) => {
      dict[id] = reason
      return dict
    }, {}),
  isFetching: Object.keys(state.isFetching)
    .filter(id => !action.payload.moments[id])
    .reduce(isWorkingReduceFn, {}),
  moments: {
    ...state.moments,
    ...Object.entries(action.payload.moments).reduce<MomentsDictionary>((acc, [momentId, moment]) => {
      const existingMoment = state.moments[momentId]
      acc[momentId] = {
        ...moment,
        textParagraphs: existingMoment?.textEdits?.length === moment.textEdits?.length && existingMoment?.textParagraphs
          ? existingMoment.textParagraphs
          : getPlaceholderParagraphs(moment.textContent?.text),
        transcriptionData: existingMoment?.transcriptionData,
      }

      return acc
    }, {}),
  },
})

const handleFetchMomentsForConversation: CaseReducer<MomentsState, FetchMomentsForConversationSuccessAction> = (state, action) => ({
  ...state,
  moments: {
    ...state.moments,
    ...action.payload.moments.reduce<MomentsDictionary>((acc, moment) => {
      const existingMoment = state.moments[moment.id]
      acc[moment.id] = {
        ...moment,
        ...moment,
        textParagraphs: existingMoment?.textParagraphs ?? getPlaceholderParagraphs(moment.textContent?.text),
        transcriptionData: existingMoment?.transcriptionData,
      }

      return acc
    }, {}),
  },
})

const handleDeletedStaticMoments: CaseReducer<MomentsState, UpdateConversationSuccessAction> = (state, action) => ({
  ...state,
  inViewStaticMoments: state.inViewStaticMoments
    .filter(id => !action.payload.conversation.recentlyDeletedMomentIds.includes(id)),
})

export type PrepareDownloadAction = PayloadAction<{ momentId: string }>

const momentsSlice = createSlice({
  name: 'moments',
  initialState,
  reducers: {
    addInViewStaticMoment,
    clearInViewStaticMoments,

    deleteMoment,
    deleteMomentSuccess,

    fetchMoments,
    fetchMomentsFailure,
    fetchMomentsSuccess,

    fetchLinkPreviews,
    fetchLinkPreviewsFailure,
    fetchLinkPreviewsSuccess,

    fetchTranscriptions,
    fetchTranscriptionsFailure,
    fetchTranscriptionsSuccess,

    forwardMoments,
    forwardMomentsFailure: clearForwardingMoments,
    forwardMomentsSuccess: clearForwardingMoments,

    prepareDownload: (state, _: PrepareDownloadAction) => state,

    reactToMoment,
    reactToMomentSuccess: reactToMoment,

    refetchMoments,
    refetchMomentsSuccess: updateMomentsSuccess,

    refreshDownloadUrl,
    refreshDownloadUrlSuccess,

    setIsStale,
    setConversationIsStale: (state, _: SetConversationIsStaleAction) => state,

    startViewingMoment,
  },
  extraReducers: builder => {
    builder.addCase(conversationsActions.fetchMomentsForConversationSuccess, handleFetchMomentsForConversation)
    builder.addCase(conversationsActions.refetchConversationSuccess, handleDeletedStaticMoments)
  },
})

export const actions = momentsSlice.actions
export const selector = {
  name: momentsSlice.name,
  select: (appState: AppState): MomentsState => appState.moments,
}
export default momentsSlice.reducer
