import { GetSlackVolMutationVariables } from 'API'
import getClientInfo, { ClientInfoParams } from 'modules/clientInfo'
import { buildMutation, createMutationMap, mutateGraphQl } from './graph-utils'

type ConversationInfo = {
  id: string | null,
  name: string | null,
  nameNormalized: string | null,
  isChannel: string | null,
  isGroup: string | null,
  isIm: string | null,
  isPrivate: string | null,
  conversationLinkCommand: string | null
}

export type SlackIntegrationData = {
  slackTeamInfo: {
    id: string | null
    name: string | null
    icon: string | null
    appVersion: number | null
    needsPermissionRefresh: boolean
  },
  conversationConnectionInfo: { [conversationId: string]: ConversationInfo }
}

export type SlackChannels = {
  [slackChannelId: string]: {
    name: string
    is_general?: boolean
  }
}

type GetSlackParams = {
  teamId: string
  conversationId?: string
}

type GetSlackVolResult = {
  getSlackVol: string
}

const GetSlackMutation = createMutationMap('getSlackVol', {
  teamID: 'ID',
  conversationID: 'ID',
  clientInfo: 'String!'
})

export async function getSlack(params: GetSlackParams): Promise<SlackIntegrationData> {
  const clientInfo = await getClientInfo()
  const variables = {
    teamID: params.teamId,
    conversationID: params.conversationId,
    clientInfo
  }

  const result = await mutateGraphQl<GetSlackVolResult, GetSlackVolMutationVariables>({
    mutation: buildMutation(variables, GetSlackMutation),
    variables
  })

  GetSlackMutation.checkForErrors(result)
  const { conversationConnectionInfo, ...rawResponse } = JSON.parse(result.data?.getSlackVol as string)
  if (conversationConnectionInfo && params.conversationId) {
    rawResponse.conversationConnectionInfo = { [params.conversationId]: conversationConnectionInfo }
  }

  return { ...rawResponse }
}

type UpdateSlackParams = {
  teamId: string
  conversationId: string
  slackTeamId: string
  slackChannelId: string
}

type UpdateSlackVolMutationParams = {
  teamID: string
  conversationID: string
  slackTeamID: string
  slackChannelID: string
}

type UpdateSlackVolParams = UpdateSlackVolMutationParams & ClientInfoParams
type UpdateSlackVolResult = {
  updateSlackVol: string
}

const UpdateSlackMutation = createMutationMap('updateSlackVol', {
  teamID: 'ID!',
  conversationID: 'ID!',
  slackTeamID: 'ID!',
  slackChannelID: 'ID!',
  clientInfo: 'String!',
})

export async function updateSlack(params: UpdateSlackParams): Promise<void> {
    const clientInfo = await getClientInfo()
    const variables = {
      teamID: params.teamId,
      conversationID: params.conversationId,
      slackTeamID: params.slackTeamId,
      slackChannelID: params.slackChannelId,
      clientInfo
    }
    const result = await mutateGraphQl<UpdateSlackVolResult, UpdateSlackVolParams>({
      mutation: buildMutation(variables, UpdateSlackMutation),
      variables
    })
    UpdateSlackMutation.checkForErrors(result)
}

export type DeleteSlackParams = {
  teamId: string
  conversationId?: string // omit if disconnecting all and removing app from slack
  slackTeamId: string
  slackChannelId: string
}

export type DeleteSlackMutationParams = {
  teamID: string
  conversationID?: string // omit if disconnecting all and removing app from slack
  slackTeamID: string
  slackChannelID: string
}
type DeleteSlackVolParams = DeleteSlackMutationParams & ClientInfoParams
type DeleteSlackVolResult = {
  deleteSlackVol: string
}

const DeleteSlackMutation = createMutationMap('deleteSlackVol', {
  teamID: 'ID!',
  conversationID: 'ID',
  slackTeamID: 'ID!',
  slackChannelID: 'ID!',
  clientInfo: 'String!',
})

export async function deleteSlack(params: DeleteSlackParams): Promise<void> {
    const clientInfo = await getClientInfo()
    const variables: DeleteSlackVolParams = {
      teamID: params.teamId,
      slackTeamID: params.slackTeamId,
      slackChannelID: params.slackChannelId,
      clientInfo
    }

    if (params.conversationId) {
      variables.conversationID = params.conversationId
    }

    const result = await mutateGraphQl<DeleteSlackVolResult, DeleteSlackVolParams>({
      mutation: buildMutation(variables, DeleteSlackMutation),
      variables
    })
    DeleteSlackMutation.checkForErrors(result)
}
