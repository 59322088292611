import { max, parseISO } from 'date-fns'
import { uniqBy } from 'lodash-es'
import { ADHOC_TEAM_ID, ALL_TEAMS_ID } from 'modules/constants'
import {
  ConversationType,
  MomentThread,
  MomentThreadsDictionary,
  RecentMoment,
  RecentMomentDictionary,
} from 'modules/types/conversations'
import { Moment } from 'modules/types/moments'
import { SearchConversationDictionary } from 'modules/types/search'
import { ConversationData, ConversationDataDictionary, GuestTeamConversations, User } from 'modules/types/users'

export function sortRecentMoments(recentMoments: RecentMoment[], fullMoments?: Moment[]): RecentMoment[] {
  const newRecentMoments = fullMoments?.map<RecentMoment>(m => ({
    isExpired: m.expired,
    momentId: m.id,
    momentTimestamp: m.createdOn,
    sortDate: new Date(m.createdOn).getTime(),
    type: m.vType,
  })) ?? []

  return uniqBy(newRecentMoments.concat(recentMoments), 'momentId').sort((a, b) => b.sortDate - a.sortDate)
}

type GetOrderedConversationIdsParams = {
  filterByTeamId?: string
  recentMoments?: RecentMomentDictionary
  searchResults?: SearchConversationDictionary | null
  searchText?: string
  user: Pick<User, 'conversationData' | 'guestTeamConversations'>
}
export function getOrderedConversationData(params: GetOrderedConversationIdsParams): ConversationData[] {
  const { filterByTeamId, recentMoments = {}, searchResults, searchText, user } = params

  const conversationData = getSortableConversationData(user.conversationData, recentMoments)
  const sortable = getFilteredConversationData({
    conversationData,
    filterByTeamId,
    guestTeamConversations: user.guestTeamConversations,
    searchResults,
  })

  let sorted: SortableConversationData[]
  if (searchResults && searchText) {
    // sort by score asc, then by text asc, then by date desc
    sorted = sortable.sort((a, b) => {
      const searchA = searchResults[a.conversationId]
      const searchB = searchResults[b.conversationId]

      return searchA?.score - searchB?.score
        || (searchA.terms === searchB.terms ? 0 : searchA.terms > searchB.terms ? 1 : -1)
        || b.sortDate.getTime() - a.sortDate.getTime()
    })
  } else {
    // sort by unviewed, then by date desc
    sorted = sortable.sort((a, b) =>
      ((b.sortToTop ? 1 : 0) - (a.sortToTop ? 1 : 0))
      || b.sortDate.getTime() - a.sortDate.getTime())
  }

  return sorted.map(s => ({ ...s, sortDate: s.sortDate.toISOString() }))
}

type SortableConversationData = Omit<ConversationData, 'sortDate'> & { score?: number, sortDate: Date }
type SortableConversationDataDictionary = { [conversationId: string]: SortableConversationData }
export function getSortableConversationData(conversationData: ConversationDataDictionary, recentMoments: RecentMomentDictionary = {}): SortableConversationDataDictionary {
  return Object.entries({ ...conversationData }).reduce<SortableConversationDataDictionary>((acc, [convoId, data]) => {
    if (data.conversationType === ConversationType.TalkToMeOnVolley
      || data.conversationType === ConversationType.TeamWelcome
      || data.conversationType === ConversationType.Thread) {
      return acc
    }

    let result = { ...data, sortDate: parseISO(data.sortDate) }

    const convoRecentMoments = recentMoments[convoId]
    const latestLocalMoment = convoRecentMoments?.filter(m => m.isLocal).sort((a, b) => b.sortDate - a.sortDate)[0]

    if (latestLocalMoment && latestLocalMoment.sortDate > result.sortDate.getTime()) {
      result = {
        ...result,
        sortDate: new Date(latestLocalMoment.sortDate),
      }
    }

    acc[result.conversationId] = result

    return acc
  }, {})
}

type GetFilteredConversationDataParams = {
  conversationData: SortableConversationDataDictionary
  filterByTeamId?: string
  guestTeamConversations: GuestTeamConversations
  searchResults?: SearchConversationDictionary | null
}
export function getFilteredConversationData(params: GetFilteredConversationDataParams): SortableConversationData[] {
  const { conversationData, filterByTeamId, guestTeamConversations, searchResults } = params
  if (!searchResults && !filterByTeamId) {
    return Object.values(conversationData)
  }

  const guestConversationIds = filterByTeamId === ADHOC_TEAM_ID
    ? new Set(Object.values(guestTeamConversations).reduce((acc, guestConvos) => acc.concat(guestConvos), []))
    : new Set()

  return searchResults
    ? Object.values(searchResults)
      .filter(s => {
        return !filterByTeamId || filterByTeamId === ALL_TEAMS_ID || filterByTeamId === s.teamId
      })
      .map(s => {
        const searchSortDate = parseISO(s.sortDate)
        const convoData = conversationData[s.id]

        return {
          conversationId: s.id,
          conversationType: s.vType,
          sortDate: convoData ? max([searchSortDate, convoData.sortDate]) : searchSortDate,
          teamId: s.teamId ?? '',
          threadUnviewedCount: convoData?.threadUnviewedCount ?? 0,
          unviewedCount: convoData?.unviewedCount ?? 0,
          userIds: convoData?.userIds ?? [],
        }
      })
    : Object.values(conversationData).filter(cd =>
      (!filterByTeamId || filterByTeamId === ALL_TEAMS_ID || filterByTeamId === cd.teamId)
      || guestConversationIds.has(cd.conversationId))
}

type OrderedMomentThreadsResult = {
  all: MomentThread[]
  following: MomentThread[]
}
export function getOrderedMomentThreads(momentThreads?: MomentThreadsDictionary, recentMoments: RecentMomentDictionary = {}): OrderedMomentThreadsResult {
  return Object.values(momentThreads ?? {})
    .map(t => addLocalMomentsToMomentThreads(t, recentMoments))
    // sort by unviewed, then by date desc
    .sort((a, b) => (b.unviewedCount ? 1 : 0) - (a.unviewedCount ? 1 : 0)
      || parseISO(b.newestMomentTimestamp).getTime() - parseISO(a.newestMomentTimestamp).getTime())
    .reduce<{ following: MomentThread[], all: MomentThread[] }>((acc, momentThread) => {
      if (momentThread.userIsMember && !!momentThread.momentCount) {
        acc.following.push(momentThread)
      } else if (!!momentThread.momentCount) {
        acc.all.push(momentThread)
      }

      return acc
    }, { all: [], following: [] })
}

function addLocalMomentsToMomentThreads(momentThread: MomentThread, recentMoments: RecentMomentDictionary): MomentThread {
  const threadRecentMoments = recentMoments[momentThread.threadId]
  if (!threadRecentMoments) {
    return momentThread
  }

  const latestLocalMoment = threadRecentMoments.filter(m => m.isLocal).sort((a, b) => b.sortDate - a.sortDate)[0]
  if (!latestLocalMoment
    || latestLocalMoment.sortDate < parseISO(momentThread.newestMomentTimestamp).getTime()) {
    return momentThread
  }

  return { ...momentThread, newestMomentTimestamp: new Date(latestLocalMoment.sortDate).toISOString() }
}

export function getThreadMomentCountText(momentCount: number, max: number): string {
  return `${momentCount >= max ? `${max}+` : momentCount} ${momentCount > 1 ? 'replies' : 'reply'}`
}
