import styled from '@emotion/styled'
import React from 'react'
import SVG from 'react-inlinesvg'
import ToggleSwitch, { ToggleSwitchProps } from '../ToggleSwitch'

type ContainerProps = {
  backgroundColor?: string
  color?: string
  hideBorderBottom?: boolean
  hideBorderTop?: boolean
  iconPathFill?: string
}
const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  width: 100%;
  padding: 0 11px 0 16px;
  background-color: ${({ backgroundColor }) => !!backgroundColor ? backgroundColor : undefined};
  border-top: ${({ hideBorderTop, theme }) => !hideBorderTop ? `1px solid ${theme.palette.divider}` : 'none'};
  border-bottom: ${({ hideBorderBottom, theme }) => !hideBorderBottom ? `1px solid ${theme.palette.divider}` : 'none'};

  & > label {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0;
    color: ${({ color, theme }) => color ?? theme.palette.common.black};
    font-size: 15px;

    & > svg {
      height: 30px;
      width: 30px;

      & .path-fill,
      & path {
        fill: ${({ iconPathFill, theme }) => iconPathFill ?? theme.palette.common.black};
      }
    }
  }
`
const ChildSpacer = styled.span`
  margin-left: 11px;
`
const Subtext = styled.p<{ hideBorderBottom?: boolean, backgroundColor?: string }>`
  font-size: 13px;
  background-color: ${({ backgroundColor }) => !!backgroundColor ? backgroundColor : undefined};
  color: ${({ theme }) => theme.palette.volleyGray04};
  margin: 0;
  padding: 0 16px 16px 16px;
  border-bottom: ${({ hideBorderBottom, theme }) => !hideBorderBottom ? `1px solid ${theme.palette.divider}` : 'none'};
`

type ActionToggleSwitchProps = Omit<ToggleSwitchProps, 'color'> & ContainerProps & {
  children: React.ReactNode
  icon?: string
  id: string
  subtext?: string
  toggleColor?: ToggleSwitchProps['color']
}
const ActionToggleSwitch = (props: ActionToggleSwitchProps): React.ReactElement => {
  const {
    backgroundColor,
    color,
    children,
    hideBorderBottom,
    hideBorderTop,
    icon,
    iconPathFill,
    id,
    subtext,
    toggleColor = 'primary',
    ...rest
  } = props

  return (
    <>
      <Container
        backgroundColor={backgroundColor}
        color={color}
        hideBorderBottom={hideBorderBottom || !!subtext}
        hideBorderTop={hideBorderTop}
        iconPathFill={iconPathFill}
      >
        {!!icon
          ? (
            <label htmlFor={id}>
              <SVG src={icon} />
              <ChildSpacer>{children}</ChildSpacer>
            </label>
          )
          : <label htmlFor={id}>{children}</label>
        }
        <ToggleSwitch {...rest} color={toggleColor} id={id} />
      </Container>
      {!!subtext && <Subtext backgroundColor={backgroundColor} hideBorderBottom={hideBorderBottom}>{subtext}</Subtext>}
    </>
  )
}

export default ActionToggleSwitch
