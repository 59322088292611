import styled from '@emotion/styled'
import { alpha } from '@mui/material'
import React from 'react'
import SVG from 'react-inlinesvg'
import PlanIcon from 'assets/plan.svg'

type ContainerProps = {
  backgroundOpacity?: number;
  disabled?: boolean;
  iconPathFill?: string;
  iconSize?: number;
  size: number;
  showIcon?: boolean;
};

const Container = styled.button<ContainerProps>`
  position: relative;
  display: grid;
  place-items: center;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  padding: 0;
  background: ${({ backgroundOpacity, theme }) =>
    alpha(theme.palette.common.black, backgroundOpacity ?? 0)};
  border: none;
  border-radius: ${({ size }) => `calc(${size}px / 2)`};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  
  & > svg {
    height: ${({ iconSize, size }) => iconSize ?? size}px;
    width: ${({ iconSize, size }) => iconSize ?? size}px;

    & > .path-fill {
      fill: ${({ iconPathFill }) => iconPathFill};
    }
    & > .path-stroke {
      stroke: ${({ iconPathFill }) => iconPathFill};
    }
  }

  .plan-icon {
    fill: ${({ theme }) => theme.palette.volleyPink};
    position: absolute;
    height: 14px;
    width: 14px;
    top: 1px;
    right: ${({ size }) => -Math.max(size * 0.2, 3)}px;
    transform: translateY(-50%);
    display: none;
  }

  &:hover {
    .plan-icon {
      display: ${({ showIcon }) => (!showIcon ? 'none' : 'block')};
    }
  }

  &:disabled {
    color: 0.65;

    & > svg > .path-fill,
    & > svg > .path-stroke {
      opacity: 0.65;
    }
  }
`

type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & Omit<ContainerProps, 'size'> & {
  size?: number;
  src: string;
  showIcon?: boolean;
};

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ size, src, showIcon, ...rest }, ref) => (
    <Container size={size ?? 30} showIcon={showIcon} {...rest} ref={ref}>
      <SVG src={src} />
      <SVG className="plan-icon" src={PlanIcon} />
    </Container>
  )
)
IconButton.displayName = 'IconButton'

export default IconButton
