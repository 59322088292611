require('regenerator-runtime/runtime')
require('./polyfills')
// require('./rollbar') // todo tech 9
const Amplify = require('aws-amplify').default
const awsconfig = require('./aws-exports').default
const { buildNumber, version } = require('./version.json')

Amplify.configure({
  ...awsconfig,
  Analytics: {
    disabled: true
  }
})
// TODO: find a way to only include this in the bundle for local dev
// require('./wdyr')
require('modules/Bootstrap')

if (version) {
  console.info(`Volley ${version} (${buildNumber})`)
}
