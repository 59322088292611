import axios from 'axios'
import gql from 'graphql-tag'
import getClientInfo, { ClientInfoParams } from 'modules/clientInfo'
import config from 'modules/config'
import {
  CreateMomentResult,
  MentionedUserId,
  Moment,
  MomentLinkPreview,
  MomentLinkPreviewDictionary,
  MomentsDictionary,
  MomentType,
  TranscriptionData,
  TranscriptionDownloadUrls,
  TranscriptionPhrase,
} from 'modules/types/moments'
import { EventChannel, eventChannel } from 'redux-saga'
import { checkForErrors, mutateGraphQl } from './graph-utils'
import { deserializeFeedUrls, deserializeMoment, Vol_FeedUrls, Vol_Moment } from './shared'
import { BatchResult, deserializeBatchMomentsResults } from './util'

type CreateMomentVolParams = {
  conversationId: string
  filename?: string
  ignoreRotMetadata?: boolean
  keepVideoRes?: boolean
  mimeType?: string
  source?: string
  type: MomentType
}
type CreateMomentVolMutationParams = CreateMomentVolParams & ClientInfoParams
type CreateMomentVolResult = {
  createMomentVol: string
}
type CreateMomentVol_Data = {
  createdOn: string
  id: string
  transcriptionAuthMethod?: string
  transcriptionEnterpriseCreds?: string
  transcriptionEnterpriseWssURL?: string
  transcriptionUploadURL?: string
  uploadURL?: string
}

const CreateMomentVolMutation = gql`
  mutation CreateMomentVol(
    $clientInfo: String
    $conversationId: ID!
    $filename: String
    $ignoreRotMetadata: Boolean
    $keepVideoRes: Boolean
    $mimeType: String
    $source: String
    $type: MomentType
  ) {
    createMomentVol(
      clientInfo: $clientInfo
      conversationID: $conversationId
      filename: $filename
      ignoreRotMetadata: $ignoreRotMetadata
      keepVideoRes: $keepVideoRes
      mimeType: $mimeType
      source: $source
      vType: $type
    )
  }
`

const deserializeCreateMomentData = (data: CreateMomentVol_Data): CreateMomentResult => ({
  createdOn: data.createdOn,
  id: data.id,
  transcriptionAuthMethod: data.transcriptionAuthMethod,
  transcriptionEnterpriseCreds: data.transcriptionEnterpriseCreds,
  transcriptionEnterpriseWssUrl: data.transcriptionEnterpriseWssURL,
  transcriptionUploadUrl: data.transcriptionUploadURL,
  uploadUrl: data.uploadURL,
})

export async function createMomentVol(params: CreateMomentVolParams): Promise<CreateMomentResult> {
  const result = await mutateGraphQl<CreateMomentVolResult, CreateMomentVolMutationParams>({
    mutation: CreateMomentVolMutation,
    variables: {
      ...params,
      ignoreRotMetadata: true, // Needed for desktop recording to work because reasons. There are plans to deprecate it in the near future.
      clientInfo: await getClientInfo(),
    }
  })

  checkForErrors('createMomentVol', result, true)
  // we've checked for errors in the previous call
  return deserializeCreateMomentData(JSON.parse(result.data?.createMomentVol ?? '{}'))
}

type GetMomentUploadUrlParams = {
  momentId: string
  segmentNumber: number
}
type GetMomentUploadUrlMutationParams = GetMomentUploadUrlParams & ClientInfoParams
type GetMomentUploadUrlResult = {
  getMomentUploadURL: string
  error?: string
}
type GetMomentUploadUrlResult_Data = {
  momentUploadURL: string
}
const GetMomentUploadUrlMutation = gql`
  mutation GetMomentUploadUrl($momentId: ID!, $segmentNumber: Int, $clientInfo: String) {
    getMomentUploadURL(
      momentID: $momentId
      segmentNumber: $segmentNumber
      clientInfo: $clientInfo
    )
  }
`

export async function getMomentUploadUrl(params: GetMomentUploadUrlParams): Promise<string> {
  const result = await mutateGraphQl<GetMomentUploadUrlResult, GetMomentUploadUrlMutationParams>({
    mutation: GetMomentUploadUrlMutation,
    variables: { ...params, clientInfo: await getClientInfo() }
  })

  checkForErrors('getMomentUploadURL', result, true)
  // we've checked for errors in the previous call
  const parsed: GetMomentUploadUrlResult_Data = JSON.parse(result.data?.getMomentUploadURL || '{}')

  return parsed.momentUploadURL
}

export async function uploadSegment(uploadUrl: string, data: Blob): Promise<void> {
  return axios.put(uploadUrl, data)
}

export type ProgressResult = { done: boolean, loaded: number, size: number }

export function createUploadSegmentChannel(uploadUrl: string, data: Blob): EventChannel<ProgressResult | Error> {
  let size = 0
  let loaded = 0
  let doneCount = 0

  return eventChannel(emit => {
    void axios
      .put(uploadUrl, data, {
        onUploadProgress: (e: ProgressEvent) => {
          size = e.total
          loaded = e.loaded
          // short circuit in case axios promise never resolves (we've seen it happen)
          if (size === loaded) {
            ++doneCount
          }

          emit({ done: doneCount >= 5, loaded, size })
        }
      })
      .then(res => {
        /*
        We'll only get data back if it's an error
        An example of what the data would look like:
        <?xml version="1.0" encoding="UTF-8"?>
        <Error>
        <Code>RequestTimeout</Code>
        <Message>Your socket connection to the server was not read from or written to within the timeout period. Idle connections will be closed.</Message>
        <RequestId>448E8D25A7AE5608</RequestId>
        <HostId>8Oy2aUZhpxn4N2+dMvXm7EH94/jc87r9M20uGBb7WamHN21u4lkWrwuI0CEgPU4CdZDB1qO9ZwU=</HostId>
        </Error>
        */
        if (res.data) {
          throw new Error(`Error uploading data to '${uploadUrl}'`)
        }

        emit({ done: true, loaded: size, size })
      })
      .catch(err => {
        if (err.response && err.response.status !== 200) {
          emit(new Error(`Failed to PUT video data to upload URL ${uploadUrl}: ${err.response.status}`))
        } else {
          emit(err)
        }
      })

    return () => {
      return
    }
  })
}

type OnMomentUploadParams = {
  momentId: string
  segmentNumber: number
  isFinalSegment: boolean
}
type OnMomentUploadMutationParams = OnMomentUploadParams & ClientInfoParams
type OnMomentUploadResult = {
  onMomentUpload: string
}
const OnMomentUploadMutation = gql`
  mutation OnMomentUpload($momentId: ID!, $segmentNumber: Int, $isFinalSegment: Boolean, $clientInfo: String) {
    onMomentUpload(
      momentID: $momentId
      segmentNumber: $segmentNumber
      isFinalSegment: $isFinalSegment
      clientInfo: $clientInfo
    )
  }
`

export async function onMomentUpload(params: OnMomentUploadParams): Promise<void> {
  const result = await mutateGraphQl<OnMomentUploadResult, OnMomentUploadMutationParams>({
    mutation: OnMomentUploadMutation,
    variables: { ...params, clientInfo: await getClientInfo() }
  })

  checkForErrors('onMomentUpload', result)
}

export type CloseMomentMutationParams = ClientInfoParams & {
  backgroundHexColor?: string
  mentionedUserIds?: string
  momentId: string
  scheduledDate?: string
  segmentCount?: number
  text?: string
}
type CloseMomentResult = {
  closeMoment: string
}
type CloseMomentResult_Data = {
  momentID: string
}

const CloseMomentMutation = gql`
  mutation CloseMoment(
    $backgroundHexColor: String
    $clientInfo: String
    $mentionedUserIds: AWSJSON
    $momentId: ID!
    $scheduledDate: AWSDateTime
    $segmentCount: Int
    $text: String
  ) {
    closeMoment(
      backgroundHexColor: $backgroundHexColor
      clientInfo: $clientInfo
      mentionedUserIDs: $mentionedUserIds
      momentID: $momentId
      sendOn: $scheduledDate
      segmentCount: $segmentCount
      text: $text
    )
  }
`

export type CloseMomentParams = Omit<CloseMomentMutationParams, 'clientInfo' | 'mentionedUserIds'> & {
  mentionedUserIds?: MentionedUserId[]
}

export async function closeMoment(params: CloseMomentParams): Promise<string> {
  const { mentionedUserIds, ...rest } = params
  const variables = {
    ...rest,
    clientInfo: await getClientInfo(),
    mentionedUserIds: serializeMentionedUserIds(mentionedUserIds),
  }
  const result = await mutateGraphQl<CloseMomentResult, CloseMomentMutationParams>({
    mutation: CloseMomentMutation,
    variables,
  })

  checkForErrors('closeMoment', result, true)
  // we've checked for errors in the previous call
  const parsed: CloseMomentResult_Data = JSON.parse(result.data?.closeMoment ?? '{}')

  return parsed.momentID
}

function serializeMentionedUserIds(mentionedUserIds: MentionedUserId[] | undefined): string | undefined {
  if (!mentionedUserIds) {
    return undefined
  }

  const serialized = mentionedUserIds.map(({ length, startLocation, userId }) => ({
    length,
    startLocation,
    userID: userId,
  }))

  return JSON.stringify(serialized)
}

type CancelMomentParams = {
  momentId: string
}
type CancelMomentMutationParams = CancelMomentParams & ClientInfoParams
type CancelMomentResult = {
  cancelMoment: string
}
type CancelMomentResult_Data = {
  momentID: string
}

const CancelMomentMutation = gql`
  mutation CancelMoment($clientInfo: String, $momentId: ID!) {
    cancelMoment(clientInfo: $clientInfo, momentID: $momentId)
  }
`

export async function cancelMoment(params: CancelMomentParams): Promise<string> {
  const result = await mutateGraphQl<CancelMomentResult, CancelMomentMutationParams>({
    mutation: CancelMomentMutation,
    variables: { ...params, clientInfo: await getClientInfo() }
  })

  checkForErrors('cancelMoment', result, true)
  // we've checked for errors in the previous call
  const parsed: CancelMomentResult_Data = JSON.parse(result.data?.cancelMoment ?? '{}')

  return parsed.momentID
}

type GetMomentVolParams = { clientInfo: string, ids: string[], onlyFields?: string[] }
type GetMomentVolResult = { getMomentVol: string | null, error?: string }
type GetMomentVol_MomentDictionary = { [id: string]: Vol_Moment }

const GET_MOMENT_VOL_ONLY_FIELDS = [
  'accessLevel',
  'bytes',
  'conversationID',
  'copyHistory',
  'createdOn',
  'creatorUserID',
  'disableDelete',
  'disableDownloading',
  'disableForwarding',
  'disableSharing',
  'downloadEOL',
  'downloadURL',
  'duration',
  'feedURLs',
  'id',
  'isExpired',
  'isSeed',
  'mentionedUserIDs',
  'mimeType',
  'name',
  'originalFilename',
  'sendGigID',
  'sharingURL',
  'source',
  'symlinkSrcID',
  'teamID',
  'textContent',
  'textEdits',
  'threadID',
  'transcriptionDownloadURLs',
  'transcription',
  'thumbURL',
  'userData',
  'vState',
  'vType',
]

const GetMomentVolQuery = gql`
  mutation GetMomentVol($clientInfo: String, $ids: [ID], $onlyFields: [String]) {
    getMomentVol(clientInfo: $clientInfo, momentIDs: $ids, onlyFields: $onlyFields)
  }
`

function deserializeMoments(moments: GetMomentVol_MomentDictionary): MomentsDictionary {
  return Object.entries(moments).reduce<MomentsDictionary>((dict, [id, moment]) => {
    dict[id] = deserializeMoment(moment)
    return dict
  }, {})
}

export async function getMoments(ids: string[]): Promise<BatchResult<Moment>> {
  const clientInfo = await getClientInfo()
  const results = await mutateGraphQl<GetMomentVolResult, GetMomentVolParams>({
    mutation: GetMomentVolQuery,
    variables: { clientInfo, ids, onlyFields: GET_MOMENT_VOL_ONLY_FIELDS }
  })

  checkForErrors('getMomentVol', results, true)
  const {
    expired,
    missingIds,
    refetchIds,
    success,
  } = deserializeBatchMomentsResults(results.data?.getMomentVol, deserializeMoments)
  return { missingIds, refetchIds, success: { ...expired, ...success } }
}

type OpenGraphData = {
  description?: string
  error?: string
  thumbnail_height?: number
  thumbnail_url?: string
  thumbnail_width?: number
  type: 'photo' | 'video' | 'rich'
  status?: number
  title?: string
  url: string
}
const ROUTE_PARAMS_REGEX = /\/teams\/(.*\-tm)(\/conversations\/(.*\-c)(\/moments\/(.*\-m))?)?/

export async function getLinkPreviews(previews: MomentLinkPreviewDictionary): Promise<MomentLinkPreviewDictionary> {
  let fetchedPreviews = {}
  if (config.iFramely.apiKey) {
    const requests = Object.entries(previews)
      .filter(([_host, preview]) => !preview.url.startsWith(`${config.app.host}/teams/`))
      .map(([host, preview]) => ([
        host,
        preview.url,
        `${config.iFramely.host}/api/oembed?url=${encodeURIComponent(preview.url)}&api_key=${config.iFramely.apiKey}`,
      ]))

    // TODO: convert to Promise.allSettled after upgrading ES target
    const responses = await Promise.all(requests.map(([_host, _url, req]) => axios.get(req)))

    fetchedPreviews = responses.reduce<MomentLinkPreviewDictionary>((acc, res, i) => {
      const [host, url] = requests[i]

      if (res.status && res.status === 200 && !res.data.error) {
        const preview = deserializeLinkPreviews(res.data)
        const tokenId = getTokenId(preview.url)

        acc[host] = { ...preview, tokenId }
      } else {
        acc[host] = { error: JSON.stringify(res.data), type: 'unknown', url }
      }

      return acc
    }, {})
  }

  const internalPreviews = Object.entries(previews)
    .filter(([_host, preview]) => preview.url.startsWith(`${config.app.host}/teams/`))
    .reduce<MomentLinkPreviewDictionary>((acc, [host, link]) => {
      const paramsMatch = link.url.match(ROUTE_PARAMS_REGEX)
      acc[host] = {
        conversationId: paramsMatch?.[3],
        momentId: paramsMatch?.[5],
        teamId: paramsMatch?.[1],
        type: 'internal',
        url: link.url.replace(config.app.host, ''),
      }

      return acc
    }, {})

  return { ...fetchedPreviews, ...internalPreviews }
}

const TOKEN_ID_REGEX = /\?tk\=(.*\-tk$)/

function getTokenId(url: string): string | undefined {
  if (!url.endsWith('-tk')) {
    return
  }

  return url.match(TOKEN_ID_REGEX)?.[1] ?? undefined
}

function deserializeLinkPreviews(data: OpenGraphData): MomentLinkPreview {
  return {
    description: data.description,
    image: data.thumbnail_url && data.thumbnail_height && data.thumbnail_width
      ? {
        height: data.thumbnail_height,
        url: data.thumbnail_url,
        width: data.thumbnail_width
      }
      : undefined,
    title: data.title,
    type: data.type,
    url: data.url,
  }
}

type TranscriptionPhrase_Data = {
  endSec: number
  endTC: string
  phrase: string
  startSec: number
  startTC: string
}

export async function fetchTranscriptions(transcriptionDownloadUrls: TranscriptionDownloadUrls): Promise<TranscriptionData> {
  const interactiveUrl = transcriptionDownloadUrls.interactive ?? transcriptionDownloadUrls.jsonPhrases
  const jsonUrl = transcriptionDownloadUrls.jsonPhrases
  if (!interactiveUrl && !jsonUrl) {
    return { interactive: [], json: [] }
  }

  const [interactiveRes, jsonRes] = await Promise.all([
    interactiveUrl ? axios.get<TranscriptionPhrase_Data[]>(interactiveUrl) : undefined,
    jsonUrl ? axios.get<TranscriptionPhrase_Data[]>(jsonUrl) : undefined,
  ])
  return {
    interactive: deserializeTranscriptionPhrases(interactiveRes?.data),
    json: deserializeTranscriptionPhrases(jsonRes?.data, true),
  }
}

function deserializeTranscriptionPhrases(data?: TranscriptionPhrase_Data[], shouldInsertAutoGenMessage = false): TranscriptionPhrase[] {
  const result = data?.map(d => ({
    endSeconds: d.endSec,
    phrase: d.phrase,
    startSeconds: d.startSec,
  })) ?? []

  if (shouldInsertAutoGenMessage) {
    const autoGenMessage = {
      endSeconds: Math.min(result[0].startSeconds, 1),
      phrase: '[Captions are Auto-Generated]',
      startSeconds: 0,
    }
    result.splice(0, 0, autoGenMessage)
  }

  return result
}

type DeleteMomentParams = {
  momentId: string
}
type DeleteMomentMutationParams = DeleteMomentParams & ClientInfoParams
type DeleteMomentResult = { deleteMomentVol: string }
const DeleteMoment = gql`
  mutation DeleteMoment($clientInfo: String, $momentId: ID!) {
    deleteMomentVol(clientInfo: $clientInfo, momentID: $momentId)
  }
`

export async function deleteMoment(momentId: string): Promise<void> {
  const result = await mutateGraphQl<DeleteMomentResult, DeleteMomentMutationParams>({
    mutation: DeleteMoment,
    variables: { clientInfo: await getClientInfo(), momentId },
  })

  checkForErrors('deleteMomentVol', result, true)
}

type CopyMomentsToConversationResult = { copyMomentsToConversation: string | null }
type CopyMomentsToConversationParams = {
  clientInfo: string
  conversationId: string
  momentIds: string[]
}
const CopyMomentsToConversation = gql`
  mutation CopyMomentsToConversation($clientInfo: String, $conversationId: ID!, $momentIds: [ID]!) {
    copyMomentsToConversation(clientInfo: $clientInfo, dstConversationID: $conversationId, srcMomentIDs: $momentIds)
  }
`

export async function forwardMoments(momentIds: string[], conversationId: string): Promise<void> {
  const result = await mutateGraphQl<CopyMomentsToConversationResult, CopyMomentsToConversationParams>({
    mutation: CopyMomentsToConversation,
    variables: {
      clientInfo: await getClientInfo(),
      conversationId,
      momentIds,
    }
  })

  checkForErrors('copyMomentsToConversation', result)
}

type UserStartsViewingMomentResult = { userStartsViewingMoment: string | null }
type UserStartsViewingMomentParams = {
  clientInfo: string
  momentIds: string[]
}
const UserStartsViewingMoment = gql`
  mutation UserStartsViewingMoment($clientInfo: String, $momentIds: [ID]!) {
    userStartsViewingMoment(clientInfo: $clientInfo, momentIDs: $momentIds)
  }
`

export async function startViewingMoment(momentIds: string[]): Promise<void> {
  const result = await mutateGraphQl<UserStartsViewingMomentResult, UserStartsViewingMomentParams>({
    mutation: UserStartsViewingMoment,
    variables: { clientInfo: await getClientInfo(), momentIds }
  })

  checkForErrors('userStartsViewingMoment', result)
}

type UserReactsToMomentResult = { userReactsToMoment: string | null }
type UserReactsToMomentParams = {
  clientInfo: string
  momentId: string
  reaction: string
}
const UserReactsToMoment = gql`
  mutation UserReactsToMoment($clientInfo: String, $momentId: ID!, $reaction: String) {
    userReactsToMoment(clientInfo: $clientInfo, momentID: $momentId, reaction: $reaction)
  }
`

export async function reactToMoment(momentId: string, reaction: string): Promise<void> {
  const result = await mutateGraphQl<UserReactsToMomentResult, UserReactsToMomentParams>({
    mutation: UserReactsToMoment,
    variables: { clientInfo: await getClientInfo(), momentId, reaction }
  })

  checkForErrors('userReactsToMoment', result)
}

export type RefreshDownloadUrlResult = {
  downloadEol: number
  downloadUrl: string
  feedUrls: string[] | null
  transcriptionDownloadUrls: TranscriptionDownloadUrls
}
type GenerateMomentDownloadUrlResult = { generateMomentDownloadURL: string | null }
type GenerateMomentDownloadUrl_Data = {
  downloadURL: string
  downloadEOL: number
  feedURLs: Vol_FeedUrls
  transcriptionDownloadURLs?: TranscriptionDownloadUrls
}
type GenerateMomentDownloadUrlParams = {
  clientInfo: string
  doForce?: boolean
  momentId: string
}
const GenerateMomentDownloadUrl = gql`
  mutation GenerateMomentDownloadUrl($clientInfo: String, $momentId: ID!) {
    generateMomentDownloadURL(clientInfo: $clientInfo, momentID: $momentId)
  }
`

export async function refreshDownloadUrl(momentId: string): Promise<RefreshDownloadUrlResult | null> {
  const result = await mutateGraphQl<GenerateMomentDownloadUrlResult, GenerateMomentDownloadUrlParams>({
    mutation: GenerateMomentDownloadUrl,
    variables: { clientInfo: await getClientInfo(), momentId }
  })

  checkForErrors('generateMomentDownloadURL', result)
  return (result.data?.generateMomentDownloadURL
    ? deserializeDownloadData(JSON.parse(result.data.generateMomentDownloadURL))
    : null)
}

function deserializeDownloadData(data: GenerateMomentDownloadUrl_Data): RefreshDownloadUrlResult | null {
  if (!data) {
    return null
  }

  return {
    downloadEol: data.downloadEOL,
    downloadUrl: data.downloadURL,
    feedUrls: deserializeFeedUrls(data.feedURLs),
    transcriptionDownloadUrls: data.transcriptionDownloadURLs ?? {},
  }
}

export async function fetchGiphyFile(url: string, name: string): Promise<File> {
  const res = await axios.get<Blob>(url, { responseType: 'blob' })
  return new File([res.data], name, { type: 'image/gif' })
}

export function createTranscriptionWebSocket(url: string, authMethod: string, creds: string): WebSocket {
  // We have to trim off extra '=' characters because the WebSocket won't allow it for some reason. Doubly weird since
  //  this is what the other clients are using.
  return new WebSocket(url, [authMethod, creds.replace(/=+$/, '')])
}

export async function uploadTranscription(url: string, segments: string[]): Promise<void> {
  const jsonSegments = segments.map(segment => JSON.parse(segment))
  const payload = JSON.stringify(jsonSegments)
  await axios.put(url, payload)
}

// The result is a stringified JSON of the entire moment. We don't need it now but adding this note in case we ever do.
type UpdateMomentVolResult = { updateMomentVol: string | null }
type UpdateMomentVolParams = ClientInfoParams & {
  backgroundHexColor?: string
  mentionedUserIds?: string
  momentId: string
  prepareDownload?: boolean
  text?: string
}
const UpdateMomentVol = gql`
  mutation UpdateMomentVol(
    $backgroundHexColor: String
    $clientInfo: String
    $mentionedUserIds: AWSJSON
    $momentId: ID!
    $prepareDownload: Boolean
    $text: String
  ) {
    updateMomentVol(
      backgroundHexColor: $backgroundHexColor
      clientInfo: $clientInfo
      mentionedUserIDs: $mentionedUserIds
      momentID: $momentId
      prepareDownload: $prepareDownload
      text: $text
    )
  }
`
type UpdateMomentParams = Omit<UpdateMomentVolParams, 'clientInfo' | 'mentionedUserIds'> & {
  mentionedUserIds?: MentionedUserId[]
}

export async function updateMoment(params: UpdateMomentParams): Promise<void> {
  const { mentionedUserIds, ...rest } = params

  const result = await mutateGraphQl<UpdateMomentVolResult, UpdateMomentVolParams>({
    mutation: UpdateMomentVol,
    variables: {
      ...rest,
      clientInfo: await getClientInfo(),
      mentionedUserIds: serializeMentionedUserIds(mentionedUserIds),
    },
  })

  checkForErrors('updateMomentVol', result)
}
