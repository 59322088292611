import EmPicker, { PickerProps as EmPickerProps, SelectedEmoji } from '@emoji-mart/react'
import styled from '@emotion/styled'
import { decomposeColor } from '@mui/material/styles'
import { Emoji as EmEmoji } from 'emoji-mart'
import Popover from 'modules/components/Popover'
import { EMOJI_PER_LINE, MAX_FREQUENT_ROWS } from 'modules/emoji-util'
import React, { useEffect, useMemo, useRef } from 'react'

export * as emojiUtils from 'modules/emoji-util'

type EmojiProps = {
  className?: string
  emojiId?: string
  fallback?: string
  nativeEmoji?: string
  size?: number
}
export const Emoji = ({ emojiId, nativeEmoji, ...rest }: EmojiProps): React.ReactElement => {
  const ref = useRef<HTMLElement | null>(null)
  const instance = useRef<EmEmoji | null>(null)
  const props = useMemo(() => ({
    id: emojiId,
    native: !emojiId ? nativeEmoji : undefined,
    set: 'apple',
    size: 21,
    ...rest
  }), [emojiId, nativeEmoji, rest])

  if (instance.current) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (instance.current as any).update(props)
  }

  useEffect(() => {
    instance.current = new EmEmoji({ ...props, ref })

    return () => {
      instance.current = null
    }
  }, [props])

  return React.createElement('div', { ref })
}

const PickerWrapper = styled.div`
  max-width: 232px;
  background-color: ${({ theme }) => theme.palette.background.popover};
  border-radius: 7px;

  em-emoji-picker {
    --rgb-background: ${({ theme }) => decomposeColor(theme.palette.background.popover).values.join(',')};
  }
`

type PickerProps = Omit<EmPickerProps,
  | 'autoFocus'
  | 'data'
  | 'emojiSize'
  | 'i18n'
  | 'navPosition'
  | 'previewPosition'
  | 'set'
  | 'skinTonesPosition'
  | 'theme'
  | 'version'
>
export const Picker = (props: PickerProps): React.ReactElement => (
  <PickerWrapper>
    <EmPicker
      autoFocus
      emojiSize={21}
      maxFrequentRows={MAX_FREQUENT_ROWS}
      navPosition="none"
      perLine={EMOJI_PER_LINE}
      previewPosition="none"
      set="apple"
      skinTonePosition="none"
      theme="dark"
      version={14}
      {...props}
    />
  </PickerWrapper>
)

type PickerPopoverProps = Omit<PickerProps, 'onEmojiSelect'> & {
  anchorEl: null | Element | (() => Element)
  isOpen: boolean
  onClose: () => void
  onSelect: (emoji: string) => void
}
export const PickerPopover = (props: PickerPopoverProps): React.ReactElement => {
  const { anchorEl, isOpen, onClose, onSelect, ...rest } = props

  const handleEmojiSelect = (emoji: SelectedEmoji) => {
    onSelect(emoji.native)
    onClose()
  }

  return (
    <Popover anchorEl={anchorEl} isOpen={isOpen} onClose={onClose}>
      <Picker {...rest} onEmojiSelect={handleEmojiSelect} />
    </Popover>
  )
}
