import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MentionedUserId, MomentTextParagraphs, TextVolleyColor } from 'modules/types/moments'
import { getPlaceholderParagraphs } from '../moments/utils'
import { AppState } from '../reducers'

export type Text = {
  color: string
  conversationId: string
  createdAt: number
  id: string
  isCanceled?: boolean
  isEditing?: boolean
  isErrored?: boolean
  mentionedUserIds?: MentionedUserId[]
  momentId?: string
  paragraphs?: MomentTextParagraphs
  parentConversationId?: string
  scheduledDate?: string
  teamId: string
  text?: string
}
type TextsDictionary = { [textId: string]: Text }
export type TextsState = {
  color: string
  texts: TextsDictionary
}

const initialState: TextsState = {
  color: TextVolleyColor.Blue,
  texts: {},
}

export type ChangeColorAction = PayloadAction<{ color: string }>
const changeColor: CaseReducer<TextsState, ChangeColorAction> = (state, action) => ({
  ...state,
  color: action.payload.color,
})

export type StartTextAction = PayloadAction<{ conversationId: string, teamId: string, textId: string }>
const startText: CaseReducer<TextsState, StartTextAction> = (state, action) => ({
  ...state,
  texts: {
    ...state.texts,
    [action.payload.textId]: {
      color: state.color,
      conversationId: action.payload.conversationId,
      createdAt: new Date().getTime(),
      id: action.payload.textId,
      teamId: action.payload.teamId,
    },
  },
})

type EditTextPayload = {
  color: string
  conversationId: string
  createdOn: string
  momentId: string
  teamId: string
  textId: string
}
export type EditTextAction = PayloadAction<EditTextPayload>
const editText: CaseReducer<TextsState, EditTextAction> = (state, action) => ({
  ...state,
  texts: {
    ...state.texts,
    [action.payload.textId]: {
      color: action.payload.color,
      conversationId: action.payload.conversationId,
      createdAt: new Date(action.payload.createdOn).getTime(),
      id: action.payload.textId,
      isEditing: true,
      momentId: action.payload.momentId,
      teamId: action.payload.teamId,
    },
  },
})

type TextMomentCreatedPayload = { createdOn: string, momentId: string, parentConversationId?: string, textId: string }
export type TextMomentCreatedAction = PayloadAction<TextMomentCreatedPayload>
const textMomentCreated: CaseReducer<TextsState, TextMomentCreatedAction> = (state, action) => {
  const text = state.texts[action.payload.textId]
  if (!text) {
    return state
  }

  return {
    ...state,
    texts: {
      ...state.texts,
      [text.id]: {
        ...text,
        momentId: action.payload.momentId,
        parentConversationId: action.payload.parentConversationId,
      },
    },
  }
}

export type CancelTextAction = PayloadAction<{ textId: string }>
const cancelText: CaseReducer<TextsState, CancelTextAction> = (state, action) => {
  const text = state.texts[action.payload.textId]
  if (!text) {
    return state
  }

  return {
    ...state,
    texts: {
      ...state.texts,
      [text.id]: {
        ...text,
        isCanceled: true,
      },
    },
  }
}

export type RemoveTextAction = PayloadAction<{ textId: string }>
const removeText: CaseReducer<TextsState, RemoveTextAction> = (state, action) => ({
  ...state,
  texts: Object.entries(state.texts).reduce<TextsDictionary>((texts, [id, text]) => {
    if (id !== action.payload.textId) {
      texts[id] = text
    }

    return texts
  }, {}),
})

export type TextErrorAction = PayloadAction<{ textId: string }>
const textError: CaseReducer<TextsState, TextErrorAction> = (state, action) => {
  const text = state.texts[action.payload.textId]
  if (!text) {
    return state
  }

  return {
    ...state,
    texts: {
      ...state.texts,
      [text.id]: {
        ...text,
        isErrored: true,
      },
    },
  }
}

type SendTextPayload = {
  color: string
  mentionedUserIds: MentionedUserId[]
  scheduledDate?: string
  text: string
  textId: string
}
export type SendTextAction = PayloadAction<SendTextPayload>
const sendText: CaseReducer<TextsState, SendTextAction> = (state, action) => {
  const text = state.texts[action.payload.textId]
  if (!text) {
    return state
  }

  return {
    ...state,
    texts: {
      ...state.texts,
      [text.id]: {
        ...text,
        color: action.payload.color,
        mentionedUserIds: action.payload.mentionedUserIds,
        paragraphs: getPlaceholderParagraphs(action.payload.text),
        scheduledDate: action.payload.scheduledDate,
        text: action.payload.text,
      },
    },
  }
}
type SendTextSuccessPayload = {
  momentId: string
  scheduledDate?: string
  teamId: string
  textId: string
  wasEdited?: boolean
}
export type SendTextSuccessAction = PayloadAction<SendTextSuccessPayload>

const textsSlice = createSlice({
  name: 'texts',
  initialState,
  reducers: {
    cancelText,
    cancelTextSuccess: removeText,

    changeColor,

    editText,

    sendText,
    sendTextSuccess: (state: TextsState, _action: SendTextSuccessAction) => state,

    startText,

    textError,

    textMomentCompleted: removeText,
    textMomentCreated,
  },
})

export const actions = textsSlice.actions
export const selector = {
  name: textsSlice.name,
  select: (appState: AppState): TextsState => appState.texts,
  isComposingText: (appState: AppState): boolean => !!Object.keys(appState.texts.texts).length
}
export default textsSlice.reducer
