import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../reducers'

export const CAMERA_SLEEP_DEFAULT = 120
export const CAMERA_SLEEP_NEVER = 9999
export type MediaDevice = {
  id: string
  label: string
}
type MediaSettings = {
  availableVideoDevices: MediaDevice[]
  availableAudioDevices: MediaDevice[]
  selectedVideoDevice?: MediaDevice
  selectedAudioDevice?: MediaDevice
}
type NetworkStatus = 'online' | 'offline' | 'suspended'
export type DeviceSettingsState = MediaSettings & {
  cameraSleep: number
  initialized: boolean
  isMediaRecorderSupported: boolean
  networkStatus: NetworkStatus
}
export const initialState: DeviceSettingsState = {
  cameraSleep: CAMERA_SLEEP_DEFAULT,
  availableVideoDevices: [],
  availableAudioDevices: [],
  initialized: false,
  isMediaRecorderSupported: false,
  networkStatus: navigator.onLine ? 'online' : 'offline'
}

type InitializeSettingsPayload = MediaSettings & { cameraSleep: number, isMediaRecorderSupported: boolean }
export type InitializeSettingsAction = PayloadAction<InitializeSettingsPayload>
const initializeSettingsSuccess: CaseReducer<DeviceSettingsState, InitializeSettingsAction> = (state, action) => ({
  ...state,
  ...action.payload,
  initialized: true,
})

export type AvailableMediaDevicesChangedAction = PayloadAction<MediaSettings>
const availableMediaDevicesChanged: CaseReducer<DeviceSettingsState, AvailableMediaDevicesChangedAction> = (state, action) => ({
  ...state,
  ...action.payload,
})

export type UpdateSelectedAudioDeviceAction = PayloadAction<MediaDevice>
export type UpdateSelectedAudioDeviceSuccessAction = PayloadAction<MediaDevice>
const updateSelectedAudioDeviceSuccess: CaseReducer<DeviceSettingsState, UpdateSelectedAudioDeviceSuccessAction> = (state, action) => ({
  ...state,
  selectedAudioDevice: action.payload
})

export type UpdateSelectedVideoDeviceAction = PayloadAction<MediaDevice>
export type UpdateSelectedVideoDeviceSuccessAction = PayloadAction<MediaDevice>
const updateSelectedVideoDeviceSuccess: CaseReducer<DeviceSettingsState, UpdateSelectedVideoDeviceSuccessAction> = (state, action) => ({
  ...state,
  selectedVideoDevice: action.payload
})

export type NetworkStatusChangedAction = PayloadAction<{ networkStatus: NetworkStatus }>
const networkStatusChanged: CaseReducer<DeviceSettingsState, NetworkStatusChangedAction> = (state, action) => ({
  ...state,
  networkStatus: action.payload.networkStatus
})

export type ValidateMediaSourcesAction = PayloadAction<{ streamAudioId?: string, streamVideoId?: string }>

export type SetCameraSleepAction = PayloadAction<{ seconds: number }>
const setCameraSleep: CaseReducer<DeviceSettingsState, SetCameraSleepAction> = (state, action) => ({
  ...state,
  cameraSleep: action.payload.seconds
})

const deviceSettingsSlice = createSlice({
  name: 'device-settings',
  initialState,
  reducers: {
    availableMediaDevicesChanged,

    initializeSettings: (state, _: PayloadAction) => state,
    initializeSettingsSuccess,

    networkStatusChanged,

    selectedDeviceDisconnected: (state, _: PayloadAction) => state,

    setCameraSleep,

    updateSelectedAudioDevice: (state, _: UpdateSelectedAudioDeviceAction) => state,
    updateSelectedAudioDeviceSuccess,
    updateSelectedVideoDevice: (state, _: UpdateSelectedVideoDeviceAction) => state,
    updateSelectedVideoDeviceSuccess,

    validateMediaSources: (state, _: ValidateMediaSourcesAction) => state,
  }
})

export const actions = deviceSettingsSlice.actions
export const selector = {
  name: deviceSettingsSlice.name,
  select: (state: AppState): DeviceSettingsState => state.deviceSettings,
}
export default deviceSettingsSlice.reducer
