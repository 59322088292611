import { call, put, SagaGenerator, select, take } from 'typed-redux-saga'

import { isElectron } from 'modules/electron-utils'
import logger from 'modules/logger'
import { actions } from '../slice'
import { actions as alertActions } from '../../alerts/slice'
import { selector as conversationSelector } from '../../conversations/slice'
import { selector as deviceSettingsSelector } from '../../device-settings/slice'
import { actions as modalsActions } from '../../modals/slice'
import { createElectronEventChannel } from '../../utils'
import { TimeLimit } from 'modules/types/tier-limits'

/*
  # How to screen record from Electron

  - User initiates screen recording via `launch-screen-recording` Electron event (see screen-recording hook)
  - User chooses a screen/app to record in Electron and `on-screen-recording-starting` is sent from Electron to web
  - Web picks up the event via event channel and creates a desktop moment via API
  - After the moment is created, web tells Electron via `screen-recording-ready` that it's OK to start recording
  - Electron starts countdown and sends `on-screen-recording-started` event when media recorder begins recording
  - Electron starts recording and sends data segments via `on-screen-recording-data` events
  - Web picks up data segment events and uploads them (standard streaming volley procedure)
  - User hits stop in Electron and `on-screen-recording-stop` is sent from Electron to web
  - Web watches for all segments to finish uploading and tells the API that we're done (standard streaming volley
    procedure)

  ## Caveats

  - Different events are used to signify that the user canceled depending on where they are in the recording process. If
    they cancel while choosing a screen/app to record, `on-screen-recording-launch-canceled` is sent from Electron to web.
    If they cancel while recording, a cancel flag is sent along with the `on-screen-recording-stop` event.
*/

type OnScreenRecordingStartingPayload = {
  conversationId: string
  isIntro?: boolean // TODO: deprecated
  noTranscription?: boolean
  reviewBeforeSend: boolean
  teamId: string
  uploadId: string
}
export function* watchOnScreenRecordingStartingElectronEvent(): SagaGenerator<void> {
  const channel = createElectronEventChannel<OnScreenRecordingStartingPayload>('on-screen-recording-starting')
  if (!channel) {
    return
  }

  while (true) {
    const { conversationId, isIntro, noTranscription, reviewBeforeSend, teamId, uploadId } = yield* take(channel)

    yield* put(actions.createDesktopUpload({
      conversationId,
      noTranscription: noTranscription || isIntro,
      reviewBeforeSend: reviewBeforeSend || !!isIntro,
      teamId,
      uploadId,
    }))
    yield* call(onDesktopVolleyStarted, conversationId, uploadId, teamId, reviewBeforeSend || !!isIntro, noTranscription || isIntro)
    yield* put(actions.prepareToRecordScreenCanceled())
  }
}

export function* watchOnScreenRecordingStartedElectronEvent(): SagaGenerator<void> {
  const channel = createElectronEventChannel<{ uploadId: string }>('on-screen-recording-started')
  if (!channel) {
    return
  }

  while (true) {
    const { uploadId } = yield* take(channel)

    yield* put(actions.recordScreenCountdownFinished({ uploadId }))
  }
}

export function* watchOnScreenRecordingErrorElectronEvent(): SagaGenerator<void> {
  const channel = createElectronEventChannel<{ reason: string }>('on-screen-recording-error')
  if (!channel) {
    return
  }

  while (true) {
    const { reason } = yield* take(channel)

    if (reason === 'media-recorder-init-failure') {
      yield* put(alertActions.pushAlert({
        severity: 'error',
        message: 'There was an error starting the screen recording. Check your microphone settings and try again.'
      }))
    }
  }
}

type OnScreenRecordingDataParams = {
  data: Uint8Array
  index: number
  isFinalSegment: boolean
  thumbnailImgUrl?: string
  type: string
  uploadId: string
}
export function* watchOnScreenRecordingDataElectronEvent(): SagaGenerator<void> {
  const channel = createElectronEventChannel<OnScreenRecordingDataParams>('on-screen-recording-data')
  if (!channel) {
    return
  }

  while (true) {
    const { data, index, isFinalSegment, thumbnailImgUrl, type, uploadId } = yield* take(channel)

    const blob = new Blob([data], { type })
    yield* put(actions.uploadSegment({
      data: blob,
      index,
      isFinalSegment,
      thumbUrl: thumbnailImgUrl,
      uploadId,
    }))
  }
}

type OnScreenRecordingAudioDataParams = {
  data: Uint8Array
  type: string
  uploadId: string
}
export function* watchOnScreenRecordingTranscriptionAudioDataElectronEvent(): SagaGenerator<void> {
  const channel = createElectronEventChannel<OnScreenRecordingAudioDataParams>('on-screen-recording-transcription-audio-data')
  if (!channel) {
    return
  }

  while (true) {
    const { data, type, uploadId } = yield* take(channel)

    const blob = new Blob([data], { type })
    yield* put(actions.uploadTranscriptionAudioSegment({ data: blob, uploadId }))
  }
}

export function* watchOnScreenRecordingLaunchCanceledElectronEvent(): SagaGenerator<void> {
  const channel = createElectronEventChannel('on-screen-recording-launch-canceled')
  if (!channel) {
    return
  }

  while (true) {
    yield* take(channel)

    yield* put(actions.prepareToRecordScreenCanceled())
  }
}

type OnScreenRecordingStopParams = {
  conversationId: string
  forced?: boolean
  isCanceled: boolean
  reviewBeforeSend: boolean
  teamId: string
  uploadId: string
}
export function* watchOnScreenRecordingStopElectronEvent(): SagaGenerator<void> {
  const channel = createElectronEventChannel<OnScreenRecordingStopParams>('on-screen-recording-stop')
  if (!channel) {
    return
  }

  while (true) {
    const { conversationId, forced, isCanceled, reviewBeforeSend, teamId, uploadId } = yield* take(channel)

    if (isCanceled) {
      yield* put(actions.cancelUpload({ uploadId }))
      continue
    }

    yield* put(actions.requestStop({
      conversationId,
      reviewBeforeSend: reviewBeforeSend || !!forced,
      teamId,
      uploadId,
    }))

    if (forced) {
      const timeLimit = yield* getScreenRecordingTimeLimit(conversationId)
      yield* put(modalsActions.showRecordingLimitReached({ limitInMinutes: timeLimit.totalMinutes }))
    }
  }
}

function* getScreenRecordingTimeLimit(conversationId: string): SagaGenerator<TimeLimit> {
  const tierLimits = yield* select(conversationSelector.currentSubscriptionTier(conversationId))
  return tierLimits.desktopTime
}

export function* onDesktopVolleyStarted(conversationId: string, uploadId: string, teamId: string, reviewBeforeSend: boolean, noTranscription?: boolean): SagaGenerator<void> {
  if (!isElectron()) {
    return
  }

  const { selectedAudioDevice } = yield* select(deviceSettingsSelector.select)
  if (!selectedAudioDevice) {
    yield* invokeElectronAction('screen-recording-error', { reason: 'no-selected-audio-device' })
    yield* put(alertActions.pushAlert({
      severity: 'error',
      message: 'Unable to find the selected microphone. Check your audio/video settings and try again.'
    }))

    return
  }

  const timeLimit = yield* getScreenRecordingTimeLimit(conversationId)

  yield* invokeElectronAction('screen-recording-ready', {
    audioDeviceId: selectedAudioDevice.id,
    audioDeviceLabel: selectedAudioDevice.label,
    isIntro: noTranscription && reviewBeforeSend, // TODO: deprecated
    noTranscription,
    reviewBeforeSend,
    timeLimit,
    uploadId,
  })
}

// trying to detect minimized screens & failed cameras, even the smallest segments will be well over this size
const MIN_FIRST_DESKTOP_BLOB_SIZE = 30000
export function isDesktopBlobTooSmall(size: number): boolean {
  return size < MIN_FIRST_DESKTOP_BLOB_SIZE
}

export function* handleEmptyDesktopDataBlob(uploadId: string): SagaGenerator<void> {
  yield* onScreenRecordingError('no-data')
  yield* put(actions.cancelUpload({ uploadId }))
  yield* put(alertActions.pushAlert({
    severity: 'error',
    message: `Nothing to record. Is the window you're recording minimized?`
  }))
}

// these should correlate with the Electron side of things
type Reason = 'create-moment-error' | 'no-data' | 'upload-segment-error'
export function* onScreenRecordingError(reason: Reason): SagaGenerator<void> {
  yield* invokeElectronAction('screen-recording-error', { reason })
}

function* invokeElectronAction<T>(channel: string, params?: T): SagaGenerator<void> {
  if (!isElectron() || !window.ipcRenderer) {
    return
  }

  try {
    if (params) {
      yield* call(window.ipcRenderer.invoke, channel, params)
    } else {
      yield* call(window.ipcRenderer.invoke, channel)
    }
  } catch (error) {
    logger.warn('Error invoking electron', error, { channel, params })
  }
}
