import { ApolloQueryResult, MutationOptions, QueryOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'
import { FetchResult } from '@apollo/client/link/core'
import { getAuthenticatedClient, getUnauthenticatedClient } from './apollo'

export type GraphQLType =
  | 'AWSJSON'
  | 'AWSDateTime'
  | 'Boolean'
  | 'ConversationType'
  | 'ID'
  | 'ID!'
  | '[ID]'
  | '[ID]!'
  | 'Int'
  | 'Int!'
  | 'String'
  | 'String!'
  | 'ThumbKey'

type CheckErrorFunction = (res: FetchResult) => void
type BackendMapping = { backendKey: string, type: GraphQLType }
export type TypeDictionary = {
  [ourKey: string]: BackendMapping | GraphQLType
}

export type ConversionDoc = {
  mutationName: string
  typeDictionary: TypeDictionary
  checkForErrors: CheckErrorFunction
}

export function createMutationMap(mutationName: string, typeDictionary: TypeDictionary): ConversionDoc {
  return {
    mutationName,
    typeDictionary,
    checkForErrors: createCheckErrorFunction(mutationName)
  }
}

export function buildMutation<T>(ourRawParams: T, conversionDoc: ConversionDoc): DocumentNode {
  const { typeDictionary, mutationName } = conversionDoc
  const inputVariables: string[] = []
  const mutationVariables: string[] = []

  Object.entries(ourRawParams).forEach(([key, value]) => {
    if (!(key in typeDictionary)) {
      throw new Error(`Unknown option "${key}" in params for mutationName "${mutationName}"`)
    }
    if (value === undefined) {
      return
    }
    const currentMapping = typeDictionary[key]
    if (isGraphQLType(currentMapping)) {
      inputVariables.push(`$${key}: ${currentMapping}`)
      mutationVariables.push(`${key}: $${key}`)
    } else {
      inputVariables.push(`$${key}: ${currentMapping.type}`)
      mutationVariables.push(`${currentMapping.backendKey}: $${key}`)
    }
  })

  return gql`${`
    mutation ${mutationName}(
      ${inputVariables.join(', \n')}
    ) {
      ${mutationName} (
        ${mutationVariables.join(', \n')}
      )
    }
  `}`
}

function isGraphQLType(value: BackendMapping | GraphQLType): value is GraphQLType {
  return typeof value === 'string'
}

function createCheckErrorFunction(mutationName: string): CheckErrorFunction {
  return (res: FetchResult) => checkForErrors(mutationName, res, true)
}

export function checkForErrors(dataField: string, { data }: FetchResult, verifyResultData = false): void {
  if (!data) {
    if (verifyResultData)
      throw new Error(`Didn't get data back from running "${dataField}".`)
    else
      return
  }

  if (data.error)
    throw new Error(`Got an error response on "${dataField}": ${data.error}`)

  const resultData = data[dataField]
  if (!resultData) {
    if (verifyResultData)
      throw new Error(`Didn't get expected data back from running "${dataField}".`)
    else
      return
  }

  if (typeof resultData === 'string') {
    const parsedResponse = JSON.parse(resultData)
    if (parsedResponse.error)
      throw new Error(`Found error in response on "${dataField}": ${parsedResponse.error}`)
  } else if (resultData.error) {
    throw new Error(`Found error in response on "${dataField}": ${resultData.error}`)
  }
}

export async function queryGraphQl<TResult, TVars>(options: QueryOptions<TVars, TResult>): Promise<ApolloQueryResult<TResult>> {
  return (await getAuthenticatedClient()).query<TResult, TVars>({ ...options, fetchPolicy: 'network-only' })
}
export async function queryUnauthenticatedGraphQl<TResult, TVars>(options: QueryOptions<TVars, TResult>): Promise<ApolloQueryResult<TResult>> {
  return (await getUnauthenticatedClient()).query<TResult, TVars>({ ...options, fetchPolicy: 'network-only' })
}
export async function mutateGraphQl<TResult, TVars>(options: MutationOptions<TResult, TVars>): Promise<FetchResult<TResult>> {
  return (await getAuthenticatedClient()).mutate<TResult, TVars>(options)
}
export async function mutateUnauthenticatedGraphQl<TResult, TVars>(options: MutationOptions<TResult, TVars>): Promise<FetchResult<TResult>> {
  return (await getUnauthenticatedClient()).mutate<TResult, TVars>(options)
}
