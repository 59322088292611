export enum SubscriptionsAction {
  Add = 'ADD',
  KeepAlive = 'KEEP_ALIVE',
  Reauthenticate = 'REAUTH',
  Remove = 'REMOVE',
  Shutdown = 'SHUTDOWN',
  SubscribeToEntity = 'SUBSCRIBE_TO_ENTITY',
  Unknown = 'UNKNOWN',
  Update = 'UPDATE',
}
export enum EntityType {
  Conversation = 'CONVERSATION',
  Moment = 'MOMENT',
  Team = 'TEAM',
  Unknown = 'UNKNOWN',
  User = 'USER',
}
export type SubscriptionsBody = {
  actor: string
  entityId: string
  entityType: EntityType
  message?: string
  parentId?: string
}
export type SubscriptionsMessage = {
  action: SubscriptionsAction
  body: SubscriptionsBody
  statusCode: number
}
