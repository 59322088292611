import { Participant } from './users'

type ConversationTokenData = {
  conversationId: string
}
type TeamTokenData = {
  teamId: string
}
type PublicMomentTokenData = {
  momentId: string
  copyToClipboard?: boolean
}
type TeamConversationTokenData = TeamTokenData & ConversationTokenData
export type TokenData = ConversationTokenData | TeamTokenData | TeamConversationTokenData | PublicMomentTokenData
export type TokenParams = { title?: string } & (
  | {
    type: TokenType.Conversation | TokenType.User | TokenType.AdhocOneOnOne | TokenType.PremiumUser
    data: ConversationTokenData
  }
  | {
    type: TokenType.Team | TokenType.TeamAdmin
    data: TeamTokenData
  }
  | {
    type: TokenType.TeamConversation | TokenType.TeamOneOnOne
    data: TeamConversationTokenData
  }
  | {
    type: TokenType.Slack
    data: TeamTokenData
  }
  | {
    type: TokenType.PublicMoment
    data: PublicMomentTokenData
  }
)
export type TokenTargetEntity = {
  id: string
  isGrowTeam?: boolean
  isPublic?: boolean
  name: string
  thumbUrl: string
}
export enum TokenType {
  Conversation = 'INVITE_TO_CONVERSATION',
  AdhocOneOnOne = 'FIXED_WITHIN_ADHOC',
  PremiumUser = 'TALK_TO_USER_PREMIUM',
  PublicMoment = 'PUBLIC_MOMENT',
  Referral = 'REFERRAL',
  SellerCoupon = 'SELLER_DISCOUNT_COUPON',
  Slack = 'SLACK_INSTALL',
  TeamOneOnOne = 'FIXED_WITHIN_TEAM',
  Team = 'INVITE_TO_TEAM',
  TeamAdmin = 'INVITE_TO_TEAM_AS_ADMIN',
  TeamConversation = 'INVITE_TO_TEAM_AND_CONVERSATION',
  User = 'TALK_TO_USER',
  VerifyEmail = 'VERIFY_EMAIL',
}
export enum TokenState {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
}
export type Token = {
  conversation?: TokenTargetEntity
  creator: Participant
  data: string
  expiresAt: string | null
  embedCode: string | null
  id: string
  landingUrl: string
  state: TokenState | null
  team?: TokenTargetEntity
  type: TokenType
  vData: { [key: string]: string }
}
export type TokenKeyResult = 'pending' | 'error' | string
export type TokenResult = {
  error: string | null
  id: TokenKeyResult | null
  token: Token | null
}
