import styled from '@emotion/styled'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import { MOBILE_BREAKPOINT } from 'modules/constants'
import React, { useCallback } from 'react'
import Body from './Body'
import Header from './Header'

export * from './Components'

const BACKDROP_CLICK = 'backdropClick'

const VolDialog = styled(MuiDialog)`
  & .MuiDialog-paper {
    border-radius: 10px;

    @media(max-width: ${MOBILE_BREAKPOINT}px) {
      margin: 32px 0;
    }
  }
`

type DialogProps = Omit<MuiDialogProps, 'onClose' | 'open'> & {
  ariaLabeledById: string
  disableBackdropClick?: boolean
  isOpen: boolean
  onClose?: OnCloseEvent
}
export type OnCloseEvent = (e?: unknown, reason?: string) => void
interface DialogExport extends React.FC<DialogProps> {
  Header: typeof Header
  Body: typeof Body
}
const Dialog: DialogExport = (props: DialogProps): React.ReactElement => {
  const { ariaLabeledById, children, disableBackdropClick, isOpen, onClose, ...rest } = props

  const handleClose = useCallback((e, reason) => {
    if (!onClose || (disableBackdropClick && reason === BACKDROP_CLICK)) {
      return
    }

    onClose(e, reason)
  }, [disableBackdropClick, onClose])

  return (
    <VolDialog aria-labelledby={ariaLabeledById} onClose={handleClose} open={isOpen} {...rest}>
      {children}
    </VolDialog>
  )
}
Dialog.Header = Header
Dialog.Body = Body

export default Dialog
