import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { AppState } from '../reducers'

export type ConversationMessagePart = { conversationId: string, teamId: string, text: string }
export type ElectronMessagePart = { electronAction: string, electronArgs?: unknown[], text: string }
export type LinkMessagePart = { isExternal?: boolean, text: string, url: string }
export type MessagePart =
  | string
  | ConversationMessagePart
  | ElectronMessagePart
  | LinkMessagePart
export type AlertMessage = string | MessagePart[]
export type Severity = 'error' | 'warning' | 'info' | 'success'
export type Alert = {
  message: AlertMessage
  severity: Severity
}
export type AlertsState = {
  isAlertOpen: boolean
  pendingAlerts: Alert[]
}
export const initialState: AlertsState = {
  isAlertOpen: false,
  pendingAlerts: [],
}

export type PushAlertAction = PayloadAction<{ message: AlertMessage, severity: Severity }>
const pushAlert: CaseReducer<AlertsState, PushAlertAction> = (state, action) => ({
  ...state,
  isAlertOpen: true,
  pendingAlerts: [...state.pendingAlerts, { ...action.payload }],
})

const shiftAlert: CaseReducer<AlertsState, PayloadAction> = (state, _) => {
  const pendingAlerts = state.pendingAlerts.slice(1)
  return {
    ...state,
    isAlertOpen: !!pendingAlerts.length,
    pendingAlerts,
  }
}

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    pushAlert,
    shiftAlert
  }
})

export const actions = alertsSlice.actions
export const selector = {
  name: alertsSlice.name,
  select: (state: AppState): AlertsState => state.alerts,
}
export default alertsSlice.reducer
