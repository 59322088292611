import { css } from '@emotion/react'
import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    fontFamilyBody: string
    fontWeightExtraBold: number
    fontWeightBlack: number
  }
  interface TypographyOptions {
    fontFamilyBody: string
    fontWeightExtraBold: number
    fontWeightBlack: number
  }
}
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    audioBlue: string
    audioPink: string
    consultWithMe: string
    popoverDivider: string
    successHighlight: string
    volleyGray01: string
    volleyGray02: string
    volleyGray03: string
    volleyGray04: string
    volleyGray05: string
    volleyPink: string
    volleyWarning: string,
    disabled: string,
    iconDisabled: string,
    buttonHighlight: {
      light: string,
      dark: string
    }
  }
  interface PaletteOptions {
    audioBlue: string
    audioPink: string
    consultWithMe: string
    popoverDivider: string
    successHighlight: string
    volleyGray01: string
    volleyGray02: string
    volleyGray03: string
    volleyGray04: string
    volleyGray05: string
    volleyPink: string
    volleyWarning: string,
    disabled: string,
    iconDisabled: string,
    buttonHighlight: {
      light: string,
      dark: string
    }
  }
  interface TypeBackground {
    avatar: string
    avatarDark: string
    expert: string
    popover: string
    tutorial: string
    tutorialIncomplete: string,
    playbackButton: string,
    options: string
  }
}

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // affects more than just buttons, nukes them everywhere
      },
    },
  },
  palette: {
    audioBlue: '#000e52', // 0, 14, 82
    audioPink: '#6e002b', // 79, 0, 31
    background: {
      avatar: 'linear-gradient(to bottom, #a8adba, #858a94)',
      avatarDark: 'linear-gradient(225deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%)',
      expert: 'linear-gradient(to bottom right, #f7b500, #b620e0, #32c5ff)',
      paper: '#f5f5fa',
      popover: '#222222',
      tutorial: '#6236ff', // 98, 54, 255
      tutorialIncomplete: '#5329cf', // 82, 47, 206
      playbackButton: '#00000033',
      options: '#ffffff3f'
    },
    consultWithMe: '#34c759', // 52, 199, 89
    divider: '#e1e1e9', // 225, 225, 233
    error: {
      main: '#f80000',
      light: '#dc3545',
    },
    disabled: '#808080',
    iconDisabled: '#535353',
    popoverDivider: '#2d2d2d',
    primary: {
      main: '#007bff',
      light: '#0094ff',
      dark: '#0062cc',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#f81268',
      dark: '#c60e53',
    },
    buttonHighlight: {
      light: '#0076FFFF',
      dark: '#0076FFB2'
    },
    successHighlight: '#6dd400',
    volleyGray01: '#1c1b1b', // 28, 27, 27
    volleyGray02: '#272727', // 39, 39, 39
    volleyGray03: '#3a3a3c', // 58, 58, 60
    volleyGray04: '#7a7a7c', // 122, 122, 124
    volleyGray05: '#9c9ca3', // 156, 156, 163
    volleyPink: '#ff0065', // 255, 0, 101
    volleyWarning: '#ffcc00', // 255, 204, 0
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontFamilyBody: 'Arial, Helvetica, sans-serif',
    fontWeightBlack: 900,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
  },
})

export const GlobalStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html,
  body,
  #app {
    height: 100%;
    margin: 0;
    background-color: ${theme.palette.background.paper};
  }
  body {
    font-family: Poppins, sans-serif;
    font-weight: ${theme.typography.fontWeightRegular};
    line-height: 1.1;
    color: #222222;
  }
  h1, p {
    margin-top: 0;
  }
  label {
    color: #7f7f7f;
    font-size: 11px;
    margin-left: 1em;
    margin-bottom: 6px;
  }
  a {
    color: ${theme.palette.primary.main};
    text-decoration: none;
    background-color: transparent;
  }
  a:hover {
    text-decoration: none;
  }
  img {
    vertical-align: middle;
  }
  button:focus {
    outline: none;
  }
`

export default theme
