type BatchRecordDictionary<T> = { [id: string]: T }
export type BatchResult<T> = {
  missingIds: string[]
  refetchIds: string[]
  success: BatchRecordDictionary<T>
}
type BatchMomentsResult<T> = BatchResult<T> & {
  expired: BatchRecordDictionary<T>
}

export function deserializeBatchResults<T, U>(raw: string | null | undefined, deserializeSuccess: (data: U) => BatchRecordDictionary<T>): BatchResult<T> {
  const { noSuchID, spaceExceeded, success } = JSON.parse(raw ?? '{}')

  return {
    missingIds: noSuchID ? Object.keys(noSuchID) : [],
    refetchIds: spaceExceeded ? Object.keys(spaceExceeded) : [],
    success: success ? deserializeSuccess(success) : {},
  }
}
export function deserializeBatchMomentsResults<T, U>(raw: string | null | undefined, deserializeSuccess: (data: U) => BatchRecordDictionary<T>): BatchMomentsResult<T> {
  const { expired, noSuchID, spaceExceeded, success } = JSON.parse(raw ?? '{}')

  return {
    expired: expired ? deserializeSuccess(expired) : {},
    missingIds: noSuchID ? Object.keys(noSuchID) : [],
    refetchIds: spaceExceeded ? Object.keys(spaceExceeded) : [],
    success: success ? deserializeSuccess(success) : {},
  }
}
