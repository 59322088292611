import styled from '@emotion/styled'
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip'
import MuiPopper from '@mui/material/Popper'
import React from 'react'

const VolTooltipPopper = styled(MuiPopper)`
  & .MuiTooltip-tooltip {
    background-color: ${({ theme }) => theme.palette.common.black};
    font-size: 11px;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    padding: 6px 7px;
    border-radius: 7px;
  }
  & .MuiTooltip-arrow {
    color: ${({ theme }) => theme.palette.common.black};
  }
`

type TooltipProps = Omit<MuiTooltipProps, 'open'> & {
  disabled?: boolean
  isOpen?: boolean
}
const Tooltip = ({ title, children, disabled, isOpen, ...rest }: TooltipProps): React.ReactElement => disabled
  ? children
  : (
    <MuiTooltip
      arrow
      enterDelay={500}
      title={title}
      {...rest}
      open={isOpen}
      PopperComponent={VolTooltipPopper}
    >
      {children}
    </MuiTooltip>
  )

export default Tooltip
