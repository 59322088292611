import { Action, CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../reducers'

type HasRequested = {
  analytics: boolean
  app: boolean
  conversations: boolean
  features: boolean
  identity: boolean
  teams: boolean
  volleyStatus: boolean
}
type HasRequestedKey = keyof HasRequested
type InitializeState = {
  hasRequested: HasRequested
}
const initialState: InitializeState = {
  hasRequested: {
    analytics: false,
    app: false,
    conversations: false,
    features: false,
    identity: false,
    teams: false,
    volleyStatus: false,
  },
}

const trackRequest: <T extends Action>(key: HasRequestedKey) =>
  CaseReducer<InitializeState, T> = key => (state, _action) => ({
    ...state,
    hasRequested: {
      ...state.hasRequested,
      [key]: true
    },
  })

export type InitializeAppAction = PayloadAction<{ hasFeatures: boolean }>

const initializeSlice = createSlice({
  name: 'initialize',
  initialState,
  reducers: {
    requestAnalytics: trackRequest<PayloadAction>('analytics'),
    requestApp: trackRequest<InitializeAppAction>('app'),
    requestConversations: trackRequest<PayloadAction>('conversations'),
    requestFeatures: trackRequest<PayloadAction>('features'),
    requestIdentity: trackRequest<PayloadAction>('identity'),
    requestTeams: trackRequest<PayloadAction>('teams'),
    requestVolleyStatus: trackRequest<PayloadAction>('volleyStatus'),
  }
})

export const actions = initializeSlice.actions
export const selector = {
  name: initializeSlice.name,
  select: (state: AppState): InitializeState => state.initialize,
}
export default initializeSlice.reducer
