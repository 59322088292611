import { call, put, SagaGenerator, takeEvery } from 'typed-redux-saga'
import { actions, ConnectSlackAction, DisconnectSlackAction, FetchSlackAction } from './slice'
import { actions as alertActions } from '../alerts/slice'
import * as api from 'modules/api/slack'
import logger from 'modules/logger'

export default function*(): SagaGenerator<void> {
  yield* takeEvery(actions.fetchSlack, fetchSlack)
  yield* takeEvery(actions.connectSlack, connectSlack)
  yield* takeEvery(actions.disconnectSlack, disconnectSlack)
}

export function* fetchSlack({ payload: { teamId, conversationId } }: FetchSlackAction): SagaGenerator<void> {
  try {
    const slack = yield* call(api.getSlack, { teamId, conversationId })
    yield* put(actions.fetchSlackSuccess({ integration: slack, teamId }))
  } catch (error) {
    logger.error('Error getting slack integration info', error, { teamId })
    yield* put(actions.fetchSlackFailure({ teamId }))
  }
}

export function* connectSlack({ payload }: ConnectSlackAction): SagaGenerator<void> {
  try {
    yield* call(api.updateSlack, { ...payload })
    yield* put(actions.connectSlackSuccess(payload))
    yield* put(alertActions.pushAlert({ severity: 'success', message: 'Channels successfully linked' }))
  } catch (error) {
    logger.error('Error connect slack integration info', error, { ...payload })
    yield* put(actions.connectSlackFailure(payload))
  }
}

export function* disconnectSlack({ payload }: DisconnectSlackAction): SagaGenerator<void> {
  try {
    yield* call(api.deleteSlack, { ...payload })
    yield* put(actions.disconnectSlackSuccess(payload))
    yield* put(actions.fetchSlack({ teamId: payload.teamId, conversationId: payload.conversationId }))
  } catch (error) {
    logger.error('Error disconnecting slack integration info', error, { payload })
    yield* put(actions.disconnectSlackFailure(payload))
  }
}
