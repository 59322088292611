import { ConversationType } from './conversations'
import { OfferCategory } from './offers'

export enum SearchType {
  Conversation = 'conversation',
  Expert = 'expert',
  Team = 'team',
  User = 'user',
}
export enum Role {
  Guest = 'guest',
  Member = 'member',
}
type Association = {
  id: string
  role: Role
  type: SearchType
}
export type BaseSearchIndex = {
  id: string
  terms: string
  type: SearchType
}
export type SearchUser = BaseSearchIndex & {
  associations: Association[]
  familyName: string
  givenName: string
  thumbUrl: string | null
  type: SearchType.User
}
export type SearchConversation = BaseSearchIndex & {
  role: Role
  sortDate: string
  teamId: string
  type: SearchType.Conversation
  vType: ConversationType
}
export type SearchTeam = BaseSearchIndex & {
  name: string
  role: Role
  type: SearchType.Team
}
export type SearchIndexItem = SearchConversation | SearchTeam | SearchUser
export type UserSearchIndex = {
  createdAt: number | null
  items: SearchIndexItem[]
}

export type UserToTeamsMap = {
  [userId: string]: string[]
}

export type SearchUserDictionary = { [id: string]: SearchUser }

export type SearchConversationResult = SearchConversation & { score: number }
export type SearchConversationDictionary = { [id: string]: SearchConversationResult }

export type SearchItem = SearchConversation | SearchExpert | SearchTeam | SearchUser
export type SearchExpert = {
  id: string
  landingPageUrl: string
  subtitle: string | null
  thumbUrl: string | null
  title: string
  type: OfferCategory
}
