import * as api from 'modules/api/features'
import logger from 'modules/logger'
import { ALL_FEATURE_IDS, FeatureId } from 'modules/types/features'
import { call, delay, put, SagaGenerator, spawn, takeEvery, takeLatest } from 'typed-redux-saga'
import { actions as initializeActions } from '../initialize/slice'
import { actions } from './slice'

export default function*(): SagaGenerator<void> {
  yield* takeLatest(initializeActions.requestFeatures, fetchStartupFeatures)
  yield* takeEvery(actions.fetchFeatures, action => fetchFeatures(action.payload.ids))
}

function* fetchStartupFeatures(): SagaGenerator<void> {
  const startupFeatureIds = ALL_FEATURE_IDS.filter(id => id !== FeatureId.TeamTiers)

  yield* fetchFeatures(startupFeatureIds)
  yield* spawn(() => refetchStartupFeatures(startupFeatureIds))
}

export function* refetchStartupFeatures(ids: FeatureId[]): SagaGenerator<void> {
  while (true) {
    yield* delay(60 * 60 * 1000)
    yield* fetchFeatures(ids)
  }
}

export function* fetchFeatures(ids: FeatureId[]): SagaGenerator<void> {
  try {
    const features = yield* call(api.getFeatures, ids)
    yield* put(actions.fetchFeaturesSuccess({ features }))
  } catch (error) {
    logger.error('Error getting features', error, { ids })
    yield* put(actions.fetchFeaturesFailure({ features: yield* call(api.getDefaultFeatures, ids) }))
  }
}
