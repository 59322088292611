import { CreateUserParams } from './users'

export type CreateAccountParams = CreateUserParams & {
  password: string
}

export enum IdentityAction {
  ChangeEmail = 'CHANGE_EMAIL',
  CreateAccount = 'CREATE_ACCOUNT',
  LogIn = 'LOG_IN',
  ResetPassword = 'RESET_PASSWORD',
  SendVerifyEmail = 'SEND_VERIFY_EMAIL',
}
export enum AuthProvider {
  Anonymous = 'anonymous',
  Apple = 'apple.com',
  EmailAndPassword = 'password',
  Google = 'google.com',
  LinkedIn = 'linkedin.com',
}
export type SsoAuthProvider = AuthProvider.Apple | AuthProvider.Google | AuthProvider.LinkedIn
export type AuthTokenSource = 'cognito' | 'firebase'
export type IdentityUser = {
  accessToken: string
  availableAuthProviders: AuthProvider[]
  email: string | null
  expiresOn: number
  familyName?: string
  givenName?: string
  isAnonymous: boolean
  isEmailVerified: boolean
  issuedOn: number
  phoneNumber?: string
  provider: AuthProvider
  source: AuthTokenSource
  userId: string
}
export enum AuthActionMode {
  RecoverEmail = 'recoverEmail',
  ResetPassword = 'resetPassword',
  SignIn = 'signIn',
  SignInWithSso = 'sso',
  Unknown = 'unknown',
  VerifyEmail = 'verifyEmail',
}
export type AuthActionPayload = {
  apiKey: string
  code: string
  mode: AuthActionMode
  provider: AuthProvider | null
  redirectTo: string
  userId: string | null
}
export type AuthActionInfo = {
  email: string | null
  recoverEmail: string | null
}
