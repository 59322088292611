import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'
import { AppState } from '../reducers'

export type NotificationsState = {
  latestNotificationTimestamp: string | null
  shouldCollapseNotifications: boolean
}
const initialState: NotificationsState = {
  latestNotificationTimestamp: null,
  shouldCollapseNotifications: true,
}

export type SetLatestNotificationTimestampAction = PayloadAction<{ timestamp: string }>
const setLatestNotificationTimestamp: CaseReducer<NotificationsState, SetLatestNotificationTimestampAction> = (state, action) => ({
  ...state,
  latestNotificationTimestamp: action.payload.timestamp,
  shouldCollapseNotifications: false,
})
const collapseNextNotifications: CaseReducer<NotificationsState, PayloadAction> = (state, action) => ({
  ...state,
  shouldCollapseNotifications: true,
})

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setLatestNotificationTimestamp,
    collapseNextNotifications,
  },
})

export const actions = notificationsSlice.actions
export const selector = {
  name: notificationsSlice.name,
  select: (state: AppState): NotificationsState => state.notifications,
}
export default notificationsSlice.reducer
