import { CancelGigMutationVariables, GetGigVolMutationVariables, UpdateGigVolMutationVariables } from 'API'
import getClientInfo from 'modules/clientInfo'
import { buildMutation, createMutationMap, mutateGraphQl } from './graph-utils'
import { GigData, Gigs, GigsDictionary, GigState } from '../types/gigs'

export type GigVol_Item = {
  gigID: string
  createdOn: string
  launchOn: string
  startedOn: string
  finishedOn: string
  ttl: string
  vType: string
  vState: GigState
  userID: string// # User ID of gig owner
  teamID: string
  conversationID: string
  momentID: string
}

export type GetGigVol_Data = {
  gigs: GigVol_Item[]
}

type GetGigVolParams = {
  ownerUserId: string
}

type GetGigVolVolResult = {
  getGigVol: string
}

const GetGigVolMutation = createMutationMap('getGigVol', {
  ownerUserID: 'ID',
  clientInfo: 'String!'
})

export async function getGigs(params: GetGigVolParams): Promise<Gigs> {
  const clientInfo = await getClientInfo()
  const variables: GetGigVolMutationVariables = {
    ownerUserID: params.ownerUserId,
    clientInfo
  }

  const result = await mutateGraphQl<GetGigVolVolResult, GetGigVolMutationVariables>({
    mutation: buildMutation(variables, GetGigVolMutation),
    variables
  })

  GetGigVolMutation.checkForErrors(result)
  const rawResponse = JSON.parse(result.data?.getGigVol as string) as GetGigVol_Data
  const gigs = deserializeGigs(rawResponse)
  return {
    fetchedOn: new Date().toISOString(),
    gigs
  }
}

type CancelGigVolParams = {
  gigId: string
}

type CancelGigVolVolResult = {
  cancelGig: string
}

const CancelGigVolMutation = createMutationMap('cancelGig', {
  gigID: 'ID',
  clientInfo: 'String!'
})

export async function cancelGig(params: CancelGigVolParams): Promise<GigData> {
  const clientInfo = await getClientInfo()
  const variables: CancelGigMutationVariables = {
    gigID: params.gigId,
    clientInfo
  }

  const result = await mutateGraphQl<CancelGigVolVolResult, CancelGigMutationVariables>({
    mutation: buildMutation(variables, CancelGigVolMutation),
    variables
  })

  CancelGigVolMutation.checkForErrors(result)
  const rawResponse = JSON.parse(result.data?.cancelGig as string) as GigVol_Item
  return deserializeGig(rawResponse)
}

type LaunchOnUpdateGigVolParams = {
  gigId: string,
  launchOn: string,
}
type LaunchNowUpdateGigVolParams = {
  gigId: string,
  launchNow: boolean,
}
type UpdateGigVolParams = LaunchNowUpdateGigVolParams | LaunchOnUpdateGigVolParams



type UpdateGigVolVolResult = {
  updateGigVol: string
}

const UpdateGigVolMutation = createMutationMap('updateGigVol', {
  gigID: 'ID',
  clientInfo: 'String!',
  launchOn: 'AWSDateTime',
  launchNow: 'Boolean'
})

export async function updateGig(params: UpdateGigVolParams): Promise<GigData> {
  const clientInfo = await getClientInfo()
  const variables: UpdateGigVolMutationVariables = {
    gigID: params.gigId,
    clientInfo
  }
  const launchOn = (params as LaunchOnUpdateGigVolParams).launchOn
  if (launchOn) {
    variables.launchOn = launchOn
  }

  const launchNow = (params as LaunchNowUpdateGigVolParams).launchNow
  if (launchNow) {
    variables.launchNow = launchNow
  }

  const result = await mutateGraphQl<UpdateGigVolVolResult, UpdateGigVolMutationVariables>({
    mutation: buildMutation(variables, UpdateGigVolMutation),
    variables
  })

  UpdateGigVolMutation.checkForErrors(result)
  const rawResponse = JSON.parse(result.data?.updateGigVol as string) as GigVol_Item
  return deserializeGig(rawResponse)
}

function deserializeGigs (data: GetGigVol_Data): GigsDictionary {
  return data.gigs.reduce((acc, gig) => {
    acc[gig.gigID] = deserializeGig(gig)
    return acc
  }, {} as GigsDictionary)
}

function deserializeGig (gig: GigVol_Item): GigData {
    return {
      conversationId: gig.conversationID,
      createdOn: gig.createdOn,
      finishedOn: gig.finishedOn,
      gigId: gig.gigID,
      launchOn: gig.launchOn,
      momentId: gig.momentID,
      startedOn: gig.startedOn,
      teamId: gig.teamID,
      ttl: gig.ttl,
      userId: gig.userID,
      vState: gig.vState,
      vType: gig.vType,
    }
}
