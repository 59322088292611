import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreateOfferParams, UpdateOfferParams } from 'modules/api/offers'
import { Offer, OffersDictionary } from 'modules/types/offers'
import { AppState } from '../reducers'

type IsWorkingDictionary = { [offerId: string]: boolean }
type IsErrorDictionary = { [offerId: string]: string }
export type OffersState = {
  createOfferFailed?: string
  fetchFailed: IsErrorDictionary
  initialized: boolean
  isCreating: boolean
  isFetching: IsWorkingDictionary
  isUpdating: IsWorkingDictionary
  offers: OffersDictionary
}
const initialState: OffersState = {
  fetchFailed: {},
  initialized: false,
  isCreating: false,
  isFetching: {},
  isUpdating: {},
  offers: {},
}

const isWorkingReduceFn = (dict: IsWorkingDictionary, id: string) => {
  dict[id] = true
  return dict
}

export type CreateOfferAction = PayloadAction<CreateOfferParams>
const createOffer: CaseReducer<OffersState, CreateOfferAction> = (state, _) => ({
  ...state,
  isCreating: true,
})
type CreateOfferFailureAction = PayloadAction<{ error: string }>
const createOfferFailure: CaseReducer<OffersState, CreateOfferFailureAction> = (state, action) => ({
  ...state,
  createOfferFailed: action.payload.error,
  isCreating: false,
})
type CreateOfferSuccessAction = PayloadAction<{ offer: Offer }>
const createOfferSuccess: CaseReducer<OffersState, CreateOfferSuccessAction> = (state, action) => ({
  ...state,
  isCreating: false,
  offers: {
    ...state.offers,
    [action.payload.offer.id]: action.payload.offer,
  },
})

export type FetchOffersAction = PayloadAction<{ ids: string[] } | undefined>
const fetchOffers: CaseReducer<OffersState, FetchOffersAction> = (state, action) => {
  const fetchIds = action.payload?.ids ?? ['user']

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      ...fetchIds.reduce(isWorkingReduceFn, {}),
    },
  }
}
type FetchOffersFailureAction = PayloadAction<{ ids?: string[], reason: string }>
const fetchOffersFailure: CaseReducer<OffersState, FetchOffersFailureAction> = (state, action) => {
  const failedIds = action.payload?.ids ?? ['user']

  return {
    ...state,
    fetchFailed: {
      ...state.fetchFailed,
      ...failedIds.reduce<IsErrorDictionary>((dict, id) => {
        dict[id] = action.payload.reason
        return dict
      }, {}),
    },
    isFetching: Object.keys(state.isFetching)
      .filter(id => !failedIds.includes(id))
      .reduce(isWorkingReduceFn, {}),
  }
}
export type FetchOffersSuccessAction = PayloadAction<{ offers: OffersDictionary, wasFetchingById: boolean }>
const fetchOffersSuccess: CaseReducer<OffersState, FetchOffersSuccessAction> = (state, action) => {
  const fetchIds = [...Object.keys(action.payload), ...(action.payload.wasFetchingById ? [] : ['user'])]

  return {
    ...state,
    fetchFailed: Object.entries(state.fetchFailed)
      .filter(([id]) => !fetchIds.includes(id))
      .reduce<IsErrorDictionary>((dict, [id, reason]) => {
        dict[id] = reason
        return dict
      }, {}),
    initialized: state.initialized || !action.payload.wasFetchingById,
    isFetching: Object.keys(state.isFetching)
      .filter(id => !fetchIds.includes(id))
      .reduce(isWorkingReduceFn, {}),
    offers: {
      ...state.offers,
      ...action.payload.offers,
    },
  }
}

export type UpdateOfferAction = PayloadAction<UpdateOfferParams>
const updateOffer: CaseReducer<OffersState, UpdateOfferAction> = (state, action) => ({
  ...state,
  isUpdating: {
    ...state.isUpdating,
    [action.payload.id]: true,
  },
  offers: {
    ...state.offers,
    [action.payload.id]: {
      ...state.offers[action.payload.id],
      ...action.payload,
    }
  },
})
export type OfferIdAction = PayloadAction<{ id: string }>
const updateOfferFailure: CaseReducer<OffersState, OfferIdAction> = (state, action) => ({
  ...state,
  isUpdating: Object.keys(state.isUpdating)
    .filter(id => id !== action.payload.id)
    .reduce(isWorkingReduceFn, {}),
})
type UpdateOfferSuccessAction = PayloadAction<{ offer: Offer }>
const updateOfferSuccess: CaseReducer<OffersState, UpdateOfferSuccessAction> = (state, action) => ({
  ...state,
  isUpdating: Object.keys(state.isUpdating)
    .filter(id => id !== action.payload.offer.id)
    .reduce(isWorkingReduceFn, {}),
  offers: {
    ...state.offers,
    [action.payload.offer.id]: action.payload.offer,
  },
})

const deleteOffer: CaseReducer<OffersState, OfferIdAction> = (state, action) => ({
  ...state,
  isUpdating: {
    ...state.isUpdating,
    [action.payload.id]: true,
  },
  offers: Object.entries(state.offers)
    .filter(([id]) => id !== action.payload.id)
    .reduce<OffersDictionary>((acc, [id, offer]) => {
      acc[id] = offer

      return acc
    }, {}),
})
const deleteOfferSuccess: CaseReducer<OffersState, OfferIdAction> = (state, action) => ({
  ...state,
  isUpdating: Object.keys(state.isUpdating)
    .filter(id => id !== action.payload.id)
    .reduce(isWorkingReduceFn, {}),
  offers: Object.entries(state.offers)
    .filter(([id]) => id !== action.payload.id)
    .reduce<OffersDictionary>((acc, [id, offer]) => {
      acc[id] = offer

      return acc
    }, {}),
})

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    createOffer,
    createOfferFailure,
    createOfferSuccess,

    deleteOffer,
    deleteOfferFailure: updateOfferFailure,
    deleteOfferSuccess,

    fetchOffers,
    fetchOffersFailure,
    fetchOffersSuccess,

    updateOffer,
    updateOfferFailure,
    updateOfferSuccess,
  },
})

const currentUserOffers = (state: AppState): OffersDictionary => {
  const { currentUserId } = state.users
  if (!currentUserId) {
    return {}
  }

  return Object.entries(state.offers.offers).reduce<OffersDictionary>((acc, [id, o]) => {
    if (o.creatorUserId === currentUserId && !o.teamId) {
      acc[id] = o
    }

    return acc
  }, {})
}

export const actions = offersSlice.actions
export const selector = {
  name: offersSlice.name,
  select: (state: AppState): OffersState => state.offers,
  currentUserOffers,
}
export default offersSlice.reducer
