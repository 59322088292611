import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AccountCreateEvent,
  ButtonPressEvent,
  ConversationJoinEvent,
  ConversationPromoteToGroupEvent,
  DeepLinkActivatedEvent,
  EmbeddedTextVideoViewEvent,
  InviteSentEvent,
  ScreenViewEvent,
  SearchOpenEvent,
  SearchResultViewEvent,
  SignInEvent,
  TeamJoinEvent,
  VideoViewEvent,
  VolleyCopyTranscriptEvent,
  VolleyOverSizeLimitEvent,
  VolleyPublicLinkSentEvent,
} from 'modules/api/analytics'
import { Token } from 'modules/types/tokens'
import { AppState } from '../reducers'

type AnalyticsState = {
  anonymousId?: string
  initialized: boolean
}
const initialState: AnalyticsState = {
  initialized: false
}

export type ButtonPressAction = PayloadAction<Omit<ButtonPressEvent, 'isAnonymous' | 'userId'>>
type ConversationJoinPayload = Omit<ConversationJoinEvent, 'conversationType' | 'inviterId' | 'tokenType' | 'userId'>
export type ConversationJoinAction = PayloadAction<ConversationJoinPayload>
export type ConversationSearchOpenAction = PayloadAction<Omit<SearchOpenEvent, 'userId'>>
export type ConversationSearchResultViewAction = PayloadAction<Omit<SearchResultViewEvent, 'userId'>>
type CreatedAccountPayload = Omit<AccountCreateEvent, 'inviterId' | 'tokenId' | 'tokenType' | 'userId'> & {
  token: Token | null
}
export type CreatedAccountAction = PayloadAction<CreatedAccountPayload>
export type DeepLinkActivatedPayload = Omit<DeepLinkActivatedEvent,
  | 'anonId'
  | 'isAnonymous'
  | 'userId'
  | 'utmCampaign'
  | 'utmMedium'
  | 'utmSource'
> & {
  search?: string
}
export type DeepLinkActivatedAction = PayloadAction<DeepLinkActivatedPayload>
export type EmbeddedTextVideoViewAction = PayloadAction<Omit<EmbeddedTextVideoViewEvent, 'userId'>>
export type InviteSentAction = PayloadAction<Omit<InviteSentEvent, 'userId'>>
export type PromoteOneOnOneToGroupConvoAction = PayloadAction<Omit<ConversationPromoteToGroupEvent, 'userId'>>
export type SignInAction = PayloadAction<Omit<SignInEvent, 'userId'>>
export type ScreenViewAction = PayloadAction<Omit<ScreenViewEvent, 'isAnonymous' | 'userId'>>
export type TeamJoinAction = PayloadAction<Omit<TeamJoinEvent, 'inviterId' | 'tokenType' | 'userId'>>
export type VideoViewAction = PayloadAction<Omit<VideoViewEvent, 'isAnonymous' | 'userId'>>
export type VolleyCopyTranscriptAction = PayloadAction<Omit<VolleyCopyTranscriptEvent, 'userId'>>
type VolleyOverSizeLimitPayload = Omit<VolleyOverSizeLimitEvent, 'mimeType' | 'size' | 'userId' | 'volleyType'> & {
  file: File
}
export type VolleyOverSizeLimitAction = PayloadAction<VolleyOverSizeLimitPayload>
export type VolleyPublicLinkSentAction = PayloadAction<Omit<VolleyPublicLinkSentEvent, 'shareDestination' | 'userId'>>
export type VolleyTriggerDownloadEmailAction = PayloadAction<{ momentId: string }>

const initializeAnonymousId: CaseReducer<AnalyticsState, PayloadAction<{ id?: string }>> = (state, action) => ({
  ...state,
  anonymousId: action.payload.id,
})
const initializeUser: CaseReducer<AnalyticsState, PayloadAction> = (state, _action) => ({
  ...state,
  initialized: true,
})

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    initializeAnonymousId,
    initializeUser,

    buttonPress: (state, _: ButtonPressAction) => state,
    conversationJoin: (state, _: ConversationJoinAction) => state,
    conversationSearchOpen: (state, _: ConversationSearchOpenAction) => state,
    conversationSearchResultView: (state, _: ConversationSearchResultViewAction) => state,
    createdAccount: (state, _: CreatedAccountAction) => state,
    createdAccountDelayed: (state, _: CreatedAccountAction) => state,
    deepLinkActivated: (state, _: DeepLinkActivatedAction) => state,
    embeddedTextVideoView: (state, _: EmbeddedTextVideoViewAction) => state,
    giphyCanceled: (state, _: PayloadAction) => state,
    inviteSent: (state, _: InviteSentAction) => state,
    promoteOneOnOneToGroupConvo: (state, _: PromoteOneOnOneToGroupConvoAction) => state,
    signIn: (state, _: SignInAction) => state,
    screenView: (state, _: ScreenViewAction) => state,
    talkToMeOnVolleyEmbedLinkCopied: (state, _: PayloadAction) => state,
    talkToMeOnVolleyIntroCreated: (state, _: PayloadAction) => state,
    talkToMeOnVolleyPreviewViewed: (state, _: PayloadAction) => state,
    teamJoin: (state, _: TeamJoinAction) => state,
    videoView: (state, _: VideoViewAction) => state,
    volleyCopyTranscript: (state, _: VolleyCopyTranscriptAction) => state,
    volleyOverSizeLimit: (state, _: VolleyOverSizeLimitAction) => state,
    volleyPublicLinkSent: (state, _: VolleyPublicLinkSentAction) => state,
    volleyTriggerDownloadEmail: (state, _: VolleyTriggerDownloadEmailAction) => state,
  },
})

export const actions = analyticsSlice.actions
export const selector = {
  name: analyticsSlice.name,
  select: (state: AppState): AnalyticsState => state.analytics,
}
export default analyticsSlice.reducer
