import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AuthActionMode, AuthProvider, AuthTokenSource, IdentityUser, SsoAuthProvider } from 'modules/types/auth'
import { CreateUserParams } from 'modules/types/users'
import { AppState } from '../reducers'

type IdentityState =
  | 'accountCreated'
  | 'emailRecovered'
  | 'emailVerified'
  | 'forgotPasswordRequested'
  | 'initial'
  | 'loggedIn'
  | 'loggedOut'
  | 'resettingPassword'
  | 'startOver'
type AppLoggedInState =
  | 'emailRecovered'
  | 'emailVerified'
  | 'loading'
  | 'loggedIn'
  | 'needsVerification'
  | 'notLoggedIn'
  | 'startOver'
  | 'userIncomplete'
type AccessToken = {
  expiresOn: number
  isAnonymous: boolean
  issuedOn: number
  provider: AuthProvider
  source: AuthTokenSource
  token: string
}
type AuthenticationState = {
  accessToken: AccessToken | null,
  availableAuthProviders: AuthProvider[]
  createAccountError?: string
  email: string | null
  identityState: IdentityState
  isCreatingSignInLink: boolean
  isRecoveringEmail: boolean
  isUpdatingEmail: boolean
  isUpdatingPassword: boolean
  isValidatingAuthAction: boolean
  passwordError?: string
  postVerifyDestination: string | null
  recoverEmail: string | null
  recoverEmailError?: string
  signInError?: string
  signInLink?: string
  updateEmailError?: string
  userId: string | null
  verifyEmailError?: string
  verifySignInLinkError?: string
}

const initialState: AuthenticationState = {
  accessToken: null,
  availableAuthProviders: [],
  email: null,
  identityState: 'initial',
  isCreatingSignInLink: false,
  isRecoveringEmail: false,
  isUpdatingEmail: false,
  isUpdatingPassword: false,
  isValidatingAuthAction: false,
  postVerifyDestination: null,
  recoverEmail: null,
  userId: null,
}

export type CreateAccountAction = PayloadAction<CreateUserParams & { password: string }>
const createAccount: CaseReducer<AuthenticationState, CreateAccountAction> = (state, _action) => ({
  ...state,
  createAccountError: undefined,
})
type ErrorPayloadAction = PayloadAction<{ error: string }>
const createAccountFailure: CaseReducer<AuthenticationState, ErrorPayloadAction> = (state, action) => ({
  ...state,
  createAccountError: action.payload.error,
})
type CreateAccountIdentityAction = PayloadAction<{ email: string, userId: string }>
const createAccountSuccess: CaseReducer<AuthenticationState, CreateAccountIdentityAction> = (state, action) => ({
  ...state,
  email: action.payload.email,
  identityState: 'accountCreated',
  createAccountError: undefined,
  userId: action.payload.userId,
})

const canSignIn = (state: AuthenticationState): boolean =>
  state.identityState === 'loggedOut'
  || state.identityState === 'accountCreated'
  || state.identityState === 'startOver'
  || state.identityState === 'forgotPasswordRequested'
  || (state.identityState === 'loggedIn' && !!state.accessToken?.isAnonymous)

export type SignInAction = PayloadAction<{ email: string, password: string }>
const signIn: CaseReducer<AuthenticationState, SignInAction> = (state, _action) => {
  if (!canSignIn(state)) {
    throw new Error(`Sign in failed. Invalid state: ${state.identityState}`)
  }

  return {
    ...state,
    createAccountError: undefined,
    signInError: undefined,
  }
}
const signInFailure: CaseReducer<AuthenticationState, ErrorPayloadAction> = (state, action) => ({
  ...state,
  email: null,
  identityState: 'loggedOut',
  signInError: action.payload.error,
  userId: null,
})
const signInIdentityFailure: CaseReducer<AuthenticationState, PayloadAction> = (state, _action) => ({
  ...state,
  email: null,
  identityState: 'loggedOut',
  userId: null,
})
export type SignInIdentitySuccessAction = PayloadAction<IdentityUser>
const signInIdentitySuccess: CaseReducer<AuthenticationState, SignInIdentitySuccessAction> = (state, action) => ({
  ...state,
  accessToken: {
    expiresOn: action.payload.expiresOn,
    isAnonymous: action.payload.isAnonymous,
    issuedOn: action.payload.issuedOn,
    provider: action.payload.provider,
    source: action.payload.source,
    token: action.payload.accessToken,
  },
  availableAuthProviders: action.payload.availableAuthProviders,
  email: action.payload.email,
  identityState: 'loggedIn',
  isUpdatingEmail: false,
  signInError: undefined,
  signInLink: undefined,
  updateEmailError: undefined,
  userId: action.payload.userId,
  userState: state.isUpdatingEmail ? 'ready' : 'initial',
})
const signInNeedsEmailVerification: CaseReducer<AuthenticationState, EmailPayloadAction> = (state, action) => ({
  ...state,
  email: action.payload.email,
  identityState: 'accountCreated',
  signInError: undefined,
  updateEmailError: undefined,
})
export type SignInWithLinkAction = PayloadAction<{ email: string, link: string }>
const signInWithLink: CaseReducer<AuthenticationState, SignInWithLinkAction> = (state, _action) => ({
  ...state,
  signInError: undefined,
})
type SignInWithSsoPayload = { authProvider: SsoAuthProvider, fromLink?: boolean, isSignUp?: boolean }
export type SignInWithSsoAction = PayloadAction<SignInWithSsoPayload>
const signInWithSso: CaseReducer<AuthenticationState, SignInWithSsoAction> = (state, action) => {
  if (!action.payload.fromLink && !canSignIn(state)) {
    throw new Error(`Sign in failed. Invalid state: ${state.identityState}`)
  }

  return {
    ...state,
    signInError: undefined,
  }
}

export type VerifyEmailAction = PayloadAction<{ email?: string, password?: string, verificationCode: string }>
const verifyEmail: CaseReducer<AuthenticationState, VerifyEmailAction> = (state, _action) => ({
  ...state,
  verifyEmailError: undefined,
})
const verifyEmailFailure: CaseReducer<AuthenticationState, ErrorPayloadAction> = (state, action) => ({
  ...state,
  verifyEmailError: action.payload.error,
})
const verifyEmailSuccess: CaseReducer<AuthenticationState, PayloadAction> = (state, _action) => ({
  ...state,
  identityState: 'emailVerified',
})

const createAccountStartOver: CaseReducer<AuthenticationState, PayloadAction> = (state, _action) => ({
  ...state,
  verifyEmailError: undefined,
})
const createAccountStartOverSuccess: CaseReducer<AuthenticationState, PayloadAction> = (state, _action) => ({
  ...state,
  identityState: 'startOver',
})

const requestForgotPasswordFailure: CaseReducer<AuthenticationState, ErrorPayloadAction> = (state, action) => ({
  ...state,
  passwordError: action.payload.error,
})
const requestForgotPasswordSuccess: CaseReducer<AuthenticationState, EmailPayloadAction> = (state, action) => ({
  ...state,
  email: action.payload.email,
  identityState: 'forgotPasswordRequested',
  passwordError: undefined,
})

export type ResetPasswordAction = PayloadAction<{ email?: string, password: string, verificationCode: string }>
const resetPassword: CaseReducer<AuthenticationState, ResetPasswordAction> = (state, _action) => ({
  ...state,
  identityState: 'resettingPassword',
  passwordError: undefined,
})
const resetPasswordFailure: CaseReducer<AuthenticationState, ErrorPayloadAction> = (state, action) => ({
  ...state,
  passwordError: action.payload.error,
})
const resetPasswordSuccess: CaseReducer<AuthenticationState, PayloadAction> = (state, _action) => ({
  ...state,
  identityState: 'loggedOut',
  passwordError: undefined,
})

export type UpdateEmailAction = PayloadAction<{ email: string, password: string }>
const updateEmail: CaseReducer<AuthenticationState, UpdateEmailAction> = (state, _action) => ({
  ...state,
  isUpdatingEmail: true,
  updateEmailError: undefined,
})
const updateEmailFailure: CaseReducer<AuthenticationState, ErrorPayloadAction> = (state, action) => ({
  ...state,
  isUpdatingEmail: false,
  updateEmailError: action.payload.error,
})
const updateEmailSuccess: CaseReducer<AuthenticationState, EmailPayloadAction> = (state, action) => ({
  ...state,
  email: action.payload.email,
  isUpdatingEmail: false,
  updateEmailError: undefined,
})

export type UpdatePasswordAction = PayloadAction<{ newPassword: string, oldPassword: string }>
const updatePassword: CaseReducer<AuthenticationState, UpdatePasswordAction> = (state, _action) => ({
  ...state,
  isUpdatingPassword: true,
  passwordError: undefined,
})
const updatePasswordFailure: CaseReducer<AuthenticationState, ErrorPayloadAction> = (state, action) => ({
  ...state,
  isUpdatingPassword: false,
  passwordError: action.payload.error,
})
const updatePasswordSuccess: CaseReducer<AuthenticationState, PayloadAction> = (state, _action) => ({
  ...state,
  isUpdatingPassword: false,
  passwordError: undefined,
})

export type RefreshIdentitySessionSuccessAction = PayloadAction<IdentityUser>
const refreshIdentitySessionSuccess: CaseReducer<AuthenticationState, RefreshIdentitySessionSuccessAction> = (state, action) => ({
  ...state,
  accessToken: {
    ...state.accessToken,
    expiresOn: action.payload.expiresOn,
    isAnonymous: action.payload.isAnonymous,
    issuedOn: action.payload.issuedOn,
    provider: action.payload.provider,
    token: action.payload.accessToken,
    source: action.payload.source,
  },
})

export type ValidateAuthActionAction = PayloadAction<{ code: string, mode: AuthActionMode }>
const validateAuthAction: CaseReducer<AuthenticationState, ValidateAuthActionAction> = (state, _action) => ({
  ...state,
  isValidatingAuthAction: true,
  recoverEmailError: undefined,
})
type ValidateAuthActionFailureAction = PayloadAction<{ error: string, mode: AuthActionMode }>
const validateAuthActionFailure: CaseReducer<AuthenticationState, ValidateAuthActionFailureAction> = (state, action) => ({
  ...state,
  isValidatingAuthAction: false,
  recoverEmailError: action.payload.mode === AuthActionMode.RecoverEmail ? action.payload.error : undefined,
})
type ValidateAuthActionSuccessAction = PayloadAction<{ email: string | null, recoverEmail: string | null }>
const validateAuthActionSuccess: CaseReducer<AuthenticationState, ValidateAuthActionSuccessAction> = (state, action) => ({
  ...state,
  email: action.payload.email,
  isValidatingAuthAction: false,
  recoverEmail: action.payload.recoverEmail,
  recoverEmailError: undefined,
})

export type RecoverEmailAction = PayloadAction<{ code: string, email: string }>
const recoverEmail: CaseReducer<AuthenticationState, RecoverEmailAction> = (state, _action) => ({
  ...state,
  isRecoveringEmail: true,
})
const recoverEmailFailure: CaseReducer<AuthenticationState, ErrorPayloadAction> = (state, action) => ({
  ...state,
  isRecoveringEmail: false,
  recoverEmailError: action.payload.error,
})
const recoverEmailSuccess: CaseReducer<AuthenticationState, PayloadAction> = (state, _action) => ({
  ...state,
  identityState: 'emailRecovered',
  isRecoveringEmail: false,
  recoverEmail: null,
  recoverEmailError: undefined,
})

export type SetPostVerifyDestinationAction = PayloadAction<{ destination: string | null }>
const setPostVerifyDestination: CaseReducer<AuthenticationState, SetPostVerifyDestinationAction> = (state, action) => ({
  ...state,
  postVerifyDestination: action.payload.destination,
})

const clearSignInLink: CaseReducer<AuthenticationState, PayloadAction> = (state, _) => ({
  ...state,
  signInLink: undefined,
})

export type CreateSignInLinkAction = PayloadAction<{ path: string, search?: string }>
const createSignInLink: CaseReducer<AuthenticationState, CreateSignInLinkAction> = (state, _) => ({
  ...state,
  isCreatingSignInLink: true,
})
const createSignInLinkFailure: CaseReducer<AuthenticationState, ErrorPayloadAction> = (state, action) => ({
  ...state,
  isCreatingSignInLink: false,
  signInError: action.payload.error,
})
type CreateSignInLinkSuccessAction = PayloadAction<{ link: string }>
const createSignInLinkSuccess: CaseReducer<AuthenticationState, CreateSignInLinkSuccessAction> = (state, action) => ({
  ...state,
  isCreatingSignInLink: false,
  signInLink: action.payload.link,
})

export type VerifySignInLinkAction = PayloadAction<{ link: string }>
const verifySignInLink: CaseReducer<AuthenticationState, VerifySignInLinkAction> = (state, _action) => ({
  ...state,
  isValidatingAuthAction: true,
})
const verifySignInLinkFailure: CaseReducer<AuthenticationState, ErrorPayloadAction> = (state, action) => ({
  ...state,
  isValidatingAuthAction: false,
  signInError: action.payload.error,
})
const verifySignInLinkSuccess: CaseReducer<AuthenticationState, VerifySignInLinkAction> = (state, action) => ({
  ...state,
  isValidatingAuthAction: false,
  signInError: undefined,
  signInLink: action.payload.link,
})

export type EmailPayloadAction = PayloadAction<{ email: string }>
type RefreshIdentitySessionAction = PayloadAction<{ isAfterVerifyEmail: boolean } | undefined>
export type SignOutAction = PayloadAction<{ destination?: string, preventRedirect?: boolean } | undefined>

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    clearSignInLink,

    createAccount,
    createAccountFailure,
    createAccountStartOver,
    createAccountStartOverSuccess,
    createAccountSuccess,

    createSignInLink,
    createSignInLinkFailure,
    createSignInLinkSuccess,

    recoverEmail,
    recoverEmailFailure,
    recoverEmailSuccess,

    refreshIdentitySession: (state: AuthenticationState, _action: RefreshIdentitySessionAction) => state,
    refreshIdentitySessionSuccess,

    requestForgotPassword: (state: AuthenticationState, _action: EmailPayloadAction) => state,
    requestForgotPasswordFailure,
    requestForgotPasswordSuccess,

    resendEmailVerification: (state: AuthenticationState, _action: EmailPayloadAction) => state,

    resetPassword,
    resetPasswordFailure,
    resetPasswordSuccess,

    setPostVerifyDestination,

    signIn,
    signInFailure,
    signInIdentityFailure,
    signInIdentitySuccess,
    signInNeedsEmailVerification,
    signInWithLink,
    signInWithSso,

    signOut: (_state: AuthenticationState, _action: SignOutAction) => initialState,

    updateEmail,
    updateEmailFailure,
    updateEmailSuccess,

    updatePassword,
    updatePasswordFailure,
    updatePasswordSuccess,

    validateAuthAction,
    validateAuthActionFailure,
    validateAuthActionSuccess,

    verifyEmail,
    verifyEmailFailure,
    verifyEmailSuccess,

    verifySignInLink,
    verifySignInLinkFailure,
    verifySignInLinkSuccess,
  },
})

export const actions = authenticationSlice.actions
export const selector = {
  name: authenticationSlice.name,
  select: (appState: AppState): AuthenticationState => appState.authentication,

  userIdOrThrow: (appState: AppState): string => {
    const { userId } = appState.authentication
    if (!userId) {
      throw new Error('Tried to access userId when not authenticated')
    }

    return userId
  },

  appLoggedInState: (appState: AppState): AppLoggedInState => {
    const { accessToken, identityState, isUpdatingEmail } = appState.authentication
    const { currentUser, initialized: hasInitializedUsers } = appState.users

    if (identityState === 'initial') {
      return 'loading'
    } else if (identityState === 'accountCreated' && !isUpdatingEmail) {
      return 'needsVerification'
    } else if (identityState === 'emailVerified') {
      return 'emailVerified'
    } else if (identityState === 'emailRecovered') {
      return 'emailRecovered'
    } else if (identityState === 'startOver') {
      return 'startOver'
    } else if (identityState === 'loggedIn' && !hasInitializedUsers) {
      return 'loading'
    } else if (identityState === 'loggedIn' && hasInitializedUsers
      && (!currentUser || (!!currentUser?.isVisitor && !accessToken?.isAnonymous))) {
      return 'userIncomplete'
    } else if ((identityState === 'loggedIn' && currentUser)
      || (identityState === 'accountCreated' && isUpdatingEmail)) {
      return 'loggedIn'
    } else {
      return 'notLoggedIn'
    }
  },
}
export default authenticationSlice.reducer
