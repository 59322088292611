import gql from 'graphql-tag'
import getClientInfo, { ClientInfoParams } from 'modules/clientInfo'
import { Purchase, PurchasesDictionary } from 'modules/types/purchases'
import { checkForErrors, mutateGraphQl } from './graph-utils'
import { deserializePurchase, Vol_Purchase } from './shared'
import { BatchResult, deserializeBatchResults } from './util'

export type CreatePurchaseMutationParams = ClientInfoParams & {
  isFromSearch?: boolean
  offerId: string
}
type CreatePurchaseResult = { createPurchase: string | null }
const CreatePurchase = gql`
  mutation CreatePurchase($clientInfo: String, $isFromSearch: Boolean, $offerId: ID!) {
    createPurchase(clientInfo: $clientInfo, fromSearch: $isFromSearch, offeringID: $offerId)
  }
`
export async function createPurchase(offerId: string, isFromSearch?: boolean): Promise<string> {
  const result = await mutateGraphQl<CreatePurchaseResult, CreatePurchaseMutationParams>({
    mutation: CreatePurchase,
    variables: { clientInfo: await getClientInfo(), isFromSearch, offerId }
  })

  checkForErrors('createPurchase', result, true)
  // we've checked for errors in the previous call
  return JSON.parse(result.data?.createPurchase ?? '{}').redirectURL
}

type GetPurchasesParams = {
  clientInfo: string
  ids: string[]
}
type GetPurchasesResult = { getPurchases: string | null }
type GetPurchases_PurchasesDictionary = { [id: string]: Vol_Purchase }

const GetPurchases = gql`
  mutation GetPurchases($clientInfo: String, $ids: [ID]!) {
    getPurchases(clientInfo: $clientInfo, purchaseIDs: $ids)
  }
`
export async function getPurchases(purchaseIds?: string[]): Promise<BatchResult<Purchase>> {
  const ids = purchaseIds ?? []

  const clientInfo = await getClientInfo()
  const results = await mutateGraphQl<GetPurchasesResult, GetPurchasesParams>({
    mutation: GetPurchases,
    variables: { clientInfo, ids }
  })

  checkForErrors('getPurchases', results, true)
  return deserializeBatchResults(results.data?.getPurchases, deserializePurchases)
}

function deserializePurchases(purchases: GetPurchases_PurchasesDictionary): PurchasesDictionary {
  return Object.entries(purchases).reduce<PurchasesDictionary>((dict, [id, purchase]) => {
    dict[id] = deserializePurchase(purchase)

    return dict
  }, {})
}

type CancelPurchaseParams = ClientInfoParams & {
  id: string
}
type CancelPurchaseResult = { cancelPurchase: string | null }
const CancelPurchaseByConversation = gql`
  mutation cancelPurchase($clientInfo: String, $id: ID!) {
    cancelPurchase(clientInfo: $clientInfo, conversationID: $id)
  }
`
const CancelPurchaseByTeam = gql`
  mutation cancelPurchase($clientInfo: String, $id: ID!) {
    cancelPurchase(clientInfo: $clientInfo, teamID: $id)
  }
`
export async function cancelPurchase(conversationId?: string, teamId?: string): Promise<void> {
  if (!conversationId && !teamId) {
    return
  }

  const result = await mutateGraphQl<CancelPurchaseResult, CancelPurchaseParams>({
    mutation: !!conversationId ? CancelPurchaseByConversation : CancelPurchaseByTeam,
    variables: { clientInfo: await getClientInfo(), id: conversationId ?? teamId ?? '(empty)' }
  })

  checkForErrors('cancelPurchase', result, true)
}
