import gql from 'graphql-tag'
import getClientInfo, { ClientInfoParams } from 'modules/clientInfo'
import { Token, TokenParams, TokenState, TokenTargetEntity, TokenType } from 'modules/types/tokens'
import { Participant } from 'modules/types/users'
import { checkForErrors, mutateGraphQl, mutateUnauthenticatedGraphQl } from './graph-utils'

type GetTokenParams = {
  id: string
}
type GetTokenVolParams = GetTokenParams & ClientInfoParams
type GetTokenVolResult = {
  getTokenVol: string
}
type GetTokenVol_Data_TargetEntity = {
  id: string
  isGrowTeam?: boolean
  isPublic?: boolean
  name: string
  thumbURL: string
}
type GetTokenVol_Data_CreatorUser = {
  id: string
  givenName: string
  familyName: string
  thumbURL: string
}
type GetTokenVol_Data = {
  conversation?: GetTokenVol_Data_TargetEntity
  creatorUser: GetTokenVol_Data_CreatorUser
  data: string
  embedCode: string | null
  expiresAt: string | null
  id: string
  landingURL: string
  team?: GetTokenVol_Data_TargetEntity
  vData: { [key: string]: string }
  vState: TokenState | null
  vType: TokenType
}

const GetTokenVol = gql`
  mutation GetTokenVol($id: ID!) {
    getTokenVol(tokenID: $id)
  }
`

function deserializeTargetEntity(data?: GetTokenVol_Data_TargetEntity): TokenTargetEntity | undefined {
  if (!data) {
    return undefined
  }

  return {
    id: data.id,
    isGrowTeam: data.isGrowTeam,
    isPublic: data.isPublic,
    name: data.name,
    thumbUrl: data.thumbURL
  }
}
function deserializeCreator(data: GetTokenVol_Data_CreatorUser): Participant {
  return {
    familyName: data.familyName,
    givenName: data.givenName,
    id: data.id,
    thumbUrl: data.thumbURL,
  }
}
function deserializeToken(data: string): Token {
  const parsed: GetTokenVol_Data = JSON.parse(data)
  return {
    conversation: deserializeTargetEntity(parsed.conversation),
    creator: deserializeCreator(parsed.creatorUser),
    data: parsed.data,
    embedCode: parsed.embedCode,
    expiresAt: parsed.expiresAt,
    id: parsed.id,
    landingUrl: parsed.landingURL,
    state: parsed.vState,
    team: deserializeTargetEntity(parsed.team),
    type: parsed.vType,
    vData: parsed.vData,
  }
}

export async function getToken(params: GetTokenParams): Promise<Token | null> {
  const result = await mutateUnauthenticatedGraphQl<GetTokenVolResult, GetTokenVolParams>({
    mutation: GetTokenVol,
    variables: {
      ...params,
      clientInfo: await getClientInfo(),
    }
  })

  checkForErrors('getTokenVol', result)

  return result.data?.getTokenVol ? deserializeToken(result.data.getTokenVol) : null
}

type UpdateTokenParams = {
  disable?: boolean
  id: string
  updateContacts?: string
  vType?: TokenType
}
type UpdateTokenVolParams = UpdateTokenParams & ClientInfoParams
type UpdateTokenVolResult = {
  updateTokenVol: string
}

const UpdateTokenVol = gql`
  mutation UpdateTokenVol($clientInfo: String!, $disable: Boolean, $id: ID!, $updateContacts: AWSJSON, $vType: TokenType) {
    updateTokenVol(clientInfo: $clientInfo, disable: $disable, tokenID: $id, updateContacts: $updateContacts, vType: $vType)
  }
`

export async function updateToken(params: UpdateTokenParams): Promise<void> {
  const result = await mutateGraphQl<UpdateTokenVolResult, UpdateTokenVolParams>({
    mutation: UpdateTokenVol,
    variables: {
      ...params,
      clientInfo: await getClientInfo(),
    }
  })

  checkForErrors('updateTokenVol', result, true)
}

type CreateTokenVolParams = ClientInfoParams & {
  data: string
  vType: TokenType
}
type CreateTokenVolResult = {
  createTokenVol: string
  error: string
}

const CreateTokenVol = gql`
  mutation CreateTokenVol($clientInfo: String!, $data: AWSJSON!, $vType: TokenType!) {
    createTokenVol(clientInfo: $clientInfo, data: $data, vType: $vType)
  }
`

function serializeTokenData(params: TokenParams): string {
  const data: { conversationID?: string, teamID?: string, momentID?: string } = {}

  if (params.type === TokenType.Conversation
    || params.type === TokenType.AdhocOneOnOne
    || params.type === TokenType.TeamConversation
    || params.type === TokenType.User
    || params.type === TokenType.PremiumUser) {
    data.conversationID = params.data.conversationId
  }
  if (params.type === TokenType.Team
    || params.type === TokenType.TeamAdmin
    || params.type === TokenType.TeamConversation
    || params.type === TokenType.Slack) {
    data.teamID = params.data.teamId
  }
  if (params.type === TokenType.PublicMoment) {
    data.momentID = params.data.momentId
  }

  return JSON.stringify(data)
}

export async function createToken(params: TokenParams): Promise<Token> {
  const result = await mutateGraphQl<CreateTokenVolResult, CreateTokenVolParams>({
    mutation: CreateTokenVol,
    variables: {
      clientInfo: await getClientInfo(),
      data: serializeTokenData(params),
      vType: params.type,
    }
  })

  checkForErrors('createTokenVol', result, true)

  return deserializeToken(result.data?.createTokenVol ?? '')
}

type ApplyTokenResult = {
  conversationId?: string
  teamId?: string
  tokenId: string
}
type ApplyTokenVolParams = {
  clientInfo: string
  tokenId: string
}
type ApplyTokenVolResult = {
  applyTokenVol: string
  error: string
}
const ApplyTokenVol = gql`
  mutation ApplyTokenVol($clientInfo: String!, $tokenId: ID!) {
    applyTokenVol(clientInfo: $clientInfo, tokenID: $tokenId)
  }
`

export async function applyToken(tokenId: string): Promise<ApplyTokenResult> {
  const result = await mutateGraphQl<ApplyTokenVolResult, ApplyTokenVolParams>({
    mutation: ApplyTokenVol,
    variables: { clientInfo: await getClientInfo(), tokenId }
  })

  checkForErrors('applyTokenVol', result, true)

  return deserializeApplyTokenResult(result.data?.applyTokenVol ?? '')
}

function deserializeApplyTokenResult(data: string): ApplyTokenResult {
  const parsed = JSON.parse(data)

  return {
    conversationId: parsed.conversationID,
    teamId: parsed.teamID,
    tokenId: parsed.tokenID,
  }
}
