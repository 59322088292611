import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreateConversationParams, UpdateConversationParams } from 'modules/api/conversations'
import { FREE_TIER } from 'modules/api/shared'
import { Conversation, ConversationsDictionary, ConversationType, RecentMoment, RecentMomentDictionary } from 'modules/types/conversations'
import { Moment } from 'modules/types/moments'
import { TierLimits } from 'modules/types/tier-limits'
import { Alert } from '../alerts/slice'
import { AppState } from '../reducers'
import { sortRecentMoments } from './utils'

type IsFetchingDictionary = { [conversationId: string]: boolean }
type IsErrorDictionary = { [conversationId: string]: string }
type ConversationFullMoments = {
  canLoadMore: boolean
  moments: RecentMoment[]
  nextTimestamp?: string
}
type FullMomentsDictionary = { [conversationId: string]: ConversationFullMoments }
export type ConversationsState = {
  conversations: ConversationsDictionary
  createConversationFailed?: string
  deleteConversationFailed?: string
  fetchConversationsFailed: IsErrorDictionary
  fetchMomentsFailed: IsErrorDictionary
  fullMoments: FullMomentsDictionary
  initialized: boolean
  isCreatingConversation: boolean
  isCreatingThread: boolean
  isDeletingConversation: boolean
  isFetching: IsFetchingDictionary
  isFetchingMoments: IsFetchingDictionary
  isUpdatingConversation: boolean
  newConversationId: string | null
  updateConversationFailed?: { conversationId: string }
}
export const initialState: ConversationsState = {
  conversations: {},
  createConversationFailed: undefined,
  fetchConversationsFailed: {},
  fetchMomentsFailed: {},
  fullMoments: {},
  initialized: false,
  isCreatingConversation: false,
  isCreatingThread: false,
  isDeletingConversation: false,
  isFetching: {},
  isFetchingMoments: {},
  isUpdatingConversation: false,
  newConversationId: null,
}

type CreateConversationPayload = Omit<CreateConversationParams, 'client'> & {
  shouldSelectOnCreate?: boolean
}
export type CreateConversationAction = PayloadAction<CreateConversationPayload>
const createConversation: CaseReducer<ConversationsState, CreateConversationAction> = (state, action) => ({
  ...state,
  newConversationId: null,
  isCreatingConversation: true,
  isCreatingThread: action.payload.vType === ConversationType.Thread,
  createConversationFailed: undefined,
})
export type CreateConversationSuccessAction = PayloadAction<{ conversation: Conversation }>
const createConversationSuccess: CaseReducer<ConversationsState, CreateConversationSuccessAction> = (state, action) => ({
  ...state,
  conversations: {
    ...state.conversations,
    [action.payload.conversation.id]: action.payload.conversation
  },
  createConversationFailed: undefined,
  isCreatingConversation: false,
  isCreatingThread: false,
  newConversationId: action.payload.conversation.id,
})
export type CreateConversationFailureAction = PayloadAction<{ error: string }>
const createConversationFailure: CaseReducer<ConversationsState, CreateConversationFailureAction> = (state, action) => ({
  ...state,
  createConversationFailed: action.payload.error,
  isCreatingConversation: false,
  isCreatingThread: false,
})
export type ClearCreateConversationFailureAction = PayloadAction
const clearCreateConversationFailure: CaseReducer<ConversationsState, ClearCreateConversationFailureAction> = (state, _action) => ({
  ...state,
  createConversationFailed: undefined,
})
const clearNewConversation: CaseReducer<ConversationsState, PayloadAction> = (state, _) => ({
  ...state,
  newConversationId: null,
})

export type DeleteConversationAction = PayloadAction<{ conversationId: string }>
const deleteConversation: CaseReducer<ConversationsState, DeleteConversationAction> = (state, _action) => ({
  ...state,
  deleteConversationFailed: undefined,
  isDeletingConversation: true,
})
type DeleteConversationFailureAction = PayloadAction<{ conversationId: string }>
const deleteConversationFailure: CaseReducer<ConversationsState, DeleteConversationFailureAction> = (state, action) => ({
  ...state,
  isDeletingConversation: false,
  updateConversationFailed: { conversationId: action.payload.conversationId },
})
export type DeleteConversationSuccessAction = PayloadAction<{ conversationId: string, teamId: string }>
const deleteConversationSuccess: CaseReducer<ConversationsState, DeleteConversationSuccessAction> = (state, action) => ({
  ...state,
  conversations: Object.entries(state.conversations).reduce<ConversationsDictionary>((acc, [id, convo]) => {
    if (id !== action.payload.conversationId)
      acc[id] = convo
    return acc
  }, {}),
  isDeletingConversation: false,
  deleteConversationFailed: undefined,
  newConversationId: null,
})

export type FetchConversationsAction = PayloadAction<{ ids: string[], initialize?: boolean }>
const fetchConversations: CaseReducer<ConversationsState, FetchConversationsAction> = (state, action) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    ...action.payload.ids.reduce<IsFetchingDictionary>((dict, id) => {
      dict[id] = true
      return dict
    }, {})
  },
})
type FetchConversationsFailureAction = PayloadAction<{ ids: string[], reason: string }>
const fetchConversationsFailure: CaseReducer<ConversationsState, FetchConversationsFailureAction> = (state, action) => ({
  ...state,
  fetchConversationsFailed: {
    ...state.fetchConversationsFailed,
    ...action.payload.ids.reduce<IsErrorDictionary>((dict, id) => {
      dict[id] = action.payload.reason
      return dict
    }, {}),
  },
  isFetching: Object.keys(state.isFetching)
    .reduce<IsFetchingDictionary>((dict, id) => {
      if (!action.payload.ids.includes(id)) {
        dict[id] = true
      }
      return dict
    }, {}),
})
export type FetchConversationsSuccessAction = PayloadAction<{ conversations: ConversationsDictionary, initialized?: boolean }>
const fetchConversationsSuccess: CaseReducer<ConversationsState, FetchConversationsSuccessAction> = (state, action) => {
  const addedConvoIds = Object.keys(action.payload.conversations)
  return {
    ...state,
    conversations: {
      ...state.conversations,
      ...action.payload.conversations
    },
    fetchConversationsFailed: Object.entries(state.fetchConversationsFailed)
      .reduce<IsErrorDictionary>((dict, [id, reason]) => {
        if (!addedConvoIds.includes(id)) {
          dict[id] = reason
        }
        return dict
      }, {}),
    initialized: state.initialized || !!action.payload.initialized,
    isFetching: Object.keys(state.isFetching)
      .reduce<IsFetchingDictionary>((dict, id) => {
        if (!addedConvoIds.includes(id)) {
          dict[id] = true
        }
        return dict
      }, {}),
  }
}

type FetchMomentsForConversationPayload = { conversationId: string, limit?: number, startOver?: boolean }
export type FetchMomentsForConversationAction = PayloadAction<FetchMomentsForConversationPayload>
const fetchMomentsForConversation: CaseReducer<ConversationsState, FetchMomentsForConversationAction> = (state, action) => {
  const convoFullMoments = state.fullMoments[action.payload.conversationId]

  return {
    ...state,
    fullMoments: {
      ...state.fullMoments,
      [action.payload.conversationId]: {
        expirationDate: new Date().toISOString(),
        canLoadMore: !convoFullMoments || action.payload.startOver ? true : convoFullMoments.canLoadMore,
        moments: convoFullMoments?.moments ?? [],
        nextTimestamp: action.payload.startOver ? undefined : convoFullMoments?.nextTimestamp,
      }
    },
    isFetchingMoments: {
      ...state.isFetchingMoments,
      [action.payload.conversationId]: true,
    },
  }
}
type FetchMomentsForConversationFailureAction = PayloadAction<{ conversationId: string, reason: string }>
const fetchMomentsForConversationFailure: CaseReducer<ConversationsState, FetchMomentsForConversationFailureAction> = (state, action) => {
  const convoFullMoments = state.fullMoments[action.payload.conversationId]

  return {
    ...state,
    fetchMomentsFailed: {
      ...state.fetchMomentsFailed,
      [action.payload.conversationId]: action.payload.reason,
    },
    fullMoments: {
      ...state.fullMoments,
      [action.payload.conversationId]: {
        ...convoFullMoments,
        canLoadMore: false,
        nextTimestamp: undefined,
      }
    },
    isFetchingMoments: Object.keys(state.isFetchingMoments)
      .reduce<IsFetchingDictionary>((dict, id) => {
        if (action.payload.conversationId !== id) {
          dict[id] = true
        }
        return dict
      }, {}),
  }
}
type FetchMomentsForConversationSuccessPayload = {
  canLoadMore: boolean
  conversationId: string
  moments: Moment[]
}
export type FetchMomentsForConversationSuccessAction = PayloadAction<FetchMomentsForConversationSuccessPayload>
const fetchMomentsForConversationSuccess: CaseReducer<ConversationsState, FetchMomentsForConversationSuccessAction> = (state, action) => {
  const convoFullMoments = state.fullMoments[action.payload.conversationId]
  const recentMoments = sortRecentMoments(convoFullMoments?.moments ?? [], action.payload.moments)

  return {
    ...state,
    fetchMomentsFailed: Object.entries(state.fetchMomentsFailed)
      .reduce<IsErrorDictionary>((dict, [id, reason]) => {
        if (action.payload.conversationId !== id) {
          dict[id] = reason
        }
        return dict
      }, {}),
    fullMoments: {
      ...state.fullMoments,
      [action.payload.conversationId]: {
        canLoadMore: action.payload.canLoadMore,
        moments: recentMoments,
        nextTimestamp: action.payload.moments[0]?.createdOn,
      },
    },
    isFetchingMoments: Object.keys(state.isFetchingMoments)
      .reduce<IsFetchingDictionary>((dict, id) => {
        if (action.payload.conversationId !== id) {
          dict[id] = true
        }
        return dict
      }, {}),
  }
}

type UpdateConversationPayload = Omit<UpdateConversationParams, 'client'> & {
  successAlert?: Alert
  userId: string
}
export type UpdateConversationAction = PayloadAction<UpdateConversationPayload>
const updateConversation: CaseReducer<ConversationsState, UpdateConversationAction> = (state, _action) => ({
  ...state,
  isUpdatingConversation: true,
  updateConversationFailed: undefined,
})
type UpdateConversationSuccessPayload = { conversation: Conversation, updateParams?: UpdateConversationPayload }
export type UpdateConversationSuccessAction = PayloadAction<UpdateConversationSuccessPayload>
const updateConversationSuccess: CaseReducer<ConversationsState, UpdateConversationSuccessAction> = (state, action) => ({
  ...state,
  conversations: {
    ...state.conversations,
    [action.payload.conversation.id]: action.payload.conversation,
  },
  isUpdatingConversation: false,
  updateConversationFailed: undefined,
})
export type UpdateConversationFailureAction = PayloadAction<{ conversationId: string }>
const updateConversationFailure: CaseReducer<ConversationsState, UpdateConversationFailureAction> = (state, action) => ({
  ...state,
  isUpdatingConversation: false,
  updateConversationFailed: { conversationId: action.payload.conversationId }
})

type AddRecentMomentAction = PayloadAction<{ conversationId: string, recentMoments: RecentMoment[] }>
const addRecentMoments: CaseReducer<ConversationsState, AddRecentMomentAction> = (state, action) => {
  const convoFullMoments = state.fullMoments[action.payload.conversationId]
  if (!convoFullMoments) {
    return state
  }

  return {
    ...state,
    fullMoments: {
      ...state.fullMoments,
      [action.payload.conversationId]: {
        ...convoFullMoments,
        moments: sortRecentMoments(action.payload.recentMoments.concat(convoFullMoments.moments)),
      },
    },
  }
}
type RemoveRecentMomentsAction = PayloadAction<{ conversationId: string, momentIds: string[] }>
const removeRecentMoments: CaseReducer<ConversationsState, RemoveRecentMomentsAction> = (state, action) => {
  const convoFullMoments = state.fullMoments[action.payload.conversationId]
  if (!convoFullMoments) {
    return state
  }

  return {
    ...state,
    fullMoments: {
      ...state.fullMoments,
      [action.payload.conversationId]: {
        ...convoFullMoments,
        moments: convoFullMoments.moments.filter(m => !action.payload.momentIds.includes(m.momentId)),
      },
    },
  }
}

export type TalkToUserAction = PayloadAction<{ teamId: string, targetUserId: string }>

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    addRecentMoments,

    clearCreateConversationFailure,
    clearNewConversation,
    createConversation,
    createConversationFailure,
    createConversationSuccess,

    deleteConversation,
    deleteConversationFailure,
    deleteConversationSuccess,

    fetchConversations,
    fetchConversationsFailure,
    fetchConversationsSuccess,

    fetchMomentsForConversation,
    fetchMomentsForConversationFailure,
    fetchMomentsForConversationSuccess,

    refetchConversationSuccess: updateConversationSuccess,

    removeRecentMoments,

    talkToUser: (state, _: TalkToUserAction) => state,

    updateConversation,
    updateConversationFailure,
    updateConversationSuccess,
  },
})

export const actions = conversationsSlice.actions
export const selector = {
  name: conversationsSlice.name,
  select: (state: AppState): ConversationsState => state.conversations,
  currentSubscriptionTier: (conversationId: string | null) => (state: AppState): TierLimits => {
    const convo = conversationId ? state.conversations.conversations[conversationId] : null

    return convo?.tierLimits ?? FREE_TIER
  },
  recentMoments: (state: AppState): RecentMomentDictionary =>
    Object.entries(state.conversations.fullMoments)
      .reduce<RecentMomentDictionary>((acc, [conversationId, fullMoments]) => {
        acc[conversationId] = fullMoments.moments
        return acc
      }, {}),
}
export default conversationsSlice.reducer
