import styled from '@emotion/styled'
import DiveChevronIcon from 'assets/dive-chevron.svg'
import theme from 'modules/theme'
import React from 'react'
import SVG from 'react-inlinesvg'
import CircularProgress from '../CircularProgress'

type ContainerProps = {
  hasRightIcon?: boolean
  hideBorderBottom?: boolean
  hideBorderTop?: boolean
  cursorUnset?: boolean
}
const Container = styled.button<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  width: 100%;
  border-top: ${({ hideBorderTop, theme }) => !hideBorderTop ? `1px solid ${theme.palette.divider}` : 'none'};
  border-right: none;
  border-bottom: ${({ hideBorderBottom, theme }) => !hideBorderBottom ? `1px solid ${theme.palette.divider}` : 'none'};
  border-left: none;
  background: none;
  padding: ${({ hasRightIcon }) => hasRightIcon ? '0 11px 0 16px' : '0 16px'};
  font-size: 15px;
  cursor: ${({ cursorUnset }) => cursorUnset ? 'unset' :  'pointer'};

  & > div {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.3;
  }
`
const Icon = styled(SVG) <{ color?: string }>`
  height: 30px;
  width: 30px;
  flex-shrink: 0;

  // TODO: prefer .path-fill class over path selector (need to update all svg files)
  & > path,
  & > .path-fill {
    fill: ${({ color }) => color};
  }
  & > .path-stroke {
    stroke: ${({ color }) => color};
  }
`
const ChildSpacer = styled.span`
  margin-left: 11px;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
`
const DiveChevron = styled(SVG)`
  height: 30px;
  width: 30px;
  flex-shrink: 0;

  & > .path-fill {
    fill: ${({ theme }) => theme.palette.volleyGray05};
  }
`
type ActionIconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & Omit<ContainerProps, 'hasRightIcon'> & {
  hasDiveChevron?: boolean
  icon: string
  iconPathFill?: string
  isWorking?: boolean
  leftAlignIcon?: boolean
  cursorUnset?: boolean
}
const ActionIconButton = React.forwardRef<HTMLButtonElement, ActionIconButtonProps>((props, ref) => {
  const {
    children,
    hasDiveChevron,
    hideBorderBottom,
    hideBorderTop,
    icon,
    iconPathFill,
    isWorking,
    leftAlignIcon,
    cursorUnset,
    ...rest
  } = props

  return (
    <Container
      hideBorderBottom={hideBorderBottom}
      hideBorderTop={hideBorderTop}
      hasRightIcon={hasDiveChevron}
      cursorUnset={cursorUnset}
      ref={ref}
      type="button"
      {...rest}
    >
      {!hasDiveChevron && !leftAlignIcon
        ? (
          <>
            <div>{children}</div>
            <Icon color={iconPathFill} src={icon} />
          </>
        )
        : (
          <>
            <div>
              <Icon color={iconPathFill} src={icon} />
              <ChildSpacer>{children}</ChildSpacer>
            </div>
            {isWorking
              ? <CircularProgress color={theme.palette.primary.main} size="small" />
              : hasDiveChevron
                ? <DiveChevron src={DiveChevronIcon} />
                : <></>
            }
          </>
        )}
    </Container>
  )
})
ActionIconButton.displayName = 'ActionIconButton'

export default ActionIconButton
