import { ADHOC_TEAM_ID, ALL_TEAMS_ID } from 'modules/constants'
import { ConversationType } from 'modules/types/conversations'
import {
  Role,
  SearchConversation,
  SearchConversationDictionary,
  SearchIndexItem,
  SearchUser,
  SearchUserDictionary,
  SearchType,
  UserToTeamsMap,
} from 'modules/types/search'

export function getSearchScore(item: SearchIndexItem, searchedTerms: string[]): number {
  if (!searchedTerms.length) {
    return 1
  }

  let score = 0
  for (const term of searchedTerms) {
    score = item.terms.indexOf(term)
    if (score < 0) {
      return 0
    }

    score += 1
    score += item.terms.length
  }

  return score
}

function isOnlyGuestOnTeams(user: SearchUser, teamId: string | null): boolean {
  if (teamId && teamId !== ALL_TEAMS_ID) {
    return false
  }

  return !!user.associations.length && user.associations.every(a => {
    return a.type === SearchType.Team && a.role === Role.Guest
  })
}
function isGuestOnTeam(user: SearchUser, teamId: string | null): boolean {
  if (!teamId || teamId === ALL_TEAMS_ID || teamId === ADHOC_TEAM_ID) {
    return true
  }

  return user.associations.some(a => a.id === teamId && a.type === SearchType.Team && a.role === Role.Guest)
}
function isOnTeam(user: SearchUser, teamId: string | null): boolean {
  if (!teamId || teamId === ALL_TEAMS_ID || teamId === ADHOC_TEAM_ID) {
    return true
  }

  return !!user.associations.find(a => {
    return a.id === teamId && a.type === SearchType.Team && a.role === Role.Member
  })
}
function isUser(item: SearchIndexItem): boolean {
  return item.type === SearchType.User
}

function parseSearchedTerms(search?: string): string[] {
  if (!search || search === '') {
    return []
  }

  return search.trim().toLowerCase().split(/\s/)
}

type UserFilterOptions = {
  search?: string
  shouldIncludeGuests?: boolean
  teamId?: string
}
export function getSearchUsers(items: SearchIndexItem[], filters?: UserFilterOptions): SearchUser[] {
  const teamId = filters?.teamId ?? null
  const terms = parseSearchedTerms(filters?.search)

  return items
    .reduce<SearchUser[]>((results, item) => {
      const user = item as SearchUser
      const includeUser = filters?.teamId && filters?.shouldIncludeGuests
        ? isUser(user) && (isOnTeam(user, teamId) || isGuestOnTeam(user, teamId))
        : isUser(user) && isOnTeam(user, teamId) && !isOnlyGuestOnTeams(user, teamId)
      if (includeUser) {
        const score = getSearchScore(user, terms)
        if (score > 0) {
          results.push(user)
        }
      }

      return results
    }, [])
    .sort((a, b) => a.terms === b.terms ? 0 : b.terms < a.terms ? 1 : -1)
}

export function getAssociatedTeamIds(index: SearchIndexItem[], userSearchResult: SearchUserDictionary): UserToTeamsMap {
  return index.reduce((res: UserToTeamsMap, item) => {
    if (!userSearchResult[item.id]) {
      return res
    }

    const user = item as SearchUser
    const userTeams = [ADHOC_TEAM_ID]

    user.associations
      .filter(assoc => assoc.type === SearchType.Team)
      .forEach(team => userTeams.push(team.id))

    return {
      ...res,
      [user.id]: userTeams
    }
  }, {})
}

function isThread(conversation: SearchConversation): boolean {
  return conversation.vType === ConversationType.Thread
}
function isIntro(conversation: SearchConversation): boolean {
  return conversation.vType === ConversationType.TeamWelcome || conversation.vType === ConversationType.TalkToMeOnVolley
}
function isConversation(item: SearchIndexItem): boolean {
  return item.type === SearchType.Conversation && !isThread(item) && !isIntro(item)
}

type ConversationFilterOptions = {
  search?: string
  teamId?: string
  type?: ConversationType
}
export function getSearchConversations(items: SearchIndexItem[], filters?: ConversationFilterOptions): SearchConversationDictionary {
  const terms = parseSearchedTerms(filters?.search)

  return items.reduce<SearchConversationDictionary>((results, item) => {
    const convo = item as SearchConversation
    const includeConvo = isConversation(convo)
      && (!filters?.teamId || convo.teamId === filters.teamId)
      && (!filters?.type || convo.vType === filters.type)
    if (includeConvo) {
      const score = getSearchScore(convo, terms)
      if (score > 0) {
        results[convo.id] = { ...convo, score }
      }
    }

    return results
  }, {})
}

export function getConversationSearchKey(search?: string, teamId?: string, type?: string): string {
  return `${search}|${teamId ?? ''}|${type ?? ''}`
}
export function getUserSearchKey(search?: string, teamId?: string, shouldIncludeGuests?: boolean): string {
  return `${search}|${teamId ?? ''}|${shouldIncludeGuests ? 'guests' : ''}`
}
