// This file is intended to be a thin facade over localStorage for testing purposes. Any additional functionality
//  should probably live elsewhere.

let hasLocalStorage: boolean | undefined
export function isLocalStorageSupported(): boolean {
  if (hasLocalStorage === undefined) {
    try {
      localStorage.setItem('grumble', 'grumble')
      hasLocalStorage = true
    } catch {
      hasLocalStorage = false
    }
  }

  return hasLocalStorage
}

let storage: Storage
function getStorage(): Storage {
  if (!storage) {
    if (isLocalStorageSupported()) {
      storage = localStorage
    } else {
      // Because safari--we've discovered that some versions throw errors when attempting to write
      //  to localStorage. Given that the experience is already limited due to lack of support for
      //  MediaRecorder, we'll at least try to get users in, then promptly tell them to use a real
      //  browser or download the app.
      storage = sessionStorage
    }
  }

  return storage
}

export function getItem(key: string): string | null {
  return getStorage().getItem(key)
}

export function setItem(key: string, value: string): void {
  getStorage().setItem(key, value)
}

export function removeItem(key: string): void {
  getStorage().removeItem(key)
}
