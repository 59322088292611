import {
  CopyHistory,
  MentionedUserId,
  Moment,
  MomentAccessLevel,
  MomentState,
  MomentTextContent,
  MomentTextEdit,
  MomentType,
  TranscriptionDownloadUrls,
  UserMomentActivityDictionary,
} from 'modules/types/moments'
import { Offer, OfferCategory, OfferState, OfferType } from 'modules/types/offers'
import { Purchase, PurchaseState } from 'modules/types/purchases'
import { TierLimits, TimeLimit } from 'modules/types/tier-limits'

export type Vol_Moment = {
  accessLevel: MomentAccessLevel
  bytes?: number | null
  conversationID: string
  copyHistory?: Vol_CopyHistory[]
  createdOn: string
  creatorUserID: string
  disableDelete?: boolean
  disableDownloading?: boolean
  disableForwarding?: boolean
  disableSharing?: boolean
  downloadEOL?: number | null
  downloadURL?: string | null
  duration: number
  feedURLs?: Vol_FeedUrls
  id: string
  isSeed?: boolean
  isExpired?: boolean
  mentionedUserIDs?: Vol_MentionedUserIds[]
  mimeType?: string | null
  name?: string | null
  originalFilename?: string | null
  sendOn?: string | null
  sendGigID?: string | null
  sharingURL?: string | null
  source?: string | null
  symlinkSrcID?: string | null
  teamID: string
  textContent?: MomentTextContent
  textEdits?: Vol_MomentTextEdit[]
  threadID?: string | null
  transcriptionDownloadURLs?: TranscriptionDownloadUrls
  transcription?: string
  thumbURL?: string | null
  userData?: Vol_UserData
  vState: MomentState
  vType: MomentType
}
type Vol_CopyHistory = {
  conversationID: string
  createdOn: string
  creatorUserID: string
  momentID: string
  teamID: string
}
export type Vol_FeedUrls = {
  highToLow: string
  lowToHigh: string
  lowToSuper?: string
  superToLow?: string
}
type Vol_MentionedUserIds = {
  length: number
  startLocation: number
  userID: string
}
type Vol_MomentTextEdit = MomentTextContent & {
  mentionedUserIDs?: string[]
  replacedOn: string
}
type Vol_UserData = {
  [userId: string]: {
    lvTS: string | null
    reaction: string | null
    reactionTS: string | null
  }
}

export function deserializeMoment(data: Vol_Moment): Moment {
  return {
    accessLevel: data.accessLevel,
    bytes: data.bytes ?? null,
    conversationId: data.conversationID,
    copyHistory: deserializeCopyHistory(data.copyHistory),
    createdOn: data.createdOn,
    creatorUserId: data.creatorUserID,
    disableDelete: !!data.disableDelete,
    disableDownloading: !!data.disableDownloading,
    disableForwarding: !!data.disableForwarding,
    disableSharing: !!data.disableSharing,
    downloadEol: data.downloadEOL ?? null,
    downloadUrl: data.downloadURL ?? null,
    duration: data.duration,
    expired: data.isExpired ?? false,
    feedUrls: deserializeFeedUrls(data.feedURLs),
    filename: data.originalFilename ?? null,
    id: data.id,
    isSeed: !!data.isSeed,
    mentionedUserIds: deserializeMentionedUserIds(data.mentionedUserIDs),
    mimeType: data.mimeType ?? null,
    name: data.name ?? null,
    sendGigId: data.sendGigID ?? null,
    sharingUrl: data.sharingURL ?? null,
    source: data.source ?? null,
    symlinkSourceId: data.symlinkSrcID ?? null,
    teamId: data.teamID,
    textContent: data.textContent ?? null,
    textEdits: deserializeTextEdits(data.textEdits),
    threadId: data.threadID ?? null,
    thumbUrl: data.thumbURL ?? null,
    transcriptionDownloadUrls: data.transcriptionDownloadURLs ?? {},
    transcription: data.transcription,
    userData: deserializeUserData(data.userData),
    vState: data.vState,
    vType: data.vType,
  }
}
function deserializeCopyHistory(copyHistory?: Vol_CopyHistory[]): CopyHistory[] {
  if (!copyHistory) {
    return []
  }

  return copyHistory.map(ch => ({
    conversationId: ch.conversationID,
    createdOn: ch.createdOn,
    creatorUserId: ch.creatorUserID,
    momentId: ch.momentID,
    teamId: ch.teamID,
  }))
}
export function deserializeFeedUrls(feedUrls?: Vol_FeedUrls): string[] | null {
  if (!feedUrls || !Object.keys(feedUrls).length) {
    return null
  }

  return [feedUrls.superToLow ?? feedUrls.highToLow, feedUrls.lowToSuper ?? feedUrls.lowToHigh]
}
function deserializeMentionedUserIds(mentionedUserIds?: Vol_MentionedUserIds[]): MentionedUserId[] {
  if (!mentionedUserIds) {
    return []
  }

  return mentionedUserIds.map(({ length, startLocation, userID }) => ({
    length,
    startLocation,
    userId: userID,
  }))
}
function deserializeTextEdits(textEdits?: Vol_MomentTextEdit[]): MomentTextEdit[] | null {
  if (!textEdits) {
    return null
  }

  return textEdits.map(e => ({
    backgroundHexColor: e.backgroundHexColor,
    mentionedUserIds: e.mentionedUserIDs ?? [],
    replacedOn: e.replacedOn,
    text: e.text,
  }))
}
function deserializeUserData(userData?: Vol_UserData): UserMomentActivityDictionary {
  if (!userData) {
    return {}
  }

  return Object.entries(userData).reduce<UserMomentActivityDictionary>((acc, [userId, activity]) => {
    return {
      ...acc,
      [userId]: {
        lastViewedTimestamp: activity.lvTS,
        reaction: activity.reaction,
        reactionTimestamp: activity.reactionTS,
        userId,
      }
    }
  }, {})
}

export type Vol_TierLimits = {
  audioMinutes: number
  desktopMinutes: number
  fileMB: number
  hasProFeatures?: boolean
  interactiveTranscripts: boolean
  isGrowTeam?: boolean
  maxAudioKbps: number
  maxVideoFPS: number
  maxVideoKbps: number
  maxVideoRes: number
  momentExpirationDays: number
  name: string
  scheduledVolleys: boolean
  speedUpPlayback: boolean
  sharePublicLink: boolean
  forwardVolleys: boolean
  downloadVolleys: boolean
  markForFollowup: boolean
  teamAdminsMax: number
  tierID: string
  videoMinutes: number
  videoResID: 'standard' | 'super'
  volleyTimeLimits: boolean,
  maxMembers: number,
  shortDesc?: string
}

const DEFAULT_FREE_TIER: Vol_TierLimits = {
  audioMinutes: 15,
  desktopMinutes: 15,
  fileMB: 50,
  hasProFeatures: false,
  interactiveTranscripts: false,
  isGrowTeam: false,
  maxAudioKbps: 48,
  maxVideoFPS: 15,
  maxVideoKbps: 512,
  maxVideoRes: 480,
  momentExpirationDays: 30,
  name: 'Free',
  scheduledVolleys: false,
  speedUpPlayback: false,
  sharePublicLink: false,
  forwardVolleys: false,
  downloadVolleys: false,
  markForFollowup: false,
  teamAdminsMax: 1,
  tierID: 'FREE',
  videoMinutes: 15,
  videoResID: 'standard',
  volleyTimeLimits: false,
  maxMembers: 10
}
export const FREE_TIER = deserializeTierLimits()

export function deserializeTierLimits(tierLimits: Vol_TierLimits = DEFAULT_FREE_TIER): TierLimits {
  return {
    audioTime: deserializeTimeLimit(tierLimits.audioMinutes),
    desktopTime: deserializeTimeLimit(tierLimits.desktopMinutes),
    fileSize: { bytes: tierLimits.fileMB * 1024 * 1024, humanReadable: tierLimits.fileMB + 'MB' },
    hasProFeatures: !!tierLimits.hasProFeatures,
    id: tierLimits.tierID,
    interactiveTranscripts: tierLimits.interactiveTranscripts,
    isGrowTeam: !!tierLimits.isGrowTeam,
    maxAudioBitrate: tierLimits.maxAudioKbps * 1000,
    maxVideoBitrate: tierLimits.maxVideoKbps * 1000,
    maxVideoFrames: tierLimits.maxVideoFPS,
    maxVideoResolution: tierLimits.maxVideoRes,
    momentExpirationDays: tierLimits.momentExpirationDays,
    name: tierLimits.name,
    scheduledVolleys: tierLimits.scheduledVolleys,
    speedUpPlayback: tierLimits.speedUpPlayback,
    sharePublicLink: tierLimits.sharePublicLink,
    forwardVolleys: tierLimits.forwardVolleys,
    downloadVolleys: tierLimits.downloadVolleys,
    markForFollowup: tierLimits.markForFollowup,
    teamAdminsMax: tierLimits.teamAdminsMax,
    videoResolutionId: tierLimits.videoResID,
    videoTime: deserializeTimeLimit(tierLimits.videoMinutes),
    volleyTimeLimits: tierLimits.volleyTimeLimits,
    maxMembers: tierLimits.maxMembers,
    shortDesc: tierLimits.shortDesc,
  }
}
function deserializeTimeLimit(minutes?: number): TimeLimit {
  return {
    totalMinutes: Math.round((minutes ?? 1) * 10) / 10,
    totalSeconds: Math.round((minutes ?? 1) * 60),
  }
}

export type Vol_Offering = {
  accessEOL?: string | null
  cost: number
  creatorUserID: string
  daysOfAccess?: number | null
  description: string
  landingURL?: string
  minutes?: number | null
  name: string
  offeringID: string
  promoText?: string
  seedConversationID?: string
  suppressFromSellerSearch?: boolean
  teamID?: string | null
  vCategory: OfferCategory
  vState: OfferState
  vType: OfferType
}
export function deserializeOffer(offering: Vol_Offering): Offer {
  return {
    accessDays: offering.daysOfAccess ?? null,
    accessEol: offering.accessEOL ?? null,
    cost: offering.cost,
    creatorUserId: offering.creatorUserID,
    description: offering.description,
    id: offering.offeringID,
    landingUrl: offering.landingURL ?? null,
    minutes: offering.minutes ?? null,
    name: offering.name,
    promoText: offering.promoText || null,
    teamId: offering.teamID ?? null,
    seedConversationId: offering.seedConversationID ?? null,
    suppressFromSellerSearch: offering.suppressFromSellerSearch ?? false,
    vCategory: offering.vCategory,
    vState: offering.vState,
    vType: offering.vType,
  }
}

export type Vol_Purchase = {
  conversationID?: string
  createdOn: string
  creatorUserID: string
  endOfBillingCycle: string
  offeringCreatorUserID: string
  offeringID: string
  offeringVType: OfferType
  purchaseID: string
  teamID?: string
  vState: PurchaseState
}
export function deserializePurchase(purchase: Vol_Purchase): Purchase {
  return {
    billingCycleEndsOn: purchase.endOfBillingCycle,
    conversationId: purchase.conversationID || null,
    createdOn: purchase.createdOn,
    creatorUserId: purchase.creatorUserID,
    id: purchase.purchaseID,
    offerCreatorUserId: purchase.offeringCreatorUserID,
    offerId: purchase.offeringID,
    offerType: purchase.offeringVType,
    teamId: purchase.teamID || null,
    vState: purchase.vState,
  }
}
