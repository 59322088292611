import * as linkify from 'linkifyjs'
import config from 'modules/config'
import {
  LinkDictionary,
  MentionedUserId,
  MomentLinkPreview,
  MomentLinkPreviewDictionary,
  MomentTextParagraph,
  MomentTextParagraphs,
} from 'modules/types/moments'

export function getLinkKey(href: string): string {
  try {
    const url = new URL(href)
    return url.origin === config.app.hiHost || url.origin === config.app.talkHost
      ? href
      : `${url.hostname.replace(/^www\./, '')}${url.pathname}`
  } catch (error) {
    return href
  }
}

export function getLinksFromText(text: string): LinkDictionary {
  return linkify
    .find(text, 'url')
    .reduce<LinkDictionary>((acc, res) => {
      const href = res.href.replace(/^http\:\/\//, 'https://')
      const linkKey = getLinkKey(href)
      if (!acc[linkKey]) {
        acc[linkKey] = href
      }

      return acc
    }, {})
}

export function getPlaceholderPreviews(links: LinkDictionary): MomentLinkPreviewDictionary {
  return Object.entries(links).reduce<MomentLinkPreviewDictionary>((acc, [host, link]) => {
    acc[host] = { type: 'unknown', url: link }
    return acc
  }, {})
}

export function getPlaceholderParagraphs(text?: string): MomentTextParagraphs | undefined {
  if (!text) {
    return undefined
  }

  const linksByHost = getLinksFromText(text)
  const previews = getPlaceholderPreviews(linksByHost)
  const paragraphs = getParagraphsWithPreviews(text, previews)

  return {
    initialized: !Object.keys(linksByHost).length,
    paragraphs,
    previews,
  }
}

type ParagraphWithPosition = { startLocation: number, text: string }
function getTextParagraphs(text: string): ParagraphWithPosition[] {
  const result: ParagraphWithPosition[] = []
  const split = text.split('\n')

  let nextParagraph = ''
  let lineBreakCount = 0
  let cursor = 0

  split.forEach((text, i) => {
    const isLastLine = i === split.length - 1

    // no text means it was only a newline
    if (!text) {
      lineBreakCount++
    } else if (!!lineBreakCount) {
      if (lineBreakCount === 1) { // we have more text after only one (1) newline
        nextParagraph = [nextParagraph, text].join('\n')
        lineBreakCount = 0
      } else if (!!nextParagraph) { // we have more text after at least two (2) newlines
        result.push({ startLocation: cursor, text: nextParagraph })

        cursor += nextParagraph.length + lineBreakCount
        nextParagraph = text
        lineBreakCount = 0
      } else if (!nextParagraph) { // text begins with at least two (2) newlines
        cursor += lineBreakCount
        nextParagraph = text
        lineBreakCount = 0
      }

      lineBreakCount++
    } else {
      nextParagraph = text
      lineBreakCount = 1
    }

    if (isLastLine) { // save final paragraph
      result.push({ startLocation: cursor, text: nextParagraph })
    }
  })

  return result
}

export function getParagraphsWithPreviews(text: string, allPreviews: MomentLinkPreviewDictionary): MomentTextParagraph[] {
  let usedLinks: LinkDictionary = {}
  const paragraphs = getTextParagraphs(text)

  return paragraphs.map(({ startLocation, text }) => {
    const links = getLinksFromText(text)
    const previews = Object.entries(links).reduce<MomentLinkPreview[]>((acc, [linkKey, link]) => {
      const preview = allPreviews[linkKey]
      if (preview && !usedLinks[linkKey]) {
        acc.push(preview)
        usedLinks = { ...usedLinks, [linkKey]: link }
      }

      return acc
    }, [])

    return { previews, startLocation, text }
  })
}

type ParagraphPart =
  | { type: 'text', text: string }
  | { type: 'mention', text: string, userId: string }
export function getParagraphParts(paragraph: string, startLocation: number, mentions: MentionedUserId[]): ParagraphPart[] {
  const paragraphMentions = mentions.filter(mention => {
    return mention.startLocation >= startLocation
      && mention.startLocation < startLocation + paragraph.length
  })
  if (!paragraphMentions.length) {
    return [{ type: 'text', text: paragraph }]
  }

  const parts: ParagraphPart[] = []
  let remainingText = paragraph

  while (remainingText.length) {
    const mention = paragraphMentions.pop() ?? null

    if (mention) {
      const start = mention.startLocation - startLocation
      const afterPart: ParagraphPart = {
        type: 'text',
        text: remainingText.slice(start + mention.length)
      }
      const mentionPart: ParagraphPart = {
        type: 'mention',
        text: remainingText.slice(start, start + mention.length),
        userId: mention.userId,
      }

      parts.splice(0, 0, mentionPart, afterPart)
      remainingText = remainingText.slice(0, start)
    } else {
      parts.splice(0, 0, { type: 'text', text: remainingText })
      remainingText = ''
    }
  }

  return parts
}
