import { ApolloClient, ApolloLink, InMemoryCache, NextLink, Operation } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { AUTH_TYPE } from 'aws-appsync'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore This is a generated file. It should be there in most cases but in some it won't and that _should_ be OK.
import awsConfig from '../../aws-exports'
import logger from 'modules/logger'
import { AuthTokenSource } from 'modules/types/auth'

const url = awsConfig.aws_appsync_graphqlEndpoint
const region = awsConfig.aws_appsync_region
const apiKey = awsConfig.aws_appsync_apiKey
// let authType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS | AUTH_TYPE.OPENID_CONNECT
let jwtToken: string

let authenticatedClient: ApolloClient<unknown>
let unauthenticatedClient: ApolloClient<unknown>

export function setAuthToken(token: string, source: AuthTokenSource): void {
  jwtToken = token
  // authType = source === 'cognito' ? AUTH_TYPE.AMAZON_COGNITO_USER_POOLS : AUTH_TYPE.OPENID_CONNECT
}

function getToken(): string {
  if (!jwtToken) {
    throw new Error('No auth token initialized.')
  }

  return jwtToken
}

async function createAuthenticatedClient(): Promise<ApolloClient<unknown>> {
  // const auth = { type: authType, jwtToken: getToken }

  const link = ApolloLink.from([
    // Casting to any fixes the createAuthLink type error. Thanks AWS!
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createErrorLink() as any,
    new RetryLink(),
    customHeader(), // we need to add in the JWT, even though using api_key auth
    // createAuthLink({ url, region, auth }),
    // createSubscriptionHandshakeLink({ url, region, auth })
    createAuthLink({ url, region, auth: { type: AUTH_TYPE.API_KEY, apiKey } }),
    createSubscriptionHandshakeLink({ url, region, auth: { type: AUTH_TYPE.API_KEY, apiKey } })
  ])

  return new ApolloClient<unknown>({ link, cache: new InMemoryCache() })
}

async function createUnauthenticatedClient(): Promise<ApolloClient<unknown>> {
  const link = ApolloLink.from([
    // Casting to any fixes the createAuthLink type error. Thanks AWS!
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createErrorLink() as any,
    new RetryLink(),
    createAuthLink({ url, region, auth: { type: AUTH_TYPE.API_KEY, apiKey } }),
    createSubscriptionHandshakeLink({ url, region, auth: { type: AUTH_TYPE.API_KEY, apiKey } })
  ])

  return new ApolloClient<unknown>({ link, cache: new InMemoryCache() })
}

function createErrorLink(): ApolloLink {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors && isFatalError(graphQLErrors)) {
      graphQLErrors?.forEach(error => logger.error('GraphQL error', error))
    }

    if (networkError) {
      logger.info('Network error', networkError)
      if (!networkError.message.startsWith('AMQJ')) { // ignoring AWS Amplify subscription errors
        logger.error('Apollo network error', networkError)
      }
    }
  })
}

function customHeader() {
  return (operation: Operation, forward: NextLink) => {
    operation.setContext(({ headers }: Record<string, string>) => ({
      headers: {
        authorization: getToken(),
        ...(headers as unknown as Record<string, string>)
      }
    }))
    return forward(operation)
  }
}

type GraphQLError = {
  path?: string[] | null
  errorType?: string
  message?: string
}
const UNAUTHORIZED_NONFATAL_PATHS = new Set(['getMoment', 'getConversation'])
function isFatalError(graphqlErrors: unknown): boolean {
  // if we don't understand what it is... boom
  if (!Array.isArray(graphqlErrors)) {
    return true
  }

  let fatality = false;

  // We're not sure which errors should be handled in which way. To start, let's
  // just let unauthorized errors be non-fatal - we can act like we just don't
  // have that data anymore.
  (graphqlErrors as GraphQLError[]).forEach(error => {
    // Unauthorized errors that are a result of a subscription re-fetch should be mostly ignored. It may be an
    //  indication of badness but likely another subscription hasn't quite caught up yet.
    if (error.errorType === 'Unauthorized' && error.path?.some(p => UNAUTHORIZED_NONFATAL_PATHS.has(p))) {
      console.debug(`Ignoring "${error.errorType}" error on path:`, error.path)
      return
    }

    fatality = true
  })

  return fatality
}

export async function getAuthenticatedClient(): Promise<ApolloClient<unknown>> {
  if (!authenticatedClient) {
    authenticatedClient = await createAuthenticatedClient()
  }

  return authenticatedClient
}

export async function getUnauthenticatedClient(): Promise<ApolloClient<unknown>> {
  if (!unauthenticatedClient) {
    unauthenticatedClient = await createUnauthenticatedClient()
  }

  return unauthenticatedClient
}
