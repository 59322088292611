import { difference } from 'lodash-es'
import * as tokensApi from 'modules/api/tokens'
import {
  createVisitorUser,
  createUser as apiCreateUser,
  FollowUps,
  getParticipants,
  getUser,
  updateUser,
  UpdateUserParams,
} from 'modules/api/users'
import { ADHOC_TEAM_ID, CREATE_USER_PARAMS_PREFERENCE } from 'modules/constants'
import { readPreference, writePreference } from 'modules/hooks/preferences'
import logger from 'modules/logger'
import { AuthProvider } from 'modules/types/auth'
import { SearchUser } from 'modules/types/search'
import { EntityType } from 'modules/types/subscriptions'
import { Token } from 'modules/types/tokens'
import {
  ConversationDataDictionary,
  CreateUserParams,
  ParticipantsDictionary,
  Settings,
  User,
  UserState
} from 'modules/types/users'
import { call, delay, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga'
import { actions as alertsActions } from '../alerts/slice'
import { actions as analyticsActions } from '../analytics/slice'
import { actions as applicationActions, selector as applicationSelector } from '../application/slice'
import { actions as authenticationActions, selector as authenticationSelector } from '../authentication/slice'
import { RefetchUserAction } from '../application/slice'
import {
  actions as conversationsActions,
  DeleteConversationAction,
  UpdateConversationAction,
} from '../conversations/slice'
import { actions as deviceSettingsActions } from '../device-settings/slice'
import { actions as gigsActions } from '../gigs/slice'
import { actions as momentsActions, selector as momentsSelector, StartViewingMomentAction } from '../moments/slice'
import { actions as searchActions, FetchUserSearchIndexSuccessAction } from '../search/slice'
import { getSearchUsers } from '../search/utils'
import {
  actions as streamUploadsActions,
  CloseUploadAction,
  selector as streamUploadsSelector,
} from '../stream-uploads/slice'
import { actions as subscriptionsActions, ConnectionOpenedAction, UpdateEntityAction } from '../subscriptions/slice'
import { actions as teamsActions, UpdateTeamAction } from '../teams/slice'
import { actions as textsActions, SendTextAction, selector as textsSelector } from '../texts/slice'
import { actions as tokensActions, ApplyTokenAction, selector as tokensSelector } from '../tokens/slice'
import {
  actions,
  FetchCurrentUserFailureAction,
  FetchParticipantsAction,
  FollowUpsPayload,
  selector,
  UpdateCurrentUserAction,
} from './slice'

export default function* main(): SagaGenerator<void> {
  yield* takeEvery(actions.updateCurrentUser, updateCurrentUser)

  yield* takeEvery(actions.fetchParticipants, fetchParticipants)
  yield* takeEvery(searchActions.fetchUserSearchIndexSuccess, loadParticipantsFromSearchIndex)

  yield* takeEvery(applicationActions.selectTeam, action => handleTeamChange(action.payload.teamId))
  yield* takeEvery(applicationActions.selectConversation, action => handleTeamChange(action.payload.teamId))
  yield* takeEvery(applicationActions.selectMoment, action => handleTeamChange(action.payload.teamId))

  // optimistic updates
  yield* takeEvery(teamsActions.updateTeam, handleRemoveTeam)
  yield* takeEvery(momentsActions.startViewingMoment, handleStartViewingMoment)
  yield* takeEvery(conversationsActions.updateConversation, handleLeaveConversation)
  yield* takeEvery(conversationsActions.deleteConversation, handleDeleteConversation)
  yield* takeEvery(streamUploadsActions.closeUpload, handleCloseUpload)
  yield* takeEvery(textsActions.sendText, handleSendText)
}

export function* unauthenticatedMain(): SagaGenerator<void> {
  yield* takeEvery(authenticationActions.signInIdentitySuccess, action => fetchCurrentUser(action.payload.userId, true, action.payload.isAnonymous))
  yield* takeEvery(actions.fetchCurrentUser, action => fetchCurrentUser(action.payload.userId))
  yield* takeEvery(actions.fetchCurrentUserFailure, handleFetchFailure)
  yield* takeEvery(actions.createUser, action => createUser(action.payload))
  yield* takeEvery(actions.refetchUser, refetchUser)
  yield* takeEvery(tokensActions.applyToken, handleApplyToken)
  yield* takeEvery(subscriptionsActions.updateEntity, handleSubscriptionsUpdateEntity)
  yield* takeEvery(subscriptionsActions.connectionOpened, handleSubscriptionsReconnect)
}

export function* fetchCurrentUser(userId: string, isInitializing?: boolean, isAnonymousSignIn?: boolean): SagaGenerator<void> {
  let retries = 0
  const { visitorTeamIds } = yield* select(selector.select)

  try {
    let user: User | null = null
    // When someone initially signs up, they are launched into the app but the back end might not be 100% ready because
    // of cognito, volley backend, etc not having the user in their various databases. It's all slow. Usually only
    // needs one retry but doing 3 for good measure since we usually have a spinner going anyway.
    do {
      user = yield* call(getUser, userId, visitorTeamIds)
      if (user) {
        break
      }

      yield* delay(2000)
      retries++
    } while (!isInitializing && retries < 3)

    if (!user || (isInitializing && user.isVisitor && !isAnonymousSignIn)) {
      const createUserParams = getCreateUserParamsFromLocalStorage()

      yield* put(actions.fetchCurrentUserFailure({
        hasCreateParams: !!createUserParams?.email && !!createUserParams?.familyName && !!createUserParams?.givenName,
        isAnonymousSignIn,
        isInitializing: !!isInitializing,
        userId,
      }))
      yield* put(deviceSettingsActions.initializeSettings())
    } else {
      yield* put(actions.fetchCurrentUserSuccess({ user }))
      yield* handleNonMemberPublicTeams(user, visitorTeamIds)
    }
  } catch (error) {
    if (isInitializing) {
      const hasNoSuchUser = error.message.includes('No such acting user')
      if (hasNoSuchUser) {
        const createUserParams = getCreateUserParamsFromLocalStorage()

        yield* put(actions.fetchCurrentUserFailure({
          hasCreateParams: !!createUserParams?.email && !!createUserParams?.familyName && !!createUserParams?.givenName,
          isAnonymousSignIn,
          isInitializing,
          userId,
        }))
      } else {
        logger.error('Failed to initialize user session', error)
        if (confirm('Failed to sign-in. Reload the page to try again. If the problem continues, contact our support at https://help.volleyapp.com.')) {
          yield* put(authenticationActions.signOut())
        }
      }
    } else {
      if (visitorTeamIds.some(id => error.message.includes(id))) {
        yield* handleNoTeamAccess()
      } else {
        logger.error('Error getting current user', error, { userId })
      }

      yield* put(actions.fetchCurrentUserFailure({ isInitializing: !!isInitializing, userId }))
    }
  }
}

function getCreateUserParamsFromLocalStorage(): CreateUserParams | null {
  return readPreference<CreateUserParams | null>(CREATE_USER_PARAMS_PREFERENCE, null)
}

function* handleNoTeamAccess(): SagaGenerator<void> {
  const { currentUser, visitorTeamIds } = yield* select(selector.select)
  if (!currentUser) {
    return
  }

  yield* handleNonMemberPublicTeams(currentUser, visitorTeamIds)
}

function* handleTeamChange(teamId?: string): SagaGenerator<void> {
  if (!teamId) {
    return
  }

  const { currentUser, visitorTeamIds } = yield* select(selector.select)
  if (!currentUser) {
    return
  }

  yield* handleNonMemberPublicTeams(currentUser, visitorTeamIds)
}

function* handleNonMemberPublicTeams(user: User, visitorTeamIds: string[]): SagaGenerator<void> {
  const { selectedTeamId } = yield* select(applicationSelector.select)

  if (!user.teamIds.includes(selectedTeamId)
    && !visitorTeamIds.includes(selectedTeamId)
    && selectedTeamId !== ADHOC_TEAM_ID) {
    yield* put(actions.addVisitorTeamId({ teamId: selectedTeamId }))
    yield* put(actions.fetchCurrentUser({ userId: user.id }))
  } else if (!user.teamIds.includes(selectedTeamId) && visitorTeamIds.includes(selectedTeamId)) {
    yield* put(actions.removeVisitorTeamId({ teamId: selectedTeamId }))
  }
}

export function* handleFetchFailure(action: FetchCurrentUserFailureAction): SagaGenerator<void> {
  const { isAnonymousSignIn, isInitializing, userId } = action.payload
  if (!isInitializing) {
    return
  }

  if (isAnonymousSignIn) {
    yield* call(createVisitorUser, userId)
    yield* put(actions.fetchCurrentUser({ userId }))
  } else {
    yield* createUser(getCreateUserParamsFromLocalStorage())
  }
}

export function* createUser(params?: CreateUserParams | null): SagaGenerator<void> {
  if (!params || !params.email || !params.familyName || !params.givenName) {
    yield* put(actions.createUserFailure())
    return
  }

  try {
    const { authProvider, email, familyName, givenName, phoneNumber, thumbB64 } = params

    const userId = yield* select(authenticationSelector.userIdOrThrow)
    const wasVisitor = yield* select(selector.isVisitor)
    yield* call(apiCreateUser, {
      email: email.trim().toLowerCase(),
      familyName,
      givenName,
      phone: phoneNumber,
      thumbB64,
      userId,
    })

    writePreference(CREATE_USER_PARAMS_PREFERENCE, null)

    yield* put(actions.fetchCurrentUser({ userId }))
    yield* handleCreateUserAnalytics(authProvider, wasVisitor)
  } catch (error) {
    logger.error('Failed to create user', error)
    yield* put(actions.createUserFailure({
      error: typeof error.message === 'string'
        ? error.message
        : 'Unexpected error occurred. Try again or contact customer support.'
    }))
  }
}

function* handleCreateUserAnalytics(authProvider: AuthProvider, wasVisitor: boolean): SagaGenerator<void> {
  yield* delay(3000)
  const { appliedToken, isApplying, tokens } = yield* select(tokensSelector.select)

  let token: Token | null = appliedToken
  if (!token) {
    const tokenId = Object.keys(isApplying)[0]
    token = tokens[tokenId]?.token ?? null

    if (tokenId && !token) {
      token = yield* call(tokensApi.getToken, { id: tokenId })

      if (!token) {
        logger.error('Unable to find token associated with invitation', { tokenId })
      }
    }
  }

  yield* put(analyticsActions.createdAccountDelayed({ authProvider, token, promotedFromVisitor: wasVisitor }))
}

export function* fetchParticipants(action: FetchParticipantsAction): SagaGenerator<void> {
  try {
    const { missingIds, refetchIds, success: participants } = yield* call(getParticipants, action.payload.userIds)
    if (refetchIds.length) {
      yield* put(actions.fetchParticipants({ userIds: refetchIds }))
    }
    if (missingIds.length) {
      logger.warn('Participants not found', { ids: missingIds })
      yield* put(actions.fetchParticipantsFailure({ ids: missingIds, reason: 'Not found' }))
    }

    if (Object.keys(participants).length) {
      yield* put(actions.fetchParticipantsSuccess({ participants }))
    }
  } catch (error) {
    logger.error('Error getting participants', error, { ids: action.payload.userIds })
  }
}

export function* loadParticipantsFromSearchIndex(action: FetchUserSearchIndexSuccessAction): SagaGenerator<void> {
  const searchUsers = getSearchUsers(action.payload.items)
  const participants = searchUsers.reduce<ParticipantsDictionary>((acc, u) => {
    const { familyName, givenName, id, thumbUrl } = (u as SearchUser)
    acc[id] = { familyName, givenName, id, thumbUrl }

    return acc
  }, {})

  yield* put(actions.fetchParticipantsSuccess({ participants }))
}

export function* updateCurrentUser(action: UpdateCurrentUserAction): SagaGenerator<void> {
  const {
    followUps,
    notificationPreferences,
    settings,
    successAlert,
    teamIds,
    vanityUrlPath,
    ...params
  } = action.payload

  const state = yield* select(selector.select)
  if (!state.currentUser || state.currentUser.isVisitor) {
    return
  }

  try {
    const mergedSettings = getMergedSettings(settings, state.currentUser.settings)
    const updatePayload: UpdateUserParams = {
      updateFollowUps: getUpdateFollowUps(followUps),
      updateNotificationPreferences: notificationPreferences,
      updateSettings: mergedSettings,
      updateTeamIds: teamIds && state.currentUser.teamIds.length === teamIds.length ? teamIds : undefined,
      updateVanityUrl: vanityUrlPath,
      ...params
    }
    const { updatedUser } = yield* call(updateUser, updatePayload)
    if (!updatedUser) {
      logger.error('Update user did not return user', { userId: state.currentUserId })
      yield* put(actions.updateCurrentUserFailure())
      return
    }

    yield* put(actions.updateCurrentUserSuccess({ user: updatedUser, updateParams: action.payload }))

    yield* handleNewBlockedUsers(state.currentUser, updatedUser)

    if (successAlert) {
      yield* put(alertsActions.pushAlert(successAlert))
    }
  } catch (error) {
    logger.error('Error updating current user', error, { userId: state.currentUserId })
    if (error.message.includes('Phone number has already been claimed')) {
      yield* put(alertsActions.pushAlert({
        message: 'Failed to up update phone number. If the problem persists, please contact support.',
        severity: 'error',
      }))
    }
    if (error.message.includes('Cannot assign vanity path')) {
      yield* put(alertsActions.pushAlert({
        message: 'The URL you requested has already been claimed. Please try another handle.',
        severity: 'error',
      }))
    }
    if (error.message.toLowerCase().includes(`can't updatesellerdata for user`)) {
      yield* put(alertsActions.pushAlert({ message: 'Failed to update consult details', severity: 'error' }))
    }
    yield* put(actions.updateCurrentUserFailure())
  }
}

function getMergedSettings(payload: Settings | undefined, current: Settings): Settings | undefined {
  if (!payload) {
    return undefined
  }

  return {
    ...current,
    textVolleyBackgroundColor: payload.textVolleyBackgroundColor || current.textVolleyBackgroundColor,
  }
}

function getUpdateFollowUps(followUps?: FollowUpsPayload): FollowUps | undefined {
  return followUps
    ? {
      addFollowUps: followUps.addFollowUps?.map(f => f.momentId),
      removeFollowUps: followUps.removeFollowUps,
    }
    : undefined
}

export function* refetchUser(action: RefetchUserAction): SagaGenerator<void> {
  const { userId ,fromReconnect = false } = action.payload
  try {
    const { currentUser, visitorTeamIds } = yield* select(selector.select)
    const user = yield* call(getUser, userId, visitorTeamIds)
    if (!user) {
      logger.warn('Re-fetch of user returned nothing', { userId })
      return
    }
    // if anything other than ACTIVE is in vState, this is the signal from the back end to log the user out
    if (user.vState !== UserState.Active) {
      yield* put(actions.updateCurrentUserSignedOut())
      return
    }

    if (currentUser) {
      yield* handleNewBlockedUsers(currentUser, user)
      yield* handleRemovedConversations(currentUser, user)
      yield* handleRemovedTeams(currentUser, user)
      yield* handleGigsUpdate(currentUser, user)
    }

    yield* put(actions.updateCurrentUserSuccess({ fromReconnect, user }))
  } catch (error) {
    logger.error('Error on current user re-fetch', error, { userId })
  }
}

function* handleNewBlockedUsers(existingUser: User, updatedUser: User): SagaGenerator<void> {
  const newBlockedUsers = difference(Object.keys(updatedUser.blockedUsers), Object.keys(existingUser.blockedUsers))
  if (!newBlockedUsers.length) return

  yield* put(actions.blockedUsersChanged())
  // when a user is blocked, their profile image is blocked as well so we need to refetch them
  yield* put(actions.fetchParticipants({ userIds: newBlockedUsers }))
}

function* handleRemovedConversations(existingUser: User, updatedUser: User): SagaGenerator<void> {
  const removedConversationIds = difference(existingUser.conversationIds, updatedUser.conversationIds)
  if (!removedConversationIds.length) {
    return
  }

  const { selectedConversationId } = yield* select(applicationSelector.select)
  if (!selectedConversationId
    || !removedConversationIds.includes(selectedConversationId)) {
    return
  }

  yield* put(applicationActions.exitConversation())
}

function* handleRemovedTeams(existingUser: User, updatedUser: User): SagaGenerator<void> {
  const removedTeamIds = difference(existingUser.teamIds, updatedUser.teamIds)
  if (!removedTeamIds.length) {
    return
  }

  const { selectedTeamId } = yield* select(applicationSelector.select)
  if (removedTeamIds.includes(selectedTeamId)) {
    yield* put(applicationActions.selectTeam({ teamId: ADHOC_TEAM_ID }))
  }
}

function* handleGigsUpdate(existingUser: User, updatedUser: User): SagaGenerator<void> {
  if ((existingUser?.gigsUpdatedOn && updatedUser?.gigsUpdatedOn && existingUser.gigsUpdatedOn < updatedUser.gigsUpdatedOn) || (!existingUser?.gigsUpdatedOn && updatedUser?.gigsUpdatedOn)) {
    yield* put(gigsActions.fetchGigs())
  }
}

export function* handleSubscriptionsUpdateEntity(action: UpdateEntityAction): SagaGenerator<void> {
  const { entityId, entityType } = action.payload
  if (entityType !== EntityType.User) {
    return
  }

  yield* put(actions.refetchUser({ userId: entityId }))
}

export function* handleSubscriptionsReconnect(action: ConnectionOpenedAction): SagaGenerator<void> {
  const { disconnectedSeconds } = action.payload
  if (!disconnectedSeconds) {
    return
  }

  const { currentUser } = yield* select(selector.select)
  if (currentUser) {
    yield* put(actions.refetchUser({ userId: currentUser.id, fromReconnect: true }))
  }
}

export function* handleApplyToken(action: ApplyTokenAction): SagaGenerator<void> {
  const { id } = action.payload

  const { visitorTeamIds } = yield* select(selector.select)
  const { tokens } = yield* select(tokensSelector.select)

  const token = tokens[id]?.token
  if (!token || !token.team || !visitorTeamIds.includes(token.team.id)) {
    return
  }

  yield* put(actions.removeVisitorTeamId({ teamId: token.team.id }))
}

export function* handleRemoveTeam(action: UpdateTeamAction): SagaGenerator<void> {
  const { removeUserIds, teamId } = action.payload
  if (!removeUserIds || !removeUserIds.length) {
    return
  }

  const { currentUser } = yield* select(selector.select)
  if (!currentUser || !removeUserIds.includes(currentUser.id)) {
    return
  }

  const { selectedTeamId } = yield* select(applicationSelector.select)
  if (selectedTeamId === teamId) {
    yield* put(applicationActions.selectTeam({ teamId: ADHOC_TEAM_ID }))
  }

  const excludeConvoIds = new Set<string>()
  const filteredConvoData = Object.entries(currentUser.conversationData)
    .reduce<ConversationDataDictionary>((acc, [id, data]) => {
      if (data.teamId !== teamId) {
        acc[id] = data
      } else {
        excludeConvoIds.add(id)
      }
      return acc
    }, {})

  yield* put(actions.updateCurrentUserSuccess({
    user: {
      ...currentUser,
      conversationData: filteredConvoData,
      conversationIds: currentUser.conversationIds.filter(id => !excludeConvoIds.has(id)),
      teamIds: currentUser.teamIds.filter(id => id !== teamId),
    },
  }))
}

export function* handleStartViewingMoment(action: StartViewingMomentAction): SagaGenerator<void> {
  const { conversationId, momentId } = action.payload

  const { currentUser } = yield* select(selector.select)

  if (!!currentUser?.followUpMoments[momentId]) {
    const { moments } = yield* select(momentsSelector.select)
    const moment = moments[momentId]
    yield* put(actions.updateCurrentUser({
      followUps: {
        removeFollowUps: [momentId],
        viewedFollowUps: [{ momentId, type: moment?.vType }],
      },
    }))
  }

  if (!!currentUser?.conversationData[conversationId]?.unviewedCount) {
    const convoData = currentUser.conversationData[conversationId]

    yield* put(actions.updateCurrentUserSuccess({
      user: {
        ...currentUser,
        conversationData: {
          ...currentUser.conversationData,
          [conversationId]: {
            ...currentUser.conversationData[conversationId],
            hasNewContent: convoData.unviewedCount > 1 ? convoData.hasNewContent : undefined,
            showNewBadge: undefined,
            sortToTop: convoData.unviewedCount > 1 ? convoData.sortToTop : undefined,
            unviewedCount: convoData.unviewedCount - 1,
          },
        },
      },
    }))
  }
}

function* handleLeaveConversation(action: UpdateConversationAction): SagaGenerator<void> {
  const { conversationId, removeUserIds } = action.payload
  if (!removeUserIds) {
    return
  }

  const { currentUser } = yield* select(selector.select)
  if (!currentUser || !removeUserIds.includes(currentUser.id)) {
    return
  }

  yield* handleRemoveConversation(conversationId, currentUser)
}

function* handleDeleteConversation(action: DeleteConversationAction): SagaGenerator<void> {
  const { conversationId } = action.payload
  const { currentUser } = yield* select(selector.select)
  if (!currentUser) {
    return
  }

  yield* handleRemoveConversation(conversationId, currentUser)
}

export function* handleRemoveConversation(conversationId: string, currentUser: User): SagaGenerator<void> {
  const filteredConvoData = Object.entries(currentUser.conversationData)
    .reduce<ConversationDataDictionary>((acc, [id, data]) => {
      if (id !== conversationId) {
        acc[id] = data
      }

      return acc
    }, {})

  yield* put(actions.updateCurrentUserSuccess({
    user: {
      ...currentUser,
      conversationData: filteredConvoData,
      conversationIds: currentUser.conversationIds.filter(id => id !== conversationId),
    },
  }))
}

function* handleCloseUpload(action: CloseUploadAction): SagaGenerator<void> {
  const { uploadId } = action.payload

  const { uploads } = yield* select(streamUploadsSelector.select)
  const upload = uploads[uploadId]
  if (!upload) {
    return
  }

  yield* handleSendVolley(upload.conversationId)
}

function* handleSendText(action: SendTextAction): SagaGenerator<void> {
  const { textId } = action.payload

  const { texts } = yield* select(textsSelector.select)
  const text = texts[textId]
  if (!text) {
    return
  }

  yield* handleSendVolley(text.conversationId)
}

export function* handleSendVolley(conversationId: string): SagaGenerator<void> {
  const { currentUser } = yield* select(selector.select)
  if (!currentUser) {
    return
  }

  const data = currentUser.conversationData[conversationId]
  if (!data) {
    return
  }

  yield* put(actions.updateCurrentUserSuccess({
    user: {
      ...currentUser,
      conversationData: {
        ...currentUser.conversationData,
        [conversationId]: {
          ...data,
          hasNewContent: undefined,
          justJoined: undefined,
          showNewBadge: undefined,
          unviewedCount: data.threadUnviewedCount,
        },
      },
    },
  }))
}
