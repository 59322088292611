import { Analytics, getAnalytics, logEvent, setUserId } from 'firebase/analytics'
import {
  applyActionCode,
  Auth,
  browserLocalPersistence,
  browserSessionPersistence,
  checkActionCode,
  confirmPasswordReset,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  IdTokenResult,
  isSignInWithEmailLink,
  linkWithCredential,
  linkWithPopup,
  OAuthProvider,
  setPersistence,
  signInAnonymously as firebaseSignInAnonymously,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  signInWithRedirect,
  signOut as firebaseSignOut,
  updateEmail as firebaseUpdateEmail,
  updatePassword as firebaseUpdatePassword,
  User,
  verifyPasswordResetCode,
} from 'firebase/auth'
import { FirebaseApp, initializeApp } from 'firebase/app'

import { AuthActionInfo, AuthProvider, IdentityUser, SsoAuthProvider } from 'modules/types/auth'
import config from 'modules/config'
import { isLocalStorageSupported } from 'modules/local-storage'
import { isElectron } from '../electron-utils'

let app: FirebaseApp
let analytics: Analytics
let auth: Auth
let availableAuthProviders: AuthProvider[] = []

function getFirebaseApp(): FirebaseApp {
  if (!app) {
    app = initializeApp(config.firebase)
  }

  return app
}

function getFirebaseAnalytics(): Analytics {
  if (!analytics) {
    analytics = getAnalytics(getFirebaseApp())
  }

  return analytics
}

async function getFirebaseAuth(): Promise<Auth> {
  if (!auth) {
    auth = getAuth(getFirebaseApp())
    const persistence = isLocalStorageSupported()
      ? browserLocalPersistence
      : browserSessionPersistence
    await setPersistence(auth, persistence)
  }

  return auth
}

function identifyAnalyticsUser(userId: string): void {
  setUserId(getFirebaseAnalytics(), userId)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function logAnalyticsEvent(name: string, params?: Record<string, any>): void {
  logEvent(getFirebaseAnalytics(), name, params)
}

async function getCurrentUser(): Promise<IdentityUser | null> {
  const auth = await getFirebaseAuth()
  if (!auth.currentUser) {
    return null
  }

  await auth.currentUser.reload()
  const tokenResult = await auth.currentUser.getIdTokenResult(true)

  return deserializeUser({ forceRefresh: true, tokenResult, user: auth.currentUser })
}

type UserParams = {
  forceRefresh?: boolean
  tokenResult: IdTokenResult
  user: User
}
async function deserializeUser({ forceRefresh, tokenResult, user }: UserParams): Promise<IdentityUser> {
  const lastSpaceIndex = user.displayName?.lastIndexOf(' ')
  const [givenName, familyName] = !!user.displayName && lastSpaceIndex
    ? [user.displayName.substring(0, lastSpaceIndex), user.displayName.substring(lastSpaceIndex + 1)]
    : ['', '']
  const provider = tokenResult.signInProvider as AuthProvider

  return {
    accessToken: tokenResult.token,
    availableAuthProviders: user.email ? await getAvailableAuthProviders(user.email, forceRefresh) : [provider],
    email: user.email,
    expiresOn: new Date(tokenResult.expirationTime).getTime(),
    familyName,
    givenName,
    isAnonymous: user.isAnonymous,
    isEmailVerified: user.emailVerified,
    issuedOn: new Date(tokenResult.issuedAtTime).getTime(),
    phoneNumber: user.phoneNumber ?? undefined,
    provider,
    source: 'firebase',
    userId: user.uid,
  }
}

async function getAvailableAuthProviders(email: string, forceRefresh?: boolean): Promise<AuthProvider[]> {
  if (forceRefresh || !availableAuthProviders.length) {
    const availableProviders = await fetchSignInMethodsForEmail(await getFirebaseAuth(), email)
    availableAuthProviders = availableProviders as AuthProvider[]
  }

  return availableAuthProviders
}

async function promoteAnonymousUser(email: string, password: string): Promise<IdentityUser> {
  const auth = await getFirebaseAuth()
  if (!auth.currentUser || !auth.currentUser.isAnonymous) {
    throw new Error('Must have anonymous user to promote')
  }

  const credential = EmailAuthProvider.credential(email, password)
  const userCreds = await linkWithCredential(auth.currentUser, credential)
  const tokenResult = await userCreds.user.getIdTokenResult()

  return deserializeUser({ tokenResult, user: auth.currentUser })
}

async function promoteAnonymousUserWithSso(authProvider: SsoAuthProvider): Promise<IdentityUser> {
  const auth = await getFirebaseAuth()
  if (!auth.currentUser || !auth.currentUser.isAnonymous) {
    throw new Error('Must have anonymous user to promote')
  }

  const provider = authProvider === AuthProvider.Google
    ? new GoogleAuthProvider()
    : new OAuthProvider(authProvider)
  provider.setCustomParameters({ prompt: 'select_account' })

  const result = await linkWithPopup(auth.currentUser, provider)
  const tokenResult = await result.user.getIdTokenResult()
  const additionalUserInfo = getAdditionalUserInfo(result)

  return deserializeUser({
    tokenResult,
    user: {
      ...result.user,
      displayName: result.user.displayName ?? (additionalUserInfo?.profile?.name as string) ?? null,
    },
  })
}

async function resetPassword(oobCode: string, newPassword: string): Promise<void> {
  return confirmPasswordReset(await getFirebaseAuth(), oobCode, newPassword)
}

async function signIn(email: string, password: string): Promise<IdentityUser> {
  const userCreds = await signInWithEmailAndPassword(await getFirebaseAuth(), email, password)
  const tokenResult = await userCreds.user.getIdTokenResult()

  return deserializeUser({ tokenResult, user: userCreds.user })
}

async function signInAnonymously(): Promise<IdentityUser> {
  const auth = await getFirebaseAuth()
  const userCreds = await firebaseSignInAnonymously(auth)
  const tokenResult = await userCreds.user.getIdTokenResult()

  return deserializeUser({ tokenResult, user: userCreds.user })
}

async function signInWithLink(email: string, link: string): Promise<IdentityUser> {
  const userCreds = await signInWithEmailLink(await getFirebaseAuth(), email, link)
  const tokenResult = await userCreds.user.getIdTokenResult()

  return deserializeUser({ tokenResult, user: userCreds.user })
}

async function signInWithSso(authProvider: SsoAuthProvider): Promise<IdentityUser> {
  const provider = authProvider === AuthProvider.Google
    ? new GoogleAuthProvider()
    : new OAuthProvider(authProvider)
  provider.setCustomParameters({ prompt: 'select_account' })
  if (isElectron()) {
    await signInWithRedirect(await getFirebaseAuth(), provider)
    const result = await getRedirectResult(await getFirebaseAuth())
    if ( result !== null && result.user) {
      const tokenResult = await result.user.getIdTokenResult()
      return deserializeUser({ tokenResult, user: result.user })
    } else {
      throw new Error('Failed to sign in with SSO')
    }
  } else {
    const userCreds = await signInWithPopup(await getFirebaseAuth(), provider)
    const tokenResult = await userCreds.user.getIdTokenResult()

    return deserializeUser({ tokenResult, user: userCreds.user })
  }

}

async function signOut(): Promise<void> {
  availableAuthProviders = []
  return firebaseSignOut(await getFirebaseAuth())
}

async function updateEmail(newEmail: string): Promise<void> {
  const user = (await getFirebaseAuth()).currentUser
  if (!user) {
    throw new Error('User not found')
  }

  return firebaseUpdateEmail(user, newEmail)
}

async function updatePassword(newPassword: string): Promise<void> {
  const user = (await getFirebaseAuth()).currentUser
  if (!user) {
    throw new Error('User not found')
  }

  return firebaseUpdatePassword(user, newPassword)
}

async function validateAuthAction(oobCode: string): Promise<AuthActionInfo> {
  const res = await checkActionCode(await getFirebaseAuth(), oobCode)

  return {
    email: res.data.previousEmail ?? null,
    recoverEmail: res.data.email ?? null,
  }
}

async function applyAuthAction(oobCode: string): Promise<void> {
  return applyActionCode(await getFirebaseAuth(), oobCode)
}

async function verifyAuthLink(link: string): Promise<boolean> {
  return isSignInWithEmailLink(await getFirebaseAuth(), link)
}

async function verifyResetPasswordCode(oobCode: string): Promise<string> {
  return verifyPasswordResetCode(await getFirebaseAuth(), oobCode)
}

export default {
  analytics: {
    identifyUser: identifyAnalyticsUser,
    logEvent: logAnalyticsEvent,
  },
  auth: {
    getAvailableAuthProviders,
    getCurrentUser,
    promoteAnonymousUser,
    promoteAnonymousUserWithSso,
    recoverEmail: applyAuthAction,
    resetPassword,
    signIn,
    signInAnonymously,
    signInWithLink,
    signInWithSso,
    signOut,
    updateEmail,
    updatePassword,
    validateAuthAction,
    verifyEmail: applyAuthAction,
    verifyAuthLink,
    verifyResetPasswordCode,
  },
}
