import gql from 'graphql-tag'
import getClientInfo from 'modules/clientInfo'
import { ADHOC_TEAM_ID } from 'modules/constants'
import { OfferType } from 'modules/types/offers'
import { PurchaseState } from 'modules/types/purchases'
import {
  GuestConversationsDictionary,
  SellerData,
  Team,
  TeamAccessLevel,
  TeamChannelsDictionary,
  TeamRoster,
  TeamRosterMember,
  TeamRosterMembersDictionary,
  TeamsDictionary,
  TeamState,
  TemplateTeam,
} from 'modules/types/teams'
import { buildMutation, checkForErrors, createMutationMap, mutateGraphQl } from './graph-utils'
import { deserializeOffer, deserializePurchase, deserializeTierLimits, Vol_Offering, Vol_Purchase, Vol_TierLimits } from './shared'
import { BatchResult, deserializeBatchResults } from './util'

type GetTeamVolParams = { clientInfo: string, ids: string[] }
type GetTeamVolWithBillingUrlParams = { clientInfo: string, id: string, onlyFields: string[] }
type GetTeamVolResult = { getTeamVol: string | null }
type GetTeamVol_TeamDictionary = { [id: string]: GetTeamVol_Team }
export type GetTeamVol_Team = {
  id: string
  accessLevel: TeamAccessLevel
  billingPortalURL?: string
  channels: GetTeamVol_Channels | null
  conversationIDs: string[] | null
  createChannelByAdminOnly: boolean | null
  createdOn: string | null
  creatorUserID: string
  disableDownloading: boolean | null
  disableForwarding: boolean | null
  disableSharing: boolean | null
  error?: string
  guestConvs?: GuestConversationsDictionary | null
  guestIDs: string[] | null
  inviteByAdminOnly: boolean | null
  isAdhoc: boolean
  isPremiumActive?: boolean
  isPublic: boolean
  name: string
  offerings?: Vol_Offering[]
  premiumSeedConversationID?: string
  premiumLandingTokenID?: string | null
  purchases?: Vol_Purchase[]
  teamAdminIDs: string[] | null
  teamID?: string // only available when error prop is set
  thumbEOL?: number
  thumbUpdatedOn: string | null
  thumbURL?: string
  tierEOL?: string
  tierID: string
  tierLimits?: Vol_TierLimits
  tierShortDesc: string
  tierWillRenew?: boolean
  tokensCreated: string[] | null
  userIDs: string[] | null
  vState: TeamState
  welcomeConversationID: string | null
}
type GetTeamVol_Channels = {
  [id: string]: {
    description?: string
    isAutoJoin?: boolean
    isEveryone?: boolean
    name: string
    preventDeletion?: boolean
    preventUsersLeaving?: boolean
    thumbEOL?: number
    thumbURL?: string | null
    thumbEmoji?: string | null
    userCountString: string | null
  }
}

const GetTeamVolWithBillingUrlMutation = gql`
  mutation GetTeamVol($clientInfo: String, $id: ID!, $onlyFields: [String]) {
    getTeamVol(clientInfo: $clientInfo, getBillingPortalURL: true, teamID: $id, onlyFields: $onlyFields)
  }
`
export async function getTeam(teamId: string, getBillingUrl?: boolean): Promise<Team | null> {
  if (getBillingUrl) {
    const clientInfo = await getClientInfo()
    const result = await mutateGraphQl<GetTeamVolResult, GetTeamVolWithBillingUrlParams>({
      mutation: GetTeamVolWithBillingUrlMutation,
      variables: { clientInfo, id: teamId, onlyFields: ['billingPortalURL', 'id'] },
    })

    checkForErrors('getTeamVol', result, true)
    return result.data?.getTeamVol
      ? deserializeTeam(JSON.parse(result.data.getTeamVol))
      : null
  }

  const results = await getTeams([teamId])
  if (results.refetchIds.length) {
    throw new Error(`Failed to get team ${teamId}`)
  }

  return !results.missingIds.length
    ? results.success[teamId]
    : null
}

const GetTeamVolMutation = gql`
  mutation GetTeamVol($clientInfo: String, $ids: [ID]!) {
    getTeamVol(clientInfo: $clientInfo, teamIDs: $ids)
  }
`
export async function getTeams(teamIds: string[]): Promise<BatchResult<Team>> {
  // _Technically_ no user has access to the adhoc team (just their own version of it). Because of this, asking for it
  //  will result in an error. Someday we hope the API will get smarter about this but until then, let's not bother
  //  asking for it (there's not much info relevant for it anyway).
  const nonAdhocTeamIds = teamIds.filter(id => id !== ADHOC_TEAM_ID)

  const clientInfo = await getClientInfo()
  const results = await mutateGraphQl<GetTeamVolResult, GetTeamVolParams>({
    mutation: GetTeamVolMutation,
    variables: { clientInfo, ids: nonAdhocTeamIds },
  })

  checkForErrors('getTeamVol', results, true)

  let batchResults = deserializeBatchResults(results.data?.getTeamVol, deserializeTeams)
  if (teamIds.includes(ADHOC_TEAM_ID)) {
    const adhocTeam = createAdhocTeam()

    batchResults = {
      ...batchResults,
      success: {
        ...batchResults.success,
        [adhocTeam.id]: adhocTeam,
      }
    }
  }

  return batchResults
}

type CreateTeamVolParams = { clientInfo: string, isGrowTeam: boolean, name: string, template: string }
type CreateTeamVolResult = { createTeamVol: string }
const CreateTeamMutation = gql`
  mutation CreateTeamVol($clientInfo: String, $isGrowTeam: Boolean, $name: String!, $template: AWSJSON) {
    createTeamVol(clientInfo: $clientInfo, isGrowTeam: $isGrowTeam, name: $name, template: $template)
  }
`
export async function createTeam(template: TemplateTeam, isGrowTeam?: boolean): Promise<string> {
  const clientInfo = await getClientInfo()
  const result = await mutateGraphQl<CreateTeamVolResult, CreateTeamVolParams>({
    mutation: CreateTeamMutation,
    variables: {
      clientInfo,
      isGrowTeam: !!isGrowTeam,
      name: template.name,
      template: JSON.stringify(template),
    }
  })

  checkForErrors('createTeamVol', result, true)

  const parsed: { id: string } = JSON.parse(result.data?.createTeamVol ?? '')
  return parsed.id
}

type UpdateTeamVolParams = {
  actionRemoveAllNonAdminUsers?: boolean
  addAdminIds?: string[]
  addUserIds?: string[]
  clientInfo: string
  createChannelByAdminOnly?: boolean
  disableDownloading?: boolean
  disableForwarding?: boolean
  disablePremiumLandingToken?: boolean
  disableSharing?: boolean
  getBillingUrl?: boolean
  inviteByAdminOnly?: boolean
  maxVolleyMinutes?: number
  name?: string
  removeAdminIds?: string[]
  removeUserIds?: string[]
  teamId: string
  thumbB64?: string | null
  thumbEmoji?: string | null
  tierId?: string | null
  updateSellerAnalyticsUrl?: boolean,
  cancelSubscription?: boolean
}
type UpdateTeamVolResult = { updateTeamVol: string | null }
type UpdateTeamVol_Data = {
  billingPortalURL?: string
  redirectURL?: string
  sellerData?: UpdateTeamVol_SellerData
}
type UpdateTeamVol_SellerData = {
  analyticsURL: string
}
type UpdateTeamResult = {
  billingUrl?: string
  sellerData: SellerData | null
  upgradeUrl?: string
}
const UpdateTeamVolMutation = createMutationMap('updateTeamVol', {
  actionRemoveAllNonAdminUsers: 'Boolean',
  addAdminIds: { backendKey: 'addAdminIDs', type: '[ID]' },
  addUserIds: { backendKey: 'addUserIDs', type: '[ID]' },
  clientInfo: 'String',
  createChannelByAdminOnly: 'Boolean',
  disableDownloading: 'Boolean',
  disableForwarding: 'Boolean',
  disablePremiumLandingToken: 'Boolean',
  disableSharing: 'Boolean',
  getBillingUrl: { backendKey: 'getBillingPortalURL', type: 'Boolean' },
  inviteByAdminOnly: 'Boolean',
  maxVolleyMinutes: 'Int',
  name: 'String',
  removeAdminIds: { backendKey: 'removeAdminIDs', type: '[ID]' },
  removeUserIds: { backendKey: 'removeUserIDs', type: '[ID]' },
  teamId: { backendKey: 'teamID', type: 'ID!' },
  thumbB64: 'String',
  thumbEmoji: 'String',
  tierId: { backendKey: 'tierID', type: 'String' },
  updateSellerAnalyticsUrl: { backendKey: 'updateSellerAnalyticsURL', type: 'Boolean' },
  cancelSubscription: { backendKey: 'cancelSubscription', type: 'Boolean' },
})
export type UpdateTeamArgs = Omit<UpdateTeamVolParams, 'clientInfo'>
export async function updateTeam(params: UpdateTeamArgs): Promise<UpdateTeamResult> {
  const clientInfo = await getClientInfo()
  const res = await mutateGraphQl<UpdateTeamVolResult, UpdateTeamVolParams>({
    mutation: buildMutation(params, UpdateTeamVolMutation),
    variables: { ...params, clientInfo },
  })

  UpdateTeamVolMutation.checkForErrors(res)

  return deserializeUpdateResults(JSON.parse(res.data?.updateTeamVol ?? '{}'))
}

function deserializeUpdateResults(data?: UpdateTeamVol_Data): UpdateTeamResult {
  return {
    billingUrl: data?.billingPortalURL,
    sellerData: deserializeSellerData(data?.sellerData),
    upgradeUrl: data?.redirectURL,
  }
}

function deserializeSellerData(data?: UpdateTeamVol_SellerData): SellerData | null {
  if (!data) {
    return null
  }

  return {
    analyticsUrl: data.analyticsURL,
  }
}

function deserializeTeams(teams: GetTeamVol_TeamDictionary): TeamsDictionary {
  return Object.entries(teams).reduce<TeamsDictionary>((dict, [id, team]) => {
    dict[id] = deserializeTeam(team)

    return dict
  }, {})
}

function deserializeTeam(team: GetTeamVol_Team): Team {
  return {
    id: team.id,
    accessLevel: team.accessLevel,
    billingUrl: team.billingPortalURL,
    channels: deserializeChannels(team.channels),
    conversationIds: team.conversationIDs || [],
    createChannelByAdminOnly: team.createChannelByAdminOnly ?? false,
    createdOn: team.createdOn,
    creatorUserId: team.creatorUserID,
    disableDownloading: !!team.disableDownloading,
    disableForwarding: !!team.disableForwarding,
    disableSharing: !!team.disableSharing,
    guestConversations: deserializeGuestConversations(team.guestConvs),
    guestIds: team.guestIDs || [],
    inviteByAdminOnly: team.inviteByAdminOnly ?? false,
    isAdhoc: team.isAdhoc,
    isPremiumActive: team.isPremiumActive ?? false,
    isPublic: team.isPublic ?? false,
    name: team.name,
    offers: team.offerings ? team.offerings.map(o => deserializeOffer(o)) : [],
    premiumSeedConversationId: team.premiumSeedConversationID ?? null,
    premiumLandingTokenId: team.premiumLandingTokenID ?? null,
    purchases: team.purchases ? team.purchases.map(p => deserializePurchase(p)) : [],
    sellerData: null,
    teamAdminIds: team.teamAdminIDs || [],
    thumbEol: team.thumbEOL ?? null,
    thumbUpdatedOn: team.thumbUpdatedOn,
    thumbUrl: team.thumbURL ?? null,
    tierDescription: team.tierShortDesc,
    tierEol: team.tierEOL ?? null,
    tierId: team.tierID,
    tierLimits: deserializeTierLimits(team.tierLimits),
    tierWillRenew: team.tierWillRenew ?? false,
    tokensCreated: team.tokensCreated || [],
    userIds: team.userIDs || [],
    vState: team.vState,
    welcomeConversationId: team.welcomeConversationID ?? null,
  }
}

function createAdhocTeam(): Team {
  return deserializeTeam({
    id: ADHOC_TEAM_ID,
    accessLevel: TeamAccessLevel.Full,
    channels: null,
    conversationIDs: null,
    createdOn: null,
    creatorUserID: 'volley',
    createChannelByAdminOnly: true,
    disableDownloading: null,
    disableForwarding: null,
    disableSharing: null,
    guestIDs: null,
    inviteByAdminOnly: false,
    isAdhoc: true,
    isPublic: false,
    name: 'Direct Messages',
    offerings: [],
    purchases: [],
    teamAdminIDs: null,
    thumbUpdatedOn: null,
    tierID: 'FREE',
    tierShortDesc: 'Free',
    tokensCreated: null,
    userIDs: null,
    vState: TeamState.Active,
    welcomeConversationID: null,
  })
}

function deserializeChannels(channels: GetTeamVol_Channels | null | undefined): TeamChannelsDictionary {
  if (!channels) {
    return {}
  }

  return Object.entries(channels).reduce<TeamChannelsDictionary>((dict, [channelId, channel]) => {
    dict[channelId] = {
      description: channel.description ?? null,
      id: channelId,
      isAutoJoin: channel.isAutoJoin ?? false,
      isEveryone: channel.isEveryone ?? false,
      name: channel.name,
      preventDeletion: channel.preventDeletion ?? false,
      preventUsersLeaving: channel.preventUsersLeaving ?? false,
      thumbEmoji: channel.thumbEmoji ?? null,
      thumbEol: channel.thumbEOL ?? null,
      thumbUrl: channel.thumbURL ?? null,
      userCountString: channel.userCountString ?? '0',
    }

    return dict
  }, {})
}

function deserializeGuestConversations(guestConvos: GuestConversationsDictionary | null | undefined): GuestConversationsDictionary {
  if (!guestConvos) {
    return {}
  }

  return Object.entries(guestConvos).reduce<GuestConversationsDictionary>((dict, [conversationId, guestIds]) => {
    if (guestIds.length) {
      dict[conversationId] = guestIds
    }

    return dict
  }, {})
}

type DeleteTeamVolParams = { clientInfo: string, teamId: string }
type DeleteTeamVolResult = { deleteTeamVol: string | null }
const DeleteTeamVolMutation = gql`
  mutation DeleteTeamVol($clientInfo: String, $teamId: ID!) {
    deleteTeamVol(clientInfo: $clientInfo, teamID: $teamId)
  }
`
export async function deleteTeam(teamId: string): Promise<void> {
  const results = await mutateGraphQl<DeleteTeamVolResult, DeleteTeamVolParams>({
    mutation: DeleteTeamVolMutation,
    variables: { clientInfo: await getClientInfo(), teamId },
  })

  checkForErrors('deleteTeamVol', results, true)
}

type GetTeamRoster_Member = {
  billingCycleEndsOn: string
  email: string
  familyName: string
  givenName: string
  isTeamAdmin: boolean
  joinedOn: string
  userID: string
  offeringVType: OfferType
  purchaseID: string
  purchaseVState: PurchaseState
}
type GetTeamRoster_Data = {
  rosterCsv?: string
  roster?: GetTeamRoster_Member[]
}
type GetTeamRosterParams = { clientInfo: string, getCsv?: boolean, teamId: string }
type GetTeamRosterResult = { getTeamRoster: string | null }
// TODO: Add `returnAsCSV` mutation param when available
const GetTeamRoster = gql`
  mutation GetTeamRoster($clientInfo: String, $teamId: ID!) {
    getTeamRoster(clientInfo: $clientInfo, teamID: $teamId)
  }
`
export async function getTeamRoster(teamId: string, getCsv?: boolean): Promise<TeamRoster> {
  const result = await mutateGraphQl<GetTeamRosterResult, GetTeamRosterParams>({
    mutation: GetTeamRoster,
    variables: { clientInfo: await getClientInfo(), getCsv, teamId }
  })

  checkForErrors('getTeamRoster', result, true)
  return deserializeTeamRoster(JSON.parse(result.data?.getTeamRoster ?? '{}'))
}

function deserializeTeamRoster(data: GetTeamRoster_Data): TeamRoster {
  return {
    roster: data.roster?.reduce<TeamRosterMembersDictionary>((acc, m) => {
      const member = deserializeTeamRosterMember(m)
      acc[member.id] = member

      return acc
    }, {}) ?? {},
    rosterCsv: data.rosterCsv,
  }
}

function deserializeTeamRosterMember(data: GetTeamRoster_Member): TeamRosterMember {
  return {
    billingCycleEndsOn: data.billingCycleEndsOn,
    email: data.email,
    familyName: data.familyName,
    givenName: data.givenName,
    id: data.userID,
    isTeamAdmin: data.isTeamAdmin,
    joinedOn: data.joinedOn,
    offerType: data.offeringVType,
    purchaseId: data.purchaseID,
    purchaseState: data.purchaseVState,
  }
}
